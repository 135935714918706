import { ISchemaExplorerType } from "@/api/schema";
import { IEnumState, IObjectTypeState } from "@/models";
import VueI18n from "vue-i18n";
import { MandatoryFieldsConfiguration } from "../../models/api-schema/mandatory-fields-configuration";
import { ApiSchema } from "@/models/api-schema";

type IBaseFieldConfig = {
  name: string;
  type: string;
  requiredOnCreate: boolean;
  requiredOnUpdate: boolean;
  isTargetConnectionField: boolean;
  isParentConnectionField: boolean;
  isMandatory: boolean;
  inputType: string;
  isBlacklisted: boolean;
  isReadOnly: boolean;
  isList: boolean;
  inputMaxLength: number;
  isConfigurable: boolean;
};

type IExtendedFieldConfig = IBaseFieldConfig & {
  key: string;
  placeholder: string;
  labelAmendment: string;
};

type IStringFieldConfig = IExtendedFieldConfig & {
  variant: "string";
};

type IBooleanFieldConfig = IExtendedFieldConfig & {
  variant: "boolean";
  selectOptions: { value: any; text: any }[];
};

type IEnumFieldConfig = IExtendedFieldConfig & {
  variant: "enum";
  selectOptions: { value: any; text: any }[];
};

type INumberFieldConfig = IExtendedFieldConfig & {
  variant: "number";
};

type IListFieldConfig = IExtendedFieldConfig & {
  variant: "list";
};

type IJsonFieldConfig = IExtendedFieldConfig & {
  variant: "json";
};

export type IFieldConfig =
  | IStringFieldConfig
  | IBooleanFieldConfig
  | IEnumFieldConfig
  | INumberFieldConfig
  | IListFieldConfig
  | IJsonFieldConfig;

export default class FieldsConfiguration {
  public readonly mandatoryFieldsConfiguration: MandatoryFieldsConfiguration;

  constructor(
    private readonly apiSchema: ApiSchema,
    private readonly objectType: IObjectTypeState,
    private readonly graphqlFieldTypes: Record<string, ISchemaExplorerType>,
    private readonly enums: IEnumState[] = [],
    private readonly shouldCreateNewEntry: boolean,
    private readonly $t: (
      key: string,
      values?: VueI18n.Values | undefined
    ) => VueI18n.TranslateResult
  ) {
    this.mandatoryFieldsConfiguration = new MandatoryFieldsConfiguration(
      graphqlFieldTypes,
      this.objectType
    );
  }

  public get readonlyKeys() {
    return this.apiSchema.readonlyKeys;
  }

  public get idKey() {
    return this.apiSchema.idKey;
  }

  public get blacklistedKeys() {
    return this.apiSchema.blacklistedKeys;
  }

  public get fields(): IFieldConfig[] {
    return this.apiSchema
      ? this.apiSchema.types[this.objectType?.name]?.fields?.map(
          (field, index) =>
            this.getFieldConfig(
              field.name,
              field.type,
              index,
              field.isList,
              field.isEnum,
              field.isBoolean,
              field.isParentConnectionField,
              field.isTargetConnectionField,
              field.isReadOnlyField,
              field.isConfigurable
            )
        )
      : [];
  }

  private getFieldConfig(
    name: string,
    type: string,
    index: number,
    isList: boolean,
    isEnum: boolean,
    isBoolean: boolean,
    isParentConnectionField: boolean,
    isTargetConnectionField: boolean,
    isReadOnlyField: boolean | undefined,
    isConfigurable: boolean | undefined
  ): IFieldConfig {
    const requiredOnCreate =
      this.mandatoryFieldsConfiguration.createInputFieldKeys.has(name);
    const requiredOnUpdate =
      this.mandatoryFieldsConfiguration.updateInputFieldKeys.has(name);
    const isReadOnly =
      isReadOnlyField ||
      (name === this.apiSchema.idKey && !this.shouldCreateNewEntry);
    const isNumber = ["Int", "Float", "AWSTimestamp"].includes(type);
    const isJson = ["Json", "AWSJSON"].includes(type);

    const baseFieldConfig: IBaseFieldConfig = {
      name,
      type,
      requiredOnCreate,
      requiredOnUpdate,
      isTargetConnectionField,
      isParentConnectionField,
      isMandatory: this.shouldCreateNewEntry
        ? requiredOnCreate
        : requiredOnUpdate,
      inputType: name ?? "unknown",
      isBlacklisted: this.apiSchema.blacklistedKeys.includes(name),
      isReadOnly,
      isList,
      inputMaxLength: type === "Int" ? 11 : 1024,
      isConfigurable: isConfigurable ?? false,
    };

    const extendedFieldConfig: IExtendedFieldConfig = {
      ...baseFieldConfig,
      placeholder: this.placeholderForField(baseFieldConfig),
      key: this.keyForField(baseFieldConfig, index),
      labelAmendment: this.labelAmendmentForField(baseFieldConfig),
    };

    if (isBoolean) {
      return {
        ...extendedFieldConfig,
        variant: "boolean",
        selectOptions: this.booleanOptions,
      };
    }

    if (isJson) {
      return {
        ...extendedFieldConfig,
        variant: "json",
      };
    }

    if (isEnum) {
      return {
        ...extendedFieldConfig,
        variant: "enum",
        selectOptions: this.selectOptionsForField(baseFieldConfig),
      };
    }

    if (isNumber) {
      return {
        ...extendedFieldConfig,
        variant: "number",
      };
    }

    return {
      ...extendedFieldConfig,
      variant: "string",
    };
  }

  public get sortedFields() {
    return this.fields.sort((el1, el2) => {
      if (el1.name.toLowerCase() === "id") {
        return -1;
      } else if (el2.name.toLowerCase() === "id") {
        return 1;
      }

      if (el1.name.toLowerCase() === "createdat") {
        return -1;
      } else if (el2.name.toLowerCase() === "createdat") {
        return 1;
      }

      if (el1.name.toLowerCase() === "updatedat") {
        return -1;
      } else if (el2.name.toLowerCase() === "updatedat") {
        return 1;
      }

      return 1;
    });
  }

  public get parentConnectionFields() {
    return this.sortedFields.filter((field) => field.isParentConnectionField);
  }

  public get targetConnectionFields() {
    return this.sortedFields.filter((field) => field.isTargetConnectionField);
  }

  public get primitiveFields() {
    return this.sortedFields.filter(
      (field) =>
        !field.isParentConnectionField &&
        !field.isTargetConnectionField &&
        !this.apiSchema.blacklistedKeys.includes(field.name)
    );
  }

  private booleanOptions = [
    { value: true, text: true },
    { value: false, text: false },
  ];

  private selectOptionsForField(
    field: IBaseFieldConfig
  ): { value: any; text: any }[] {
    const enumName = field.type;
    if (this.enums.length > 0) {
      const enumValues =
        this.enums.find((enumDefinition) => enumDefinition.name === enumName)
          ?.values ?? [];

      return enumValues?.map((fieldType) => ({
        value: fieldType.name,
        text: fieldType.name,
      }));
    }
    const enumValues = this.graphqlFieldTypes[enumName]?.enumValues ?? [];

    return enumValues?.map((fieldType: ISchemaExplorerType) => {
      return {
        value: fieldType.name,
        text: fieldType.name,
      };
    });
  }

  protected labelAmendmentForField(field: IBaseFieldConfig): string {
    const { type, isMandatory, isList } = field;

    return `${isList ? "[" : ""}${type}${isList ? "]" : ""}${
      isMandatory ? " !" : ""
    }`;
  }

  private keyForField(field: IBaseFieldConfig, index: number): string {
    return `${this.objectType?.name}-${field.name}-${index}-${field.type}`;
  }

  private canFieldBeAutogenerated(field: IBaseFieldConfig) {
    return field.isReadOnly || field.name === this.apiSchema.idKey;
  }

  private placeholderForField(field: IBaseFieldConfig): string {
    return `${this.$t("form_placeholder_data_entry", { key: field.name })}${
      this.canFieldBeAutogenerated(field)
        ? ` ${this.$t("form_placeholder_optional")}`
        : ""
    }`;
  }
}
