import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import style from "./ObjectView.component.module.scss";
import * as tsx from "vue-tsx-support";
import posed from "vue-pose";
import closeIconBlack from "@/assets/img/icon-close-black.svg";
import closeIconWhite from "@/assets/img/icon-close-white.svg";
import { getErrorMessage, idFromObjectName } from "@/utils";
import { Loader } from "../shared/Loader.component";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import { IEnumState, IObjectTypeState } from "@/models";
import FieldsConfiguration from "./fields-configuration";
import { ApiSchema } from "@/models/api-schema";
import { IGraphqlApi } from "@/api-client/shared/types";
import { BAlert } from "@/lib/typed-bootstrap";

const AnimatedDiv = posed.div({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
    transition: { duration: 300 },
  },
});

const closeIconSrc = {
  dark: closeIconWhite,
  light: closeIconBlack,
};

@Component
export class ObjectView extends Vue {
  _tsx!: tsx.DeclareProps<{
    isOnly?: ObjectView["isOnly"];
    objectType: ObjectView["objectType"];
    typeName: ObjectView["typeName"];
    recordId: ObjectView["recordId"];
    customerApi: ObjectView["customerApi"];
    enumTypes?: ObjectView["enumTypes"];
    apiSchema: ObjectView["apiSchema"];
    prevObjectTypeName: ObjectView["prevObjectTypeName"];
    prevObjectRecordId: ObjectView["prevObjectRecordId"];
  }> &
    tsx.DeclareOnEvents<{
      onClose: void;
    }>;
  $scopedSlots!: tsx.InnerScopedSlots<{
    view?: {
      data: object;
      fieldsConfiguration: FieldsConfiguration;
      onDataEntryError: (event: Event) => void;
    };
  }>;

  @Prop({ default: false }) isOnly?: boolean;
  @Prop({ required: true }) objectType: IObjectTypeState;
  @Prop({ required: true }) typeName: string;
  @Prop({ required: true }) recordId: string;
  @Prop({ required: true }) prevObjectTypeName: string;
  @Prop({ required: true }) prevObjectRecordId: string;
  @Prop({ required: true }) customerApi: IGraphqlApi;
  @Prop({ default: () => [] }) enumTypes: IEnumState[];
  @Prop({ required: true }) apiSchema: ApiSchema;

  private data: object = {};
  private isLoading: boolean = false;
  private errorMessage: string = "";
  private subscription: ZenObservable.Subscription | null = null;

  @Emit("close") public onClose() {}

  get fieldsHash() {
    return JSON.stringify(
      this.customerApi.schemaFieldTypes?.[this.typeName]?.fields
    );
  }

  private get fieldsConfiguration() {
    return Vue.observable(
      new FieldsConfiguration(
        this.apiSchema,
        this.objectType,
        this.customerApi.schemaFieldTypes,
        this.enumTypes,
        !this.recordId,
        this.$t.bind(this)
      )
    );
  }

  private get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  @Watch("recordId")
  @Watch("fieldsHash")
  @Watch("typeName", { immediate: true })
  async onDependentFieldsChange() {
    this.errorMessage = "";
    this.subscription?.unsubscribe();

    if (!this.recordId) {
      this.data = await this.getNewObjectEntry();
      return;
    }

    this.isLoading = true;
    this.subscription = this.customerApi
      .watchQueryObject(this.objectType, this.recordId)
      .subscribe({
        next: (data) => {
          this.data = this.extendWithMissingFields(data);
          this.isLoading = false;
        },
        error: (e) => {
          this.errorMessage = getErrorMessage(e);
          this.isLoading = false;
        },
      });
  }

  handleError(event: Event) {
    this.errorMessage = getErrorMessage(event);
  }

  private async getNewObjectEntry() {
    return Object.fromEntries(
      this.fieldsConfiguration.sortedFields.map((field) => {
        return field.name === idFromObjectName(this.prevObjectTypeName)
          ? [field.name, this.prevObjectRecordId]
          : [field.name, null];
      })
    );
  }

  private extendWithMissingFields(data: object) {
    const newData: Record<string, unknown> = { ...data };

    this.fieldsConfiguration.sortedFields.forEach((field) => {
      if (!newData[field.name]) {
        newData[field.name] = null;
      }
    });

    return newData;
  }

  get ctaPrefix() {
    return this.recordId
      ? "global_cta_edit_model_entry"
      : "global_cta_add_model_entry";
  }

  render() {
    const { isOnly, typeName, data, isLoading, ctaPrefix } = this;

    return (
      <AnimatedDiv
        class={{
          [style.objectView]: true,
          [style.isOnly]: isOnly,
        }}
      >
        <div class={style.header}>
          <div class={style.headerNavbar}>
            <div class={style.title} data-testid="objectViewTitle">
              <i18n path={ctaPrefix}>
                <template slot="model">{typeName}</template>
              </i18n>
            </div>
            <button class={style.closeButton} onClick={this.onClose}>
              <ThemedImage src={closeIconSrc} class={style.icon} />
            </button>
          </div>
          <BAlert show={this.showErrorMessage} variant="danger">
            {this.errorMessage}
          </BAlert>
        </div>
        <div class={style.content}>
          {isLoading ? (
            <div class={style.loader}>
              <Loader />
            </div>
          ) : (
            this.$scopedSlots.view?.({
              data,
              fieldsConfiguration: this.fieldsConfiguration,
              onDataEntryError: this.handleError,
            })
          )}
        </div>
      </AnimatedDiv>
    );
  }
}
