import { Component, Prop } from "vue-property-decorator";
import { OrgModule } from "@/store/modules/org";
import { createRoleMutation, updateRoleMutation } from "@/api/role";

import FormEmail from "@/components/forms/inputs/FormEmail.component.vue";
import FormSelect from "@/components/forms/inputs/FormSelect.component";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import { RoleTypeEnum } from "@/api/common-types";

@Component({
  components: { FormEmail, FormSelect },
})
export default class OrgUserInvite extends UserPermissions {
  @Prop({ required: false }) readonly email: string | undefined;
  @Prop({ required: false }) readonly role: string | undefined;
  @Prop({ required: false }) readonly roleId: string | undefined;

  isLoading = false;
  errorMessage = "";
  form = null;
  selectedRole: string | null = null;
  state = null;
  roleOptions = [
    { value: null, text: this.$t("org_users_invite_role_placeholder") },
    {
      value: "OWNER",
      text: this.$t("org_users_invite_role_owner"),
      disabled: true,
    },
    {
      value: "ADMIN",
      text: this.$t("org_users_invite_role_admin"),
      disabled: true,
    },
    {
      value: "CONTENT",
      text: this.$t("org_users_invite_role_content"),
      disabled: true,
    },
    {
      value: "DEV",
      text: this.$t("org_users_invite_role_dev"),
      disabled: true,
    },
    {
      value: "GUEST",
      text: this.$t("org_users_invite_role_guest"),
      disabled: true,
    },
  ];

  inviteeEmail = "";

  get isEditMode(): boolean {
    return this.$route.params.action === "edit";
  }

  get buttonCopy(): string {
    return this.isEditMode ? "Update" : this.$t("form_cta_invite").toString();
  }

  get emailEditingIsDisabled(): boolean {
    return this.isEditMode ? true : false;
  }

  get shouldValidateEmail(): boolean {
    return this.isEditMode ? false : true;
  }

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  async onSubmit(): Promise<void> {
    try {
      if (!this.selectedRole) {
        throw new Error(this.$t("global_org_invite_form_error").toString());
      }

      if (!this.inviteeEmail) {
        throw new Error(this.$t("global_email_error_format_email").toString());
      }

      if (this.selectedRole) {
        this.isLoading = true;
        if (this.isEditMode) {
          if (this.roleId) {
            await updateRoleMutation(
              {
                input: {
                  id: this.roleId,
                  type: this.selectedRole as RoleTypeEnum,
                },
              },
              this.orgId,
              true
            );
          }
        } else {
          const input = {
            userEmail: this.inviteeEmail,
            type: this.selectedRole,
          };

          await createRoleMutation(input);
        }
        this.$router.push({
          name: "org-users",
          params: { orgId: OrgModule.id },
        });
        this.resetFormData();
        this.isLoading = false;
      }
    } catch (err) {
      this.errorMessage = (err as any).message;
      this.isLoading = false;
    }
  }

  mounted() {
    this.initialize();
  }

  initialize() {
    this.inviteeEmail = this.email ?? "";
    this.selectedRole = this.role ?? null;

    if (this.canManageOrg) {
      this.roleOptions.forEach((item) => {
        item.disabled = false;
      });
    }
  }

  resetFormData(): void {
    this.selectedRole = null;
  }
}
