import { Vue, Component } from "vue-property-decorator";
import { Loader } from "./Loader.component";
import { BContainer } from "bootstrap-vue";
import style from "./LoadingScreen.component.module.scss";

@Component
export class LoadingScreen extends Vue {
  showLoader = false;
  timeout: number | null = null;

  mounted() {
    this.timeout = window.setTimeout(() => {
      this.showLoader = true;
    }, 100);
  }

  beforeDestroy() {
    if (this.timeout !== null) {
      window.clearTimeout(this.timeout);
    }
  }

  render() {
    return (
      <BContainer class={style.container}>
        {this.showLoader && (
          <div class={["text-center", style.loaderContainer]}>
            <Loader />
          </div>
        )}
      </BContainer>
    );
  }
}
