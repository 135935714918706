import { ApolloClient, MutationOptions } from "apollo-boost";
import { ApiMutation } from "./api-mutation";
import { FieldNameComposer } from "../shared/fieldname-composer";
import { MutationTypeEnum } from "@graphapi-io/api-declaration";
import { GqlMutationComposer } from "./gql-mutation-composer";

export class ApiDeleteMutations {
  private customerApiMutation: ApiMutation;

  constructor(private apolloClient: ApolloClient<unknown>) {
    this.customerApiMutation = new ApiMutation(
      MutationTypeEnum.DELETE,
      this.apolloClient
    );
  }

  public async deleteObject(
    typeName: string,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.customerApiMutation.mutate(
      GqlMutationComposer.buildDeleteMutation(typeName),
      variables,
      FieldNameComposer.fieldNameForMutationType(
        MutationTypeEnum.DELETE,
        typeName
      ),
      updateCache
    );
  }

  public async batchDeleteObjects(
    typeName: string,
    variables: { input: Record<string, unknown>[] },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.customerApiMutation.batchMutate(
      GqlMutationComposer.buildBatchDeleteMutation(typeName),
      variables,
      FieldNameComposer.fieldNameForMutationType(
        MutationTypeEnum.BATCH_DELETE,
        typeName
      ),
      updateCache
    );
  }
}
