import {
  ICustomerListByVariables,
  IVariablesParser,
  ofType,
} from "../shared/types";
import {
  BaseFieldTypeEnum,
  ComplexFieldTypeEnum,
  DateFieldTypeEnum,
  IObjectTypeState,
} from "@/models";
import { MutationTypeEnum } from "@graphapi-io/api-declaration";
import { ApiSchema, IFieldConfig } from "@/models/api-schema";
import { idFromObjectName } from "@/utils";

const reservedFields: Record<string, boolean> = {
  id: true,
  createdAt: true,
  updatedAt: true,
  pbac0sub: true,
  gsi1sk: true,
  gsi2sk: true,
  gsi0sortby: true,
  pk: true,
  sk: true,
  fields: true,
  connections: true,
  directives: true,
  ofType: true,
  namespace: true,
  version: true,
};

export class GenericApiVariableParser implements IVariablesParser {
  constructor(
    private apiSchema: ApiSchema,
    private objectTypes: IObjectTypeState[]
  ) {}

  public static normalizedTypeName(typeName: string) {
    return typeName.toLowerCase();
  }

  public parseVariablesForListByQuery(
    variables: ICustomerListByVariables,
    typeName: string,
    parentTypeName: string
  ) {
    const typeConfig = this.apiSchema.types[typeName];
    const parentKey =
      typeConfig.secondParent === parentTypeName ? "SECOND" : "FIRST";

    const { id, ...rest } = variables;

    return {
      parentId: id,
      parentKey,
      ...rest,
    };
  }

  public buildMutationVariables(
    variant: MutationTypeEnum,
    variables: { input: Record<string, unknown> },
    objectTypeName: string,
    objectTypeId: string,
    namespace: string
  ) {
    if (
      variant === MutationTypeEnum.DELETE ||
      variant === MutationTypeEnum.BATCH_DELETE
    ) {
      return this.buildDeleteMutationInput(variables, objectTypeId, namespace);
    }
    const fields = this.apiSchema.types[objectTypeName].fields;
    return this.buildUpdateOrCreateMutationVariables(
      variables,
      objectTypeName,
      objectTypeId,
      namespace,
      fields
    );
  }

  private buildUpdateOrCreateMutationVariables(
    variables: { input: Record<string, unknown> },
    objectTypeName: string,
    objectTypeId: string,
    namespace: string,
    fields: IFieldConfig[]
  ) {
    const input = variables.input;
    const directives: { sortby?: string } = {};
    const reservedFieldsResult: Record<string, unknown> = {};
    const mergeResult: Record<string, unknown> = {};
    const fieldsMap = Object.fromEntries(
      fields.map((field) => {
        if (field.isSortField) {
          directives.sortby = field.name;
        }

        return [field.name, field];
      })
    );
    const connections: {
      first?: {
        link: string;
        with: string;
      };
      second?: {
        link: string;
        with: string;
      };
    } = {};

    const firstParentName = this.apiSchema.types[objectTypeName].firstParent;
    const firstParentObject = this.objectTypes.find(
      (t) => t.name.toLowerCase() === firstParentName?.toLowerCase()
    );
    const secondParentName = this.apiSchema.types[objectTypeName].secondParent;
    const secondParentObject = this.objectTypes.find(
      (t) => t.name.toLowerCase() === secondParentName?.toLowerCase()
    );
    const firstParent = firstParentName
      ? this.apiSchema.types[firstParentName]
      : undefined;
    const secondParent = secondParentName
      ? this.apiSchema.types[secondParentName]
      : undefined;

    const firstParentConnectorField =
      firstParentName && firstParent
        ? idFromObjectName(firstParentName, firstParent.connector)
        : null;
    const secondParentConnectorField =
      secondParentName && secondParent
        ? idFromObjectName(secondParentName, secondParent.connector)
        : null;

    for (const key in input) {
      if (reservedFields[key]) {
        reservedFieldsResult[key] = input[key];
      } else {
        const type = fieldsMap[key]?.type;
        const value = input[key];

        if (key === firstParentConnectorField) {
          connections.first = {
            link: GenericApiVariableParser.normalizedTypeName(
              firstParentObject
                ? ofType(firstParentObject)
                : (firstParentName as string)
            ),
            with: firstParentConnectorField,
          };
        }
        if (key === secondParentConnectorField) {
          connections.second = {
            link: GenericApiVariableParser.normalizedTypeName(
              secondParentObject
                ? ofType(secondParentObject)
                : (secondParentName as string)
            ),
            with: secondParentConnectorField,
          };
        }

        if (type === ComplexFieldTypeEnum.Json && typeof value === "string") {
          mergeResult[key] = JSON.parse(value);
        } else if (
          type === BaseFieldTypeEnum.Integer ||
          type === BaseFieldTypeEnum.Float ||
          type === DateFieldTypeEnum.Timestamp
        ) {
          mergeResult[key] = +(value as string);
        } else {
          mergeResult[key] = value;
        }
      }
    }

    return {
      input: {
        ...reservedFieldsResult,
        fields: JSON.stringify(mergeResult),
        connections: JSON.stringify(connections),
        directives: JSON.stringify(directives),
        ofType: GenericApiVariableParser.normalizedTypeName(objectTypeId),
        namespace,
      },
    };
  }

  private buildDeleteMutationInput = (
    variables: { input: Record<string, unknown> },
    objectTypeId: string,
    namespace: string
  ) => {
    return {
      input: {
        id: variables.input.id,
        ofType: GenericApiVariableParser.normalizedTypeName(objectTypeId),
        namespace,
      },
    };
  };
}
