import * as tsx from "vue-tsx-support";
import {
  BBadge as UntypedBBadge,
  BButton as UntypedBButton,
  BCol as UntypedBCol,
  BContainer as UntypedBContainer,
  BDropdown as UntypedBDropdown,
  BDropdownForm as UntypedBDropdownForm,
  BDropdownHeader as UntypedBDropdownHeader,
  BDropdownDivider as UntypedBDropdownDivider,
  BFormCheckbox as UntypedBFormCheckbox,
  BFormFile as UntypedBFormFile,
  BFormGroup as UntypedBFormGroup,
  BFormInput as UntypedBFormInput,
  BFormSelect as UntypedBFormSelect,
  BFormSelectOption as UntypedBFormSelectOption,
  BSidebar as UntypedBSidebar,
  BSpinner as UntypedBSpinner,
  BRow as UntypedBRow,
  BFormRadioGroup as UntypedBFormRadioGroup,
  BFormRadio as UntypedBFormRadio,
  BAlert as UntypedBAlert,
  BFormTextarea as UntypedBFormTextarea,
  BForm as UntypedBForm,
  BModal as UntypedBModal,
  BCard as UntypedBCard,
  BCardText as UntypedBCardText,
  BFormInvalidFeedback as UntypedBFormInvalidFeedback,
  BCardGroup as UntypedBCardGroup,
  BPagination as UntypedBPagination,
  BProgress as UntypedBProgress,
  BDropdownText as UntypedBDropdownText,
} from "bootstrap-vue";

type VueClass = string | string[] | { [key: string]: boolean };

export interface IBButtonEvents {
  onClick: MouseEvent;
}

export const BButton = tsx
  .ofType<
    { disabled?: boolean; type?: string; size?: string; block?: boolean },
    IBButtonEvents
  >()
  .convert(UntypedBButton);

export interface IBDropdownEvents {
  onShow: void;
  onShown: void;
  onHide: void;
  onHidden: void;
}

export const BDropdown = tsx
  .ofType<
    {
      disabled?: boolean;
      toggleClass?: string;
      menuClass?: string;
      lazy?: boolean;
    },
    IBDropdownEvents
  >()
  .convert(UntypedBDropdown);

export const BDropdownForm = tsx
  .ofType<{
    role?: string;
  }>()
  .convert(UntypedBDropdownForm);

export const BDropdownHeader = tsx
  .ofType<{
    role?: string;
  }>()
  .convert(UntypedBDropdownHeader);

export const BFormCheckbox = tsx
  .ofType<{
    checked: boolean;
    name: string;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    switch?: boolean;
    size?: string;
  }>()
  .convert(UntypedBFormCheckbox);

export const BFormGroup = tsx
  .ofType<{
    invalidFeedback?: string;
    state?: boolean | null;
    labelFor?: string;
    label?: string;
    labelCols?: string;
  }>()
  .convert(UntypedBFormGroup);

export interface IBFormInputProps {
  "aria-invalid"?: boolean | string;
  autocomplete?: string;
  autofocus?: boolean;
  debounce?: number | string;
  disabled?: boolean;
  form?: string;
  formatter?: (value: string) => string;
  id?: string;
  lazy?: boolean;
  "lazy-formatter"?: boolean;
  list?: string;
  maxLength?: number;
  max?: string | number;
  min?: string | number;
  name?: string;
  "no-wheel"?: boolean;
  number?: boolean;
  placeholder?: string;
  plaintext?: boolean;
  readonly?: boolean;
  required?: boolean;
  size?: string;
  state?: boolean | null;
  step?: number | string;
  trim?: boolean;
  type?: string;
  value?: number | string;
}

export interface IBFormInputEvents {
  onBlur: FocusEvent;
  onChange: string | number;
  onInput: string | number;
  onUpdate: string | number;
}

export const BFormInput = tsx
  .ofType<IBFormInputProps, IBFormInputEvents>()
  .convert(UntypedBFormInput);

export type IBFormSelectOption<T> =
  | {
      value: T | null;
      text: string;
      disabled?: boolean;
    }
  | {
      label?: string;
      options: IBFormSelectOption<T>[];
    };

export interface IBFormSelectProps<T> {
  "aria-invalid"?: boolean | string;
  autofocus?: boolean;
  disabled?: boolean;
  form?: string;
  id?: string;
  name?: string;
  options: IBFormSelectOption<T>[];
  required?: boolean;
  size?: string;
  state?: boolean | null;
  value?: T | null;
}

export interface IBFormSelectEvents<T> {
  onChange: T;
  onInput: T;
}

export const BFormSelect = tsx
  .ofType<IBFormSelectProps<any>, IBFormSelectEvents<any>>()
  .convert(UntypedBFormSelect);

export const getTypedBFormSelect = <T>() =>
  tsx
    .ofType<IBFormSelectProps<T>, IBFormSelectEvents<T>>()
    .convert(UntypedBFormSelect);

export interface IBFormSelectOptionProps<T> {
  disabled?: boolean;
  value?: T | null;
  text?: string;
}

export const BFormSelectOption = tsx
  .ofType<IBFormSelectOptionProps<any>>()
  .convert(UntypedBFormSelectOption);

export const getTypedBFormSelectOption = <T>() =>
  tsx.ofType<IBFormSelectOptionProps<T>>().convert(UntypedBFormSelectOption);

export const BSidebar = tsx
  .ofType<
    {
      id?: string;
      bgVariant?: "dark" | "light";
      textVariant?: "dark" | "light";
      title?: string;
      right?: boolean;
      shadow?: boolean;
      noHeader?: boolean;
      backdrop?: boolean;
      lazy?: boolean;
      visible?: boolean;
      width?: string;
      sidebarClass?: string;
      noSlide?: boolean;
    },
    {
      onShown: void;
      onHidden: void;
    }
  >()
  .convert(UntypedBSidebar);

export const BSpinner = tsx
  .ofType<{
    variant?: string;
    type?: string;
    label?: string;
    small?: boolean;
    show?: boolean;
  }>()
  .convert(UntypedBSpinner);

export const BCol = tsx
  .ofType<{
    cols?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  }>()
  .convert(UntypedBCol);

export const BRow = tsx
  .ofType<{
    alignV?: "start" | "center" | "end";
  }>()
  .convert(UntypedBRow);

export interface IBFormRadioOption<T> {
  value: T;
  text: string;
  disabled?: boolean;
}

export const getTypedBFormRadioGroup = <T>() =>
  tsx
    .ofType<
      {
        options: IBFormRadioOption<T>[];
        checked: T;
        disabled?: boolean;
      },
      {
        onChange: T;
      }
    >()
    .convert(UntypedBFormRadioGroup);

export const getTypedBFormRadio = <T>() =>
  tsx
    .ofType<
      {
        value: T;
        disabled?: boolean;
      },
      {
        onChange: T;
      }
    >()
    .convert(UntypedBFormRadio);

export const BAlert = tsx
  .ofType<{
    show?: boolean;
    variant?: string;
  }>()
  .convert(UntypedBAlert);

export const BBadge = tsx
  .ofType<{
    variant?: string;
  }>()
  .convert(UntypedBBadge);

export const BFormFile = tsx
  .ofType<
    {
      accept?: string;
      value?: File | File[] | null;
      placeholder?: string;
      dropPlaceholder?: string;
      multiple?: boolean;
    },
    {
      onInput: File | File[];
    },
    {
      "file-name": { names: string[] };
    }
  >()
  .convert(UntypedBFormFile);

export const BContainer = tsx
  .ofType<{ fluid?: boolean }>()
  .convert(UntypedBContainer);

export const BFormTextarea = tsx
  .ofType<{
    rows?: number;
    noResize?: boolean;
    placeholder?: string;
    value?: string;
    plaintext?: boolean;
  }>()
  .convert(UntypedBFormTextarea);

export interface BFormEvents {
  onSubmit: Event;
}

export const BForm = tsx
  .ofType<
    {
      role?: string;
      novalidate?: boolean;
      show?: boolean;
    },
    BFormEvents
  >()
  .convert(UntypedBForm);

export interface IBModalEvents {
  onShow: void;
  onHide: void;
  onHidden: void;
  onOk: Event;
}

export const BModal = tsx
  .ofType<
    {
      size?: string;
      modalClass?: VueClass;
      dialogClass?: VueClass;
      contentClass?: VueClass;
      bodyClass?: VueClass;
      headerClass?: VueClass;
      footerClass?: VueClass;
      title?: string;
      visible?: boolean;
    },
    IBModalEvents,
    {
      "modal-header"?: { close: () => void };
      "modal-footer"?: { cancel: () => void; ok: () => void };
      default?: { cancel: () => void; ok: () => void; visible: boolean };
    }
  >()
  .convert(UntypedBModal);

export const BCard = tsx
  .ofType<
    {
      bodyClass?: string;
    },
    {
      onClick: void;
    }
  >()
  .convert(UntypedBCard);

export const BCardText = tsx.ofType<{}>().convert(UntypedBCardText);

export const BFormInvalidFeedback = tsx
  .ofType<{
    state?: boolean | null;
  }>()
  .convert(UntypedBFormInvalidFeedback);

export const BCardGroup = tsx
  .ofType<{
    deck?: boolean;
  }>()
  .convert(UntypedBCardGroup);

export const BPagination = tsx
  .ofType<
    {
      value?: number;
      totalRows?: number;
      perPage?: number;
      hideGotoEndButtons?: boolean;
      align?: string;
      prevText?: string;
      nextText?: string;
      limit?: number;
    },
    {
      onChange: number;
    }
  >()
  .convert(UntypedBPagination);

export const BProgress = tsx
  .ofType<{
    value?: number;
  }>()
  .convert(UntypedBProgress);

export const BDropdownText = tsx.ofType<{}>().convert(UntypedBDropdownText);

export const BDropdownDivider = tsx
  .ofType<{}>()
  .convert(UntypedBDropdownDivider);
