import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { InputSizeEnum } from "../shared/Input.component";
import LabeledTextInput from "../shared/LabeledTextInput.component";
import { Button, ButtonThemeEnum } from "../shared/Button.component";
import styles from "./ApiDataObjectTypeForm.component.module.scss";
import { IApiState, IObjectTypeState, createObjectTypeBase } from "@/models";
import { BForm } from "@/lib/typed-bootstrap";
import { Loader } from "../shared/Loader.component";
import _cloneDeep from "lodash/cloneDeep";
import ApiTypesUpdater from "../object-type/ApiTypesUpdater.mixin";
import { mixins } from "vue-class-component";
import { ObjectTypeNameValidator } from "../object-type/ObjectTypeNameValidator.mixin";
import {
  omitInitialDigits,
  upperCaseFirstLetter,
  removeSpecialCharacters,
} from "@/utils";
import Modal from "../shared/Modal.component";

@Component
export default class ApiDataObjectTypeForm extends mixins(
  ObjectTypeNameValidator,
  ApiTypesUpdater
) {
  _tsx!: tsx.DeclareProps<{
    apiState?: ApiDataObjectTypeForm["apiState"];
    typeName?: ApiDataObjectTypeForm["typeName"];
  }> &
    tsx.DeclareOnEvents<{
      onCancel: void;
      onSubmit: IObjectTypeState;
    }>;
  @Prop({
    default: () => ({}),
  })
  apiState: IApiState;
  @Prop() typeName: string;

  @Emit("cancel") private onCancel() {}
  @Emit("submit") private onSubmit(_objectType: IObjectTypeState) {}

  private typeIndex: number = 0;

  @Watch("typeName", { immediate: true })
  onTypeNameChange() {
    if (this.typeName === undefined) {
      const latestNewTypeX = this.apiState.types.reduce((top, type) => {
        if (type.name.startsWith("NewTable")) {
          const num = parseInt(type.name.replace("NewTable", ""), 10);
          if (num > top) {
            return num;
          }
        }
        return top;
      }, 0);

      this.objectTypes = [
        ...this.apiState.types,
        createObjectTypeBase(
          `NewTable${latestNewTypeX + 1}`,
          this.apiState.usePrefix
        ),
      ];
      this.typeIndex = this.objectTypes.length - 1;
    } else {
      this.typeIndex = this.apiState.types.findIndex(
        (type) => type.name === this.typeName
      );
      this.objectTypes = [...this.apiState.types];
    }
  }

  private inputFieldFormatter(value: string): string {
    return omitInitialDigits(
      upperCaseFirstLetter(removeSpecialCharacters(value))
    );
  }

  private handleInput(value: string) {
    Vue.set(this.objectTypes, this.typeIndex, {
      ...this.objectTypes[this.typeIndex],
      name: value ?? "",
    });
  }

  private async handleSubmit(ev: Event) {
    ev.preventDefault();

    if (this.errorMessage) {
      return;
    }

    const newState = _cloneDeep(this.apiState);
    newState.types = this.objectTypes;

    await this.updateApiDeclarationFromState(newState);
    if (!this.serverSideErrors) {
      this.onSubmit(this.objectTypes[this.typeIndex]);
      this.apiState.types = newState.types;
    }
  }

  private get showErrors() {
    return !!this.errorMessage;
  }

  public render() {
    return (
      <BForm
        onSubmit={this.handleSubmit}
        role="form"
        novalidate
        class={styles.form}
      >
        <LabeledTextInput
          id="modelNameInput"
          labelText={this.$t("form_input_label_table").toString()}
          value={this.objectTypes?.[this.typeIndex]?.name}
          onInput={this.handleInput}
          inputSize={InputSizeEnum.md}
          formatter={this.inputFieldFormatter}
          autoFocus
          selectAllInitially
        />
        {this.showErrors && (
          <span class={styles.error}>{this.errorMessage}</span>
        )}
        <Modal.ActionButtons
          class={[
            styles.actionButtons,
            { [styles.withErrors]: this.showErrors },
          ]}
        >
          <Button
            onClick={this.onCancel}
            class={styles.button}
            theme={ButtonThemeEnum.naked}
            type="button"
          >
            {this.$t("global_cta_cancel")}
          </Button>
          <Button
            theme={ButtonThemeEnum.primary}
            class={styles.button}
            data-testid="uploadCloudFunction"
            type="submit"
            disabled={this.showErrors}
          >
            {this.isLoading ? (
              <Loader />
            ) : this.typeName === undefined ? (
              this.$t("global_cta_create")
            ) : (
              this.$t("global_cta_save")
            )}
          </Button>
        </Modal.ActionButtons>
      </BForm>
    );
  }
}
