import { Component, Watch } from "vue-property-decorator";
import { ApiSetupInitBasicInfo } from "@/components/project-setup/types";
import WizardStep from "@/components/wizard/WizardStep.component";
import { BAlert, BForm, BSpinner } from "@/lib/typed-bootstrap";
import * as tsx from "vue-tsx-support";
import styles from "./ProjectPublishRegion.view.module.scss";
import { ApiRegionEnum } from "@/models";
import Vue from "vue";
import ApiDataManager from "@/components/api-data/api-data-manager";
import Card from "@/components/shared/Card.component";
import {
  Button,
  ButtonShapeEnum,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "@/components/shared/Button.component";
import germanFlag from "svg-country-flags/svg/de.svg";
import indiaFlag from "svg-country-flags/svg/in.svg";
import southKoreaFlag from "svg-country-flags/svg/kr.svg";
import singaporeFlag from "svg-country-flags/svg/sg.svg";
import australiaFlag from "svg-country-flags/svg/au.svg";
import japanFlag from "svg-country-flags/svg/jp.svg";
import canadaFlag from "svg-country-flags/svg/ca.svg";
import irelandFlag from "svg-country-flags/svg/ie.svg";
import londonFlag from "svg-country-flags/svg/gb.svg";
import franceFlag from "svg-country-flags/svg/fr.svg";
import swedenFlag from "svg-country-flags/svg/se.svg";
import bahrainFlag from "svg-country-flags/svg/bh.svg";
import brazilFlag from "svg-country-flags/svg/br.svg";
import usFlag from "svg-country-flags/svg/us.svg";

@Component
export default class ProjectPublishRegion extends Vue {
  private selectedRegion: ApiSetupInitBasicInfo["apiRegion"] =
    ApiRegionEnum.EuropeFrankfurt;
  private apiDataManager = Vue.observable(new ApiDataManager());

  get apiId(): string {
    return this.$route.params.apiId;
  }

  @Watch("apiId", { immediate: true }) async onApiIdChange() {
    if (!this.apiId) {
      return;
    }

    await this.apiDataManager.onApiIdChange(this.apiId);

    if (!this.apiDataManager.apiState) {
      return;
    }
    this.selectedRegion =
      this.apiDataManager.apiState.region ?? ApiRegionEnum.EuropeFrankfurt;
  }

  private get regionOptions() {
    return [
      {
        value: ApiRegionEnum.AsiaPacificMumbai,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.AsiaPacificMumbai}`
        ).toString(),
        icon: indiaFlag,
      },
      {
        value: ApiRegionEnum.AsiaPacificSeoul,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.AsiaPacificSeoul}`
        ).toString(),
        icon: southKoreaFlag,
      },
      {
        value: ApiRegionEnum.AsiaPacificSingapore,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.AsiaPacificSingapore}`
        ).toString(),
        icon: singaporeFlag,
      },
      {
        value: ApiRegionEnum.AsiaPacificSydney,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.AsiaPacificSydney}`
        ).toString(),
        icon: australiaFlag,
      },
      {
        value: ApiRegionEnum.AsiaPacificTokyo,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.AsiaPacificTokyo}`
        ).toString(),
        icon: japanFlag,
      },
      {
        value: ApiRegionEnum.Canada,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.Canada}`
        ).toString(),
        icon: canadaFlag,
      },
      {
        value: ApiRegionEnum.EuropeFrankfurt,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.EuropeFrankfurt}`
        ).toString(),
        icon: germanFlag,
      },
      {
        value: ApiRegionEnum.EuropeIreland,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.EuropeIreland}`
        ).toString(),
        icon: irelandFlag,
      },
      {
        value: ApiRegionEnum.EuropeLondon,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.EuropeLondon}`
        ).toString(),
        icon: londonFlag,
      },
      {
        value: ApiRegionEnum.EuropeParis,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.EuropeParis}`
        ).toString(),
        icon: franceFlag,
      },
      {
        value: ApiRegionEnum.EuropeStockholm,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.EuropeStockholm}`
        ).toString(),
        icon: swedenFlag,
      },
      {
        value: ApiRegionEnum.MiddleEast,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.MiddleEast}`
        ).toString(),
        icon: bahrainFlag,
      },
      {
        value: ApiRegionEnum.SouthAmerica,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.SouthAmerica}`
        ).toString(),
        icon: brazilFlag,
      },
      {
        value: ApiRegionEnum.USEast,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.USEast}`
        ).toString(),
        icon: usFlag,
      },
      {
        value: ApiRegionEnum.USWestNCalifornia,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.USWestNCalifornia}`
        ).toString(),
        icon: usFlag,
      },
      {
        value: ApiRegionEnum.USWestOregon,
        text: this.$t(
          `aws_region_countries.${ApiRegionEnum.USWestOregon}`
        ).toString(),
        icon: usFlag,
      },
    ];
  }

  private async onRegionClick(item: ApiRegionEnum) {
    this.selectedRegion = item;
  }

  private async onSubmit(): Promise<void> {
    if (!this.selectedRegion) {
      this.apiDataManager.errorMessage = this.$t(
        "error_api_create_requires_region"
      ).toString();
    }

    await this.apiDataManager.updateApiRegion({ region: this.selectedRegion });
    if (!this.apiDataManager.errorMessage) {
      this.$router.push({ name: "project-publish-bundles" });
    }
  }

  render() {
    return (
      <WizardStep
        toLocationOnClose={{
          name: "api-details-data",
          params: { apiId: this.apiId },
        }}
      >
        <template slot="wizardName">
          {this.$t("api_publish_wizard_name", { step: 2, stepCount: 3 })}
        </template>
        <template slot="header">
          {this.$t("api_setup_init_region_headline")}
        </template>
        <template slot="description">
          {this.$t("api_setup_init_region_subline")}
        </template>
        <BAlert
          show={this.apiDataManager.showErrorMessage}
          variant="danger"
          data-testid="apiSetupFormError"
        >
          {this.apiDataManager.errorMessage}
        </BAlert>
        <BForm
          ref="form"
          class="default__inline-form"
          accept-charset="UTF-8"
          role="form"
          novalidate
          onSubmit={tsx.modifiers.stop.prevent(this.onSubmit)}
        >
          <div class={styles.cardsContainer}>
            {!this.apiDataManager.isFetching &&
              this.regionOptions.map((option) => {
                return (
                  <Card
                    selected={this.selectedRegion === option.value}
                    onClick={() => this.onRegionClick(option.value)}
                    class={styles.card}
                    data-testid={`apiRegion${option.value}`}
                    aria-selected={this.selectedRegion === option.value}
                  >
                    <div class={styles.cardBody}>
                      <div class={styles.iconContainer}>
                        <img
                          src={option.icon}
                          alt={`${option.text} icon`}
                          class={styles.icon}
                        />
                      </div>
                      <span class={styles.cardTitle}>{option.text}</span>
                      <span class={styles.cardSubtitle}>{option.value}</span>
                    </div>
                  </Card>
                );
              })}
          </div>

          <Button
            type="submit"
            onClick={tsx.modifiers.prevent(this.onSubmit)}
            data-testid="continue-button"
            class={styles.submitButton}
            size={ButtonSizeEnum.lg}
            theme={ButtonThemeEnum.primary}
            shape={ButtonShapeEnum.square}
          >
            {this.apiDataManager.isUpdating && (
              <div class="d-flex default__spinner" role="status">
                <BSpinner
                  class="ml-auto"
                  type="grow"
                  label="Spinning"
                  show={false}
                ></BSpinner>
              </div>
            )}
            <div class="button__text">{this.$t("form_cta_continue")}</div>
          </Button>
        </BForm>
      </WizardStep>
    );
  }
}
