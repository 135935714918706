import { Component, Emit, Prop } from "vue-property-decorator";
import Plate from "@/components/shared/Plate.component";
import * as tsx from "vue-tsx-support";
import Modal from "../shared/Modal.component";
import { Button, ButtonThemeEnum } from "../shared/Button.component";
import { Loader } from "../shared/Loader.component";
import styles from "./ApiManagementCreateApp.component.module.scss";
import { BForm } from "@/lib/typed-bootstrap";
import LabeledTextInput from "../shared/LabeledTextInput.component";
import { InputSizeEnum } from "../shared/Input.component";
import ErrorHandling from "@/mixins/ErrorHandling.mixin";
import { createCustomerApp } from "@/models/customer-apps";
import { getErrorMessage } from "@/utils";
import { IAppDeclaration } from "@graphapi-io/customer-components";
import { UIModule } from "@/store/modules/ui";

const TOP_OFFSET = 12;

@Component
export default class ApiManagementCreateApp extends ErrorHandling {
  _tsx!: tsx.DeclareProps<{
    anchor?: ApiManagementCreateApp["anchor"];
    apiId?: ApiManagementCreateApp["apiId"];
  }> &
    tsx.DeclareOnEvents<{
      onClose: void;
    }>;

  @Prop({ required: true }) private anchor: HTMLElement;
  @Prop({ required: true }) private apiId: string;

  @Emit("close") closeAddEntryPlate() {}

  $refs!: {
    plate?: Plate;
  };

  private isLoading = false;
  private name = "";
  private nameError: string | null = null;

  mounted() {
    window.addEventListener("click", this.onClick, true);
    window.addEventListener("keydown", this.onKeydown);
  }

  beforeDestroy() {
    window.removeEventListener("click", this.onClick, true);
    window.removeEventListener("keydown", this.onKeydown);
  }

  private onKeydown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.closeAddEntryPlate();
    }
  }

  private onClick(event: Event) {
    const path = event.composedPath ? event.composedPath() : undefined;
    const plateHtmlElement = this.$refs.plate?.$refs.plate;

    if (
      path &&
      plateHtmlElement &&
      !path.includes(plateHtmlElement) &&
      !path.includes(this.anchor)
    ) {
      this.closeAddEntryPlate();
    }
  }

  private validateNameLive() {
    if (this.name.length > 255) {
      this.nameError = this.$t("error_app_name_max_length").toString();
    }
  }

  private validateNameBeforeSubmit() {
    if (this.name.length < 3) {
      this.nameError = this.$t("error_app_name_min_length").toString();
    }
  }

  private handleNameInput(value: string) {
    this.nameError = null;
    this.name = value;
    this.validateNameLive();
  }

  private async handleSubmit(ev: Event) {
    ev.preventDefault();
    try {
      if (this.nameError) {
        return;
      }
      this.validateNameBeforeSubmit();
      if (this.nameError) {
        return;
      }
      const appSchema: IAppDeclaration = {
        name: this.name,
        routes: [],
      };

      await createCustomerApp({
        apiId: this.apiId,
        name: this.name,
        schema: JSON.stringify(appSchema),
      });
      this.closeAddEntryPlate();
    } catch (error) {
      this.errorMessage = getErrorMessage(error);
    }
  }

  render() {
    return (
      <Plate
        ref={"plate"}
        anchor={this.anchor}
        plateClassName={styles.plate}
        topOffset={TOP_OFFSET}
        style={styles.plate}
      >
        <BForm
          onSubmit={this.handleSubmit}
          role="form"
          novalidate
          class={styles.form}
        >
          <LabeledTextInput
            id="addModelInput"
            labelText={this.$t("form_input_label_app_name").toString()}
            placeholder={this.$t("form_placeholder_app_name").toString()}
            value={this.name}
            onInput={this.handleNameInput}
            inputSize={InputSizeEnum.md}
            autoFocus
            selectAllInitially
            error={this.nameError}
          />
          <Modal.ActionButtons class={styles.actionButtons}>
            <Button
              onClick={this.closeAddEntryPlate}
              class={styles.button}
              theme={ButtonThemeEnum.naked}
              type="button"
            >
              {this.$t("global_cta_cancel")}
            </Button>
            <Button
              theme={ButtonThemeEnum.primary}
              class={styles.button}
              type="submit"
            >
              {this.isLoading ? (
                <Loader variant={UIModule.isLightTheme ? "light" : "dark"} />
              ) : (
                this.$t("form_cta_create")
              )}
            </Button>
          </Modal.ActionButtons>
        </BForm>
      </Plate>
    );
  }
}
