export function valuesFromCsvString(
  csvString: string,
  numOfColums: number,
  separator: string = ","
) {
  const normalizedCsvString = csvString.trim();

  const regex = new RegExp(`${separator}(?=(?:[^"]*"[^"]*")*[^"]*$)`, "g");
  return normalizedCsvString.split(regex, numOfColums).map((cell) => {
    if (cell.startsWith('"') && cell.endsWith('"')) {
      return cell.slice(1, -1).replace(/""/g, '"');
    } else {
      return cell;
    }
  });
}

export const csvReader = (
  file: File,
  separator: string,
  fieldsOnly: boolean = false
): Promise<{ fields: string[]; entries: string[][] }> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = async () => {
      const fields: string[] = [];
      const entries: string[][] = [];
      const readLines = (fileReader.result as string)?.split(
        /(?:\r\n|\r|\n)(?=(?:(?:[^"]*"){2})*[^"]*$)/
      );
      // const readLines = (fileReader.result as string)?.split(/\r\n|\r|\n/);
      const headerLine = readLines.shift();
      if (headerLine) {
        fields.push(
          ...headerLine.split(separator).map((header) => header.trim())
        );
      }

      if (!fieldsOnly) {
        readLines.forEach((e: string) => {
          const newEntry = valuesFromCsvString(e, fields.length, separator);

          if (newEntry?.length === fields.length) {
            entries.push(newEntry);
          }
        });
      }

      resolve({ fields, entries });
    };

    fileReader.onerror = reject;

    fileReader.readAsText(file);
  });
