import Vue from "vue";
import * as tsx from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import styles from "./BundleBenefitSection.component.module.scss";

@Component
export default class BundleBenefitsSection extends Vue {
  _tsx!: tsx.DeclareProps<{
    benefits: BundleBenefitsSection["benefits"];
  }>;

  @Prop({ required: true }) readonly benefits: string[];

  render() {
    return (
      <div class={styles.section}>
        <div class={styles.benefits}>
          {this.benefits.map((benefit) => {
            return <div class={styles.benefit}>{benefit}</div>;
          })}
        </div>
      </div>
    );
  }
}
