import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { IEnumState } from "@/models";
import { removeSpecialCharacters } from "@/utils";
import FormEnumValue from "./FormEnumValue.component.vue";
import * as tsx from "vue-tsx-support";
import _debounce from "lodash/debounce";

export interface IEnumNameBlurEvent {
  oldName: string;
  newName: string;
}

@Component({
  components: { FormEnumValue },
})
export default class FormEnumWidget extends Vue {
  _tsx!: tsx.DeclareOnEvents<{
    onEnumNameChange: IEnumNameBlurEvent;
  }>;
  @Prop({ required: true }) value: IEnumState;

  @Emit("enumNameChange") onEnumNameChange(_event: IEnumNameBlurEvent) {}

  $refs!: {
    enumNameInput: HTMLFormElement;
  };

  selectedValueAction = null;
  actionOptions = [{ value: "delete", text: this.$t("global_cta_delete") }];

  get noValueSelected(): boolean {
    return (
      this.value.values.some((value) => {
        return value.selected;
      }) != true
    );
  }

  get debouncedNameChangeEmitter() {
    return _debounce((newName: string, oldName: string) => {
      this.onEnumNameChange({ oldName, newName });
    }, 300);
  }

  mounted() {
    if (!this.value.name) {
      this.$refs.enumNameInput.focus();
    }
  }

  deleteValue(index: number) {
    this.value.values.splice(index, 1);
  }

  @Watch("value.name")
  onNameChanged(newName: string, oldName: string) {
    this.debouncedNameChangeEmitter(newName, oldName);
  }

  inputFieldFormatter(value: string): string {
    return removeSpecialCharacters(value);
  }

  isUniqueValue(valueName: string, valueIndex: number): boolean | null {
    const isDuplicate = this.value.values.some((value, index) => {
      return index !== valueIndex && value.name === valueName;
    });

    return isDuplicate ? false : null;
  }

  onAddValue() {
    try {
      const lastValue = this.value.values[this.value.values.length - 1];
      if (!lastValue || lastValue?.name?.length > 0) {
        this.value.values.push({ name: "", selected: false });
      }
    } catch (err) {
      console.log(err);
    }
  }
}
