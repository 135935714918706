import { MetadataObj } from "@/api/common-types";
import CREATE_PUBLISH_RUN from "./create_publish_run.graphql";
import QUERY_PUBLISH_RUNS_BY_API from "./query_publish_runs.graphql";
import QUERY_PUBLISH_RUN from "./query_publish_run.graphql";
import QUERY_API from "../api/query_api.graphql";
import _get from "lodash/get";
import _set from "lodash/set";
import { apolloClient, queryFunctionFactory } from "../internal-utils";
import {
  IApiUpdateInput,
  IPublishRunInput,
  IInlineS3ObjectInput,
  IPublishRun,
  IQueryPublishRunQueryVariables,
  IQueryPublishRunQuery,
} from "@/generated/types";

export interface IListPublishRunsQueryByApiResponse {
  listPublishRunsByApi: {
    items: IPublishRun[];
  };
}

export const publishApiMutation = (
  apiUpdateInput: IApiUpdateInput,
  publishRunInput: IPublishRunInput,
  inlineS3ObjectInput: IInlineS3ObjectInput
) => {
  if (!apiUpdateInput.awsAccountId) {
    throw new Error("Publishing not possible. Please contact support.");
  }

  return apolloClient.mutate({
    mutation: CREATE_PUBLISH_RUN,
    variables: { publishRunInput, apiUpdateInput, inlineS3ObjectInput },
    update: (cache, mutationResult) => {
      try {
        const newPublishRun = mutationResult?.data?.createPublishRun;
        const publishRunsData = cache.readQuery({
          query: QUERY_PUBLISH_RUNS_BY_API,
          variables: { apiId: apiUpdateInput.id },
        });
        const items = _get(publishRunsData, "listPublishRunsByApi.items", []);
        const extendedData = _set(
          publishRunsData as MetadataObj,
          "listPublishRunsByApi.items",
          [newPublishRun, ...items]
        );
        cache.writeQuery({
          query: QUERY_PUBLISH_RUNS_BY_API,
          variables: { apiId: apiUpdateInput.id },
          data: extendedData,
        });

        const apiData = cache.readQuery({
          query: QUERY_API,
          variables: { id: apiUpdateInput.id },
        });
        const newPublishRunItems = _set(
          apiData as MetadataObj,
          "api.publishRunConnection.items",
          [newPublishRun]
        );

        cache.writeQuery({
          query: QUERY_API,
          variables: { id: apiUpdateInput.id },
          data: newPublishRunItems,
        });
      } catch (err) {
        console.log(err);
      }
    },
  });
};

export const publishRunsByApiQuery = queryFunctionFactory<
  IListPublishRunsQueryByApiResponse,
  {
    apiId: string;
  }
>(QUERY_PUBLISH_RUNS_BY_API);

export const publishRunQuery = queryFunctionFactory<
  IQueryPublishRunQuery,
  IQueryPublishRunQueryVariables
>(QUERY_PUBLISH_RUN);
