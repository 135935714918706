import _set from "lodash/fp/set";
import _omit from "lodash/fp/omit";
import _isEmpty from "lodash/isEmpty";

export class SelectionVector {
  public vector: Record<number, boolean> = {};

  constructor(values: number[] = []) {
    values.forEach((value) => this.add(value));
  }

  public add(index: number): void {
    this.vector = _set(index, true, this.vector);
  }

  public delete(index: number): void {
    this.vector = _omit(index, this.vector);
  }

  public has(index: number): boolean {
    return this.vector[index] ?? false;
  }

  public positiveValues(): number[] {
    return Object.keys(this.vector)
      .map((key) => parseInt(key))
      .sort();
  }

  public clear(): void {
    if (!_isEmpty(this.vector)) {
      this.vector = {};
    }
  }

  public get size(): number {
    return Object.keys(this.vector).length;
  }

  public values() {
    return Object.keys(this.vector)
      .map((key) => parseInt(key))
      .sort();
  }
}
