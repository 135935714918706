import { IRenderFn } from "@/utils/types";
import style from "./Row.component.module.scss";

interface IRowProps {
  index: number;
}

export const renderRow: IRenderFn<IRowProps> = (
  h,
  { props: { index }, children, data }
) => (
  <tr class={style.row} aria-rowindex={index} key={data?.key}>
    {children}
  </tr>
);
