import { Vue, Component, Prop } from "vue-property-decorator";
import { ItemChangesDiffRow } from "@/components/diff/ItemChangesDiffRow.component";
import style from "./ItemChangesListItem.component.module.scss";
import iconChangedTo from "@/assets/img/icon-changed-to.svg";
import { IDiffRowState } from "./types";
import * as tsx from "vue-tsx-support";
import { BCol, BRow } from "@/lib/typed-bootstrap";

@Component
export default class ItemChangesListItem extends Vue {
  _tsx!: tsx.DeclareProps<{
    diffRows: ItemChangesListItem["diffRows"];
    objectHasBeenCreated: ItemChangesListItem["objectHasBeenCreated"];
    objectHasBeenDeleted: ItemChangesListItem["objectHasBeenDeleted"];
    toTypeName?: ItemChangesListItem["toTypeName"];
    fromTypeName?: ItemChangesListItem["fromTypeName"];
    typeNameHasChanged?: ItemChangesListItem["typeNameHasChanged"];
  }>;
  @Prop({ required: true }) diffRows: IDiffRowState[];
  @Prop({ required: true }) objectHasBeenCreated: boolean;
  @Prop({ required: true }) objectHasBeenDeleted: boolean;
  @Prop({ default: false }) typeNameHasChanged: boolean;
  @Prop({ default: "" }) toTypeName: string;
  @Prop({ default: "" }) fromTypeName: string;

  private get fromOperator(): string {
    return this.objectHasBeenDeleted ? "-" : "";
  }

  private get toOperator(): string {
    return this.objectHasBeenCreated ? "+" : "";
  }

  render() {
    return (
      <div class={style.listItem}>
        <BRow>
          <BCol
            cols="12"
            class={{
              [style.header]: true,
              [style.created]: this.objectHasBeenCreated,
              [style.deleted]: this.objectHasBeenDeleted,
            }}
          >
            {this.objectHasBeenDeleted && (
              <span class={style.operator}>{this.fromOperator}</span>
            )}
            {this.typeNameHasChanged && (
              <span
                class={{
                  [style.typeName]: true,
                  [style.deleted]: this.objectHasBeenDeleted,
                }}
              >
                {this.fromTypeName}
              </span>
            )}
            {this.typeNameHasChanged && (
              <img class={style.typeNameArrow} src={iconChangedTo} />
            )}
            {this.objectHasBeenCreated && (
              <span class={style.operator}>{this.toOperator}</span>
            )}
            <span>{this.toTypeName}</span>
          </BCol>
        </BRow>
        {this.diffRows.map((diff, idx) => {
          return <ItemChangesDiffRow diff={diff} key={idx} />;
        })}
      </div>
    );
  }
}
