import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Modal from "../shared/Modal.component";
import Tabs from "../shared/Tabs.component";
import style from "./FileUploadModal.component.module.scss";
import { FormDropzone } from "../forms/inputs/FormDropzone.component";
import * as tsx from "vue-tsx-support";
import { Button, ButtonThemeEnum } from "../shared/Button.component";
import { BAlert, IBModalEvents } from "@/lib/typed-bootstrap";
import { getErrorMessage } from "@/utils";

@Component
export default class FileUploadModal extends Vue {
  _tsx!: tsx.DeclareProps<{
    initialFiles?: FileUploadModal["initialFiles"];
    modalId: FileUploadModal["modalId"];
    accept?: FileUploadModal["accept"];
  }> &
    tsx.DeclareOnEvents<IBModalEvents> &
    tsx.DeclareOnEvents<{ onFileSelected: File | File[] }>;
  $scopedSlots!: tsx.InnerScopedSlots<{
    file: {
      file: File;
      titleClassName: string;
      subtitleClassName: string;
    };
    mappings?: {
      alertClassName: string;
      hintClassName?: string;
    };
    submitButton: {
      files: File[] | null;
      onSuccess: () => void;
      onError: (error: unknown) => void;
      className: string;
      theme: ButtonThemeEnum;
    };
  }>;

  @Prop({ default: null }) initialFiles: File[] | null;
  @Prop() modalId: string;
  @Prop() accept?: string;

  private files: File[] | null = null;
  private errorMessage = "";

  @Watch("initialFiles", { immediate: true })
  private onFilesChange(files: File[] | null) {
    this.files = files?.length ? files : null;
    this.errorMessage = "";
    this.$emit("fileSelected", this.files);
  }

  private handleHidden() {
    this.files = null;
    this.$emit("hidden");
    this.errorMessage = "";
  }

  private async onSuccess() {
    this.$bvModal.hide(this.modalId);
  }

  private async onError(error: unknown) {
    this.errorMessage = getErrorMessage(error);
  }

  private onInvalidFormat() {
    this.errorMessage = this.$t("form_dropzone.invalid_format").toString();
  }

  render() {
    return (
      <Modal
        id={this.modalId}
        on={{
          ...this.$listeners,
          hidden: this.handleHidden,
        }}
        scopedSlots={{
          body: ({ cancel }) => (
            <Tabs currentTabIndex={0} class={style.tabs}>
              <Tabs.Tab
                id="upload"
                title={this.$t("global_cta_upload").toString()}
              >
                <BAlert
                  show={!!this.errorMessage}
                  variant="danger"
                  data-testid="apiSettingsFormError"
                >
                  {this.errorMessage}
                </BAlert>
                {!this.files ? (
                  <FormDropzone
                    id="fileUploadDropzone"
                    onFileUpload={this.onFilesChange}
                    onInvalidFormat={this.onInvalidFormat}
                    accept={this.accept}
                  />
                ) : (
                  <div class={style.filesContainer}>
                    {this.$slots.header && (
                      <div class={style.filesHeader}>{this.$slots.header}</div>
                    )}
                    <div class={style.files}>
                      {this.files.map((file) =>
                        this.$scopedSlots.file?.({
                          file,
                          titleClassName: style.fileTitle,
                          subtitleClassName: style.fileSubtitle,
                        })
                      )}
                    </div>
                    <div class={style.mappings}>
                      {this.$scopedSlots.mappings?.({
                        alertClassName: style.mappingsTitle,
                      })}
                    </div>
                    <Modal.ActionButtons class={style.actionButtons}>
                      <Button
                        onClick={cancel}
                        class={style.button}
                        theme={ButtonThemeEnum.naked}
                      >
                        {this.$t("global_cta_cancel")}
                      </Button>
                      {this.$scopedSlots.submitButton?.({
                        files: this.files,
                        onSuccess: this.onSuccess,
                        onError: this.onError,
                        className: style.button,
                        theme: ButtonThemeEnum.primary,
                      })}
                    </Modal.ActionButtons>
                  </div>
                )}
              </Tabs.Tab>
            </Tabs>
          ),
        }}
      />
    );
  }
}
