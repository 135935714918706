import { Vue, Component } from "vue-property-decorator";
import FormPassword from "@/components/forms/inputs/FormPassword.component.vue";
import { UserModule } from "@/store/modules/user";
import FormInputWithLabel from "@/components/forms/inputs/FormInputWithLabel.component";
import { auth } from "@/utils/auth-service";

@Component({
  components: {
    FormInputWithLabel,
    FormPassword,
  },
})
export default class ChangePasswordForm extends Vue {
  errorMessage = "";
  form = null;
  isLoading = false;
  verificationCode = "";

  newPassword = "";
  confirmNewPassword = "";

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  get username(): string {
    return (this.$route.query.email as string) ?? UserModule.email ?? "";
  }

  get confirmPasswordMatches(): boolean {
    return this.newPassword === this.confirmNewPassword;
  }

  async onSubmit(): Promise<void> {
    this.errorMessage = "";

    if (!this.confirmPasswordMatches) {
      this.errorMessage = "Passwords do not match";
      return;
    }

    if (this.username?.length > 0) {
      this.isLoading = true;
      try {
        await auth.forgotPasswordSubmit(
          this.username,
          this.verificationCode,
          this.newPassword
        );
        if (UserModule.isAuthenticated) {
          this.$router.push({ name: "account-settings" });
        } else {
          this.$router.push({ name: "login", query: { email: this.username } });
        }
      } catch (err) {
        this.errorMessage = (err as Error).message;
      } finally {
        this.isLoading = false;
      }
    } else {
      this.$router.push({ name: "password-reset" });
    }
  }
}
