import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store/store";

export interface IFormState {
  data: { [key: string]: string };
  email: string;
  password: string;
}

@Module({ dynamic: true, store, name: "form", namespaced: true })
class Form extends VuexModule implements IFormState {
  public data: { [key: string]: string } = {};
  public email = "";
  public password = "";

  @Mutation
  private SET_DATA(data: { [key: string]: string }): void {
    this.data = data;
  }

  @Mutation
  public setEmail(email: string): void {
    this.email = email;
  }

  @Mutation
  public setPassword(password: string): void {
    this.password = password;
  }

  @Action
  handleFormInput(input: { key: string; value: string }): void {
    this.data[input.key] = input.value;
  }

  @Action
  handleValidCredentials(credentials: {
    username: string;
    password: string;
  }): void {
    this.setEmail(credentials.username);
    this.setPassword(credentials.password);
  }

  @Action
  handleSignOut(): void {
    this.SET_DATA({});
    this.setEmail("");
    this.setPassword("");
  }
}

export const FormModule = getModule(Form);
