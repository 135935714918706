import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store/store";
import { persistanceService } from "@/utils/persistance-service";

export interface IUIState {
  isLightTheme: boolean;
}

export const LIGHT_THEME_CLASS_NAME = "light-theme";
export const DARK_THEME_CLASS_NAME = "dark-theme";

@Module({ dynamic: true, store, name: "ui", namespaced: true })
class UI extends VuexModule implements IUIState {
  public isLightTheme: boolean =
    persistanceService.getActiveTheme() === LIGHT_THEME_CLASS_NAME;
  public isDarkTheme: boolean =
    persistanceService.getActiveTheme() === DARK_THEME_CLASS_NAME;

  @Mutation
  private SET_IS_LIGHT_THEME(isLightTheme: boolean) {
    this.isLightTheme = isLightTheme;
  }

  private get preferredTheme() {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      return DARK_THEME_CLASS_NAME;
    }
    return LIGHT_THEME_CLASS_NAME;
  }

  @Action
  setTheme(themeClass: string) {
    const activeTheme = persistanceService.getActiveTheme();
    if (activeTheme) {
      document.body.classList.remove(activeTheme);
    }

    document.body.classList.add(themeClass);
    persistanceService.setActiveTheme(themeClass);
    this.SET_IS_LIGHT_THEME(themeClass === LIGHT_THEME_CLASS_NAME);
  }

  @Action
  toggleTheme() {
    if (this.isLightTheme) {
      this.setTheme(DARK_THEME_CLASS_NAME);
    } else {
      this.setTheme(LIGHT_THEME_CLASS_NAME);
    }
  }

  @Action
  initTheme() {
    if (this.isLightTheme) {
      this.setTheme(LIGHT_THEME_CLASS_NAME);
    } else if (this.isDarkTheme) {
      this.setTheme(DARK_THEME_CLASS_NAME);
    } else {
      if (this.preferredTheme === LIGHT_THEME_CLASS_NAME) {
        this.setTheme(LIGHT_THEME_CLASS_NAME);
      }
      if (this.preferredTheme === DARK_THEME_CLASS_NAME) {
        this.setTheme(DARK_THEME_CLASS_NAME);
      }
    }
  }
}

export const UIModule = getModule(UI);
