import { IRenderFn } from "@/utils/types";
import style from "./Cell.component.module.scss";

export type ICellEvents = {
  click: MouseEvent;
  mousedown: MouseEvent;
};

export type ICellProps = {
  isCellSelected: boolean;
  isBottomCellSelected: boolean;
  isTopCellSelected: boolean;
  isRightCellSelected: boolean;
  isLeftCellSelected: boolean;
  isFirstDataColumn: boolean;
  isPrevCellSelection: boolean;
  hasOptions?: boolean;
  dataTestId?: string;
  contentClass?: string;
};

export const renderCell: IRenderFn<ICellProps, ICellEvents> = (
  h,
  { props, children, listeners, data }
) => (
  <td
    data-testid={props.dataTestId}
    class={{
      ...(typeof data?.class === "object" ? data?.class : {}),
      ...(typeof data?.class === "string" ? { [data?.class]: true } : {}),
      [style.cell]: true,
      [style.selected]: props.isCellSelected,
      [style.bottomSelected]: props.isBottomCellSelected,
      [style.topSelected]: props.isTopCellSelected,
      [style.rightSelected]: props.isRightCellSelected,
      [style.leftSelected]: props.isLeftCellSelected,
      [style.firstDataColumn]: props.isFirstDataColumn,
      [style.prevCellIsSelection]: props.isPrevCellSelection,
      [style.hasOptions]: props.isCellSelected && props.hasOptions,
    }}
    on={listeners}
    ref={data?.ref}
    key={data?.key}
  >
    <span class={[style.content, props.contentClass]}>{children}</span>
  </td>
);
