import { Component, Prop, Watch } from "vue-property-decorator";
import { isValidUrl } from "@/utils";
import { updateApiMutation, deleteApiMutation } from "@/api/api";
import {
  AuthMethodTypeEnum,
  PublishingStateEnum,
  ApiCreationTypeEnum,
} from "@/api/common-types";
import {
  declarationSchemaForInput,
  IApiState,
  IOidcConfigState,
} from "@/models";
import { formattedTimeString } from "@/utils";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { deleteBundleSubscription } from "@/api/bundle_subscription";
import { OrgModule } from "@/store";
import { persistanceService } from "@/utils/persistance-service";

// Component imports
import DeleteModal from "@/components/modal/DeleteModal.component";
import FormModal from "@/components/modal/FormModal.component.vue";
import FormReadonly from "@/components/forms/FormReadonly.component.vue";
import FormSelect from "@/components/forms/inputs/FormSelect.component";
import FormTable from "@/components/forms/FormTable.component.vue";
import ApiManagementKeysSection from "@/components/api-management/ApiManagementKeysSection.component.vue";
import CollapsibleSection from "@/components/shared/CollapsibleSection.component.vue";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import FormInputWithLabel from "@/components/forms/inputs/FormInputWithLabel.component";
import UrlWidget from "@/components/widgets/UrlWidget.component.vue";
import { IBundleSubscription } from "@/generated/types";
import { getSubscriptions } from "@/models/subscriptions";
import { ApolloClient } from "apollo-boost";

@Component({
  components: {
    DeleteModal,
    FormModal,
    FormReadonly,
    FormSelect,
    FormTable,
    ApiManagementKeysSection,
    CollapsibleSection,
    FormInputWithLabel,
    UrlWidget,
  },
})
export default class ApiSettings extends UserPermissions {
  @Prop({ required: true }) value: IApiState;
  @Prop({ required: true }) apiClient: ApolloClient<unknown>;

  errorMessage = "";
  form = null;

  isLoading = false;
  confirmName = "";
  apiName: string | null | undefined = "";
  oidcSettings: IOidcConfigState | null = null;

  selectedUserAuthMethod: AuthMethodTypeEnum | null = null;
  subscriptions: IBundleSubscription[] = [];

  get canManageKeys() {
    return !this.isImported && this.value.graphqlUrl && this.isAuthModeApiKey;
  }

  get apiRegion() {
    return this.$t(`aws_region.${this.value.region}`);
  }

  get authModeApiKeyHeader(): string {
    return `"x-api-key: ${this.value.apiKey ?? "custom-api-key"}"`;
  }

  get authModeApiKeyLabel(): string {
    return "Api Key";
  }

  get authModeOidcLabel(): string {
    return "OpenID Connect";
  }

  get authModeCognitoLabel(): string {
    return "AWS Cognito";
  }

  get authorizationOptions() {
    const base = [
      {
        value: AuthMethodTypeEnum.AWS_COGNITO_USER_POOLS,
        text: this.authModeCognitoLabel,
      },
    ];

    return base;
  }

  get canDeleteApis(): boolean {
    return this.canManageOrg;
  }

  get defaultAuthMode() {
    return this.selectedUserAuthMethod ?? AuthMethodTypeEnum.AWS_API_KEY;
  }

  get additionalAuthModes() {
    return this.defaultAuthMode !== AuthMethodTypeEnum.AWS_API_KEY &&
      this.value.creationType !== ApiCreationTypeEnum.IMPORT
      ? [AuthMethodTypeEnum.AWS_API_KEY]
      : [];
  }

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  get deleteTitle(): string {
    return this.$t("warning_delete_api_title", {
      name: this.value.name,
    }).toString();
  }

  get deleteInfo(): string {
    return this.$t("warning_delete_api_info", {
      name: this.value.name,
    }).toString();
  }

  get deleteInstruction(): string {
    return this.$t("warning_delete_api_instruction").toString();
  }

  get hasAdditionalAuthModes() {
    return (this.value.additionalAuthModes ?? []).length > 0;
  }

  get isAuthModeApiKey(): boolean {
    return (
      this.value.defaultAuthMode === AuthMethodTypeEnum.AWS_API_KEY ||
      (this.value.additionalAuthModes
        ? this.value.additionalAuthModes.some(
            (auth) => auth === AuthMethodTypeEnum.AWS_API_KEY
          )
        : false)
    );
  }

  get isAuthModeCognito(): boolean {
    return (
      this.selectedUserAuthMethod === AuthMethodTypeEnum.AWS_COGNITO_USER_POOLS
    );
  }

  get showUserAuthenticationSection() {
    return !this.isPublished || this.isAuthModeCognito || this.isAuthModeOidc;
  }

  get isAuthModeOidc(): boolean {
    return this.selectedUserAuthMethod === AuthMethodTypeEnum.AWS_OIDC;
  }

  get isImported(): boolean {
    return this.value.creationType === ApiCreationTypeEnum.IMPORT;
  }

  get isPublished(): boolean {
    return (
      this.value.state !== PublishingStateEnum.INITIALIZED && !this.isImported
    );
  }

  get oidcClientId(): string {
    return this.oidcSettings?.clientId ?? "";
  }

  set oidcClientId(clientId: string) {
    this.oidcSettings = {
      clientId,
      issuerUrl: this.oidcSettings?.issuerUrl ?? "",
    };
  }

  get oidcIssuerUrl(): string {
    return this.oidcSettings?.issuerUrl ?? "";
  }

  set oidcIssuerUrl(issuerUrl: string) {
    this.oidcSettings = {
      clientId: this.oidcSettings?.clientId,
      issuerUrl,
    };
  }

  get realtimeURL() {
    if (this.value.graphqlUrl) {
      const uri = new URL(this.value.graphqlUrl ?? "");
      const hostnameParts = uri.hostname.split(".");
      hostnameParts[1] = "appsync-realtime-api";
      return `wss://${hostnameParts.join(".")}${uri.pathname}`;
    }

    return "";
  }

  get schema(): string {
    return declarationSchemaForInput({
      ...this.value,
      name: this.apiName,
      defaultAuthMode: this.defaultAuthMode,
      additionalAuthModes: this.additionalAuthModes,
      ...(this.isAuthModeOidc &&
        this.oidcSettings && { oidc: this.oidcSettings }),
    });
  }

  get showNameCancelSaveButtons(): boolean {
    return this.apiName !== this.value.name;
  }

  get showAuthCancelSaveButtons(): boolean {
    return (
      this.value.additionalAuthModes?.length !=
        this.additionalAuthModes.length ||
      (this.additionalAuthModes.length > 0 &&
        this.selectedUserAuthMethod !== this.value.defaultAuthMode) ||
      (this.isAuthModeOidc && !_isEqual(this.value.oidc, this.oidcSettings))
    );
  }

  get subscription() {
    const bundle = this.subscriptions[0]?.products?.find((p) =>
      p?.match(/.*_bundle/g)
    );

    return (bundle ?? "starter_bundle").replace(/_bundle/g, "").toUpperCase();
  }

  async created() {
    await this.initialize();
  }

  @Watch("value")
  async initialize() {
    this.apiName = this.value.name;
    this.selectedUserAuthMethod =
      this.value.defaultAuthMode !== AuthMethodTypeEnum.AWS_API_KEY
        ? this.value.defaultAuthMode ?? null
        : null;

    if (this.isAuthModeOidc) {
      this.oidcSettings = {
        issuerUrl: this.value.oidc?.issuerUrl ?? "",
        clientId: this.value.oidc?.clientId,
      };
    }

    this.subscriptions = await getSubscriptions(this.value.id);
  }

  async onDelete(): Promise<void> {
    try {
      if (!this.canManageOrg) {
        throw new Error(this.$t("error_api_deletion_requires_role").toString());
      }

      this.subscriptions = await getSubscriptions(this.value.id);

      if (this.subscriptions.length > 0) {
        await Promise.all([
          deleteApiMutation(OrgModule.id, { input: { id: this.value.id } }),
          ...this.subscriptions.map(({ id, paymentProviderId }) =>
            deleteBundleSubscription({
              input: {
                id,
                paymentProviderId: paymentProviderId ?? "",
              },
            })
          ),
        ]);
      } else {
        await deleteApiMutation(OrgModule.id, {
          input: { id: this.value.id },
        });
      }
      persistanceService.removeActiveRouteKey();
      this.$router.push({
        name: "org-api-dashboard",
        params: { orgId: OrgModule.id },
      });
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }
  }

  onCancelNameChange() {
    this.apiName = this.value.name;
  }

  onCancelAuthModeChange() {
    this.selectedUserAuthMethod = this.value.defaultAuthMode ?? null;
  }

  async onSaveSettingsChange() {
    try {
      if (this.isAuthModeOidc) {
        if (!isValidUrl(this.oidcSettings?.issuerUrl ?? "")) {
          throw new Error(
            this.$t("error_api_create_requires_issuerurl").toString()
          );
        }

        if (!this.oidcSettings?.clientId) {
          throw new Error(
            this.$t("error_api_create_requires_clientid").toString()
          );
        }
      }

      this.isLoading = true;
      this.errorMessage = "";
      const response = await updateApiMutation({
        id: this.value.id,
        name: this.apiName,
        defaultAuthMode: this.defaultAuthMode,
        additionalAuthModes: this.additionalAuthModes,
        oidc: this.isAuthModeOidc ? JSON.stringify(this.oidcSettings) : null,
        schema: this.schema,
      });
      this.value.name = _get(response, "data.updateApi.name");
      this.value.defaultAuthMode = _get(
        response,
        "data.updateApi.defaultAuthMode"
      );
      this.value.additionalAuthModes = _get(
        response,
        "data.updateApi.additionalAuthModes"
      );
      const oidcJsonString = _get(response, "data.updateApi.oidc");
      this.value.oidc = oidcJsonString ? JSON.parse(oidcJsonString) : null;
      this.value.schema = _get(response, "data.updateApi.schema");
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isLoading = false;
    }
  }

  formattedTimeString(expiresAtDate: string): string {
    return formattedTimeString(expiresAtDate);
  }
}
