import { mapResponseToPrice, IPriceResponseMappableProps } from "./price";
import { ProductNameEnum } from "@graphapi-io/products";
import {
  BillingIntervalEnum,
  ProductTypeEnum,
  UsageTypeEnum,
} from "@/api/common-types";
import { IBenefitResponseMappableProps, ILocalizedBenefits } from "./benefit";

export interface IProductResponseMappableProps {
  priceConnection: { items: IPriceResponseMappableProps[] };
  benefitConnection: { items: IBenefitResponseMappableProps[] };
  type?: ProductTypeEnum;
  usageType: UsageTypeEnum;
  name?: ProductNameEnum;
  selectedPricingIndex?: number;
}

export type IPreselectedLineItems = Record<string, number>;

const iconProductMapping: { [key: string]: string } = {
  starter_graphql: "graphql",
  pro_graphql: "graphql",
  starter_data_transfer: "transfer",
  pro_data_transfer: "transfer",
  starter_data_storage: "storage",
  pro_data_storage: "storage",
  starter_data_entries: "dbentries",
  pro_data_entries: "dbentries",
  starter_app_users: "appusers",
  pro_app_users: "appusers",
};

const isLicensedBaseProduct = (product: IProductResponseMappableProps) =>
  product.type === ProductTypeEnum.BASE &&
  product.usageType === UsageTypeEnum.LICENSED;

const isMeteredBaseProduct = (product: IProductResponseMappableProps) =>
  product.type === ProductTypeEnum.BASE &&
  product.usageType === UsageTypeEnum.METERED;

const isDefaultProductBundle = (product: IProductResponseMappableProps) =>
  product.type === ProductTypeEnum.BUNDLE &&
  product.name === ProductNameEnum.starter_bundle;

const isPreselectedProduct = (
  product: IProductResponseMappableProps,
  preselectedProductNames: ProductNameEnum[]
) => preselectedProductNames.includes(product.name as ProductNameEnum);

const extendWithIcon = <T extends Pick<IProductResponseMappableProps, "name">>(
  data: T
) => ({
  ...data,
  icon: (data.name && iconProductMapping[data.name.toLowerCase()]) ?? "graphql",
});

const parseBenefits = <
  T extends Pick<IProductResponseMappableProps, "benefitConnection">
>({
  benefitConnection,
  ...rest
}: T) => ({
  ...rest,
  ...(benefitConnection && {
    benefits: benefitConnection.items.reduce(
      (acc, benefit) => {
        if (benefit?.lang && benefit?.description) {
          acc[benefit.lang as keyof ILocalizedBenefits].push(
            benefit.description
          );
        }

        return acc;
      },
      { EN: [], DE: [] } as ILocalizedBenefits
    ),
  }),
});

const parsePrices = <T extends IProductResponseMappableProps>(product: T) => {
  const { priceConnection, ...rest } = product;
  const prices = (product.priceConnection?.items || []).map((item) =>
    mapResponseToPrice<T["priceConnection"]["items"][number]>(item)
  );

  type pricesType = typeof prices;

  return {
    ...rest,
    pricings: prices.reduce((acc, price, index) => {
      const billingInterval: BillingIntervalEnum | undefined =
        prices[index].billingInterval;
      if (billingInterval) {
        acc[billingInterval] = acc[billingInterval] ?? [];
        acc[billingInterval]?.push(price);
      }

      return acc;
    }, {} as Partial<Record<BillingIntervalEnum, pricesType>>),
  };
};

const extendWithSelection =
  <T extends IProductResponseMappableProps>(
    lineItems: IPreselectedLineItems,
    preselectedProductNames: ProductNameEnum[]
  ) =>
  (product: T) => {
    const { selectedPricingIndex = 0, priceConnection } = product;
    const prices = priceConnection?.items || [];
    const defaultSelected =
      isMeteredBaseProduct(product) ||
      isDefaultProductBundle(product) ||
      isPreselectedProduct(product, preselectedProductNames);
    const defaultPricingIndex =
      selectedPricingIndex < prices.length
        ? Math.max(selectedPricingIndex, 0)
        : 0;

    const preselectedPricingIndex = prices.reduce((acc, price, index) => {
      const paymentProviderId: string | undefined = price.paymentProviderId;

      return paymentProviderId && lineItems[paymentProviderId] ? index : acc;
    }, -1);

    const selectedProductPricingIndex =
      preselectedPricingIndex >= 0 && preselectedPricingIndex <= prices.length
        ? preselectedPricingIndex
        : defaultPricingIndex;
    const isProductSelected =
      preselectedPricingIndex >= 0 && preselectedPricingIndex <= prices.length
        ? !isLicensedBaseProduct(product)
        : defaultSelected;

    return {
      ...product,
      selected: isProductSelected,
      selectedPricingIndex: selectedProductPricingIndex,
    };
  };

export const mapResponseToProduct =
  <T extends IProductResponseMappableProps>(
    lineItems: IPreselectedLineItems,
    preselectedProductNames: ProductNameEnum[]
  ) =>
  (data: T) => {
    const productWithIcon = extendWithIcon<T>(data);
    const productWithSelection = extendWithSelection<typeof productWithIcon>(
      lineItems,
      preselectedProductNames
    )(productWithIcon);
    const productWithPrices =
      parsePrices<typeof productWithSelection>(productWithSelection);

    return parseBenefits<typeof productWithPrices>(productWithPrices);
  };
