import { DocumentNode, MutationUpdaterFn } from "apollo-boost";
import {
  addToListFrontAfterCreation,
  addToListFrontAfterBatchCreation,
  removeFromListAfterBatchDeletion,
  clearObjectsCacheAfterBatchDeletion,
} from "../../utils/apollo-cache-updates";
import { FieldNameComposer } from "../shared/fieldname-composer";
import { MutationTypeEnum, QueryTypeEnum } from "@graphapi-io/api-declaration";

export abstract class CacheUpdateWrapper {
  public static addToListFrontAfterCreationFactory(
    typeName: string,
    variables: Record<string, unknown>,
    query: DocumentNode
  ): MutationUpdaterFn<any> {
    return addToListFrontAfterCreation<
      any,
      string,
      any,
      string,
      Record<string, unknown>
    >(
      FieldNameComposer.fieldNameForMutationType(
        MutationTypeEnum.CREATE,
        typeName
      ),
      variables,
      FieldNameComposer.fieldNameForQueryType(QueryTypeEnum.LIST, typeName),
      query
    );
  }

  public static addToListFrontAfterBatchCreationFactory(
    typeName: string,
    variables: Record<string, unknown>,
    query: DocumentNode
  ): MutationUpdaterFn<any> {
    return addToListFrontAfterBatchCreation<
      any,
      string,
      any,
      string,
      Record<string, unknown>
    >(
      FieldNameComposer.fieldNameForMutationType(
        MutationTypeEnum.BATCH_CREATE,
        typeName
      ),
      variables,
      FieldNameComposer.fieldNameForQueryType(QueryTypeEnum.LIST, typeName),
      query
    );
  }

  public static clearObjectsAfterBatchDeletionFactory(
    typeName: string,
    query: DocumentNode
  ): MutationUpdaterFn<any> {
    return clearObjectsCacheAfterBatchDeletion(
      FieldNameComposer.fieldNameForMutationType(
        MutationTypeEnum.BATCH_DELETE,
        typeName
      ),
      query
    );
  }

  public static clearListAfterBatchDeletionFactory(
    typeName: string,
    variables: Record<string, unknown>,
    query: DocumentNode
  ): MutationUpdaterFn<any> {
    return removeFromListAfterBatchDeletion<
      any,
      string,
      any,
      string,
      Record<string, unknown>
    >(
      FieldNameComposer.fieldNameForMutationType(
        MutationTypeEnum.BATCH_DELETE,
        typeName
      ),
      variables,
      FieldNameComposer.fieldNameForQueryType(QueryTypeEnum.LIST, typeName),
      query
    );
  }

  public static callBoth(
    fn1: MutationUpdaterFn<any>,
    fn2: MutationUpdaterFn<any>
  ): MutationUpdaterFn<any> {
    return (...params) => {
      fn1(...params);
      fn2(...params);
    };
  }
}
