import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import Table from "../shared/table/TableView.component";
import { ICellChangePayload } from "../shared/table/types";
import { ApiDataObjectTypeTableData } from "./ApiDataObjectTypeTableData.mixin";
import { exportString, getErrorMessage, slugFromName } from "@/utils";
import { TableSelectionState } from "../shared/table/table-selection-state";
import Vue from "vue";
import { SelectedDataExtractor } from "../shared/table/selected-data-extractor";
import _pick from "lodash/pick";
import { TableEditingState } from "../shared/table/table-editing-state";
import { IObjectTypeState } from "@/models/object-type/types";
import { IObjectStackElement } from "../object-view/ObjectStack.component";
import UserPermissions from "@/mixins/UserPermissions.mixin";

@Component
export class ApiDataObjectTypeTableState extends Mixins(
  ApiDataObjectTypeTableData,
  UserPermissions
) {
  @Prop({ required: true }) objectStack: IObjectStackElement[];

  $refs!: {
    table: Table;
  };

  protected get tableEditingState() {
    return Vue.observable(new TableEditingState(this.fields));
  }

  protected tableSelectionState = Vue.observable(
    new TableSelectionState(true, true)
  );

  protected selectedDataExtractor = new SelectedDataExtractor(
    [],
    [],
    true,
    true
  );

  @Watch("objectStack", { immediate: true }) protected onObjectStackChanged(
    value: IObjectTypeState[]
  ) {
    if (this.listDataManager.data[0]?.id === null && value[0]?.id !== null) {
      this.listDataManager.data.shift();
    }
  }

  @Watch("errorMessage", { immediate: true }) protected onError() {
    this.$emit("error", this.listDataManager.errorMessage);
  }

  protected editTableEntry(dataEntryIndex: number) {
    const dataEntry = this.listDataManager.data[dataEntryIndex];
    this.$emit("editTableEntry", {
      dataEntry,
      objectType: this.objectType,
    });
  }

  protected onAddEntry() {
    if (!this.apiState.graphqlUrl) {
      this.listDataManager.errorMessage = this.$t(
        "error_api_management_before_publish"
      ).toString();
      return;
    }

    this.listDataManager.errorMessage = "";

    this.listDataManager.data.unshift({
      id: null as unknown as string,
      createdAt: "",
      updatedAt: "",
    });
    this.editTableEntry(0);
  }

  protected async onDeleteEntries() {
    try {
      if (this.hasAdminPermissions) {
        const selectedEntries = this.listDataManager
          .entriesFromSelectionState(this.tableSelectionState)
          .map((entry) => _pick(entry, this.requiredDeleteInputParams));

        if (
          window.confirm(
            this.$t("api_details_data_delete_entries_confirm", {
              numOfEntries:
                selectedEntries.length > 1
                  ? this.$t("global_many_entries", {
                      numOfEntries: selectedEntries.length,
                    })
                  : this.$t("global_single_entry"),
              typeName: this.objectType.name,
            }).toString()
          )
        ) {
          await this.deleteData(selectedEntries);
        }
      } else {
        throw new Error(
          this.$t("error_api_management_requires_role").toString()
        );
      }
    } catch (err) {
      this.listDataManager.errorMessage = getErrorMessage(err);
    }
  }

  protected async onExportData() {
    try {
      this.exportData();
    } catch (err) {
      this.listDataManager.errorMessage = getErrorMessage(err);
    }
  }

  private exportData(currentPage = 1): void {
    exportString(
      `${slugFromName(this.apiState.name ?? "", "")}-${
        this.objectType?.name
      }-page${currentPage}`,
      this.selectedDataExtractor.extractDataToCopy(
        this.tableSelectionState.selectedRows,
        this.tableSelectionState.selectedColumns,
        this.tableSelectionState.selectedCells,
        true
      )
    );
  }

  protected async handleCellChange(payload: ICellChangePayload) {
    await this.updateItem(payload);
    this.tableEditingState.isEditing = false;
    this.tableEditingState.shouldReplaceContent = false;
    this.tableSelectionState.deselectAll();
    this.tableSelectionState.selectCellBelow();
  }
}
