import { Vue, Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import {
  emailFormatter,
  floatFormatter,
  locationFormatter,
  intFormatter,
  phoneFormatter,
  websiteFormatter,
  isValidUrl,
  upperCaseFirstLetter,
  isNotNullable,
} from "@/utils";
import { BFormInput, IBFormInputEvents } from "@/lib/typed-bootstrap";

@Component
export default class FormInput extends Vue {
  _tsx!: tsx.DeclareProps<
    Pick<FormInput, "placeholderText"> &
      Partial<
        Pick<
          FormInput,
          | "value"
          | "shouldValidate"
          | "readonly"
          | "autocomplete"
          | "min"
          | "max"
          | "maxLength"
          | "inputType"
          | "id"
        >
      >
  > &
    tsx.DeclareOnEvents<IBFormInputEvents>;

  @Prop() value?: any;
  @Prop({ required: true }) readonly placeholderText: string;
  @Prop({ default: false }) readonly shouldValidate?: boolean;
  @Prop({ default: false }) readonly readonly?: boolean;
  @Prop({ default: "off" }) readonly autocomplete?: string;
  @Prop() readonly min?: number;
  @Prop() readonly max?: number;
  @Prop({ default: 65536 }) readonly maxLength?: number;
  @Prop({ default: "unknown" }) readonly inputType?: string;
  @Prop() readonly id?: string;

  $refs!: {
    inputField: HTMLInputElement;
  };

  private get inputKey(): string {
    return `formInput${upperCaseFirstLetter(
      this.id || this.inputType || Date.now().toString()
    )}`;
  }

  private get internalValue(): any {
    return this.value;
  }

  private set internalValue(value: any) {
    this.$emit("input", value);
  }

  private get minValue(): number {
    if (this.min !== undefined) return this.min;

    switch (this.inputType) {
      case "Float":
        return Number.NEGATIVE_INFINITY;

      default:
      case "Int":
        return -Math.pow(2, 31);
    }
  }

  private get maxValue(): number {
    if (this.max !== undefined) return this.max;

    switch (this.inputType) {
      case "Float":
        return Number.MAX_VALUE;

      default:
      case "Int":
        return Math.pow(2, 31) - 1;
    }
  }

  private get isValidValue(): boolean {
    switch (this.inputType) {
      case "url":
      case "website":
        return isValidUrl(this.internalValue);

      case "Int":
      case "Float":
        return (
          isNotNullable(this.internalValue) &&
          (this.internalValue as number) <= this.maxValue &&
          (this.internalValue as number) > this.minValue
        );

      default:
        return !!this.internalValue;
    }
  }

  private inputFormatter(value: string): string {
    switch (this.inputType) {
      case "Int":
        return intFormatter(value, this.maxLength);

      case "Float":
        return floatFormatter(value);

      case "email":
        return emailFormatter(value);

      case "location":
        return locationFormatter(value);

      case "phone":
        return phoneFormatter(value);

      case "website":
        return websiteFormatter(value);

      default:
        return value;
    }
  }

  private handleKeyUp(ev: KeyboardEvent) {
    if (ev.key === "Escape") {
      this.$refs.inputField.blur();
      this.$emit("onEscapeEditing");
    }
  }

  public onBeginEditing(): void {
    setTimeout(() => {
      this.$refs.inputField.focus();
    }, 1);
  }

  render() {
    const {
      inputKey,
      placeholderText,
      shouldValidate,
      readonly,
      autocomplete,
      inputType,
      maxLength,
      minValue,
      maxValue,
      internalValue,
      isValidValue,
    } = this;

    return (
      <BFormInput
        id={inputKey}
        data-testid={inputKey}
        ref="inputField"
        class="default__inline-form-input"
        placeholder={placeholderText}
        value={internalValue}
        state={shouldValidate && !isValidValue ? false : null}
        maxLength={maxLength}
        min={[inputType === "Int" || inputType === "Float"] && minValue}
        max={[inputType === "Int" || inputType === "Float"] && maxValue}
        required
        type={inputType === "Int" ? "number" : "text"}
        autocomplete={autocomplete}
        formatter={this.inputFormatter}
        readonly={!!readonly}
        nativeOn={{
          keyup: this.handleKeyUp,
        }}
        on={this.$listeners}
      ></BFormInput>
    );
  }
}
