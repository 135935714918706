import { authManager } from "@/utils/auth-service/auth-manager";
import { Vue, Component } from "vue-property-decorator";
import { Button, ButtonThemeEnum } from "../shared/Button.component";

@Component({
  components: { Button },
})
export default class SessionExpiredModal extends Vue {
  modalId = "session-modal";
  countDown = 60;
  timerId: ReturnType<typeof setTimeout>;

  get title() {
    return this.$t("form_session_expired_title").toString();
  }

  get info(): string {
    return this.$t("form_session_expired_info", {
      countDown: this.countDown,
    }).toString();
  }

  get instruction(): string {
    return this.$t("form_session_expired_question").toString();
  }

  get logoutButtonTheme() {
    return ButtonThemeEnum.naked;
  }

  get continueButtonTheme() {
    return ButtonThemeEnum.primary;
  }

  countDownTimer() {
    if (this.countDown > 0) {
      this.timerId = setTimeout(() => {
        this.countDown -= 1;
        this.countDownTimer();
      }, 1000);
    } else {
      this.onLogout();
    }
  }

  showModal() {
    this.countDown = 60;
    this.countDownTimer();
  }

  resetModal() {
    clearTimeout(this.timerId);
  }

  onLogout() {
    this.$bvModal.hide(this.modalId);
    authManager.logout();
  }

  onContinue() {
    this.$bvModal.hide(this.modalId);
  }
}
