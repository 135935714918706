import {
  BaseFieldTypeEnum,
  ComplexFieldTypeEnum,
  DateFieldTypeEnum,
  IFieldType,
  ListFieldTypeEnum,
} from "@/models/object-type/types";

import iconTextWhite from "@/assets/img/field-type-text-white.svg";
import iconTextBlack from "@/assets/img/field-type-text-black.svg";

import iconListTextWhite from "@/assets/img/field-type-text-list-white.svg";
import iconListTextBlack from "@/assets/img/field-type-text-list-black.svg";

import iconNumberWhite from "@/assets/img/field-type-number-white.svg";
import iconNumberBlack from "@/assets/img/field-type-number-black.svg";

import iconListNumberWhite from "@/assets/img/field-type-number-list-white.svg";
import iconListNumberBlack from "@/assets/img/field-type-number-list-black.svg";

import iconIdWhite from "@/assets/img/field-type-id-white.svg";
import iconIdBlack from "@/assets/img/field-type-id-black.svg";

import iconListIdWhite from "@/assets/img/field-type-id-list-white.svg";
import iconListIdBlack from "@/assets/img/field-type-id-list-black.svg";

import iconIntWhite from "@/assets/img/field-type-int-white.svg";
import iconIntBlack from "@/assets/img/field-type-int-black.svg";

import iconListIntWhite from "@/assets/img/field-type-int-list-white.svg";
import iconListIntBlack from "@/assets/img/field-type-int-list-black.svg";

import iconBooleanWhite from "@/assets/img/field-type-checkbox-white.svg";
import iconBooleanBlack from "@/assets/img/field-type-checkbox-black.svg";

import iconListBooleanWhite from "@/assets/img/field-type-boolean-list-white.svg";
import iconListBooleanBlack from "@/assets/img/field-type-boolean-list-black.svg";

import iconTimeWhite from "@/assets/img/field-type-time-white.svg";
import iconTimeBlack from "@/assets/img/field-type-time-black.svg";

import iconTimestampWhite from "@/assets/img/field-type-timestamp-white.svg";
import iconTimestampBlack from "@/assets/img/field-type-timestamp-black.svg";

import iconDateWhite from "@/assets/img/field-type-date-white.svg";
import iconDateBlack from "@/assets/img/field-type-date-black.svg";

import iconDatetimeWhite from "@/assets/img/field-type-datetime-white.svg";
import iconDatetimeBlack from "@/assets/img/field-type-datetime-black.svg";

import iconLinkWhite from "@/assets/img/field-type-link-white.svg";
import iconLinkBlack from "@/assets/img/field-type-link-black.svg";

import iconJsonWhite from "@/assets/img/field-type-json-white.svg";
import iconJsonBlack from "@/assets/img/field-type-json-black.svg";

import iconEmailWhite from "@/assets/img/field-type-email-white.svg";
import iconEmailBlack from "@/assets/img/field-type-email-black.svg";

import iconPhoneWhite from "@/assets/img/field-type-phone-white.svg";
import iconPhoneBlack from "@/assets/img/field-type-phone-black.svg";

import iconIpWhite from "@/assets/img/field-type-ip-white.svg";
import iconIpBlack from "@/assets/img/field-type-ip-black.svg";

import iconSelectWhite from "@/assets/img/field-type-select-white.svg";
import iconSelectBlack from "@/assets/img/field-type-select-black.svg";

export const FIELD_TYPE_ICONS: Record<
  | BaseFieldTypeEnum
  | ListFieldTypeEnum
  | DateFieldTypeEnum
  | ComplexFieldTypeEnum,
  { dark: string; light: string }
> = {
  [BaseFieldTypeEnum.Boolean]: {
    dark: iconBooleanWhite,
    light: iconBooleanBlack,
  },
  [BaseFieldTypeEnum.Float]: {
    dark: iconNumberWhite,
    light: iconNumberBlack,
  },
  [BaseFieldTypeEnum.ID]: {
    dark: iconIdWhite,
    light: iconIdBlack,
  },
  [BaseFieldTypeEnum.Integer]: {
    dark: iconIntWhite,
    light: iconIntBlack,
  },
  [BaseFieldTypeEnum.String]: {
    dark: iconTextWhite,
    light: iconTextBlack,
  },
  [ListFieldTypeEnum.ListBoolean]: {
    dark: iconListBooleanWhite,
    light: iconListBooleanBlack,
  },
  [ListFieldTypeEnum.ListFloat]: {
    dark: iconListNumberWhite,
    light: iconListNumberBlack,
  },
  [ListFieldTypeEnum.ListId]: {
    dark: iconListIdWhite,
    light: iconListIdBlack,
  },
  [ListFieldTypeEnum.ListInteger]: {
    dark: iconListIntWhite,
    light: iconListIntBlack,
  },
  [ListFieldTypeEnum.ListString]: {
    dark: iconListTextWhite,
    light: iconListTextBlack,
  },
  [DateFieldTypeEnum.Date]: {
    dark: iconDateWhite,
    light: iconDateBlack,
  },
  [DateFieldTypeEnum.DateTime]: {
    dark: iconDatetimeWhite,
    light: iconDatetimeBlack,
  },
  [DateFieldTypeEnum.Time]: {
    dark: iconTimeWhite,
    light: iconTimeBlack,
  },
  [DateFieldTypeEnum.Timestamp]: {
    dark: iconTimestampWhite,
    light: iconTimestampBlack,
  },
  [ComplexFieldTypeEnum.Email]: {
    dark: iconEmailWhite,
    light: iconEmailBlack,
  },
  [ComplexFieldTypeEnum.IpAddress]: {
    dark: iconIpWhite,
    light: iconIpBlack,
  },
  [ComplexFieldTypeEnum.Json]: {
    dark: iconJsonWhite,
    light: iconJsonBlack,
  },
  [ComplexFieldTypeEnum.Phone]: {
    dark: iconPhoneWhite,
    light: iconPhoneBlack,
  },
  [ComplexFieldTypeEnum.Url]: {
    dark: iconLinkWhite,
    light: iconLinkBlack,
  },
};

export const FIELD_TYPE_INPUT_TYPES = {
  [BaseFieldTypeEnum.Boolean]: "text",
  [BaseFieldTypeEnum.Float]: "number",
  [BaseFieldTypeEnum.ID]: "text",
  [BaseFieldTypeEnum.Integer]: "number",
  [BaseFieldTypeEnum.String]: "text",
  [ListFieldTypeEnum.ListBoolean]: "text",
  [ListFieldTypeEnum.ListFloat]: "text",
  [ListFieldTypeEnum.ListId]: "text",
  [ListFieldTypeEnum.ListInteger]: "text",
  [ListFieldTypeEnum.ListString]: "text",
  [DateFieldTypeEnum.Date]: "text",
  [DateFieldTypeEnum.DateTime]: "text",
  [DateFieldTypeEnum.Time]: "time",
  [DateFieldTypeEnum.Timestamp]: "number",
  [ComplexFieldTypeEnum.Email]: "email",
  [ComplexFieldTypeEnum.IpAddress]: "text",
  [ComplexFieldTypeEnum.Json]: "text",
  [ComplexFieldTypeEnum.Phone]: "tel",
  [ComplexFieldTypeEnum.Url]: "url",
};

export const iconSrcForEnumTypes = {
  dark: iconSelectWhite,
  light: iconSelectBlack,
};

export const getIconForFieldType = (fieldType: IFieldType | null) => {
  return fieldType !== null
    ? FIELD_TYPE_ICONS[fieldType] ?? iconSrcForEnumTypes
    : FIELD_TYPE_ICONS[BaseFieldTypeEnum.String];
};

export const getInputTypeForFieldType = (fieldType: IFieldType | undefined) => {
  return fieldType
    ? FIELD_TYPE_INPUT_TYPES[fieldType] ??
        FIELD_TYPE_INPUT_TYPES[BaseFieldTypeEnum.String]
    : FIELD_TYPE_INPUT_TYPES[BaseFieldTypeEnum.String];
};

export const getSelectionColumnIndex = () => {
  return 0;
};

export const getEditColumnIndex = (hasSelectionColumn: boolean) => {
  return hasSelectionColumn ? 1 : 0;
};

export const getDataColumnIndexOffset = (
  hasSelectionColumn: boolean,
  hasEditColumn: boolean
) => {
  if (hasSelectionColumn && hasEditColumn) {
    return 2;
  }
  if (hasSelectionColumn || hasEditColumn) {
    return 1;
  }
  return 0;
};
