import { upperCaseFirstLetter } from "@/utils";
import { Vue, Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
@Component
export default class FormReadonly extends Vue {
  _tsx!: tsx.DeclareProps<{
    id?: FormReadonly["id"];
    value?: FormReadonly["value"];
    label: FormReadonly["label"];
    labelAmendment: FormReadonly["labelAmendment"];
    upperCaseLabel?: FormReadonly["upperCaseLabel"];
  }>;

  @Prop() id?: string;
  @Prop() value: any;
  @Prop({ required: true }) readonly label: string;
  @Prop({ required: true }) readonly labelAmendment: string;
  @Prop({ default: true }) readonly upperCaseLabel: boolean;

  get fieldName() {
    return `formReadonlyInput${upperCaseFirstLetter(this.id ?? "unknown")}`;
  }

  get readonlyValue(): string {
    return this.value ?? this.$t("form_placeholder_auto_generated");
  }
}
