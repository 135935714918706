import { IAnyArray, IAnyFunction, IAnyObject } from "./types";

export function isDate(val: any): boolean {
  return Object.prototype.toString.call(val) === "[object Date]";
}

export function isValidEmailAddress(email: string): boolean {
  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return emailRegex.test(email);
}

export function isValidUrl(url: string): boolean {
  const urlRegex = /^(sftp|ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(url);
}

export function isNotNullable<T>(
  val: T | null | undefined
): val is NonNullable<T> {
  return val !== undefined && val !== null;
}

export const isArray = (val: unknown): val is IAnyArray => Array.isArray(val);

export const isObject = (val: unknown): val is IAnyObject =>
  typeof val === "object" && val !== null;

export const isFunction = (val: unknown): val is IAnyFunction => {
  return Object.prototype.toString.call(val) === "[object Function]";
};
