import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { ICellProps, renderCell } from "./Cell.component";
import styles from "./JSONCellInEditMode.component.module.scss";
import { ICellDataContent } from "../types";
import { Element } from "vue-tsx-support/types/base";
import JsonEditorVue from "json-editor-vue";

type JSONValue =
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>
  | null;

@Component
export default class JSONCellInEditMode extends Vue {
  _tsx!: tsx.DeclareProps<{
    cellState: JSONCellInEditMode["cellState"];
    value: JSONCellInEditMode["value"];
    rawValue: JSONCellInEditMode["rawValue"];
    inputType?: JSONCellInEditMode["inputType"];
    replaceModeEnabled?: JSONCellInEditMode["replaceModeEnabled"];
  }> &
    tsx.DeclareOnEvents<{
      onCellChange: string | number;
    }>;
  $refs!: {
    wrapper?: HTMLElement;
  };

  @Prop({ required: true }) cellState: ICellProps;
  @Prop({ required: true }) value: Element | string | undefined;
  @Prop({ required: true }) rawValue: ICellDataContent;
  @Prop({ default: "text" }) inputType: string;
  @Prop({ default: false }) replaceModeEnabled: boolean;

  private internalValue: JSONValue = {};

  @Watch("value", { immediate: true })
  onValueChange() {
    this.internalValue = JSON.parse(this.rawValue?.toString() ?? "");
  }

  private handleKeyDown(ev: KeyboardEvent) {
    if (ev.key === "Enter" && ev.metaKey) {
      ev.stopPropagation();
      ev.stopImmediatePropagation();
      ev.preventDefault();
      this.$emit("cellChange", this.internalValue);
    }
  }

  private handleChange(value: string) {
    this.internalValue = value;
  }

  render() {
    return renderCell(this.$createElement, {
      props: {
        ...this.cellState,
        contentClass: styles.content,
      },
      data: {
        class: {
          [styles.editCell]: true,
        },
      },
      children: (
        <span class={styles.wrapper}>
          <div
            tabindex={0}
            onKeydown={this.handleKeyDown}
            style={{ outline: "none" }}
          >
            <JsonEditorVue
              value={this.internalValue}
              onInput={this.handleChange}
              onKeyDown={this.handleKeyDown}
              class={styles.jsonEditor}
              {...{
                props: {
                  mainMenuBar: false,
                  navigationBar: false,
                  mode: "text",
                },
              }}
            />
          </div>
        </span>
      ),
    });
  }
}
