import { Vue, Component, Prop } from "vue-property-decorator";
import { isValidEmailAddress, emailFormatter } from "@/utils";
import * as tsx from "vue-tsx-support";

@Component
export default class FormEmail extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: FormEmail["value"];
    placeholderText: FormEmail["placeholderText"];
    shouldValidate?: FormEmail["shouldValidate"];
    isDisabled?: FormEmail["isDisabled"];
    label?: FormEmail["label"];
    autocompleteType?: FormEmail["autocompleteType"];
  }> &
    tsx.DeclareOnEvents<{
      onInput: string;
    }>;
  @Prop({ required: true }) value: string;
  @Prop({ required: true }) readonly placeholderText!: string;
  @Prop({ default: false }) readonly shouldValidate: boolean;
  @Prop({ required: false, default: false }) readonly isDisabled: boolean;
  @Prop() readonly label!: string;
  @Prop({ default: "username" }) readonly autocompleteType: string;

  get formLabel(): string {
    return this.label ?? this.$t("global_email");
  }

  get internalValue(): string {
    return this.value;
  }

  set internalValue(email: string) {
    this.$emit("input", email);
  }

  get isValidEmailAddress(): boolean | null {
    return this.internalValue?.length > 0
      ? isValidEmailAddress(this.internalValue)
      : null;
  }

  inputFormatter(value: string): string {
    return emailFormatter(value);
  }
}
