import { createSubscriptionItemsMutation } from "@/api/subscription_item";

export const createSubscriptionItemsFromIds = async (
  ids: string[],
  bundleSubscriptionId: string
) => {
  await Promise.all(
    ids.map((id) =>
      createSubscriptionItemsMutation({
        input: {
          paymentProviderId: id,
          paymentProviderPriceId: "",
          bundleSubscriptionId,
          quantity: 0,
        },
      })
    )
  );
};
