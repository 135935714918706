import { IRenderFn } from "@/utils/types";
import { ICellEvents, ICellProps, renderCell } from "./Cell.component";

export interface IUrlCellProps extends ICellProps {
  url: string;
}

export const renderUrlCell: IRenderFn<IUrlCellProps, ICellEvents> = (
  h,
  { props, children, listeners, data }
) =>
  renderCell(h, {
    props,
    children: (
      <a
        href={props.url}
        target="_blank"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      >
        {children}
      </a>
    ),
    listeners,
    data,
  });
