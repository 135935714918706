import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import _debounce from "lodash/debounce";
import { IApiState, IObjectTypeState } from "@/models";
import ApiDataObjectTypeTable from "@/components/api-data/ApiDataObjectTypeTable.component";
import Tabs from "@/components/shared/Tabs.component";
import styles from "./ApiDataObjectsData.component.module.scss";
import rightArrowIconDark from "@/assets/img/tabs-right-arrow-white.svg";
import rightArrowIconLight from "@/assets/img/tabs-right-arrow-dark.svg";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import Vue from "vue";
import { DedicatedApi } from "@/api-client";
import { ISchemaExplorerType } from "@/api/schema";
import { IItem } from "./ApiDataObjectTypeTableData.mixin";
import * as tsx from "vue-tsx-support";
import { PublishingState } from "@/generated/types";
import ApiDataNewTypeDropdown from "./ApiDataNewTypeDropdown.component";
import { ApiSchema } from "@/models/api-schema";
import { ApiDataObjectTypeTabButton } from "./ApiDataObjectTypeTabButton.component";
import { IGraphqlApi } from "@/api-client/shared/types";
import { IObjectStackElement } from "../object-view/ObjectStack.component";

const rightArrowIcon = {
  dark: rightArrowIconDark,
  light: rightArrowIconLight,
};

const SCROLL_PADDING = 40;
const STATIC_ELEMENT_WIDTH = 150;

@Component
export default class ApiDataObjectsData extends Vue {
  _tsx!: tsx.DeclareProps<{
    selectedObjectType?: ApiDataObjectsData["selectedObjectType"];
    apiState: ApiDataObjectsData["apiState"];
    schemaFieldTypes?: ApiDataObjectsData["schemaFieldTypes"];
    customerApi?: ApiDataObjectsData["customerApi"];
    specificCustomerApi?: ApiDataObjectsData["specificCustomerApi"];
    objectStack: ApiDataObjectsData["objectStack"];
    apiSchema: ApiDataObjectsData["apiSchema"];
  }> &
    tsx.DeclareOnEvents<{
      onUpdateSelectedObjectType: IObjectTypeState;
      onEditTableEntry: {
        dataEntry: IItem;
        objectType: IObjectTypeState;
      };
      onError: string;
    }>;
  @Prop({ default: null }) selectedObjectType: IObjectTypeState | null;
  @Prop({ required: true }) apiState: IApiState;
  @Prop({ default: undefined }) schemaFieldTypes?: {
    [key: string]: ISchemaExplorerType;
  };
  @Prop({ default: undefined }) customerApi?: IGraphqlApi;
  @Prop({ default: undefined }) specificCustomerApi?: DedicatedApi;
  @Prop({ required: true }) objectStack: IObjectStackElement[];
  @Prop({ required: true }) apiSchema: ApiSchema;

  $refs!: {
    tabs: Tabs;
  };

  private canScrollLeft = false;
  private canScrollRight = false;
  private mustScroll = false;
  private containerObserver = new ResizeObserver(
    _debounce(this.recalculateScrollFlags, 100)
  );

  private get isImportedApi() {
    return this.apiState?.state === PublishingState.IMPORTED;
  }

  @Emit("updateSelectedObjectType") onUpdateSelectedObjectType(
    _objectType: IObjectTypeState
  ) {}

  @Emit("editTableEntry") onEditTableEntry(_params: {
    dataEntry: IItem;
    objectTypeName: string;
  }) {}

  @Emit("error") onError(_error: string) {}

  private onTabIndexChange(index: number) {
    const objectType = this.apiState.types[index];
    this.onUpdateSelectedObjectType(objectType);
  }

  private get currentTabIndex() {
    return this.apiState.types?.findIndex(
      (type) => type.name === this.selectedObjectType?.name
    );
  }

  private recalculateScrollFlags() {
    const container = this.$refs.tabs?.buttonsContainer;
    if (!container) {
      return;
    }
    const parentContainer = container.parentElement;
    if (!parentContainer) {
      return;
    }
    const tabsTotalWidth = Array.from(container.children).reduce(
      (acc, child) => {
        if (child.getAttribute("role") === "tab") {
          return acc + child.clientWidth;
        }
        return acc;
      },
      0
    );
    this.mustScroll =
      tabsTotalWidth > parentContainer.clientWidth - STATIC_ELEMENT_WIDTH;
    this.canScrollLeft = container.scrollLeft > 0;
    this.canScrollRight =
      container.scrollLeft + container.clientWidth + 1 < container.scrollWidth;
  }

  private onDirectionButtonClick(direction: "left" | "right") {
    const container = this.$refs.tabs?.buttonsContainer;
    if (!container) {
      return;
    }
    const children =
      direction === "left"
        ? Array.from(container.children).reverse()
        : Array.from(container.children);
    const firstNotVisibleButton = children.find((child) => {
      const childRect = child.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      return (
        (direction === "left" && childRect.left < containerRect.left) ||
        (direction === "right" && childRect.right > containerRect.right)
      );
    });
    if (!firstNotVisibleButton) {
      return;
    }
    const firstNotVisibleButtonRect =
      firstNotVisibleButton.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    container.scrollBy({
      behavior: "smooth",
      left:
        direction === "left"
          ? firstNotVisibleButtonRect.left - containerRect.left - SCROLL_PADDING
          : firstNotVisibleButtonRect.right -
            containerRect.right +
            SCROLL_PADDING,
    });
  }

  private onRightButtonClick() {
    this.onDirectionButtonClick("right");
  }

  private onLeftButtonClick() {
    this.onDirectionButtonClick("left");
  }

  private scrollToSelectedTab() {
    const buttonsContainer = this.$refs.tabs?.buttonsContainer;
    if (!buttonsContainer) {
      return;
    }

    const selectedButton = Array.from(buttonsContainer.children).find(
      (child) => child.getAttribute("aria-selected") === "true"
    );
    if (!selectedButton) {
      return;
    }

    const buttonRect = selectedButton.getBoundingClientRect();
    const buttonsContainerRect = buttonsContainer.getBoundingClientRect();

    if (buttonRect.left < buttonsContainerRect.left) {
      buttonsContainer.scrollBy({
        behavior: "smooth",
        left: buttonRect.left - buttonsContainerRect.left - SCROLL_PADDING,
        top: 0,
      });
    } else if (buttonRect.right > buttonsContainerRect.right) {
      buttonsContainer.scrollBy({
        behavior: "smooth",
        left: buttonRect.right - buttonsContainerRect.right + SCROLL_PADDING,
        top: 0,
      });
    }
  }

  @Watch("selectedObjectType", { immediate: true })
  onSelectedObjectTypeChange() {
    this.$nextTick(() => {
      const buttonsContainer = this.$refs.tabs.buttonsContainer;
      if (!buttonsContainer) {
        return;
      }

      this.scrollToSelectedTab();
      buttonsContainer.removeEventListener(
        "scroll",
        this.recalculateScrollFlags
      );
      buttonsContainer.addEventListener("scroll", this.recalculateScrollFlags);
      this.containerObserver.unobserve(buttonsContainer);
      this.containerObserver.observe(buttonsContainer);
      this.recalculateScrollFlags();
    });
  }

  private get buttonsScrollContainerClassName() {
    return [styles.objectTypeButtons, this.mustScroll ? styles.scrollable : ""]
      .join(" ")
      .trim();
  }

  private get outerContainerClassName() {
    return this.mustScroll ? styles.outerContainerWithBg : "";
  }

  private get scrollLeftButtonClass() {
    return [
      styles.scrollLeftButton,
      {
        [styles.visible]: this.canScrollLeft,
      },
    ];
  }

  private get scrollRightButtonClass() {
    return [
      styles.scrollRightButton,
      {
        [styles.visible]: this.canScrollRight,
      },
    ];
  }

  public render() {
    return (
      <Tabs
        ref="tabs"
        currentTabIndex={this.currentTabIndex}
        onTabIndexChange={this.onTabIndexChange}
        lazy
        buttonsScrollContainerClassName={this.buttonsScrollContainerClassName}
        outerContainerClassName={this.outerContainerClassName}
        class={styles.objectTypes}
        scopedSlots={{
          button: (props) => (
            <ApiDataObjectTypeTabButton
              key={props.index}
              typeName={props.title ?? ""}
              isSelected={props["aria-selected"] === "true"}
              onClick={props.onClick}
              role={props.role}
              aria-controls={props["aria-controls"]}
              aria-selected={props["aria-selected"]}
              apiState={this.apiState}
              onUpdateSelectedObjectType={this.onUpdateSelectedObjectType}
            />
          ),
        }}
      >
        <template slot="beforeButtons">
          <button
            class={this.scrollLeftButtonClass}
            onClick={this.onLeftButtonClick}
          >
            <ThemedImage
              src={rightArrowIcon}
              alt="Scroll Left"
              class={styles.icon}
            />
          </button>
        </template>
        <template slot="afterButtons">
          {!this.isImportedApi && !this.mustScroll && (
            <ApiDataNewTypeDropdown
              isCompact={false}
              apiState={this.apiState}
              onUpdateSelectedObjectType={this.onUpdateSelectedObjectType}
            />
          )}
          {this.canScrollRight && (
            <button
              class={this.scrollRightButtonClass}
              onClick={this.onRightButtonClick}
            >
              <ThemedImage
                src={rightArrowIcon}
                alt="Scroll Right"
                class={styles.icon}
              />
            </button>
          )}
        </template>
        <template slot="afterInnerContainer">
          {!this.isImportedApi && this.mustScroll && (
            <ApiDataNewTypeDropdown isCompact={true} apiState={this.apiState} />
          )}
        </template>
        {this.apiState.types?.map((type) => (
          <Tabs.Tab title={type.name} id={type.id} key={type.id}>
            {this.selectedObjectType && (
              <ApiDataObjectTypeTable
                apiSchema={this.apiSchema}
                apiState={this.apiState}
                objectType={this.selectedObjectType}
                schemaFieldTypes={this.schemaFieldTypes}
                customerApi={this.customerApi}
                dedicatedApi={this.specificCustomerApi}
                onEditTableEntry={this.onEditTableEntry}
                objectStack={this.objectStack}
                onError={this.onError}
              />
            )}
          </Tabs.Tab>
        ))}
      </Tabs>
    );
  }
}
