import {
  ICustomerApp,
  IListCustomerAppsByApiIdQuery,
  IQueryListCustomerAppsByApiArgs,
  ICreateCustomerAppMutation,
  ICreateCustomerAppMutationVariables,
  IQueryCustomerAppQuery,
  IQueryCustomerAppQueryVariables,
  IUpdateCustomerAppMutation,
  IUpdateCustomerAppMutationVariables,
  IDeleteCustomerAppMutation,
  IDeleteCustomerAppMutationVariables,
} from "@/generated/types";
import {
  mutationFunctionFactory,
  watchListObjectsFactory,
  watchQueryFunctionFactory,
} from "../internal-utils";
import LIST_CUSTOMER_APPS_BY_API_ID from "./list_customer_apps_by_api_id.graphql";
import CREATE_CUSTOMER_APP from "./create_customer_app.graphql";
import UPDATE_CUSTOMER_APP from "./update_customer_app.graphql";
import DELETE_CUSTOMER_APP from "./delete_customer_app.graphql";
import CUSTOMER_APP_QUERY from "./customer_app.graphql";
import { isNotNullable } from "@/utils";
import {
  addToListFrontAfterCreation,
  removeFromListAfterDeletion,
} from "@/utils/apollo-cache-updates";

export const watchCustomerAppsByApiIdQuery = watchListObjectsFactory<
  IListCustomerAppsByApiIdQuery,
  IQueryListCustomerAppsByApiArgs,
  ICustomerApp
>(
  LIST_CUSTOMER_APPS_BY_API_ID,
  (result) => {
    const items =
      result.listCustomerAppsByApi?.items?.filter(isNotNullable) ?? [];
    const nextToken = result.listCustomerAppsByApi?.nextToken ?? null;

    return { items, nextToken };
  },
  (updatedResult) => ({
    listCustomerAppsByApi: {
      items: updatedResult.items,
      nextToken: updatedResult.nextToken,
    },
  })
);

export const createCustomerAppMutation = mutationFunctionFactory<
  ICreateCustomerAppMutation,
  ICreateCustomerAppMutationVariables
>(
  CREATE_CUSTOMER_APP,
  undefined,
  undefined,
  (dataProxy, mutationResult, variables) =>
    addToListFrontAfterCreation<
      ICreateCustomerAppMutation,
      "createCustomerApp",
      IListCustomerAppsByApiIdQuery,
      "listCustomerAppsByApi",
      IQueryListCustomerAppsByApiArgs
    >(
      "createCustomerApp",
      { apiId: variables?.input.apiId || "" },
      "listCustomerAppsByApi",
      LIST_CUSTOMER_APPS_BY_API_ID
    )(dataProxy, mutationResult)
);

export const updateCustomerAppMutation = mutationFunctionFactory<
  IUpdateCustomerAppMutation,
  IUpdateCustomerAppMutationVariables
>(UPDATE_CUSTOMER_APP);

export const deleteCustomerAppMutation = mutationFunctionFactory<
  IDeleteCustomerAppMutation,
  IDeleteCustomerAppMutationVariables
>(DELETE_CUSTOMER_APP, undefined, undefined, (dataProxy, mutationResult) =>
  removeFromListAfterDeletion<
    IDeleteCustomerAppMutation,
    "deleteCustomerApp",
    IListCustomerAppsByApiIdQuery,
    "listCustomerAppsByApi",
    IQueryListCustomerAppsByApiArgs
  >(
    "deleteCustomerApp",
    { apiId: mutationResult.data?.deleteCustomerApp?.apiId || "" },
    "listCustomerAppsByApi",
    LIST_CUSTOMER_APPS_BY_API_ID
  )(dataProxy, mutationResult)
);

export const watchCustomerAppQuery = (
  variables: IQueryCustomerAppQueryVariables
) =>
  watchQueryFunctionFactory<
    IQueryCustomerAppQuery,
    IQueryCustomerAppQueryVariables
  >(CUSTOMER_APP_QUERY, variables);
