import { Vue, Component } from "vue-property-decorator";
import { getErrorMessage } from "@/utils";
import { updateApiMutation } from "@/api/api";
import { IApiState, watchApiDetails } from "@/models";

@Component
export default class ApiData extends Vue {
  protected apiDataError: string = "";
  protected apiData: IApiState | null = null;
  protected apiDataLoading: boolean = true;

  protected get apiId() {
    return this.$route.params.apiId ?? this.apiData?.id;
  }

  protected get isPublishing() {
    return this.apiData?.state?.startsWith("PUBLISHING");
  }

  async created() {
    this.apiDataLoading = true;
    if (this.$route?.params.apiId) {
      watchApiDetails({
        id: this.$route.params.apiId,
      }).subscribe({
        next: (data) => {
          this.apiData = data;
          this.apiDataLoading = false;
        },
        error: (e) => {
          this.apiDataError = getErrorMessage(e);
          this.apiDataLoading = false;
        },
      });
    }
  }

  protected async updateSchema(schema: string) {
    return updateApiMutation({
      id: this.apiId,
      schema: schema,
      ...(this.apiData?.awsAccountId && {
        awsAccountId: this.apiData.awsAccountId,
      }),
    });
  }

  protected async updateQueryString(query: string) {
    return updateApiMutation({
      id: this.apiId,
      queryExplorerString: query,
    });
  }

  protected handleSignInClick() {
    this.$bvModal.show("auth-modal");
  }

  protected handlePublishClick() {
    if (this.isPublishing) {
      this.$router.push({
        name: "api-setup-publishing",
        params: { apiId: this.apiId },
      });
    } else {
      if (this.apiData?.graphqlUrl) {
        this.$router.push({
          name: "api-setup-overview",
          params: { apiId: this.apiId },
        });
      } else {
        this.$router.push({
          name: "project-publish-auth",
          params: { apiId: this.apiId },
        });
      }
    }
  }
}
