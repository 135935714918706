import * as tsx from "vue-tsx-support";
import Vue from "vue";
import style from "./HeaderCell.component.module.scss";
import { IThemedSrc, ThemedImage } from "../../ThemedImage.component";
import downChevronWhite from "@/assets/img/down-chevron-white.svg";
import downChevronDark from "@/assets/img/down-chevron-dark.svg";
import downArrowPrimary from "@/assets/img/down-arrow-primary.svg";
import downArrowBlack from "@/assets/img/down-arrow-black.svg";
import downArrowWhite from "@/assets/img/down-arrow-white.svg";
import Dropdown from "../../Dropdown.component";
import { Component, Emit, Prop } from "vue-property-decorator";
import { SortOrderType } from "@/generated/types";
import { addSpacesToCamelCase } from "@/utils";

export type IHeaderCellEvents = {
  onClick: MouseEvent;
  onMousedown: MouseEvent;
};

const downChevronIconSrc = {
  dark: downChevronWhite,
  light: downChevronDark,
};

const downChevronIconSelectedSrc = {
  dark: downChevronDark,
  light: downChevronWhite,
};

const downArrowIconSrc = {
  dark: downArrowPrimary,
  light: downArrowBlack,
};

const downArrowIconSelectedSrc = {
  dark: downArrowBlack,
  light: downArrowWhite,
};

@Component
export class HeaderCell extends Vue {
  _tsx!: tsx.DeclareProps<{
    isReadonly?: HeaderCell["isReadonly"];
    isSelected?: HeaderCell["isSelected"];
    isInactive?: HeaderCell["isInactive"];
    isConfigurable?: HeaderCell["isConfigurable"];
    text?: HeaderCell["text"];
    iconSrc?: HeaderCell["iconSrc"];
    sortOrder?: HeaderCell["sortOrder"];
  }> &
    tsx.DeclareOnEvents<{ onSortToggle: SortOrderType }> &
    tsx.DeclareOnEvents<IHeaderCellEvents>;

  $refs!: {
    container: HTMLDivElement;
  };

  $scopedSlots!: tsx.InnerScopedSlots<{
    configureColumnForm: { close: () => void };
  }>;

  @Prop({ default: false }) isReadonly: boolean;
  @Prop({ default: false }) isSelected: boolean;
  @Prop({ default: false }) isInactive: boolean;
  @Prop({ default: false }) isConfigurable: boolean;
  @Prop({ default: null }) sortOrder: SortOrderType | null;
  @Prop({ default: "" }) text: string;
  @Prop({ required: false, default: null }) iconSrc: IThemedSrc | null;

  @Emit("sortToggle") onSortToggle(_value: SortOrderType) {}

  private containerElement: HTMLDivElement | null = null;

  private get inverseIconSrc() {
    return (
      this.iconSrc && {
        light: this.iconSrc?.dark,
        dark: this.iconSrc?.light,
      }
    );
  }

  private handleSortToggle(ev: MouseEvent) {
    ev.stopPropagation();
    this.onSortToggle(
      this.sortOrder === SortOrderType.ASC
        ? SortOrderType.DESC
        : SortOrderType.ASC
    );
  }

  private get cellText() {
    return this.text && this.isReadonly
      ? addSpacesToCamelCase(this.text).toUpperCase()
      : this.text;
  }

  mounted() {
    this.containerElement = this.$refs.container;
  }

  updated() {
    this.containerElement = this.$refs.container;
  }

  render() {
    return (
      <th
        class={{
          [style.cell]: true,
          [style.selected]: this.isSelected,
          [style.inactive]: this.isInactive,
        }}
        on={this.$listeners}
        ref="container"
      >
        <div class={style.layout}>
          {((this.iconSrc && this.inverseIconSrc) || this.cellText) && (
            <div class={style.label}>
              {this.iconSrc &&
                this.inverseIconSrc &&
                (this.isSelected ? (
                  <ThemedImage src={this.inverseIconSrc} />
                ) : (
                  <ThemedImage src={this.iconSrc} />
                ))}
              {this.cellText && (
                <span class={{ [style.readonly]: this.isReadonly }}>
                  {this.cellText}
                </span>
              )}
            </div>
          )}
          {this.$slots.default}
          {(this.isReadonly || this.isConfigurable || this.sortOrder) && (
            <div class={style.actions}>
              {this.sortOrder && (
                <button
                  class={[
                    style.sortButton,
                    { [style.selected]: this.isSelected },
                  ]}
                  onClick={this.handleSortToggle}
                >
                  <ThemedImage
                    class={[
                      style.sortIcon,
                      { [style.desc]: this.sortOrder === SortOrderType.DESC },
                    ]}
                    src={
                      this.isSelected
                        ? downArrowIconSelectedSrc
                        : downArrowIconSrc
                    }
                  />
                </button>
              )}
              {this.isConfigurable && (
                <Dropdown
                  toggleClass={[
                    style.configureButton,
                    { [style.selected]: this.isSelected },
                  ]}
                  anchor={this.containerElement}
                  topPlateOffset={10}
                  leftPlateOffset={10}
                  scopedSlots={{
                    menuContent: ({ close }) =>
                      this.$scopedSlots.configureColumnForm({ close }),
                  }}
                >
                  <template slot="buttonContent">
                    <ThemedImage
                      src={
                        this.isSelected
                          ? downChevronIconSelectedSrc
                          : downChevronIconSrc
                      }
                      class={style.configureIcon}
                      alt="Edit Field"
                    />
                  </template>
                </Dropdown>
              )}
            </div>
          )}
        </div>
      </th>
    );
  }
}
