import { Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { withNumberInput } from "@/utils";
import FormReadonly from "@/components/forms/FormReadonly.component.vue";
import FormTags from "@/components/forms/FormTags.component.vue";
import FormInputWithLabel from "../forms/inputs/FormInputWithLabel.component";
import FormSelect from "../forms/inputs/FormSelect.component";
import { HeadlineSeparator } from "../shared/HeadlineSeparator.component";
import DataEntryFormFields, { IFieldConfig } from "./fields-configuration";
import Vue from "vue";
import FormJsonInput from "../forms/inputs/FormJsonInput.component";

@Component
export default class DataEntryFormPrimitiveFields extends Vue {
  _tsx!: tsx.DeclareProps<{
    dataInput: DataEntryFormPrimitiveFields["dataInput"];
    blacklistedKeys: DataEntryFormPrimitiveFields["blacklistedKeys"];
    fields: DataEntryFormPrimitiveFields["fields"];
    objectType: DataEntryFormFields["objectType"];
  }>;

  @Prop({ required: true }) dataInput: any;
  @Prop({ required: true }) blacklistedKeys: string[];
  @Prop({ required: true }) fields: IFieldConfig[];

  render() {
    return (
      <div>
        <div class="right-sidebar__headline">
          {this.$t("object_view_object_fields")}
        </div>
        <HeadlineSeparator />
        {this.fields.map((field) => (
          <div>
            {!field.isBlacklisted && (
              <div>
                {field.isReadOnly ? (
                  <FormReadonly
                    id={field.name}
                    value={this.dataInput[field.name]}
                    key={field.key}
                    label={field.name}
                    upperCaseLabel={false}
                    labelAmendment={field.labelAmendment}
                  />
                ) : field.isList ? (
                  <FormTags
                    value={this.dataInput[field.name]}
                    onInput={(newVal: any) => {
                      this.dataInput[field.name] = newVal;
                    }}
                    input-id={`tags-${field.name}`}
                    label={field.name}
                    upperCaseLabel={false}
                    labelAmendment={field.labelAmendment}
                    placeholderText="Add entries"
                    key={field.key}
                    shouldValidate={field.isMandatory}
                  />
                ) : field.variant === "enum" || field.variant === "boolean" ? (
                  <FormSelect
                    value={this.dataInput[field.name]}
                    onInput={(newVal) => {
                      this.dataInput[field.name] = newVal;
                    }}
                    key={field.key}
                    label={field.name}
                    upperCaseLabel={false}
                    labelAmendment={field.labelAmendment}
                    selectOptions={field.selectOptions}
                  />
                ) : field.variant === "number" ? (
                  <FormInputWithLabel
                    value={this.dataInput[field.name]}
                    onInput={withNumberInput((newVal) => {
                      this.dataInput[field.name] = newVal;
                    })}
                    maxLength={field.inputMaxLength}
                    id={field.name}
                    label={field.name}
                    labelAmendment={field.labelAmendment}
                    key={field.key}
                    shouldValidate={field.isMandatory}
                    placeholderText={field.placeholder}
                    upperCaseLabel={false}
                    inputType={field.inputType}
                    readonly={field.isReadOnly}
                  />
                ) : field.variant === "json" ? (
                  <FormJsonInput
                    value={this.dataInput[field.name]}
                    onInput={(newVal) => {
                      this.dataInput[field.name] = newVal;
                    }}
                    id={field.name}
                    label={field.name}
                    labelAmendment={field.labelAmendment}
                    key={field.key}
                    placeholderText={field.placeholder}
                    upperCaseLabel={false}
                    readonly={field.isReadOnly}
                  />
                ) : (
                  <FormInputWithLabel
                    value={this.dataInput[field.name]}
                    onInput={(newVal) => {
                      this.dataInput[field.name] = newVal;
                    }}
                    id={field.name}
                    label={field.name}
                    labelAmendment={field.labelAmendment}
                    key={field.key}
                    shouldValidate={field.isMandatory}
                    placeholderText={field.placeholder}
                    upperCaseLabel={false}
                    inputType={field.placeholder}
                    readonly={field.isReadOnly}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
