import { IGraphQlError } from "./common-types";
import { apolloClient, updateApiClientConfig } from "./internal-utils";
import type { UserModule as UserModuleType } from "@/store/modules/user";
import type { OrgModule as OrgModuleType } from "@/store/modules/org";
import { fromPromise } from "apollo-boost";
import type { IAuthManager } from "@/utils/auth-service/auth-manager";

export const configureApiClient = ({
  UserModule,
  OrgModule,
  authManager,
}: {
  UserModule: typeof UserModuleType;
  OrgModule: typeof OrgModuleType;
  authManager: IAuthManager;
}) => {
  updateApiClientConfig({
    request: (operation) => {
      operation.setContext({
        headers: {
          authorization: UserModule.accessToken,
          ...(OrgModule.id && { pbac0pal: btoa(`org#${OrgModule.id}`) }),
        },
      });
    },
    onError: ({ graphQLErrors, networkError, operation, forward }) => {
      const typedGraphqlErrors = graphQLErrors as unknown as
        | IGraphQlError[]
        | undefined;
      if (typedGraphqlErrors) {
        for (const err of typedGraphqlErrors) {
          switch (err.errorType) {
            case "Unauthorized":
            case "UnauthorizedException":
              try {
                const oldHeaders = operation.getContext().headers;

                return fromPromise(authManager.refreshToken()).flatMap(() => {
                  const newToken = UserModule.accessToken;
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: newToken,
                    },
                  });
                  return forward(operation);
                });
              } catch (err) {
                console.log(err);
              }
          }
        }
      }

      if (networkError) {
        console.log("[Network Error]:", networkError);
      }
    },
  });
};

export const clearApiClientCache = () => {
  apolloClient.cache.reset();
};

export const getApiClientImplementation = () => apolloClient;
