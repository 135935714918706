import { listProductBundles } from "@/api/product_bundle";
import { BillingIntervalEnum, BundleTypeEnum } from "@/api/common-types";
import { UnwrappedPromise, SimplifyType } from "@/utils/types";
import {
  mapResponseToProduct,
  IPreselectedLineItems,
  IProductResponseMappableProps,
} from "./product";
import {
  extractData,
  extractItems,
  extractQueryname,
  parseJsonStringProp,
} from "./utils";
import { ProductNameEnum } from "@graphapi-io/products";

export const getProductBundles = async (
  preselectedLineItems: IPreselectedLineItems,
  preselectedProductNames: ProductNameEnum[]
) => {
  const response = await listProductBundles();

  return mapResponseToProductBundleMap(
    extractItems(extractQueryname(extractData(response), "listProductBundles")),
    preselectedLineItems,
    preselectedProductNames
  );
};

function mapResponseToProductBundleMap<
  T extends IProductBundleResponseMappableProps
>(
  productBundles: T[],
  lineItems: IPreselectedLineItems,
  preselectedProductNames: ProductNameEnum[]
) {
  const mappedProductBundles = productBundles.map(
    mapResponseToProductBundle<T>(lineItems, preselectedProductNames)
  );

  // TODO: Fix prettier issue and remove eslint-disable directive
  // eslint-disable-next-line
  type ProductList = (typeof mappedProductBundles)[number];

  return mappedProductBundles.reduce(
    (acc, productBundle, index) => ({
      ...acc,
      [productBundles[index].type]: productBundle,
    }),
    {} as Record<T["type"], ProductList>
  );
}

function parsePrices<
  T extends Pick<IProductBundleResponseMappableProps, "productConnection">
>(
  productBundle: T,
  lineItems: IPreselectedLineItems,
  preselectedProductNames: ProductNameEnum[]
) {
  const { productConnection, ...rest } = productBundle;

  return {
    ...rest,
    products: productConnection
      ? productConnection.items
          .map(
            mapResponseToProduct<T["productConnection"]["items"][number]>(
              lineItems,
              preselectedProductNames
            )
          )
          .filter((product) => Object.keys(product.pricings).length > 0)
      : [],
  };
}

export const mapResponseToProductBundle =
  <T extends IProductBundleResponseMappableProps>(
    lineItems: IPreselectedLineItems,
    preselectedProductNames: ProductNameEnum[]
  ) =>
  (data: T) => {
    const productWithFreeTiers = parseJsonStringProp<
      T,
      "freeTiers",
      IFreeTiers
    >(data, "freeTiers");

    return parsePrices(
      productWithFreeTiers,
      lineItems,
      preselectedProductNames
    );
  };

export interface IProductBundleResponseMappableProps {
  type: BundleTypeEnum;
  productConnection: { items: IProductResponseMappableProps[] };
  freeTiers?: string;
}

export type IProductBundleMap = SimplifyType<
  UnwrappedPromise<ReturnType<typeof getProductBundles>>
>;

export type IProductState = SimplifyType<
  IProductBundleMap[BundleTypeEnum]["products"][number]
>;

export type IPriceState = SimplifyType<
  NonNullable<IProductState["pricings"][BillingIntervalEnum]>[number]
>;

export interface IFreeTiers {
  EN: string[];
  DE: string[];
}
