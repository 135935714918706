import {
  apolloClient,
  queryFunctionFactory,
  watchQueryFunctionFactory,
} from "@/api/internal-utils";
import { MutationUpdaterFn } from "apollo-boost";
import CREATE_API from "./create_api.graphql";
import DELETE_API from "./delete_api.graphql";
import QUERY_API from "./query_api.graphql";
import QUERY_ORG from "../org/query_org.graphql";
import UPDATE_API from "./update_api.graphql";
import { v4 as uuid4 } from "uuid";
import _get from "lodash/get";
import _set from "lodash/set";
import _remove from "lodash/remove";
import _omit from "lodash/omit";
import {
  ApiCreationTypeEnum,
  MetadataObj,
  PublishingStateEnum,
  modelReadonlyFieldKeys,
} from "@/api/common-types";
import {
  IQueryApiQuery,
  ICreateApiMutationVariables,
  IQueryApiQueryVariables,
  IDeleteApiMutationVariables,
  IApiUpdateInput,
  ICreateApiMutation,
} from "@/generated/types";

const onApiDeletion =
  (orgId: string, variables: IDeleteApiMutationVariables): MutationUpdaterFn =>
  (cache) => {
    const data = cache.readQuery({
      query: QUERY_ORG,
      variables: { id: orgId },
    });

    const items = _get(data, "org.apiConnection.items", null);
    if (items) {
      _remove(items, { id: variables.input.id });
    }

    cache.writeQuery({
      query: QUERY_ORG,
      variables: { id: orgId },
      data: data,
    });
  };

export const apiQuery = queryFunctionFactory<
  IQueryApiQuery,
  IQueryApiQueryVariables
>(QUERY_API, undefined, "cache-first");

export const deleteApiMutation = (
  orgId: string,
  variables: IDeleteApiMutationVariables
) => {
  return apolloClient.mutate({
    mutation: DELETE_API,
    variables,
    update: onApiDeletion(orgId, variables),
  });
};

export const watchApiQuery = (variables: IQueryApiQueryVariables) =>
  watchQueryFunctionFactory<IQueryApiQuery, IQueryApiQueryVariables>(
    QUERY_API,
    variables
  );

export const createApiMutation = (variables: ICreateApiMutationVariables) => {
  variables.input.creationType =
    variables.input.creationType ?? ApiCreationTypeEnum.PUBLISH;
  variables.input.state =
    variables.input.state ?? PublishingStateEnum.INITIALIZED;

  if (variables.input.oidc) {
    variables.input.oidc = JSON.stringify(variables.input.oidc);
  }

  return apolloClient.mutate<ICreateApiMutation>({
    mutation: CREATE_API,
    variables,
    update: (cache, mutationResult) => {
      const newApi = mutationResult?.data?.createApi;
      const data = cache.readQuery({
        query: QUERY_ORG,
        variables: { id: variables.input.orgId },
      });
      const items = _get(data, "org.apiConnection.items", []);
      const extendedData = _set(
        data as MetadataObj,
        "org.apiConnection.items",
        [newApi, ...items]
      );
      cache.writeQuery({
        query: QUERY_ORG,
        variables: { id: variables.input.orgId },
        data: extendedData,
      });
    },
    optimisticResponse: {
      __typename: "Mutation",
      createApi: {
        __typename: "Api",
        id: `${uuid4()}`,
        name: variables.input.name,
        region: "eu-central-1",
        state: PublishingStateEnum.INITIALIZED,
        createdAt: new Date(Date.now()).toISOString(),
        updatedAt: new Date(Date.now()).toISOString(),
        graphqlUrl: variables.input.graphqlUrl ?? "",
      },
    },
  });
};

export const updateApiMutation = (apiUpdateInputData: IApiUpdateInput) => {
  const apiUpdateInput = _omit(apiUpdateInputData, modelReadonlyFieldKeys);
  return apolloClient.mutate({
    mutation: UPDATE_API,
    variables: { apiUpdateInput },
    update: (cache) => {
      try {
        const data: IQueryApiQuery | null = cache.readQuery({
          query: QUERY_API,
          variables: { id: apiUpdateInput.id },
        });

        if (data) {
          const updatedData = {
            api: {
              ..._get(data, "api"),
              ...apiUpdateInput,
            },
          };

          cache.writeQuery({
            query: QUERY_API,
            variables: { id: apiUpdateInput.id },
            data: updatedData,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  });
};
