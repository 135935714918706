import { Vue, Component, Prop } from "vue-property-decorator";
import { BCol, BRow } from "@/lib/typed-bootstrap";
import style from "./FormInputsRow.component.module.scss";
import * as tsx from "vue-tsx-support";
@Component
export default class FormInputsRow extends Vue {
  _tsx!: tsx.DeclareProps<{
    rightColLg?: FormInputsRow["rightColLg"];
  }>;

  @Prop({ default: "7" }) rightColLg: string;

  render() {
    return (
      <BRow align-v="center" class={style.row}>
        <BCol class={style.col + " p-0"}>{this.$slots.leftColumn}</BCol>
        <BCol
          class={style.col + " pt-1 pt-sm-0 pl-0 pl-md-2 pr-0"}
          lg={this.rightColLg}
        >
          {this.$slots.rightColumn}
        </BCol>
      </BRow>
    );
  }
}
