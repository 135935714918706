import { Component } from "vue-property-decorator";
import ApiData from "@/components/api-management/ApiData.mixin";
import {
  getObjectTypeArraysDiff,
  IEnumState,
  IObjectTypeState,
  parseDeclarationSchema,
} from "@/models";
import { getEnumArraysDiff } from "@/models/enum";
import { isUnchangedLeaf } from "@/utils";
@Component
export default class ApiPublishedTypes extends ApiData {
  protected get publishedTypes(): {
    enumTypes: IEnumState[];
    objectTypes: IObjectTypeState[];
  } {
    const publishedSchema = this.apiData?.publishRuns?.[0]?.schema;
    return parseDeclarationSchema(this.apiId, publishedSchema);
  }

  protected get unpublishedTypes(): {
    enumTypes: IEnumState[];
    objectTypes: IObjectTypeState[];
  } {
    return parseDeclarationSchema(this.apiId, this.apiData?.schema);
  }

  protected get hasUnpublishedChanges() {
    const enumTypesDiffResult = getEnumArraysDiff(
      this.publishedTypes.enumTypes,
      this.unpublishedTypes.enumTypes
    );
    const objectTypesDiffResult = getObjectTypeArraysDiff(
      this.publishedTypes.objectTypes,
      this.unpublishedTypes.objectTypes
    );
    return !(
      isUnchangedLeaf(enumTypesDiffResult) &&
      isUnchangedLeaf(objectTypesDiffResult)
    );
  }
}
