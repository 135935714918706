import { Component, Vue, Watch } from "vue-property-decorator";
import { IItem } from "./ApiDataObjectTypeTableData.mixin";
import { IObjectTypeState } from "@/models/object-type/types";
import { IObjectStackElement } from "../object-view/ObjectStack.component";

@Component
export class ApiDataObjectStack extends Vue {
  protected objectStack: IObjectStackElement[] = [];

  @Watch("objectStack", { immediate: true }) onObjectStackChanged(
    value: IObjectTypeState[]
  ) {
    if (value.length === 0) {
      this.$emit("closeRightSidebar");
    }
    if (value.length > 0) {
      this.$emit("openRightSidebar");
    }
  }

  protected onEditTableEntry({
    dataEntry,
    objectType,
  }: {
    dataEntry: IItem;
    objectType: IObjectTypeState;
  }) {
    if (dataEntry) {
      const recordId: string = dataEntry.id;

      if (
        this.objectStack[0]?.recordId &&
        this.objectStack[0]?.recordId === recordId
      ) {
        this.objectStack = [];
      } else {
        this.objectStack = [{ recordId, objectType }];
      }
    }
  }

  protected onCancelDataCreation() {
    this.objectStack = [];
  }
}
