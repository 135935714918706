import { CurrencyTypeEnum } from "../common-types";
import { queryFunctionFactory } from "../internal-utils";
import LIST_INVOICES_BY_BILLING_ACCOUNT from "./list_invoice_by_billing_account.graphql";

export enum InvoiceStatusEnum {
  draft = "draft",
  open = "open",
  paid = "paid",
  void = "void",
  uncollectible = "uncollectible",
}

export interface InvoiceApiResponse {
  date: string;
  status: InvoiceStatusEnum;
  amountPaid: number;
  currency: CurrencyTypeEnum;
  invoicePdf: any;
}

interface IInvoiceListApiResponse {
  listInvoicesByBillingAccount: {
    items: InvoiceApiResponse[];
  };
}

export const invoicesQuery = queryFunctionFactory<
  IInvoiceListApiResponse,
  { billingAccountPaymentProviderId: string }
>(LIST_INVOICES_BY_BILLING_ACCOUNT);
