import QUERY_USER from "./query_user.graphql";
import CREATE_USER from "./create_user.graphql";
import UPDATE_USER from "./update_user.graphql";
import {
  apolloClient,
  mutationFunctionFactory,
  queryFunctionFactory,
} from "../internal-utils";
import {
  ICreateUserMutation,
  ICreateUserMutationVariables,
  IQueryUserArgs,
  IQueryUserQuery,
} from "@/generated/types";

export const createUserMutation = mutationFunctionFactory<
  ICreateUserMutation,
  ICreateUserMutationVariables
>(CREATE_USER);

export const updateUserMutation = (input: any) => {
  return apolloClient.mutate({
    mutation: UPDATE_USER,
    variables: {
      input,
    },
  });
};

export const userQuery = queryFunctionFactory<IQueryUserQuery, IQueryUserArgs>(
  QUERY_USER
);
