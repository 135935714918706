import { listBundleSubscriptionsByApiQuery } from "@/api/bundle_subscription";
import {
  extractAndFilterItemsOrProvideDefault,
  extractData,
  extractPropOrThrow,
} from "./utils";

export const getSubscriptions = async (apiId: string) => {
  const response = await listBundleSubscriptionsByApiQuery({
    apiId,
  });

  return extractAndFilterItemsOrProvideDefault(
    extractPropOrThrow(extractData(response), "listBundleSubscriptionsByApi")
  );
};
