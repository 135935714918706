import Vue from "vue";
import { PoseTransition } from "vue-pose";
import { Component, Prop } from "vue-property-decorator";
import { ObjectView } from "./ObjectView.component";
import { PrevObjectView } from "./PrevObjectView.component";
import style from "./ObjectStack.component.module.scss";
import * as tsx from "vue-tsx-support";
import { IEnumState, IObjectTypeState } from "@/models";
import FieldsConfiguration from "./fields-configuration";
import { ApiSchema } from "@/models/api-schema";
import { IGraphqlApi } from "@/api-client/shared/types";
import { IObjectStackPushEvent } from "./types";
import { ObjectDirectiveEnum } from "@/api/common-types";

export interface IObjectStackElement {
  recordId: string;
  objectType: IObjectTypeState;
}

@Component
export class ObjectStack extends Vue {
  _tsx!: tsx.DeclareProps<
    Pick<
      ObjectStack,
      | "stack"
      | "customerApi"
      | "objectTypes"
      | "enumTypes"
      | "apiSchema"
      | "dedicatedApi"
    >
  >;
  $scopedSlots!: tsx.InnerScopedSlots<{
    view?: {
      data: object;
      customerApi: IGraphqlApi;
      recordId: string | null;
      typeName: string;
      prevObjectId: string | null;
      prevObjectTypeName: string | null;
      handlePush: (ev: IObjectStackPushEvent) => void;
      handlePop: () => void;
      handleReplace: (ev: IObjectStackPushEvent) => void;
      handleError: (ev: Event) => void;
      objectType: IObjectTypeState;
      fieldsConfiguration: FieldsConfiguration;
    };
  }>;
  @Prop({ required: true }) stack: IObjectStackElement[];
  @Prop({ required: true }) customerApi: IGraphqlApi;
  @Prop() dedicatedApi?: IGraphqlApi;
  @Prop({ required: true }) objectTypes: IObjectTypeState[];
  @Prop({ required: true }) enumTypes: IEnumState[];
  @Prop({ required: true }) apiSchema: ApiSchema;

  get currentObject() {
    return this.stack[this.stack.length - 1];
  }

  get prevObject() {
    return this.stack[this.stack.length - 2];
  }

  get hasPrevObject() {
    return this.stack.length > 1;
  }

  private handleHidden() {
    this.stack.splice(0, this.stack.length);
  }

  private handlePush({ objectTypeId, recordId }: IObjectStackPushEvent) {
    const objectType = this.objectTypes.find((t) => t.id === objectTypeId);
    objectType && this.stack.push({ objectType, recordId });
  }

  private handlePop() {
    this.stack.pop();
  }

  private handleError() {}

  private handleReplace({ objectTypeId, recordId }: IObjectStackPushEvent) {
    const objectType = this.objectTypes.find((t) => t.id === objectTypeId);
    objectType &&
      this.stack.splice(this.stack.length - 1, 1, { objectType, recordId });
  }

  private get isUserType() {
    return this.currentObject?.objectType.directives.includes(
      ObjectDirectiveEnum.USERS
    );
  }

  private get apiClient() {
    return this.isUserType && this.dedicatedApi
      ? this.dedicatedApi
      : this.customerApi;
  }

  render() {
    return (
      <portal to="right-sidebar">
        <div class={style.stack}>
          <PoseTransition>
            {this.hasPrevObject && (
              <PrevObjectView
                typeName={this.prevObject.objectType.name}
                name={this.prevObject.recordId}
                onClick={this.handlePop}
              ></PrevObjectView>
            )}
          </PoseTransition>
          <PoseTransition>
            {this.currentObject?.objectType && (
              <ObjectView
                isOnly={!this.hasPrevObject}
                onClose={this.handleHidden}
                objectType={this.currentObject.objectType}
                typeName={this.currentObject.objectType.name}
                recordId={this.currentObject.recordId}
                customerApi={this.apiClient}
                enumTypes={this.enumTypes}
                apiSchema={this.apiSchema}
                prevObjectRecordId={this.prevObject?.recordId}
                prevObjectTypeName={this.prevObject?.objectType.name}
                scopedSlots={{
                  view: ({ data, fieldsConfiguration, onDataEntryError }) =>
                    this.$scopedSlots?.view?.({
                      fieldsConfiguration,
                      customerApi: this.apiClient,
                      data,
                      objectType: this.currentObject?.objectType,
                      recordId: this.currentObject?.recordId,
                      typeName: this.currentObject?.objectType.name,
                      prevObjectId: this.prevObject?.recordId,
                      prevObjectTypeName: this.prevObject?.objectType.name,
                      handlePush: this.handlePush,
                      handlePop: this.handlePop,
                      handleReplace: this.handleReplace,
                      handleError: onDataEntryError,
                    }),
                }}
              />
            )}
          </PoseTransition>
        </div>
      </portal>
    );
  }
}
