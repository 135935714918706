import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import style from "./ApiDataObjectTypeActions.component.module.scss";
import { sortObjectArrayByKey } from "@/utils";
import { ISchemaExplorerType } from "@/api/schema";
import {
  IFilterOpState,
  IFilterSchemaExplorerType,
  IFilterValueState,
} from "@/models/filters";
import { ApiDataModule } from "@/store";
import filterIconDark from "@/assets/img/icon-filter.svg";
import filterIconWhite from "@/assets/img/icon-filter-light.svg";
import iconCloseWhite from "@/assets/img/icon-close-white.svg";
import iconCloseBlack from "@/assets/img/icon-close-black.svg";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import ApiDataFilter from "./ApiDataFilter.component";
import TableActions from "../shared/table/TableActions.component";
import TableActionButton from "../shared/table/TableActionButton.component";
import { IObjectTypeState } from "@/models/object-type/types";

const filterIconSrc = {
  light: filterIconDark,
  dark: filterIconWhite,
};

const closeIconSrc = {
  light: iconCloseBlack,
  dark: iconCloseWhite,
};

@Component
export default class ApiDataObjectTypeActions extends Vue {
  _tsx!: tsx.DeclareProps<{
    objectType: ApiDataObjectTypeActions["objectType"];
    totalRows: ApiDataObjectTypeActions["totalRows"];
    canDeleteItems: ApiDataObjectTypeActions["canDeleteItems"];
    canExport?: ApiDataObjectTypeActions["canExport"];
    canAddItems?: ApiDataObjectTypeActions["canAddItems"];
    canFilter?: ApiDataObjectTypeActions["canFilter"];
    canImport?: ApiDataObjectTypeActions["canImport"];
    isLoading?: ApiDataObjectTypeActions["isLoading"];
    schemaFieldTypes?: ApiDataObjectTypeActions["schemaFieldTypes"];
    filterSchemaTypes: ApiDataObjectTypeActions["filterSchemaTypes"];
  }> &
    tsx.DeclareOnEvents<{
      onAddEntry: void;
      onImport: void;
      onExport: void;
      onReload: void;
      onDeleteEntries: void;
    }>;

  @Prop({ required: true }) objectType: IObjectTypeState;
  @Prop({ required: true }) totalRows: number;
  @Prop() schemaFieldTypes?: Record<string, ISchemaExplorerType>;
  @Prop({ required: true }) filterSchemaTypes:
    | ISchemaExplorerType[]
    | undefined;
  @Prop({ default: false }) canDeleteItems: boolean;
  @Prop({ default: false }) canExport: boolean;
  @Prop({ default: false }) canAddItems: boolean;
  @Prop({ default: false }) canImport: boolean;
  @Prop({ default: false }) canFilter: boolean;
  @Prop({ default: false }) isLoading: boolean;
  @Prop() hideImport?: boolean;
  @Prop() hideExport?: boolean;
  @Prop() hideDelete?: boolean;

  @Emit("addEntry") onAddEntry() {}
  @Emit("deleteEntries") onDeleteEntries() {}
  @Emit("import") onImport() {}
  @Emit("export") onExport() {}
  @Emit("reload") onReload() {}

  private queryFilter: IFilterValueState = {};
  private idFilterObject = {
    name: "id",
    selected: false,
    ops: [
      {
        name: "equals",
        text: "equals",
        op: "eq",
        selected: false,
      },
    ],
  };
  private filterFieldsBlacklist: string[] = ["and", "not", "or"];
  private filterOpsMetaData: { [key: string]: { name: string; text: string } } =
    {
      ["eq"]: { name: "equals", text: "equals" },
      ["ne"]: { name: "not equals", text: "is not equals" },
      ["lt"]: { name: "less than", text: "is less than" },
      ["le"]: { name: "less equal", text: "is less equal" },
      ["gt"]: { name: "greater than", text: "is greater than" },
      ["ge"]: { name: "greater equal", text: "is greater equal" },
      ["beginsWith"]: { name: "begins with", text: "begins with" },
      ["contains"]: { name: "contains", text: "contains" },
      ["notContains"]: { name: "not contains", text: "does not contain" },
      ["attributeExists"]: {
        name: "attribute exists",
        text: "attribute exists",
      },
    };

  private get addEntryCopy(): string {
    return this.objectType?.name
      ? this.$t("global_cta_add_model_entry", {
          model: "",
        }).toString()
      : this.$t("global_cta_new").toString();
  }

  private get filterFields(): IFilterSchemaExplorerType[] {
    let filterFields = [];

    if (this.filterSchemaTypes) {
      filterFields =
        this.filterSchemaTypes?.reduce((acc: any[], field) => {
          if (field.name && !this.filterFieldsBlacklist.includes(field.name)) {
            const comparisonInputFields =
              this.schemaFieldTypes?.[field?.type?.name ?? ""]?.inputFields;
            if (comparisonInputFields && comparisonInputFields.length > 0) {
              acc.push({
                ...field,
                selected: false,
                ops:
                  comparisonInputFields?.reduce(
                    (acc: IFilterOpState[], field) => {
                      if (this.filterOpsMetaData[field.name] && field.name) {
                        acc.push({
                          ...this.filterOpsMetaData[field.name],
                          op: field.name,
                          selected: false,
                        });
                      }

                      return acc;
                    },
                    []
                  ) ?? [],
              });
            }
          }

          return acc;
        }, []) ?? [];
    }

    return sortObjectArrayByKey(
      [{ ...this.idFilterObject, selected: false }, ...filterFields],
      "name"
    );
  }

  private onFilterValueChange(value: IFilterValueState) {
    this.queryFilter = value;
  }

  private handleApplyFilter() {
    ApiDataModule.setActiveFilters(this.queryFilter);
  }

  render() {
    return (
      <TableActions
        canDeleteItems={this.canDeleteItems}
        canExport={this.canExport}
        canImport={this.canImport}
        canAddItems={this.canAddItems}
        isLoading={this.isLoading}
        withImport
        withExport
        withDelete
        withDivider
        withReload
        onAddEntry={this.onAddEntry}
        onImport={this.onImport}
        onExport={this.onExport}
        onReload={this.onReload}
        onDeleteEntries={this.onDeleteEntries}
        addActionCopy={this.addEntryCopy}
      >
        <ApiDataFilter
          value={this.queryFilter}
          key={`${this.objectType?.name ?? ""}-filterbar-${
            this.filterFields.length
          }`}
          items={this.filterFields}
          disabled={!this.canFilter || this.filterFields.length === 0}
          onInput={this.onFilterValueChange}
          onApplyFilter={this.handleApplyFilter}
          scopedSlots={{
            button: ({ onClick, disabled }) => (
              <TableActionButton
                type="button"
                onClick={onClick}
                disabled={disabled}
              >
                <ThemedImage src={filterIconSrc} class={style.icon} />
                <span class={style.desktopOnly}>
                  {this.$t("global_cta_filter")}
                </span>
              </TableActionButton>
            ),
            preview: ({
              onClick,
              selectedFieldName,
              selectedOpName,
              filterValue,
            }) => (
              <TableActionButton isActive onClick={onClick}>
                <ThemedImage src={filterIconSrc} class={style.icon} />
                <span class={style.desktopOnly}>
                  {selectedFieldName} {selectedOpName} {filterValue}
                </span>
                <span class={style.cancelFilterButton}>
                  <ThemedImage src={closeIconSrc} class={style.icon} />
                </span>
              </TableActionButton>
            ),
          }}
        />
      </TableActions>
    );
  }
}
