import { Vue, Component, Prop } from "vue-property-decorator";
import style from "./TextSection.component.module.scss";
import * as tsx from "vue-tsx-support";

export enum TextSectionSizeEnum {
  xs = "xs",
  m = "m",
  l = "l",
  xl = "xl",
}

export enum TextSectionComponentEnum {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h6 = "h6",
}

@Component
export default class TextSection extends Vue {
  _tsx!: tsx.DeclareProps<{
    size?: TextSection["size"];
    titleComponent?: TextSection["titleComponent"];
    title: TextSection["title"];
    subtitle?: TextSection["subtitle"];
    titleClassname?: TextSection["titleClassname"];
    subtitleClassname?: TextSection["subtitleClassname"];
    bodyClassname?: TextSection["bodyClassname"];
  }>;

  @Prop({ default: TextSectionSizeEnum.m }) size: TextSectionSizeEnum;
  @Prop({ default: TextSectionComponentEnum.h2 })
  titleComponent: TextSectionComponentEnum;
  @Prop({ required: true }) title: string;
  @Prop() subtitle?: string;
  @Prop() titleClassname?: string;
  @Prop() subtitleClassname?: string;
  @Prop() bodyClassname?: string;

  get titleCSSClass() {
    return {
      [style.title]: true,
      [style[`title-${this.size}`]]: true,
      [this.titleClassname || ""]: this.titleClassname !== undefined,
    };
  }

  get subtitleCSSClass() {
    return {
      [style.subtitle]: true,
      [style[`subtitle-${this.size}`]]: true,
      [this.bodyClassname || ""]: this.bodyClassname !== undefined,
    };
  }

  get bodyCSSClass() {
    return {
      [style.body]: true,
      [style[`body-${this.size}`]]: true,
      [this.bodyClassname || ""]: this.bodyClassname !== undefined,
    };
  }

  render() {
    return (
      <section>
        <this.titleComponent class={this.titleCSSClass}>
          {this.title}
        </this.titleComponent>
        {this.subtitle && <p class={this.subtitleCSSClass}>{this.subtitle}</p>}
        {this.$slots.default && (
          <div class={this.bodyCSSClass}>{this.$slots.default}</div>
        )}
      </section>
    );
  }
}
