import {
  createFlowMutation,
  updateFlowMutation,
  deleteFlowMutation,
  watchFlowQuery,
  watchFlowsByApiIdQuery,
} from "@/api/flow";
import {
  extractAndFilterItemsOrProvideDefault,
  extractData,
  extractPropOrThrow,
  parseJsonStringProp,
} from "./utils";
import {
  ICreateFlowMutationVariables,
  IDeleteFlowMutationVariables,
  IListFlowsByApiIdQuery,
  IQueryFlowArgs,
  IQueryListFlowsByApiArgs,
  IUpdateFlowMutationVariables,
} from "@/generated/types";
import { ApolloQueryResult } from "apollo-boost";
import { IFlowDeclaration } from "@graphapi-io/flow-declaration";
import Observable from "zen-observable";

const parseFlow = <T extends { schema?: string }>(flow: T) =>
  parseJsonStringProp<T, "schema", IFlowDeclaration>(flow, "schema");

const parseFlowList = (value: ApolloQueryResult<IListFlowsByApiIdQuery>) => {
  const flowsByApi = extractPropOrThrow(extractData(value), "listFlowsByApi");
  return {
    ...flowsByApi,
    items: extractAndFilterItemsOrProvideDefault(flowsByApi),
  };
};

export function watchFlowsByApiId(variables: IQueryListFlowsByApiArgs) {
  const { observable, ...rest } = watchFlowsByApiIdQuery(variables);

  return {
    observable: observable.map(parseFlowList),
    ...rest,
  };
}

export async function createFlow(input: ICreateFlowMutationVariables["input"]) {
  const result = await createFlowMutation({ input });
  const mutationResult = extractPropOrThrow(
    extractPropOrThrow(result, "data"),
    "createFlow"
  );

  return mutationResult;
}

export async function updateFlow(input: IUpdateFlowMutationVariables["input"]) {
  const result = await updateFlowMutation({ input });
  const mutationResult = extractPropOrThrow(
    extractPropOrThrow(result, "data"),
    "updateFlow"
  );

  return mutationResult;
}

export async function deleteFlow(input: IDeleteFlowMutationVariables["input"]) {
  const result = await deleteFlowMutation({ input });
  const mutationResult = extractPropOrThrow(
    extractPropOrThrow(result, "data"),
    "deleteFlow"
  );

  return mutationResult;
}

export function watchFlow(variables: IQueryFlowArgs) {
  return watchFlowQuery(variables)
    .observable.map(extractData)
    .map((value) => extractPropOrThrow(value, "flow"))
    .map((value) => parseFlow(value));
}

export type IFlowItem = (ReturnType<
  typeof watchFlowsByApiId
>["observable"] extends Observable<infer T>
  ? T
  : never)["items"][number];

export type IFlow = ReturnType<typeof watchFlow> extends Observable<infer T>
  ? T
  : never;
