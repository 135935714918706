import {
  BAlert,
  BFormGroup,
  BFormInput,
  BModal,
  BSpinner,
} from "@/lib/typed-bootstrap";
import { Vue, Component, Prop } from "vue-property-decorator";
import styles from "./DeleteModal.component.module.scss";
import * as tsx from "vue-tsx-support";

@Component
export default class DeleteModal extends Vue {
  _tsx!: tsx.DeclareProps<{
    id?: DeleteModal["id"];
    name: DeleteModal["name"];
    title: DeleteModal["title"];
    info: DeleteModal["info"];
    instruction: DeleteModal["instruction"];
  }> &
    tsx.DeclareOnEvents<{
      onConfirmDelete: void;
      onUpdate: string;
    }>;

  @Prop({ default: "delete-modal" }) id: string;
  @Prop({ required: true }) readonly name: string;
  @Prop({ required: true }) readonly title: string;
  @Prop({ required: true }) readonly info: string;
  @Prop({ required: true }) readonly instruction: string;

  private nameState: boolean | null = null;
  private isLoading: boolean = false;
  private errorMessage: string = "";
  private internalValue: string = "";

  private get invalidFeedback(): string {
    return this.$t("form_feedback_delete_modal", {
      name: this.name,
    }).toString();
  }

  private get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  private isFormValid() {
    this.nameState = this.internalValue.trim() === this.name.trim();
    return this.nameState;
  }

  private onOk(bvModalEvt: Event): void {
    bvModalEvt.preventDefault();
    this.onSubmit();
  }

  private onSubmit(): void {
    if (!this.isFormValid()) {
      return;
    }

    this.isLoading = true;
    this.$emit("confirmDelete");
    this.resetModal();
  }

  private resetModal(): void {
    this.nameState = null;
  }

  private onInput(value: string): void {
    this.internalValue = value;
    this.$emit("input", value);
  }

  render() {
    return (
      <BModal
        id={this.id}
        modalClass={styles.deleteModal}
        bodyClass={styles.modalBody}
        contentClass={styles.modalContent}
        headerClass={styles.modalHeader}
        footerClass={styles.modalFooter}
        ref="modal"
        title={this.title}
        onShow={this.resetModal}
        onHidden={this.resetModal}
        onOk={this.onOk}
        scopedSlots={{
          "modal-footer": ({ ok, cancel }) => (
            <div>
              <button class={styles.outlineButtonCancel} onClick={cancel}>
                {this.$t("global_cta_cancel")}
              </button>
              <button
                class={{
                  [styles.outlineButtonDelete]: true,
                  [styles.spinnerPadding]: this.isLoading,
                }}
                onClick={ok}
                data-testid="confirm-delete-button"
              >
                {this.isLoading && (
                  <div class="default__spinner--small" role="status">
                    <BSpinner type="grow" label="Deleting..." small></BSpinner>
                  </div>
                )}
                {this.$t("global_cta_delete")}
              </button>
            </div>
          ),
        }}
      >
        <p class={styles.deleteInfo}>{this.info}</p>
        <p class={styles.deleteInstruction}>{this.instruction}</p>
        <BAlert show={this.showErrorMessage} variant="danger">
          {this.errorMessage}
        </BAlert>
        <form ref="form" onSubmit={tsx.modifiers.stop.prevent(this.onSubmit)}>
          <BFormGroup
            labelFor="delete-input"
            invalidFeedback={this.invalidFeedback}
            state={this.nameState}
          >
            <BFormInput
              data-testid="confirm-delete-input"
              class="default__inline-form-input"
              placeholder={this.name}
              id="name-input"
              value={this.internalValue}
              state={this.nameState}
              on={{
                ...this.$listeners,
                input: ($event: string | number) => {
                  this.onInput($event.toString());
                },
              }}
              required
            ></BFormInput>
          </BFormGroup>
        </form>
      </BModal>
    );
  }
}
