import { getErrorMessage, isNotNullable } from "@/utils";
import Observable from "zen-observable";
import { ApolloQueryResult } from "apollo-boost";
import { IPaginationState } from "@/components/shared/table/types";
import { TableSelectionState } from "@/components/shared/table/table-selection-state";

export class ListDataManager<
  IDataType extends { items: unknown[]; nextToken?: string | null },
  IVariablesType extends { nextToken?: string | null; limit?: number | null }
> {
  constructor(
    private watchList: (variables: IVariablesType) => {
      observable: Observable<IDataType>;
      refetch: (
        variables: IVariablesType
      ) => Promise<ApolloQueryResult<unknown>>;
      fetchMore: (
        variables: IVariablesType
      ) => Promise<ApolloQueryResult<unknown>>;
    },
    public paginationState: IPaginationState = {
      pageSizes: [50, 100],
      perPage: 50,
      currentPage: 1,
    }
  ) {
    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.subscribeToList = this.subscribeToList.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  public onPaginationChange(paginationState: IPaginationState) {
    this.paginationState = paginationState;

    if (
      this.listVariables?.nextToken &&
      this.fetchMore &&
      paginationState.currentPage * paginationState.perPage > this.data.length
    ) {
      this.fetchMore(this.listVariables);
    }
  }

  public errorMessage = "";

  public get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  private subscription: ZenObservable.Subscription | null = null;
  public fetchMore:
    | ((variables: IVariablesType) => Promise<ApolloQueryResult<unknown>>)
    | null = null;
  public refetch:
    | ((variables: IVariablesType) => Promise<ApolloQueryResult<unknown>>)
    | null = null;
  public totalRows = 0;
  public data: IDataType["items"] = [];
  public isLoading = false;
  public listVariables: IVariablesType | null = null;

  public subscribeToList(initialListVariables: IVariablesType) {
    this.listVariables = initialListVariables;
    this.data = [];
    this.unsubscribe();
    this.isLoading = true;
    const { observable, fetchMore, refetch } = this.watchList(
      this.listVariables
    );

    this.subscription = observable.subscribe({
      next: (data) => {
        this.data = data.items.filter(isNotNullable);
        this.totalRows = data.items.length;

        if (
          this.paginationState.currentPage * this.paginationState.perPage >
          this.totalRows
        ) {
          this.paginationState.currentPage = Math.max(
            Math.ceil(this.totalRows / this.paginationState.perPage),
            1
          );
        }

        if (this.listVariables) {
          this.listVariables.nextToken = data.nextToken;
        }

        if (
          this.listVariables?.nextToken &&
          (this.listVariables.limit || 100) > this.data.length &&
          this.fetchMore &&
          !this.isLoading
        ) {
          this.fetchMore(this.listVariables);
        } else {
          this.isLoading = false;
        }
      },
      error: (e) => {
        this.errorMessage = getErrorMessage(e);
        this.isLoading = false;
      },
    });

    this.fetchMore = (variables: IVariablesType) => {
      this.isLoading = true;
      return fetchMore(variables);
    };

    this.refetch = (variables: IVariablesType) => {
      this.isLoading = true;
      return refetch(variables).finally(() => (this.isLoading = false));
    };
  }

  public async unsubscribe() {
    this.subscription?.unsubscribe();
  }

  public entriesFromSelectionState(state: TableSelectionState) {
    const pageOffset =
      (this.paginationState.currentPage - 1) * this.paginationState.perPage;

    return Object.entries(state.selectedRows.vector)
      .map(([index, isSelected]) =>
        isSelected ? this.data[+index + pageOffset] : undefined
      )
      .filter(isNotNullable);
  }
}
