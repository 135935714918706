import { MutationUpdaterFn } from "apollo-boost";
import { GqlGenericQueryComposer } from "./gql-generic-query-composer";
import { CacheUpdateWrapper } from "../shared/cache-update-wrapper";
import { ICacheUpdatesHandler, ofType } from "../shared/types";
import { IObjectTypeState } from "@/models/object-type/types";

export class GenericApiCacheUpdates implements ICacheUpdatesHandler {
  constructor(private namespace: string) {}

  public addToListFrontAfterCreationFactory(
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ): MutationUpdaterFn<any> {
    return CacheUpdateWrapper.addToListFrontAfterCreationFactory(
      "Type",
      variables,
      GqlGenericQueryComposer.buildListQuery(ofType(objectType), this.namespace)
    );
  }

  public addToListFrontAfterBatchCreationFactory(
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ): MutationUpdaterFn<any> {
    return CacheUpdateWrapper.addToListFrontAfterBatchCreationFactory(
      "Type",
      variables,
      GqlGenericQueryComposer.buildListQuery(ofType(objectType), this.namespace)
    );
  }

  public clearObjectsAfterBatchDeletionFactory(
    objectType: IObjectTypeState
  ): MutationUpdaterFn<any> {
    return CacheUpdateWrapper.clearObjectsAfterBatchDeletionFactory(
      "Type",
      GqlGenericQueryComposer.buildObjectQuery(
        ofType(objectType),
        this.namespace
      )
    );
  }

  public clearListAfterBatchDeletionFactory(
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ): MutationUpdaterFn<any> {
    return CacheUpdateWrapper.clearListAfterBatchDeletionFactory(
      "Type",
      variables,
      GqlGenericQueryComposer.buildListQuery(ofType(objectType), this.namespace)
    );
  }

  public clearAllAfterBatchDeletionFactory(
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ): MutationUpdaterFn<any> {
    return CacheUpdateWrapper.callBoth(
      this.clearObjectsAfterBatchDeletionFactory(objectType),
      this.clearListAfterBatchDeletionFactory(objectType, variables)
    );
  }
}
