import { Vue, Component } from "vue-property-decorator";

@Component
export default class Media extends Vue {
  public isMobile = !window.matchMedia("(min-width: 768px)").matches;

  mounted() {
    window.addEventListener("resize", this.onWindowResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  private onWindowResize() {
    this.isMobile = !window.matchMedia("(min-width: 768px)").matches;
  }
}
