import { QueryTypeEnum } from "@graphapi-io/api-declaration";
import { FieldNameComposer } from "../shared/fieldname-composer";
import { IResultParser } from "../shared/types";

export class DedicatedApiResultParser implements IResultParser {
  public parseObjectQueryResult(data: any, typeName: string) {
    const result =
      data[
        FieldNameComposer.fieldNameForQueryType(QueryTypeEnum.OBJECT, typeName)
      ];

    return result;
  }

  public parseListQueryResult(items: any[]) {
    return items;
  }
}
