import { ProductNameEnum } from "@graphapi-io/products";
import {
  BillingIntervalEnum,
  BundleTypeEnum,
  CurrencyTypeEnum,
  ProductTypeEnum,
  UnitTypeEnum,
  UsageTypeEnum,
} from "../common-types";
import { queryFunctionFactory } from "../internal-utils";
import LIST_PRODUCT_BUNDLES from "./list_product_bundles.graphql";

export interface IListProductBundleQueryResponse {
  listProductBundles: {
    items: IProductBundleResponse[];
  };
}

interface IProductBundleResponse {
  name?: string;
  id: string;
  type: BundleTypeEnum;
  freeTiers?: string;
  productConnection: {
    items: IProductResponse[];
  };
}

interface IProductResponse {
  id: string;
  name?: ProductNameEnum;
  displayName?: string;
  selectedPricingIndex?: number;
  type?: ProductTypeEnum;
  usageType: UsageTypeEnum;
  benefitConnection: { items: IBenefitResponse[] };
  priceConnection: { items: IPriceResponse[] };
  productBundleType: BundleTypeEnum;
}

interface IBenefitResponse {
  description?: string;
}

interface IPriceResponse {
  price?: number;
  currency?: CurrencyTypeEnum;
  reservedUnits?: number;
  unitAmount?: number;
  unitType?: UnitTypeEnum;
  usageType?: UsageTypeEnum;
  recurring?: boolean;
  billingInterval?: BillingIntervalEnum;
  paymentProviderId?: string;
  tiers?: string;
}

export const listProductBundles =
  queryFunctionFactory<IListProductBundleQueryResponse>(LIST_PRODUCT_BUNDLES);
