import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { AuthMethodTypeEnum } from "@/api/common-types";
import { IObjectTypeState, IEnumState } from "@/models";

import { ObjectTypeConnections } from "./ObjectTypeConnections.component";
import { ObjectTypeTriggers } from "./ObjectTypeTriggers.component";
import { BCard, BFormGroup } from "@/lib/typed-bootstrap";
import style from "./ObjectTypeWidget.component.module.scss";
import { ApolloClient } from "apollo-boost";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import * as tsx from "vue-tsx-support";
import Tabs from "../shared/Tabs.component";
import ObjectTypeFields from "./ObjectTypeFields.component";
import { ObjectTypeSectionHeader } from "./ObjectTypeSectionHeader.component";
import { ObjectTypeNameInput } from "./ObjectTypeNameInput.component";

@Component
export default class ObjectTypeWidget extends Vue {
  _tsx!: tsx.DeclareProps<{
    apiAuthMode: ObjectTypeWidget["apiAuthMode"];
    value: ObjectTypeWidget["value"];
    allTypes: ObjectTypeWidget["allTypes"];
    currentTabIndex: ObjectTypeWidget["currentTabIndex"];
    allEnumTypes?: ObjectTypeWidget["allEnumTypes"];
    isUnique?: ObjectTypeWidget["isUnique"];
    isReserved?: ObjectTypeWidget["isReserved"];
    apiClient?: ObjectTypeWidget["apiClient"];
    apiSchemaHandler?: ObjectTypeWidget["apiSchemaHandler"];
  }> &
    tsx.DeclareOnEvents<{
      onAddEnumType: void;
      onAddObjectTypeField: void;
      onTabIndexChange: number;
    }>;

  @Prop({ required: true }) apiAuthMode: AuthMethodTypeEnum;
  @Prop({ required: true }) value: IObjectTypeState;
  @Prop({ required: true }) allTypes: IObjectTypeState[];
  @Prop({ required: true }) currentTabIndex: number;
  @Prop({ required: false, default: null }) allEnumTypes: IEnumState[] | null;
  @Prop({ required: false, default: null }) readonly isUnique: boolean | null;
  @Prop({ required: false, default: false }) readonly isReserved: boolean;
  @Prop({ required: false, default: null })
  apiClient: ApolloClient<unknown> | null;
  @Prop({ required: false, default: null })
  apiSchemaHandler: ApiSchemaHandler | null;

  @Emit("addEnumType") onAddEnumType() {}
  @Emit("addObjectTypeField") onAddObjectTypeField() {}
  @Emit("tabIndexChange") onTabIndexChange(_index: number) {}

  private get invalidFeedback() {
    if (this.isUnique === false) {
      return this.$t("form_feedback_type_name_uniq", {
        name: this.value.name,
      }).toString();
    }

    if (this.isReserved) {
      return this.$t("form_feedback_type_name_reserved", {
        name: this.value.name,
      }).toString();
    }
  }

  private get nameInputState() {
    return !this.isReserved && this.isUnique ? undefined : false;
  }

  render() {
    return (
      <BCard bodyClass={style.body} class={style.card}>
        <ObjectTypeSectionHeader noUnderline={true}>
          {this.$t("model_widget_model_name")}
        </ObjectTypeSectionHeader>
        <BFormGroup
          invalidFeedback={this.invalidFeedback}
          state={this.nameInputState}
        >
          <ObjectTypeNameInput
            value={this.value}
            nameInputState={this.nameInputState ?? null}
            apiAuthMode={this.apiAuthMode}
          />
        </BFormGroup>
        <Tabs
          class={style.tabs}
          currentTabIndex={this.currentTabIndex}
          onTabIndexChange={this.onTabIndexChange}
        >
          <Tabs.Tab title="Fields" id="fields">
            <ObjectTypeFields
              value={this.value}
              allTypes={this.allTypes}
              allEnumTypes={this.allEnumTypes}
              onAddEnumType={this.onAddEnumType}
              onAddObjectTypeField={this.onAddObjectTypeField}
            />
          </Tabs.Tab>
          <Tabs.Tab title="Connections" id="connections">
            <ObjectTypeConnections
              value={this.value}
              allTypes={this.allTypes}
              allEnumTypes={this.allEnumTypes ?? undefined}
            />
          </Tabs.Tab>
          {this.apiClient && this.apiSchemaHandler && (
            <Tabs.Tab title="Triggers" id="triggers">
              <ObjectTypeTriggers
                value={this.value}
                allTypes={this.allTypes}
                allEnumTypes={this.allEnumTypes ?? undefined}
                apiClient={this.apiClient}
                apiSchemaHandler={this.apiSchemaHandler}
              />
            </Tabs.Tab>
          )}
        </Tabs>
      </BCard>
    );
  }
}
