import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import { ApolloClient } from "apollo-boost";
import {
  FILE_DOCUMENT_TYPE,
  CustomerApiForFiles,
  DedicatedApi,
  ICustomerListVariables,
} from "@/api-client";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import { IApiState } from "@/models/api";
import {
  exportString,
  getErrorMessage,
  slugFromName,
  sortFieldsArray,
  upperCaseFirstLetter,
} from "@/utils";
import { AccessControlType, SortOrderType } from "@/generated/types";
import { BAlert, BContainer } from "@/lib/typed-bootstrap";
import TableActions from "@/components/shared/table/TableActions.component";
import Table from "@/components/shared/table/Table.component";
import { ICellChangePayload, IItem } from "@/components/shared/table/types";
import { TableSelectionState } from "@/components/shared/table/table-selection-state";
import { SelectedDataExtractor } from "@/components/shared/table/selected-data-extractor";
import TableDataImport from "@/components/shared/table/TableDataImport.component";
import FileUploadModal from "@/components/shared/FileUploadModal.component";
import { Button } from "@/components/shared/Button.component";
import { TableEditingState } from "@/components/shared/table/table-editing-state";
import tableActionsStyle from "@/components/shared/table/TableActions.component.module.scss";
import { ApiSchema } from "@/models/api-schema";
import {
  BaseFieldTypeEnum,
  ComplexFieldTypeEnum,
} from "@/models/object-type/types";
import { ListDataManager } from "@/utils/list-data-manager";
import ApiManagementSectionHeader from "@/components/api-management/ApiManagementSectionHeader.component";
import { Loader } from "@/components/shared/Loader.component";
import _pick from "lodash/pick";

const getInitialVariables = (): ICustomerListVariables => ({
  limit: 50,
  nextToken: null,
  filter: undefined,
  sortOrder: SortOrderType.DESC,
});

const FILE_MODAL_ID = "fileUploadModal";

@Component
export default class ApiDetailsAssetsExplorer extends UserPermissions {
  @Prop({ required: true }) value: IApiState;
  @Prop({ required: true }) apiClient: ApolloClient<unknown>;
  @Prop({ required: true }) apiSchemaHandler: ApiSchemaHandler | null;
  private inputFiles: File[] | null = null;
  private uploadProgress = 0;

  private get listDataManager() {
    return Vue.observable(
      new ListDataManager<
        { items: IItem[]; nextToken?: string | null },
        ICustomerListVariables
      >(
        this.customerApiForFiles.watchFileDocuments.bind(
          this.customerApiForFiles
        )
      )
    );
  }

  private tableSelectionState = Vue.observable(
    new TableSelectionState(true, false)
  );

  private get tableEditingState() {
    return Vue.observable(new TableEditingState(this.fields));
  }

  private selectedDataExtractor = new SelectedDataExtractor(
    [],
    [],
    true,
    false
  );

  private get schemaFieldTypes() {
    return this.apiSchemaHandler?.inProgress || !this.apiSchemaHandler
      ? {}
      : this.apiSchemaHandler?.schemaFieldTypes;
  }

  private get customerApi() {
    return new DedicatedApi(
      this.apiClient,
      this.schemaFieldTypes,
      new ApiSchema(this.value, this.schemaFieldTypes)
    );
  }

  private get customerApiForFiles() {
    return new CustomerApiForFiles(this.customerApi);
  }

  private get fields() {
    const fieldNames =
      this.schemaFieldTypes[FILE_DOCUMENT_TYPE]?.fields
        ?.filter(
          (field) =>
            field.args &&
            field.args?.length === 0 &&
            field.name !== "id" &&
            field.name !== "key" &&
            field.name !== "etag" &&
            field.name !== "createdAt"
        )
        .map((field) => field.name) ?? [];

    return sortFieldsArray(fieldNames).map((field) => ({
      key: field,
      label: upperCaseFirstLetter(field),
      type:
        field.toLowerCase() === "url"
          ? ComplexFieldTypeEnum.Url
          : BaseFieldTypeEnum.String,
      readOnly: field.toLowerCase() === "name" ? false : true,
    }));
  }

  private get requiresPublishing() {
    return !this.value.graphqlUrl;
  }

  @Watch("apiSchemaHandler.inProgress", { immediate: true })
  protected onApiSchemaHandlerInitialized() {
    if (this.apiSchemaHandler && !this.apiSchemaHandler?.inProgress) {
      this.listDataManager.subscribeToList(getInitialVariables());
    }
  }

  private async handleFileDropped(file: File | File[] | null) {
    if (!file) return;

    this.inputFiles = Array.isArray(file) ? file : [file];

    if (this.inputFiles.length === 0) return;

    try {
      this.uploadProgress = 10;
      const intents = await Promise.all(
        this.inputFiles.map(async (inputFile) => {
          const intent = await this.customerApi.uploadFileIntent({
            input: {
              key: inputFile.name,
              mimeType: inputFile.type || undefined,
              accessControl: AccessControlType.PUBLIC,
            },
          });

          if (!intent) {
            throw new Error("No intent returned");
          }

          const response = await fetch(intent.uploadUrl, {
            method: "PUT",
            body: inputFile,
            headers: {
              "Content-Type": intent.mimeType,
            },
          });

          if (!response.ok) {
            throw new Error("Upload failed");
          }
          this.uploadProgress += (100 / (this.inputFiles?.length ?? 1)) * 0.8;
          return intent;
        })
      );

      this.uploadProgress = 90;

      await this.customerApiForFiles.batchCreateFileDocumentsFromIntents(
        intents,
        getInitialVariables()
      );

      this.uploadProgress = 100;
    } catch (e) {
      this.listDataManager.errorMessage = getErrorMessage(e);
    } finally {
      this.$nextTick(() => {
        this.uploadProgress = 0;
      });
    }
  }

  private async handleCellChange(payload: ICellChangePayload) {
    await this.customerApiForFiles.updateFileDocument({
      id: `${payload.item?.id ?? ""}`,
      [payload.field]: payload.value,
    });
    this.tableEditingState.isEditing = false;
    this.tableEditingState.shouldReplaceContent = false;
    this.tableSelectionState.deselectAll();
  }

  private async onDeleteEntries() {
    try {
      if (this.hasAdminPermissions) {
        const selectedEntries = this.listDataManager
          .entriesFromSelectionState(this.tableSelectionState)
          .map((entry) => _pick(entry, ["id"]));

        if (
          window.confirm(
            this.$t("api_details_data_delete_entries_confirm", {
              numOfEntries:
                selectedEntries.length > 1
                  ? this.$t("global_many_entries", {
                      numOfEntries: selectedEntries.length,
                    })
                  : this.$t("global_single_entry"),
              typeName: "Files",
            }).toString()
          )
        ) {
          await this.customerApiForFiles.batchDeleteFileDocuments(
            selectedEntries,
            getInitialVariables()
          );
        }
      } else {
        throw new Error(
          this.$t("error_api_management_requires_role").toString()
        );
      }
    } catch (err) {
      this.listDataManager.errorMessage = getErrorMessage(err);
    }
  }

  private async onExportData() {
    try {
      exportString(
        `${slugFromName(this.value.name ?? "", "")}-Files-page${
          this.listDataManager.paginationState.currentPage
        }`,
        this.selectedDataExtractor.extractDataToCopy(
          this.tableSelectionState.selectedRows,
          this.tableSelectionState.selectedColumns,
          this.tableSelectionState.selectedCells,
          true
        )
      );
    } catch (err) {
      this.listDataManager.errorMessage = getErrorMessage(err);
    }
  }

  render() {
    return (
      <BContainer class="api__view-setup" fluid>
        <ApiManagementSectionHeader
          title={this.$t("api_details_section_title_files").toString()}
          description={this.$t(
            "api_details_section_description_files"
          ).toString()}
        />
        <BAlert
          show={this.listDataManager.showErrorMessage}
          variant="danger"
          data-testid="apiSettingsFormError"
        >
          {this.listDataManager.errorMessage}
        </BAlert>
        <TableDataImport
          uploadProgress={this.uploadProgress}
          modalId={FILE_MODAL_ID}
          scopedSlots={{
            actions: ({ onImport }) => (
              <TableActions
                class={tableActionsStyle.roundedBorderTop}
                onAddEntry={onImport}
                withDelete
                canDeleteItems={
                  this.tableSelectionState.hasOnlyFullRowsSelected
                }
                onDeleteEntries={this.onDeleteEntries}
                withExport
                onExport={this.onExportData}
                canExport={this.tableSelectionState.hasAnySelection}
                canAddItems={!this.requiresPublishing}
                addActionCopy={this.$t("global_cta_upload_file").toString()}
              />
            ),
            modal: ({ pastedSupportedFiles, onModalHidden }) => (
              <FileUploadModal
                initialFiles={pastedSupportedFiles}
                modalId={FILE_MODAL_ID}
                scopedSlots={{
                  file: (props) => (
                    <div>
                      <div class={props.titleClassName}>{props.file.name}</div>
                      <div class={props.subtitleClassName}>
                        {this.$t("global_size").toString()}: {props.file.size}{" "}
                        bytes
                      </div>
                    </div>
                  ),
                  submitButton: (props) => (
                    <Button
                      theme={props.theme}
                      class={props.className}
                      onClick={() => {
                        props.onSuccess();
                        onModalHidden();
                        this.handleFileDropped(props.files);
                      }}
                      data-testid="uploadCsvFile"
                    >
                      {this.$t("global_cta_upload")}
                    </Button>
                  ),
                }}
              >
                <template slot="header">{this.$t("global_files")}</template>
              </FileUploadModal>
            ),
          }}
        >
          <Table
            ref="table"
            items={this.listDataManager.data}
            hasMoreItems={!!this.listDataManager.listVariables?.nextToken}
            fields={this.fields}
            withSelectionColumn
            tableSelectionState={this.tableSelectionState}
            tableEditingState={this.tableEditingState}
            selectedDataExtractor={this.selectedDataExtractor}
            onPaginationChange={this.listDataManager.onPaginationChange}
            paginationState={this.listDataManager.paginationState}
            onCellChange={this.handleCellChange}
          >
            {this.requiresPublishing ? (
              <div>{this.$t("api_details_files_publishing_hint")}</div>
            ) : this.listDataManager.isLoading || !this.schemaFieldTypes ? (
              <Loader data-testid="tableLoader" />
            ) : null}
          </Table>
        </TableDataImport>
      </BContainer>
    );
  }
}
