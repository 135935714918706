import { MutationTypeEnum } from "@graphapi-io/api-declaration";
import { FieldNameComposer } from "./fieldname-composer";
import { ApiFields } from "./api-fields";
import { gql } from "apollo-boost";

export abstract class GqlMutationComposer {
  public static buildCreateMutation(typeName: string, fields: ApiFields) {
    const inputName = FieldNameComposer.inputNameForType(
      MutationTypeEnum.CREATE,
      typeName
    );
    const nonObjectFields = fields.getNonObjectFieldsForObjectType(typeName);
    const mutationName = FieldNameComposer.fieldNameForMutationType(
      MutationTypeEnum.CREATE,
      typeName
    );

    return gql`
      mutation ${mutationName}($input: ${inputName}) {
        ${mutationName}(input: $input) {
          ${nonObjectFields.join("\n")}
        }
      }`;
  }

  public static buildBatchCreateMutation(typeName: string, fields: ApiFields) {
    const inputName = FieldNameComposer.inputNameForType(
      MutationTypeEnum.BATCH_CREATE,
      typeName
    );
    const nonObjectFields = fields.getNonObjectFieldsForObjectType(typeName);
    const mutationName = FieldNameComposer.fieldNameForMutationType(
      MutationTypeEnum.BATCH_CREATE,
      typeName
    );

    return gql`
      mutation ${mutationName}($input: [${inputName}]) {
        ${mutationName}(input: $input) {
          ${nonObjectFields.join("\n")}
        }
      }`;
  }

  public static buildUpdateMutation(typeName: string, fields: ApiFields) {
    const inputName = FieldNameComposer.inputNameForType(
      MutationTypeEnum.UPDATE,
      typeName
    );
    const nonObjectFields = fields.getNonObjectFieldsForObjectType(typeName);
    const mutationName = FieldNameComposer.fieldNameForMutationType(
      MutationTypeEnum.UPDATE,
      typeName
    );

    return gql`
      mutation ${mutationName}($input: ${inputName}) {
        ${mutationName}(input: $input) {
          ${nonObjectFields.join("\n")}
        }
      }`;
  }

  public static buildDeleteMutation(typeName: string) {
    const mutationName = FieldNameComposer.fieldNameForMutationType(
      MutationTypeEnum.DELETE,
      typeName
    );

    return gql`
      mutation ${mutationName}($input: ${FieldNameComposer.inputNameForType(
      MutationTypeEnum.DELETE,
      typeName
    )}) {
        ${mutationName}(input: $input) {
          id
        }
      }`;
  }

  public static buildBatchDeleteMutation(typeName: string) {
    const mutationName = FieldNameComposer.fieldNameForMutationType(
      MutationTypeEnum.BATCH_DELETE,
      typeName
    );

    return gql`
      mutation ${mutationName}($input: [${FieldNameComposer.inputNameForType(
      MutationTypeEnum.BATCH_DELETE,
      typeName
    )}]) {
        ${mutationName}(input: $input) {
          id
        }
      }`;
  }
}
