import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { ObjectTypeFieldNameInput } from "./ObjectTypeFieldNameInput.component";
import FormInputsRow from "../forms/inputs/FormInputsRow.component";
import { BButton } from "@/lib/typed-bootstrap";
import * as tsx from "vue-tsx-support";
import { ObjectTypeField } from "./ObjectTypeField.component";
import {
  excludeSortByFromFieldDirectives,
  extendFieldWithSortByDirective,
  getParentConnectionField,
  getParentConnectionFieldNameInChild,
  IEnumState,
  IFieldState,
  IObjectTypeState,
  nonSortableFieldTypes,
  toggleFieldRequiredDirective,
  toggleFieldRequiredOnCreateDirective,
  toggleFieldRequiredOnDeleteDirective,
  toggleFieldRequiredOnUpdateDirective,
} from "@/models";
import { FieldDirectiveEnum, PublishingStateEnum } from "@/api/common-types";
import ShortUniqueId from "short-unique-id";
import style from "./ObjectTypeFields.component.module.scss";
import plusIcon from "@/assets/img/plus-icon-placeholder.png";
import _setFp from "lodash/fp/set";

const { randomUUID } = new ShortUniqueId({ length: 6 });
@Component({ components: { ObjectTypeFieldNameInput, FormInputsRow } })
export default class ObjectTypeFields extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: ObjectTypeFields["value"];
    allTypes: ObjectTypeFields["allTypes"];
    allEnumTypes?: ObjectTypeFields["allEnumTypes"];
  }> &
    tsx.DeclareOnEvents<{
      onAddEnumType: void;
      onAddObjectTypeField: void;
    }>;

  @Prop({ required: true }) value: IObjectTypeState;
  @Prop({ required: true }) allTypes: IObjectTypeState[];
  @Prop({ required: false, default: null }) allEnumTypes: IEnumState[] | null;

  @Emit("addEnumType") onAddEnumType() {}

  private get parentFields(): IFieldState[] {
    return Object.values(this.value.parents)
      .map((parent) => parent?.parentId)
      .filter(Boolean)
      .map((parentId) => {
        const parentType = this.allTypes.find((type) => type.id === parentId);
        const connectorField = getParentConnectionField(parentType);
        return {
          id: connectorField?.id ?? randomUUID(),
          name: getParentConnectionFieldNameInChild(parentType),
          type: "ID",
          readonly: true,
          state: PublishingStateEnum.INITIALIZED,
        };
      });
  }

  private get enumTypesOptions(): { value: string; text: string }[] {
    return (this.allEnumTypes ?? []).map((type) => ({
      value: type.name,
      text: type.name,
    }));
  }

  private get sortByField() {
    return (
      this.value.fields.find((field) =>
        field?.directives?.includes(FieldDirectiveEnum.SORTBY)
      )?.name ?? "updatedAt"
    );
  }

  private isUniqueField(fieldName: string, fieldIndex: number): boolean | null {
    const isDuplicate = this.value.fields.some((field, index) => {
      return index !== fieldIndex && field.name === fieldName;
    });

    return isDuplicate ? false : null;
  }

  private onInput(field: IFieldState, index: number) {
    if (
      field.type &&
      (nonSortableFieldTypes as readonly string[]).includes(field.type)
    ) {
      this.value.fields = _setFp(
        index,
        excludeSortByFromFieldDirectives(field),
        this.value.fields
      );
    } else {
      this.value.fields = _setFp(index, field, this.value.fields);
    }
  }

  private onSortByFieldChange(isSelected: boolean, updateIndex: number) {
    this.value.fields = this.value.fields.map((field, index) => {
      if (index === updateIndex && isSelected) {
        return extendFieldWithSortByDirective(field);
      }

      return excludeSortByFromFieldDirectives(field);
    });
  }

  private onRequiredFieldToggle(
    directive: FieldDirectiveEnum,
    updateIndex: number
  ) {
    this.value.fields = this.value.fields.map((field, index) => {
      if (index === updateIndex) {
        switch (directive) {
          case FieldDirectiveEnum.REQUIRED:
            return toggleFieldRequiredDirective(field);
          case FieldDirectiveEnum.REQUIRED_ON_CREATE:
            return toggleFieldRequiredOnCreateDirective(field);
          case FieldDirectiveEnum.REQUIRED_ON_DELETE:
            return toggleFieldRequiredOnDeleteDirective(field);
          case FieldDirectiveEnum.REQUIRED_ON_UPDATE:
            return toggleFieldRequiredOnUpdateDirective(field);
        }
      }

      return field;
    });
  }

  private onDeleteField(index: number) {
    this.value.fields.splice(index, 1);
  }

  private hasDuplicate(): { hasDuplicate: boolean; fieldName: string } {
    const seen = new Set();
    let fieldName = "";
    const hasDuplicate = this.value.fields.some((field) => {
      fieldName = field.name;
      return seen.size === seen.add(field.name).size;
    });

    return { hasDuplicate, fieldName };
  }

  private onAddField() {
    try {
      const duplicateValidation = this.hasDuplicate();
      if (duplicateValidation.hasDuplicate) {
        throw new Error(
          this.$t("form_feedback_field_name", {
            name: duplicateValidation.fieldName,
          }).toString()
        );
      }

      const lastField = this.value.fields[this.value.fields.length - 1];
      if (lastField?.type && lastField?.name?.length > 0) {
        this.value.fields.push({
          id: randomUUID(),
          type: "String",
          name: "",
          typeId: this.value.id,
          directives: [],
          state: PublishingStateEnum.INITIALIZED,
        });
      }

      this.$emit("addObjectTypeField");
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div>
        <div class="ml-0">
          <FormInputsRow>
            <template slot="leftColumn">
              <label class={style.fieldLabel}>
                {this.$t("model_field_type")}
              </label>
            </template>
            <template slot="rightColumn">
              <label class={style.fieldLabel}>{this.$t("global_name")}</label>
            </template>
          </FormInputsRow>
          {this.parentFields.map((field, index) => (
            <ObjectTypeField
              key={`parentField-${index}`}
              value={field}
              isUnique={true}
              enumTypesOptions={this.enumTypesOptions}
              index={index}
              isSortByField={false}
            ></ObjectTypeField>
          ))}
          {this.value.fields.map((field, index) => (
            <ObjectTypeField
              key={`field-${index}`}
              value={field}
              isUnique={this.isUniqueField(field.name, index)}
              enumTypesOptions={this.enumTypesOptions}
              index={index}
              isSortByField={field.name === this.sortByField}
              onInput={(updatedField) => this.onInput(updatedField, index)}
              onAddEnumType={this.onAddEnumType}
              onSortByFieldChange={(isSelected) =>
                this.onSortByFieldChange(isSelected, index)
              }
              onRequiredFieldChange={(directive) =>
                this.onRequiredFieldToggle(directive, index)
              }
              onDelete={() => this.onDeleteField(index)}
            ></ObjectTypeField>
          ))}
        </div>
        <BButton
          class="create__button--shallow mt-2"
          type="button"
          onClick={tsx.modifiers.prevent(this.onAddField)}
        >
          <div class="add-button__icon">
            <img src={plusIcon} />
          </div>
          <div class="button__icon-text">
            {this.$t("model_widget_add_field")}
          </div>
        </BButton>
      </div>
    );
  }
}
