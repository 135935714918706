import { ApolloClient, DocumentNode, MutationOptions } from "apollo-boost";
import { MutationTypeEnum } from "@graphapi-io/api-declaration";

export class ApiMutation {
  constructor(
    protected variant: MutationTypeEnum,
    protected apolloClient: ApolloClient<unknown>
  ) {}

  public async mutate(
    mutation: DocumentNode,
    variables: { input: Record<string, unknown> },
    mutationName: string,
    updateCache?: MutationOptions<any>["update"]
  ) {
    const result = await this.apolloClient.mutate({
      mutation,
      variables,
      update: updateCache,
    });

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    return result.data[mutationName];
  }

  public async batchMutate(
    mutation: DocumentNode,
    variables: { input: Record<string, unknown>[] },
    mutationName: string,
    updateCache?: MutationOptions<any>["update"]
  ) {
    const result = await this.apolloClient.mutate({
      mutation,
      variables,
      update: updateCache,
    });

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    return result.data[mutationName];
  }
}
