import { Component, Watch } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { deleteRoleMutation } from "@/api/role";
import { OrgModule } from "@/store/modules/org";
import _remove from "lodash/remove";

import FormTable from "@/components/forms/FormTable.component.vue";
import { RoleTypeEnum } from "@/api/common-types";
import { getRolesByOrg, IRoleByOrgState } from "@/models/roles";
import UserPermissions from "@/mixins/UserPermissions.mixin";

@Component({
  components: { FormTable },
})
export default class OrgUserManagement extends UserPermissions {
  $refs!: {
    collaboratorsTable: FormTable;
  };

  errorMessage = "";
  showErrorMessage = false;
  selected: IRoleByOrgState[] = [];
  indeterminate = false;
  isLoading = false;

  collaborators: IRoleByOrgState[] = [];

  tableFields = [
    { key: "selected", label: "" },
    { key: "userEmail", label: this.$t("org_users_table_header_email") },
    { key: "type", label: this.$t("org_users_table_header_role") },
    { key: "lastSignInAt", label: this.$t("org_users_table_header_signin") },
  ];

  selectedAction = null;
  actionOptions = [
    { value: "edit", text: this.$t("global_cta_edit"), disabled: true },
    { value: "delete", text: this.$t("global_cta_delete"), disabled: true },
  ];

  get orgId(): string {
    return OrgModule.id;
  }

  get noRowSelected(): boolean {
    return this.selected.length === 0;
  }

  @Watch("orgId", { immediate: true })
  async onOrgChange() {
    await this.getCollaborators();

    if (this.canManageUsers) {
      this.actionOptions.map((item) => (item.disabled = false));
    }
  }

  async getCollaborators(): Promise<any> {
    try {
      this.isLoading = true;
      this.collaborators = await getRolesByOrg(this.orgId);
      this.isLoading = false;
    } catch (err) {
      console.log(err);
      this.isLoading = false;
    }
  }

  onInvite(invitee: { email?: string; role?: string; roleId?: string }): void {
    try {
      if (this.canManageUsers) {
        this.$router.push({
          name: "org-users-manage",
          params: {
            orgId: this.orgId,
            action: invitee.email ? "edit" : "invite",
          },
          ...(invitee.email && { query: invitee }),
        });

        this.resetActionsMenu();
      } else {
        throw new Error(this.$t("error_user_mgt_can_manage").toString());
      }
    } catch (err) {
      this.showError((err as Error).message);
    }
  }

  onRowSelected(items: IRoleByOrgState[]): void {
    this.selected = items;
    const role = this.selected[0];
    if (
      this.roleIdBelongsToCurrentUser(role?.id) ||
      (role?.type === RoleTypeEnum.OWNER && !this.canManageOrg)
    ) {
      this.actionOptions.map((item) => (item.disabled = true));
    } else if (this.canManageUsers) {
      this.actionOptions.map((item) => (item.disabled = false));
    }
  }

  async onSelectAction(item: string): Promise<void> {
    try {
      if (this.canManageUsers) {
        const role = this.selected[0];
        if (role.type === RoleTypeEnum.OWNER && !this.isOwner) {
          this.showError(this.$t("error_user_mgt_can_manage_owner").toString());
        } else {
          if (item === "delete") {
            if (this.roleIdBelongsToCurrentUser(role?.id)) {
              this.showError(
                this.$t("error_user_mgt_cant_remove_self").toString()
              );
            } else {
              await deleteRoleMutation({ input: { id: role.id } });
              _remove(this.collaborators, { id: role.id });
            }
          } else if (item === "edit") {
            this.onInvite({
              email: role.userEmail,
              role: role.type,
              roleId: role.id,
            });
          }

          this.resetActionsMenu();
        }
      } else {
        throw new Error(this.$t("error_user_mgt_can_manage").toString());
      }
    } catch (err) {
      this.showError((err as Error).message);
    }
  }

  resetActionsMenu(): void {
    setTimeout(() => {
      this.selectedAction = null;
      (this.$refs.collaboratorsTable as any)?.refresh();
    }, 1);
  }

  roleIdBelongsToCurrentUser(roleId: string): boolean {
    return UserModule.roles[OrgModule.id]?.id === roleId;
  }

  showError(errorMessage: string): void {
    this.errorMessage = errorMessage;
    this.showErrorMessage = true;
  }
}
