import { Component, Prop, Watch } from "vue-property-decorator";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import { ApolloClient } from "apollo-boost";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import {
  ICustomerListVariables,
  CLOUD_FUNCTION_TYPE,
  CustomerApiCloudFunctions,
  DedicatedApi,
  ICloudFunction,
} from "@/api-client";
import { IApiState } from "@/models/api";
import {
  exportString,
  getErrorMessage,
  slugFromName,
  sortFieldsArray,
} from "@/utils";
import { BAlert, BContainer } from "@/lib/typed-bootstrap";
import ApiManagementCloudFunctionsModal, {
  CLOUD_FUNCTION_MODAL_ID,
} from "@/components/api-management/ApiManagementCloudFunctionsModal.component";
import TableActions from "@/components/shared/table/TableActions.component";
import TableDataImport from "@/components/shared/table/TableDataImport.component";
import Table from "@/components/shared/table/Table.component";
import { TableSelectionState } from "@/components/shared/table/table-selection-state";
import { SelectedDataExtractor } from "@/components/shared/table/selected-data-extractor";
import Vue from "vue";
("s");
import { TableEditingState } from "@/components/shared/table/table-editing-state";
import tableActionsStyle from "@/components/shared/table/TableActions.component.module.scss";
import { ApiSchema } from "@/models/api-schema";
import { BaseFieldTypeEnum } from "@/models";
import { SortOrderType } from "@/generated/types";
import { ListDataManager } from "@/utils/list-data-manager";
import ApiManagementSectionHeader from "@/components/api-management/ApiManagementSectionHeader.component";
import { Loader } from "@/components/shared/Loader.component";
import _pick from "lodash/pick";

const getInitialVariables = (): ICustomerListVariables => ({
  limit: 50,
  nextToken: null,
  filter: undefined,
  sortOrder: SortOrderType.DESC,
});

@Component
export default class ApiDetailsCloudFunctionsExplorer extends UserPermissions {
  @Prop({ required: true }) private value: IApiState;
  @Prop({ required: true }) private apiClient: ApolloClient<unknown>;
  @Prop({ required: true }) private apiSchemaHandler: ApiSchemaHandler | null;

  private get listDataManager() {
    return Vue.observable(
      new ListDataManager<
        { items: ICloudFunction[]; nextToken?: string | null },
        ICustomerListVariables
      >(
        this.customerApiCloudFunctions.watchCloudFunctions.bind(
          this.customerApiCloudFunctions
        )
      )
    );
  }

  private tableSelectionState = Vue.observable(
    new TableSelectionState(true, false)
  );

  private get tableEditingState() {
    return Vue.observable(new TableEditingState(this.fields));
  }

  private selectedDataExtractor = new SelectedDataExtractor(
    [],
    [],
    true,
    false
  );

  private get schemaFieldTypes() {
    return this.apiSchemaHandler?.inProgress || !this.apiSchemaHandler
      ? {}
      : this.apiSchemaHandler?.schemaFieldTypes;
  }

  private get customerApi() {
    return new DedicatedApi(
      this.apiClient,
      this.schemaFieldTypes,
      new ApiSchema(this.value, this.schemaFieldTypes)
    );
  }

  private get customerApiCloudFunctions() {
    return new CustomerApiCloudFunctions(this.customerApi);
  }

  private get fields() {
    const fieldNames =
      this.schemaFieldTypes[CLOUD_FUNCTION_TYPE]?.fields
        ?.filter(
          (field) =>
            field.args &&
            field.args?.length === 0 &&
            field.name !== "id" &&
            field.name !== "key" &&
            field.name !== "etag" &&
            field.name !== "url" &&
            field.name !== "createdAt" &&
            field.name !== "mimeType"
        )
        .map((field) => field.name) ?? [];

    return sortFieldsArray(fieldNames).map((field) => ({
      key: field,
      label: field,
      type: BaseFieldTypeEnum.String,
      readOnly: true,
    }));
  }

  private get requiresPublishing() {
    return !this.value.graphqlUrl;
  }

  @Watch("apiSchemaHandler.inProgress", { immediate: true })
  protected onApiSchemaHandlerInitialized() {
    if (this.apiSchemaHandler && !this.apiSchemaHandler?.inProgress) {
      this.listDataManager.subscribeToList(getInitialVariables());
    }
  }

  private async onDeleteEntries() {
    try {
      if (this.hasAdminPermissions) {
        const selectedEntries = this.listDataManager
          .entriesFromSelectionState(this.tableSelectionState)
          .map((entry) => _pick(entry, ["id"]));

        if (
          window.confirm(
            this.$t("api_details_data_delete_entries_confirm", {
              numOfEntries:
                selectedEntries.length > 1
                  ? this.$t("global_many_entries", {
                      numOfEntries: selectedEntries.length,
                    })
                  : this.$t("global_single_entry"),
              typeName: "Cloud Functions",
            }).toString()
          )
        ) {
          await this.customerApiCloudFunctions.batchDeleteCloudFunctions(
            selectedEntries,
            getInitialVariables()
          );
        }
      } else {
        throw new Error(
          this.$t("error_api_management_requires_role").toString()
        );
      }
    } catch (err) {
      this.listDataManager.errorMessage = getErrorMessage(err);
    }
  }

  private async onExportData() {
    try {
      exportString(
        `${slugFromName(this.value.name ?? "", "")}-Files-page${
          this.listDataManager.paginationState.currentPage
        }`,
        this.selectedDataExtractor.extractDataToCopy(
          this.tableSelectionState.selectedRows,
          this.tableSelectionState.selectedColumns,
          this.tableSelectionState.selectedCells,
          true
        )
      );
    } catch (err) {
      this.listDataManager.errorMessage = getErrorMessage(err);
    }
  }

  private get items() {
    return this.listDataManager.data.map((item) => ({ ...item }));
  }

  render() {
    return (
      <BContainer class="api__view-setup" fluid>
        <ApiManagementSectionHeader
          title={this.$t("api_details_section_title_functions").toString()}
          description={this.$t(
            "api_details_section_description_functions"
          ).toString()}
        />
        <BAlert
          show={this.listDataManager.showErrorMessage}
          variant="danger"
          data-testid="apiSettingsFormError"
        >
          {this.listDataManager.errorMessage}
        </BAlert>

        <TableDataImport
          modalId={CLOUD_FUNCTION_MODAL_ID}
          accept="js,mjs,cjs,py,rb"
          scopedSlots={{
            actions: ({ onImport }) => (
              <TableActions
                class={tableActionsStyle.roundedBorderTop}
                onAddEntry={onImport}
                withDelete
                canDeleteItems={
                  this.tableSelectionState.hasOnlyFullRowsSelected
                }
                onDeleteEntries={this.onDeleteEntries}
                withExport
                onExport={this.onExportData}
                canExport={
                  this.tableSelectionState.hasAnyCellSelection ||
                  this.tableSelectionState.hasAnyRowSelection ||
                  this.tableSelectionState.hasAnyColumnSelection
                }
                canAddItems={!this.requiresPublishing}
                addActionCopy={this.$t("global_cta_upload_function").toString()}
              />
            ),
            modal: ({ pastedSupportedFiles, onModalHidden }) => (
              <ApiManagementCloudFunctionsModal
                onHidden={onModalHidden}
                initialFiles={pastedSupportedFiles}
                customerApiCloudFunctions={this.customerApiCloudFunctions}
                initialVariables={getInitialVariables()}
              />
            ),
          }}
        >
          <Table
            ref="table"
            items={this.items}
            hasMoreItems={!!this.listDataManager.listVariables?.nextToken}
            fields={this.fields}
            withSelectionColumn
            tableSelectionState={this.tableSelectionState}
            tableEditingState={this.tableEditingState}
            selectedDataExtractor={this.selectedDataExtractor}
            onPaginationChange={this.listDataManager.onPaginationChange}
            paginationState={this.listDataManager.paginationState}
          >
            {this.requiresPublishing ? (
              <div>{this.$t("api_details_files_publishing_hint")}</div>
            ) : this.listDataManager.isLoading || !this.schemaFieldTypes ? (
              <Loader data-testid="tableLoader" />
            ) : null}
          </Table>
        </TableDataImport>
      </BContainer>
    );
  }
}
