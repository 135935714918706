import Vue from "vue";
import { BRow } from "@/lib/typed-bootstrap";
import style from "./ObjectTypeSectionHeader.component.module.scss";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";

@Component
export class ObjectTypeSectionHeader extends Vue {
  _tsx!: tsx.DeclareProps<
    Partial<Pick<ObjectTypeSectionHeader, "noUnderline">>
  >;
  @Prop({ default: false }) noUnderline: boolean;

  render() {
    return (
      <BRow alignV="center" class={style.section}>
        <div class={style.header}>{this.$slots.default}</div>
        {!this.noUnderline && <div class={style.underline}></div>}
      </BRow>
    );
  }
}
