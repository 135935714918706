import { NON_GERMANY_EU_COUNTRIES } from "@/utils";
import VueI18n from "vue-i18n";
import { IBillingAddressInputState } from "./types";

export const validateBillingAddress = (
  billingAddress: Partial<IBillingAddressInputState>,
  $t: (
    key: string,
    values?: VueI18n.Values | undefined
  ) => VueI18n.TranslateResult
) => {
  if (!billingAddress.country) {
    throw new Error($t("error_billing_requires_country").toString());
  }

  if (!billingAddress.line1 || billingAddress.line1.length <= 0) {
    throw new Error($t("error_billing_requires_address").toString());
  }

  if (!billingAddress.postalCode || billingAddress.postalCode.length <= 0) {
    throw new Error($t("error_billing_requires_address").toString());
  }

  if (!billingAddress.city || billingAddress.city.length <= 0) {
    throw new Error($t("error_billing_requires_address").toString());
  }
};

export const validateVatId = (
  country: string,
  vatId: string | undefined,
  $t: (
    key: string,
    values?: VueI18n.Values | undefined
  ) => VueI18n.TranslateResult
) => {
  if (
    NON_GERMANY_EU_COUNTRIES.map((country) => country.value).includes(
      country
    ) &&
    !vatId
  ) {
    throw new Error($t("error_billing_requires_vat_id").toString());
  }
};
