import { BFormGroup } from "@/lib/typed-bootstrap";
import { upperCaseFirstLetter } from "@/utils";
import { Vue, Component, Prop } from "vue-property-decorator";
import tsx from "vue-tsx-support";

@Component
export default class FormGroupWithLabel extends Vue {
  _tsx!: tsx.DeclareProps<{
    label?: FormGroupWithLabel["label"];
    labelAmendment?: FormGroupWithLabel["labelAmendment"];
    upperCaseLabel?: FormGroupWithLabel["upperCaseLabel"];
    inputType?: FormGroupWithLabel["inputType"];
  }>;

  @Prop() id?: string;
  @Prop() readonly label?: string;
  @Prop() readonly labelAmendment?: string;
  @Prop({ default: true }) readonly upperCaseLabel: boolean;
  @Prop({ default: "unknown" }) readonly inputType: string;

  get inputKey(): string {
    return `formInput${upperCaseFirstLetter(
      this.id || this.inputType || Date.now().toString()
    )}`;
  }

  public render() {
    const { label, labelAmendment, upperCaseLabel, inputKey } = this;

    return (
      <BFormGroup
        class={{
          "default__form-group": true,
          uppercase: upperCaseLabel,
        }}
      >
        {label && (
          <label class="default__label" for={inputKey}>
            {labelAmendment && (
              <span class="form-group__label-amendment">{labelAmendment}</span>
            )}
            {label}
          </label>
        )}
        {this.$slots.default}
      </BFormGroup>
    );
  }
}
