import {
  IFlow,
  IListFlowsByApiIdQuery,
  IQueryListFlowsByApiArgs,
  ICreateFlowMutation,
  ICreateFlowMutationVariables,
  IUpdateFlowMutation,
  IUpdateFlowMutationVariables,
  IQueryFlowQueryVariables,
  IQueryFlowQuery,
  IDeleteFlowMutationVariables,
  IDeleteFlowMutation,
} from "@/generated/types";
import {
  mutationFunctionFactory,
  watchListObjectsFactory,
  watchQueryFunctionFactory,
} from "../internal-utils";
import LIST_FLOWS_BY_API_ID from "./list_flows_by_api_id.graphql";
import CREATE_FLOW from "./create_flow.graphql";
import UPDATE_FLOW from "./update_flow.graphql";
import DELETE_FLOW from "./delete_flow.graphql";
import FLOW from "./flow.graphql";
import { isNotNullable } from "@/utils";
import {
  addToListFrontAfterCreation,
  removeFromListAfterDeletion,
} from "@/utils/apollo-cache-updates";

export const watchFlowsByApiIdQuery = watchListObjectsFactory<
  IListFlowsByApiIdQuery,
  IQueryListFlowsByApiArgs,
  IFlow
>(
  LIST_FLOWS_BY_API_ID,
  (result) => {
    const items = result.listFlowsByApi?.items?.filter(isNotNullable) ?? [];
    const nextToken = result.listFlowsByApi?.nextToken ?? null;

    return { items, nextToken };
  },
  (updatedResult) => ({
    listFlowsByApi: {
      items: updatedResult.items,
      nextToken: updatedResult.nextToken,
    },
  })
);

export const createFlowMutation = mutationFunctionFactory<
  ICreateFlowMutation,
  ICreateFlowMutationVariables
>(CREATE_FLOW, undefined, undefined, (dataProxy, mutationResult, variables) =>
  addToListFrontAfterCreation<
    ICreateFlowMutation,
    "createFlow",
    IListFlowsByApiIdQuery,
    "listFlowsByApi",
    IQueryListFlowsByApiArgs
  >(
    "createFlow",
    { apiId: variables?.input.apiId || "" },
    "listFlowsByApi",
    LIST_FLOWS_BY_API_ID
  )(dataProxy, mutationResult)
);

export const updateFlowMutation = mutationFunctionFactory<
  IUpdateFlowMutation,
  IUpdateFlowMutationVariables
>(UPDATE_FLOW);

export const deleteFlowMutation = mutationFunctionFactory<
  IDeleteFlowMutation,
  IDeleteFlowMutationVariables
>(DELETE_FLOW, undefined, undefined, (dataProxy, mutationResult) =>
  removeFromListAfterDeletion<
    IDeleteFlowMutation,
    "deleteFlow",
    IListFlowsByApiIdQuery,
    "listFlowsByApi",
    IQueryListFlowsByApiArgs
  >(
    "deleteFlow",
    { apiId: mutationResult.data?.deleteFlow?.apiId || "" },
    "listFlowsByApi",
    LIST_FLOWS_BY_API_ID
  )(dataProxy, mutationResult)
);

export const watchFlowQuery = (variables: IQueryFlowQueryVariables) =>
  watchQueryFunctionFactory<IQueryFlowQuery, IQueryFlowQueryVariables>(
    FLOW,
    variables
  );
