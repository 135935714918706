import { Vue, Component } from "vue-property-decorator";
import style from "./FormInputWithAction.component.module.scss";
import * as tsx from "vue-tsx-support";

@Component
export class FormInputWithAction extends Vue {
  $scopedSlots!: tsx.InnerScopedSlots<{ action?: string; input?: string }>;

  render() {
    return (
      <div class={style.inputWrapper}>
        {this.$scopedSlots.action?.(style.action)}
        {this.$scopedSlots?.input?.(style.input)}
      </div>
    );
  }
}
