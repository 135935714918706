import { ISchemaExplorerType } from "@/api/schema";

export class ApiFields {
  public objectTypes: Record<string, ISchemaExplorerType>;

  constructor(objectTypes: Record<string, ISchemaExplorerType>) {
    this.objectTypes = objectTypes;
  }

  private getFields(typeName: string) {
    const objectType = this.objectTypes[typeName];
    if (!objectType) return [];

    return objectType.fields ?? [];
  }

  public getNonObjectFieldsForObjectType(typeName: string) {
    return (
      this.getFields(typeName)
        .filter((field) => field.type?.kind !== "OBJECT")
        .map((field) => field.name) ?? ["id"]
    );
  }

  public isTypePublished(typeName: string) {
    return !!this.objectTypes[typeName];
  }
}
