import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import styles from "./TableDataImport.component.module.scss";
import { PasteHandler } from "@/mixins/PasteHandler.mixin";
import { BProgress } from "@/lib/typed-bootstrap";

@Component
export default class TableDataImport extends PasteHandler {
  _tsx!: tsx.DeclareProps<{
    modalId: string;
    accept?: PasteHandler["accept"];
    uploadProgress?: number;
  }> &
    tsx.DeclareOnEvents<{ onFileSelected: File | File[] }>;
  $scopedSlots!: tsx.InnerScopedSlots<{
    actions: { onImport: () => void };
    modal: { pastedSupportedFiles: File[] | null; onModalHidden: () => void };
  }>;

  @Prop({ required: true }) private modalId: string;
  @Prop({ default: 0 }) private uploadProgress: number;

  @Emit("fileSelected") onFileSelected(_file: File | File[]) {}

  @Watch("pastedSupportedFiles", { immediate: true })
  onPastedSupportedFiles() {
    if (this.pastedSupportedFiles && this.pastedSupportedFiles.length > 0) {
      this.showModal();
    }
  }

  private showModal() {
    this.$bvModal.show(this.modalId);
    this.isPasteHandlerActive = false;
  }

  private onModalHidden() {
    this.isPasteHandlerActive = true;
  }

  render() {
    return (
      <div class={styles.container}>
        {this.$scopedSlots.actions?.({ onImport: this.showModal })}
        <BProgress
          value={this.uploadProgress}
          class={styles.progress}
        ></BProgress>
        {this.$slots.default}
        {this.$scopedSlots.modal?.({
          pastedSupportedFiles: this.pastedSupportedFiles,
          onModalHidden: this.onModalHidden,
        })}
      </div>
    );
  }
}
