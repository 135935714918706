import { BillingIntervalEnum, UsageTypeEnum } from "@/api/common-types";
import { ILocalizedBenefits } from "@/models/benefit";
import { IPriceState, IProductState } from "@/models/product-bundle";
import { ProductNameEnum } from "@graphapi-io/products";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Button } from "../shared/Button.component";
import Card from "../shared/Card.component";

@Component({
  components: {
    Card,
    Button,
  },
})
export default class AddonProductWidget extends Vue {
  @Prop({ required: true }) product: IProductState;
  @Prop() billingInterval: BillingIntervalEnum;

  iconNames = {
    default: {
      name: "graphql",
    },
  };

  get addButtonCopy() {
    return this.product.selected
      ? this.$t("global_cta_remove")
      : this.$t("global_cta_add");
  }

  get billingIntervalString() {
    return {
      [BillingIntervalEnum.DAY]: `${this.$t("billing_interval_day")}`,
      [BillingIntervalEnum.MONTH]: `${this.$t("billing_interval_month")}`,
      [BillingIntervalEnum.WEEK]: `${this.$t("billing_interval_week")}`,
      [BillingIntervalEnum.YEAR]: `${this.$t("billing_interval_year")}`,
      [BillingIntervalEnum.ONETIME]: "",
    };
  }

  get sliderComponentWidth(): number {
    return 100 / this.sliderMaxValue;
  }

  get selectedPricings() {
    return (
      this.product.pricings[this.billingInterval] ??
      this.product.pricings[BillingIntervalEnum.MONTH] ??
      []
    );
  }

  get selectedPricing(): IPriceState {
    return this.selectedPricings[0];
  }

  get localizedBenefits() {
    return this.product.benefits[
      this.$i18n.locale.toUpperCase() as keyof ILocalizedBenefits
    ];
  }

  get monthlyPrice() {
    return this.price / this.priceDivisor;
  }

  get priceTiers() {
    return this.selectedPricing?.tiers;
  }

  get price() {
    return this.priceTiers?.[0]
      ? +(
          this.priceTiers?.[0]?.flat_amount_decimal ??
          this.priceTiers?.[0]?.unit_amount_decimal ??
          0
        ) / 100
      : this.selectedPricing?.price ?? 0;
  }

  get priceDivisor() {
    return this.selectedPricing?.billingInterval === BillingIntervalEnum.YEAR
      ? 12
      : 1;
  }

  get priceString() {
    return this.monthlyPrice.toLocaleString(undefined, {
      style: "currency",
      currency: "EUR",
    });
  }

  get priceTimeframe(): string {
    return this.selectedPricing?.recurring &&
      this.selectedPricing?.billingInterval
      ? ` / ${this.billingIntervalString[BillingIntervalEnum.MONTH]}`
      : "";
  }

  get unitType(): string {
    return this.selectedPricing?.unitType || "";
  }

  get unitAmountLocaleString() {
    return this.selectedPricing?.usageType === UsageTypeEnum.METERED
      ? this.$t("bundle_product_pricing_unit", {
          unitAmount: (this.selectedPricing?.unitAmount ?? 0).toLocaleString(),
          unitType: this.unitType,
        })
      : "";
  }

  get sliderMaxValue(): number {
    return this.selectedPricings?.length - 1;
  }

  get iconName(): string {
    return require(`@/assets/img/icon-${this.product.icon}.svg`);
  }

  get isReadOnly() {
    return (
      [
        ProductNameEnum.seed_cognito_auth,
        ProductNameEnum.launch_cognito_auth,
        ProductNameEnum.scale_cognito_auth,
        ProductNameEnum.seed_oidc_auth,
        ProductNameEnum.launch_oidc_auth,
        ProductNameEnum.scale_oidc_auth,
      ] as string[]
    ).includes(this.product.name || "");
  }

  onToggle() {
    this.product.selected = !this.product.selected;
  }
}
