import { ISchemaExplorerType } from "@/api/schema";
import { ApolloClient, MutationOptions } from "apollo-boost";
import { GenericApiCacheUpdates } from "./generic-api-cache-updates";
import { ApiCreateMutations } from "../shared/api-create-mutations";
import { ApiUpdateMutations } from "../shared/api-update-mutations";
import { ApiDeleteMutations } from "../shared/api-delete-mutations";
import { ApiFields } from "../shared/api-fields";
import { ApiQueries } from "../shared/api-queries";
import {
  ICacheUpdatesHandler,
  ICustomerListByVariables,
  ICustomerListVariables,
  IGraphqlApi,
  ofType,
} from "../shared/types";
import { ApiSchema } from "@/models/api-schema";
import { GqlGenericQueryComposer } from "./gql-generic-query-composer";
import { GenericApiVariableParser } from "./generic-api-variables-parser";
import { GenericApiResultParser } from "./generic-api-result-parser";
import { MutationTypeEnum, QueryTypeEnum } from "@graphapi-io/api-declaration";
import { FieldNameComposer } from "../shared/fieldname-composer";
import { IObjectTypeState } from "@/models";

export class GenericApi implements IGraphqlApi {
  private queries: ApiQueries;
  private deleteMutations: ApiDeleteMutations;
  private createMutations: ApiCreateMutations;
  private updateMutations: ApiUpdateMutations;
  private variableParser: GenericApiVariableParser;
  public customerApiFields: ApiFields;
  public schemaFieldTypes: Record<string, ISchemaExplorerType>;
  public cacheUpdates: ICacheUpdatesHandler;
  public hasGenericType: boolean = false;

  constructor(
    apolloClient: ApolloClient<unknown>,
    schemaFieldTypes: Record<string, ISchemaExplorerType>,
    private objectTypes: IObjectTypeState[],
    public apiSchema: ApiSchema,
    private namespace: string
  ) {
    this.customerApiFields = new ApiFields(schemaFieldTypes);
    this.schemaFieldTypes = schemaFieldTypes;
    this.variableParser = new GenericApiVariableParser(
      this.apiSchema,
      this.objectTypes
    );
    this.queries = new ApiQueries(
      apolloClient,
      new GenericApiResultParser(this.apiSchema),
      this.variableParser
    );
    this.deleteMutations = new ApiDeleteMutations(apolloClient);
    this.createMutations = new ApiCreateMutations(
      apolloClient,
      this.customerApiFields
    );
    this.updateMutations = new ApiUpdateMutations(
      apolloClient,
      this.customerApiFields
    );
    this.cacheUpdates = new GenericApiCacheUpdates(this.namespace);
  }

  public async queryObject(objectType: IObjectTypeState, id: string) {
    return this.queries.queryObject(
      objectType.name,
      id,
      GqlGenericQueryComposer.buildObjectQuery(
        ofType(objectType),
        this.namespace
      )
    );
  }

  public watchQueryObject(objectType: IObjectTypeState, id: string) {
    return this.queries.watchQueryObject(
      objectType.name,
      id,
      GqlGenericQueryComposer.buildObjectQuery(
        ofType(objectType),
        this.namespace
      )
    );
  }

  public watchListObjects<T = object>(
    objectType: IObjectTypeState,
    variables: ICustomerListVariables
  ) {
    return this.queries.watchListObjects<T>(
      objectType.name,
      variables,
      GqlGenericQueryComposer.buildListQuery(
        ofType(objectType),
        this.namespace
      ),
      FieldNameComposer.fieldNameForQueryType(QueryTypeEnum.LIST, "Type")
    );
  }

  public watchListConnections<T = object>(
    objectType: IObjectTypeState,
    parentObjectType: IObjectTypeState,
    variables: ICustomerListByVariables
  ) {
    const query = GqlGenericQueryComposer.buildListByQuery(
      ofType(objectType),
      ofType(parentObjectType),
      this.namespace
    );
    return this.queries.watchListConnections<T>(
      objectType.name,
      parentObjectType.name,
      variables,
      query,
      FieldNameComposer.fieldNameForListByParentType("Type", "ParentType")
    );
  }

  public async createObject(
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.createMutations.createObject(
      "Type",
      this.variableParser.buildMutationVariables(
        MutationTypeEnum.CREATE,
        variables,
        objectType.name,
        ofType(objectType),
        this.namespace
      ),
      updateCache
    );
  }

  public async batchCreateObjects(
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown>[] },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.createMutations.batchCreateObjects(
      "Type",
      {
        input: variables.input.map((input) => {
          return this.variableParser.buildMutationVariables(
            MutationTypeEnum.BATCH_CREATE,
            { input },
            objectType.name,
            ofType(objectType),
            this.namespace
          ).input;
        }),
      },
      updateCache
    );
  }

  public async updateObject(
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.updateMutations.updateObject(
      "Type",
      this.variableParser.buildMutationVariables(
        MutationTypeEnum.UPDATE,
        variables,
        objectType.name,
        ofType(objectType),
        this.namespace
      ),
      updateCache
    );
  }

  public deleteObject(
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.deleteMutations.deleteObject(
      "Type",
      this.variableParser.buildMutationVariables(
        MutationTypeEnum.DELETE,
        variables,
        objectType.name,
        ofType(objectType),
        this.namespace
      ),
      updateCache
    );
  }

  public batchDeleteObjects(
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown>[] },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.deleteMutations.batchDeleteObjects(
      "Type",
      {
        input: variables.input.map((input) => {
          return this.variableParser.buildMutationVariables(
            MutationTypeEnum.BATCH_DELETE,
            { input },
            objectType.name,
            ofType(objectType),
            this.namespace
          ).input;
        }),
      },
      updateCache
    );
  }
}
