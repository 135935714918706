import {
  batchQueryOrgNames,
  orgQuery,
  updateOrgWithBillingAccountMutation,
  updateOrgMutation,
  workSpaceWithOwnerMutation,
  deleteOrgMutation,
} from "@/api/org";
import { UnwrappedPromise, SimplifyType } from "@/utils/types";
import {
  extractAndFilterItemsOrProvideDefault,
  extractData,
  extractPropOrThrow,
  parseJsonStringProp,
  provideNameDefault,
} from "./utils";
import { isNotNullable } from "@/utils";
import { v4 as uuid4 } from "uuid";
import {
  IOrgInput,
  IUpdateOrgMutationVariables,
  IUpdateOrgWithBillingAcountMutationVariables,
} from "@/generated/types";
import { deleteAllRoles, getAllRoleIdsByOrg } from "./roles";

export interface IAddressState {
  country: string | null;
  city: string;
  line1: string;
  line2?: string;
  postalCode: string;
  state?: string;
}

export interface IDataProcessingCategories {
  dataCategories: string;
  subjectCategories: string;
}

export interface IOrgResponseMappableProps {
  billingAddress?: string | null;
  dataProcessingCategories?: string | null;
  apiConnection?: {
    items?: (unknown | null)[] | null;
  } | null;
  billingAccountConnection?: {
    items?: (IBillingAccountResponseMappableProps | null)[] | null;
  } | null;
}

export interface IBillingAccountResponseMappableProps {
  billingAddress?: string | null;
}

export type IOrgWithApisState = SimplifyType<
  UnwrappedPromise<ReturnType<typeof getOrg>>
>;

export type IOrgState = SimplifyType<
  Omit<IOrgWithApisState, "apis" | "apiConnection">
>;

export type IOrgApiState = SimplifyType<IOrgWithApisState["apis"][number]>;

export async function createOrgWithOwner(
  orgId: string,
  userEmail: string,
  input: IOrgInput
) {
  const roleId = uuid4();
  const response = await workSpaceWithOwnerMutation({
    userEmail,
    roleId,
    orgId,
    orgInput: {
      ...input,
      id: orgId,
    },
  });

  const data = extractPropOrThrow(response, "data");
  const org = extractPropOrThrow(data, "createOrg");
  const role = extractPropOrThrow(data, "createRole");
  return { org, role };
}

export async function getOrg(id: string) {
  const response = await orgQuery({ id });

  return mapResponseToOrg(extractPropOrThrow(extractData(response), "org"));
}

export async function getOrgApis(orgId: string) {
  return (await getOrg(orgId)).apis;
}

export async function getOrgNames(orgIds: string[]) {
  const result = await batchQueryOrgNames({ ids: orgIds });

  return extractPropOrThrow(extractData(result), "batchGetOrg")
    .filter(isNotNullable)
    .map((val) => provideNameDefault(val, ""));
}

export async function updateOrgWithBillingAccount(
  variables: IUpdateOrgWithBillingAcountMutationVariables
) {
  const response = await updateOrgWithBillingAccountMutation(variables);
  const data = extractPropOrThrow(response, "data");
  const updateOrgData = extractPropOrThrow(data, "updateOrg");
  const { dataProcessingCategories, ...orgDataRest } = updateOrgData;

  const updateBillingAccountData = extractPropOrThrow(
    data,
    "updateBillingAccount"
  );
  const { billingAddress, ...billingAccountDataRest } =
    updateBillingAccountData;

  return {
    ...orgDataRest,
    billingAddress: JSON.parse(billingAddress),
    dataProcessingCategories: JSON.parse(dataProcessingCategories),
    billingAccount: {
      ...billingAccountDataRest,
      billingAddress: JSON.parse(billingAddress),
    },
  };
}

export async function updateOrg(variables: IUpdateOrgMutationVariables) {
  const response = await updateOrgMutation(variables);
  const data = extractPropOrThrow(response, "data");
  const updateOrgData = extractPropOrThrow(data, "updateOrg");

  const orgWithDataCategories = parseJsonStringProp<
    typeof updateOrgData,
    "dataProcessingCategories",
    IDataProcessingCategories
  >(updateOrgData, "dataProcessingCategories");

  return orgWithDataCategories;
}

function mapResponseToOrg<T extends IOrgResponseMappableProps>(data: T) {
  const orgWithBillingAddress = parseJsonStringProp<
    T,
    "billingAddress",
    IAddressState
  >(data, "billingAddress");

  const orgWithBillingAddressAndDataProcessingCategories = parseJsonStringProp<
    typeof orgWithBillingAddress,
    "dataProcessingCategories",
    IDataProcessingCategories
  >(orgWithBillingAddress, "dataProcessingCategories");

  type IApiConnection = T["apiConnection"];
  type IApiConnectionItem = NonNullable<
    NonNullable<T["apiConnection"]>["items"]
  >[number];

  type IBillingAccount = T["billingAccountConnection"];
  type IBillingAccountItem = NonNullable<
    NonNullable<T["billingAccountConnection"]>["items"]
  >[number];

  const billingAccount = extractAndFilterItemsOrProvideDefault<
    IBillingAccount,
    IBillingAccountItem
  >(data.billingAccountConnection)[0];

  const billingAccountWithBillingAddress = billingAccount
    ? parseJsonStringProp<
        typeof billingAccount,
        "billingAddress",
        IAddressState
      >(billingAccount, "billingAddress")
    : null;

  const { billingAccountConnection, ...rest } =
    orgWithBillingAddressAndDataProcessingCategories;

  return {
    ...rest,
    apis: extractAndFilterItemsOrProvideDefault<
      IApiConnection,
      IApiConnectionItem
    >(data.apiConnection),
    ...(billingAccountWithBillingAddress && {
      billingAccount: billingAccountWithBillingAddress,
    }),
  };
}

export const deleteOrg = async (
  orgId: string,
  billingAccountId: string,
  paymentProviderId: string
) => {
  const [, roleIds] = await Promise.all([
    deleteOrgMutation(
      {
        id: orgId,
      },
      {
        id: billingAccountId,
        paymentProviderId,
      }
    ),
    await getAllRoleIdsByOrg(orgId),
  ]);

  await deleteAllRoles(roleIds);
};
