import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import {
  ICellChangePayload,
  ICellClickPayload,
  IFieldConfig,
  IHeaderCellClickPayload,
  IItem,
  IPaginationState,
  ITableField,
  ReservedFieldsEnum,
} from "./types";
import TableView from "./TableView.component";
import { TableController } from "./table-controller";
import { TableSelectionState } from "./table-selection-state";
import { SelectedDataExtractor } from "./selected-data-extractor";
import _map from "lodash/map";
import { PaginationStateWrapper } from "./PaginationStateWrapper.component";
import { TableEditingState } from "./table-editing-state";
import { SortOrderType } from "@/generated/types";

@Component
export default class Table extends Vue {
  _tsx!: tsx.DeclareProps<{
    fields: Table["fields"];
    items: Table["items"];
    withSelectionColumn?: Table["withSelectionColumn"];
    withEditColumn?: Table["withEditColumn"];
    tableSelectionState: Table["tableSelectionState"];
    tableEditingState: Table["tableEditingState"];
    selectedDataExtractor: Table["selectedDataExtractor"];
    hasMoreItems: Table["hasMoreItems"];
    paginationState: PaginationStateWrapper["paginationState"];
  }> &
    tsx.DeclareOnEvents<{
      onEditTableEntry: number;
      onPaginationChange: IPaginationState;
      onCellChange: ICellChangePayload;
    }> &
    tsx.DeclareOnEvents<{ onSortToggle: SortOrderType }>;
  $refs!: {
    table: TableView;
  };
  $scopedSlots!: tsx.InnerScopedSlots<{
    addColumnForm: { cancel: () => void };
    configureColumnForm: { close: () => void; field: IFieldConfig };
  }>;
  @Prop({ required: true }) private fields: ITableField[];
  @Prop({ required: true }) private items: IItem[];
  @Prop({ default: false }) private withSelectionColumn: boolean;
  @Prop({ default: false }) private withEditColumn: boolean;
  @Prop({ required: true }) private tableSelectionState: TableSelectionState;
  @Prop({ required: true }) private tableEditingState: TableEditingState;
  @Prop({ required: true })
  private selectedDataExtractor: SelectedDataExtractor;
  @Prop({ required: true }) private hasMoreItems: boolean;
  @Prop({ required: true }) paginationState: IPaginationState;

  @Emit("editTableEntry") private onEditTableEntry(_index: number) {}
  @Emit("paginationChange") private onPaginationChange(
    _paginationState: IPaginationState
  ) {}
  @Emit("cellChange") private onCellChange(_payload: ICellChangePayload) {}
  @Emit("sortToggle") onSortToggle(_value: SortOrderType) {}

  private handleCellClick(payload: ICellClickPayload) {
    const fieldIndex = this.fields.findIndex(
      (field) => field.key === payload.field
    );

    switch (payload.field) {
      case ReservedFieldsEnum.edit:
        this.onEditTableEntry(payload.index);
        break;
      default:
        this.tableController?.handleCellClick({
          ...payload,
          fieldIndex,
        });
        break;
    }
  }

  private handleCellDblClick(payload: ICellClickPayload) {
    const fieldIndex = this.fields.findIndex(
      (field) => field.key === payload.field
    );

    this.tableController?.handleCellDblClick({
      ...payload,
      fieldIndex,
    });
  }

  private handleHeaderCellClick(payload: IHeaderCellClickPayload) {
    const fieldIndex = this.fields.findIndex(
      (field) => field.key === payload.field
    );

    this.tableController?.handleHeaderCellClick({
      ...payload,
      fieldIndex,
    });
  }

  private tableController: TableController | null = null;

  mounted() {
    this.initTableController();
  }

  @Watch("tableSelectionState")
  @Watch("tableEditingState")
  @Watch("selectedDataExtractor")
  private initTableController() {
    this.tableController?.stopKeyListener();
    this.tableController = new TableController(
      this.tableSelectionState,
      this.tableEditingState,
      this.selectedDataExtractor,
      this.withSelectionColumn,
      this.withEditColumn,
      this.$refs.table
    );
    this.tableController.startKeyListener();
  }

  public beforeDestroy() {
    this.tableController?.stopKeyListener();
  }

  @Watch("fields")
  protected onFieldsChange() {
    this.tableSelectionState.updateRanges({
      dataColumnsLength: this.fields.length,
    });
  }

  private onItemsToRenderChange(itemsToRender: IItem[]) {
    this.tableSelectionState.updateRanges({
      maxRowIndex: itemsToRender.length - 1,
      dataColumnsLength: this.fields.length,
    });
    this.selectedDataExtractor.updateData(
      itemsToRender,
      _map(this.fields, "key")
    );
  }

  render() {
    return (
      <PaginationStateWrapper
        totalRows={this.items.length}
        hasMoreItems={this.hasMoreItems}
        items={this.items}
        onPaginationChange={this.onPaginationChange}
        onItemsToRenderChange={this.onItemsToRenderChange}
        paginationState={this.paginationState}
        scopedSlots={{
          content: ({ itemsToRender, startIndex }) => (
            <TableView
              ref="table"
              fields={this.fields}
              items={itemsToRender}
              startIndex={startIndex}
              withSelectionColumn={this.withSelectionColumn}
              withEditColumn={this.withEditColumn}
              withAddColumn={!!this.$scopedSlots.addColumnForm}
              onCellClick={this.handleCellClick}
              onCellDblClick={this.handleCellDblClick}
              onHeaderCellClick={this.handleHeaderCellClick}
              onCellChange={this.onCellChange}
              onSortToggle={this.onSortToggle}
              selectedRows={this.tableSelectionState.selectedRows}
              selectedCells={this.tableSelectionState.selectedCells}
              selectedColumns={this.tableSelectionState.selectedColumns}
              isFullTableSelected={this.tableSelectionState.isFullTableSelected}
              cellInEditMode={this.tableController?.cellInEditMode}
              cellInEditReplaceMode={
                this.tableController?.cellInEditReplaceMode
              }
              scopedSlots={{
                addColumnForm: this.$scopedSlots.addColumnForm,
                configureColumnForm: this.$scopedSlots.configureColumnForm,
              }}
            >
              {this.$slots.default}
            </TableView>
          ),
        }}
      />
    );
  }
}
