import { SelectionMatrix } from "@/utils/selection-matrix";
import { ICellIndex, ITableRange } from "./types";
import { ActivityState } from "./activity-state";

export class CellSelectionState {
  private minRowIndex: number;
  private minColIndex: number;
  private maxRowIndex: number;
  private maxColIndex: number;
  private activityState: ActivityState;
  public selectedCells = new SelectionMatrix();

  constructor(
    { minRowIndex, maxRowIndex, minColIndex, maxColIndex }: ITableRange,
    activityState: ActivityState
  ) {
    this.minRowIndex = minRowIndex;
    this.minColIndex = minColIndex;
    this.maxRowIndex = maxRowIndex;
    this.maxColIndex = maxColIndex;
    this.activityState = activityState;
  }

  public deselectAllCells() {
    this.selectedCells.clear();
  }

  public toggleCell(cellIndex: ICellIndex) {
    const isSelected = this.selectedCells.has({
      x: cellIndex.column,
      y: cellIndex.row,
    });
    if (!isSelected) {
      this.selectCell(cellIndex);
    } else {
      this.deselectCell(cellIndex);
    }
  }

  public selectCell(cellIndex: ICellIndex) {
    this.activityState.updateActiveCell(cellIndex);
    this.selectedCells.select({
      x: cellIndex.column,
      y: cellIndex.row,
    });
  }

  public deselectCell(cellIndex: ICellIndex) {
    this.activityState.updateActiveCell(cellIndex);
    this.selectedCells.deselect({ x: cellIndex.column, y: cellIndex.row });
  }

  public isCellSelected(rowIndex: number, columnIndex: number) {
    return this.selectedCells.has({ x: columnIndex, y: rowIndex });
  }

  private selectCellAbove() {
    const { activeCellIndex } = this.activityState;

    if (activeCellIndex.row <= this.minRowIndex) {
      this.selectCell(activeCellIndex);
      return;
    }
    this.selectCell({
      row: activeCellIndex.row - 1,
      column: activeCellIndex.column,
    });
  }

  public selectCellBelow() {
    const { activeCellIndex } = this.activityState;

    if (activeCellIndex.row >= this.maxRowIndex) {
      this.selectCell(activeCellIndex);
      return;
    }
    this.selectCell({
      row: activeCellIndex.row + 1,
      column: activeCellIndex.column,
    });
  }

  private selectCellOnTheLeft() {
    const { activeCellIndex } = this.activityState;

    if (activeCellIndex.column <= this.minColIndex) {
      this.selectCell(activeCellIndex);
      return;
    }
    this.selectCell({
      row: activeCellIndex.row,
      column: activeCellIndex.column - 1,
    });
  }

  private selectCellOnTheRight() {
    const { activeCellIndex } = this.activityState;
    if (!activeCellIndex) {
      return;
    }
    if (activeCellIndex.column >= this.maxColIndex) {
      this.selectCell(activeCellIndex);
      return;
    }
    this.selectCell({
      row: activeCellIndex.row,
      column: activeCellIndex.column + 1,
    });
  }

  public selectAdjustedCell(direction: "TOP" | "BOTTOM" | "LEFT" | "RIGHT") {
    switch (direction) {
      case "TOP":
        this.selectCellAbove();
        break;
      case "BOTTOM":
        this.selectCellBelow();
        break;
      case "LEFT":
        this.selectCellOnTheLeft();
        break;
      case "RIGHT":
        this.selectCellOnTheRight();
        break;
    }
  }

  public updateRanges({
    minRowIndex,
    maxRowIndex,
    minColIndex,
    maxColIndex,
  }: ITableRange) {
    this.minRowIndex = minRowIndex;
    this.minColIndex = minColIndex;
    this.maxRowIndex = maxRowIndex;
    this.maxColIndex = maxColIndex;
  }

  public deselectCellsInRow(rowIndex: number) {
    this.selectedCells.deselectRow(rowIndex);
  }

  public deselectCellsInColumn(columnIndex: number) {
    this.selectedCells.deselectColumn(columnIndex);
  }

  public deselectCellsInColumnRange(startIndex: number, endIndex: number) {
    for (let i = startIndex; i <= endIndex; i++) {
      this.selectedCells.deselectColumn(i);
    }
  }

  public selectAllCellsInRowExcept(
    cellIndex: ICellIndex,
    selectedColumns: number[]
  ) {
    for (let i = this.minColIndex; i <= this.maxColIndex; i++) {
      if (i !== cellIndex.column && !selectedColumns.includes(i)) {
        this.selectedCells.select({ y: cellIndex.row, x: i });
      }
    }
    this.selectedCells.deselect({ y: cellIndex.row, x: cellIndex.column });
    this.activityState.updateActiveCell(cellIndex);
  }

  public selectAllCellsInColumnExcept(
    cellIndex: ICellIndex,
    selectedRows: number[]
  ) {
    for (let i = this.minRowIndex; i <= this.maxRowIndex; i++) {
      if (i !== cellIndex.row && !selectedRows.includes(i)) {
        this.selectedCells.select({ y: i, x: cellIndex.column });
      }
    }
    this.selectedCells.deselect({ y: cellIndex.row, x: cellIndex.column });
    this.activityState.updateActiveCell(cellIndex);
  }

  public selectCellsByActiveRange() {
    const { rangeWidth, rangeHeight } = this.activityState;
    const { activeCellIndex } = this.activityState;
    const rowStart = Math.min(
      activeCellIndex.row,
      activeCellIndex.row + rangeHeight
    );
    const rowEnd = Math.max(
      activeCellIndex.row,
      activeCellIndex.row + rangeHeight
    );
    const columnStart = Math.min(
      activeCellIndex.column,
      activeCellIndex.column + rangeWidth
    );
    const columnEnd = Math.max(
      activeCellIndex.column,
      activeCellIndex.column + rangeWidth
    );

    for (let i = rowStart; i <= rowEnd; i++) {
      for (let j = columnStart; j <= columnEnd; j++) {
        this.selectedCells.select({ y: i, x: j });
      }
    }
  }

  private selectFirstCellInRow() {
    const cellIndex = {
      row: this.activityState.activeCellIndex.row,
      column: this.minColIndex,
    };
    this.selectCell(cellIndex);
    this.activityState.updateActiveCell(cellIndex);
  }

  private selectLastCellInRow() {
    const cellIndex = {
      row: this.activityState.activeCellIndex.row,
      column: this.maxColIndex,
    };
    this.selectCell(cellIndex);
    this.activityState.updateActiveCell(cellIndex);
  }

  private selectFirstCellInColumn() {
    const cellIndex = {
      row: this.minRowIndex,
      column: this.activityState.activeCellIndex.column,
    };
    this.selectCell(cellIndex);
    this.activityState.updateActiveCell(cellIndex);
  }

  private selectLastCellInColumn() {
    const cellIndex = {
      row: this.maxRowIndex,
      column: this.activityState.activeCellIndex.column,
    };
    this.selectCell(cellIndex);
    this.activityState.updateActiveCell(cellIndex);
  }

  public selectLastCellByDirection(
    direction: "TOP" | "BOTTOM" | "LEFT" | "RIGHT"
  ) {
    switch (direction) {
      case "TOP":
        this.selectFirstCellInColumn();
        break;
      case "BOTTOM":
        this.selectLastCellInColumn();
        break;
      case "LEFT":
        this.selectFirstCellInRow();
        break;
      case "RIGHT":
        this.selectLastCellInRow();
        break;
    }
  }
}
