import { Vue, Component, Prop } from "vue-property-decorator";
import { BillingIntervalEnum } from "@/api/common-types";
import { IFreeTiers, IProductState } from "@/models/product-bundle";

import BundleWidget from "@/components/widgets/BundleWidget.component.vue";
import * as tsx from "vue-tsx-support";

import { BCardGroup } from "@/lib/typed-bootstrap";

@Component
export default class BundleWidgetGroup extends Vue {
  _tsx!: tsx.DeclareProps<{
    bundleConfigs: BundleWidgetGroup["bundleConfigs"];
    billingInterval?: BundleWidgetGroup["billingInterval"];
  }>;
  @Prop({ required: true }) readonly bundleConfigs: {
    product: IProductState;
    freeTiers?: IFreeTiers;
    selected?: boolean;
  }[];
  @Prop() billingInterval: BillingIntervalEnum;

  private onChooseBundle(bundleIndex: number) {
    this.bundleConfigs.map(({ product }, index: number) => {
      if (bundleIndex === index) {
        product.selected = true;
      } else {
        product.selected = false;
      }
    });
  }

  render() {
    return (
      <div>
        <BCardGroup deck>
          {this.bundleConfigs.map((bundle, idx) => (
            <BundleWidget
              value={bundle.selected ?? false}
              key={`bundle-${idx}`}
              product={bundle.product}
              billingInterval={this.billingInterval}
              freeTiers={bundle.freeTiers}
              onChooseBundle={() => this.onChooseBundle(idx)}
            />
          ))}
        </BCardGroup>
      </div>
    );
  }
}
