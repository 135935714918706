import * as tsx from "vue-tsx-support";
import style from "./PlaceholderHeaderCell.component.module.scss";

export const PlaceholderHeaderCell = tsx.component({
  functional: true,
  props: {
    isSelected: Boolean,
  },
  render(_, { props: { isSelected } }) {
    return (
      <th
        class={{
          [style.cell]: true,
          [style.active]: isSelected,
        }}
      ></th>
    );
  },
});
