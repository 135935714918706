import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store/store";
import { ICheckoutSessionState } from "@/models/checkout-session";

export interface ICheckoutState {
  checkoutSessions: { [sessionId: string]: ICheckoutSessionState };
}

@Module({ dynamic: true, store, name: "checkout", namespaced: true })
class Checkout extends VuexModule implements ICheckoutState {
  public checkoutSessions: { [apiId: string]: ICheckoutSessionState } = {};

  @Mutation
  private SET_CHECKOUT_SESSIONS(checkoutSessions: {
    [apiId: string]: ICheckoutSessionState;
  }): void {
    this.checkoutSessions = checkoutSessions;
  }

  @Action
  initializeCheckoutSessionEntry(apiId: string) {
    if (!(apiId in this.checkoutSessions)) {
      this.SET_CHECKOUT_SESSIONS(
        Object.assign({}, this.checkoutSessions, { [apiId]: "" })
      );
    }
  }

  @Action
  handleCheckoutSessionResponse(response: {
    apiId: string;
    session: ICheckoutSessionState;
  }): void {
    this.SET_CHECKOUT_SESSIONS({
      ...this.checkoutSessions,
      [response.apiId]: response.session,
    });
  }
}

export const CheckoutModule = getModule(Checkout);
