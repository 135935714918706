import Component from "vue-class-component";
import { ThemedImage } from "../shared/ThemedImage.component";
import downArrowIconWhite from "@/assets/img/down-chevron-white.svg";
import downArrowIconDark from "@/assets/img/down-chevron-dark.svg";
import { Emit, Prop } from "vue-property-decorator";
import styles from "./ApiDataObjectTypeDropdown.component.module.scss";
import * as tsx from "vue-tsx-support";
import Dropdown from "../shared/Dropdown.component";
import { IApiState, IObjectTypeState } from "@/models";
import { ApiDataObjectTypeOptions } from "./ApiDataObjectTypeOptions.component";
import Vue from "vue";

const downArrowIcon = {
  dark: downArrowIconWhite,
  light: downArrowIconDark,
};

@Component
export class ApiDataObjectTypeDropdown extends Vue {
  _tsx!: tsx.DeclareProps<{
    typeName: ApiDataObjectTypeDropdown["typeName"];
    apiState?: ApiDataObjectTypeDropdown["apiState"];
    containerElement?: ApiDataObjectTypeDropdown["containerElement"];
  }> &
    tsx.DeclareOnEvents<{
      onUpdateSelectedObjectType: IObjectTypeState;
    }>;
  $refs!: {
    dropdown?: Dropdown;
  };

  @Prop({
    default: () => ({}),
  })
  apiState: IApiState;
  @Prop({ required: true }) private typeName: string;

  @Emit("updateSelectedObjectType") onUpdateSelectedObjectType(
    _objectType: IObjectTypeState
  ) {}

  @Prop({ default: null })
  private containerElement: HTMLDivElement | null;

  private handleSubmit(newType: IObjectTypeState) {
    this.onUpdateSelectedObjectType(newType);
    this.$refs.dropdown?.close();
  }

  public render() {
    return (
      <Dropdown
        toggleClass={styles.button}
        leftPlateOffset={0}
        topPlateOffset={2}
        anchor={this.containerElement}
        ref="dropdown"
      >
        <template slot="buttonContent">
          <ThemedImage
            src={downArrowIcon}
            class={styles.icon}
            alt="Edit Object Type"
          />
        </template>
        <ApiDataObjectTypeOptions
          onUpdateSelectedObjectType={this.handleSubmit}
          apiState={this.apiState}
          typeName={this.typeName}
        />
      </Dropdown>
    );
  }
}
