import { Vue, Component, Prop } from "vue-property-decorator";
import ProgressStep, { IProgressStepState } from "./ProgressStep.component";
import style from "./StepProgressBar.component.module.scss";
import * as tsx from "vue-tsx-support";

@Component
export default class StepProgressBar extends Vue {
  _tsx!: tsx.DeclareProps<{
    steps: StepProgressBar["steps"];
    showSpinner?: StepProgressBar["showSpinner"];
  }>;

  @Prop({ required: true }) steps: IProgressStepState[];
  @Prop({ required: false, default: false }) showSpinner: boolean;

  render() {
    return (
      <div class={style.publishingProgress}>
        {this.steps.map((step, index) => (
          <ProgressStep
            key={`progress-step-${index}`}
            step={step}
            stepIndex={index}
            numOfSteps={this.steps.length}
            showSpinner={this.showSpinner}
          />
        ))}
      </div>
    );
  }
}
