import Vue from "vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import { IItem, IPaginationState } from "./types";
import TablePagination from "./TablePagination.component";
import * as tsx from "vue-tsx-support";

@Component
export class PaginationStateWrapper extends Vue {
  _tsx!: tsx.DeclareProps<{
    totalRows: PaginationStateWrapper["totalRows"];
    hasMoreItems: PaginationStateWrapper["hasMoreItems"];
    paginationState?: PaginationStateWrapper["paginationState"];
    items: PaginationStateWrapper["items"];
  }> &
    tsx.DeclareOnEvents<{
      onPaginationChange: IPaginationState;
      onItemsToRenderChange: IItem[];
    }>;
  $scopedSlots: tsx.InnerScopedSlots<{
    content: {
      itemsToRender: IItem[];
      startIndex: number;
    };
  }>;

  @Prop({ required: true }) totalRows: number;
  @Prop({ default: false }) hasMoreItems: boolean;
  @Prop({ required: true }) items: IItem[];
  @Prop({ required: true }) paginationState: IPaginationState;

  @Emit("paginationChange") onPaginationChange(
    _paginationState: IPaginationState
  ) {}
  @Emit("itemsToRenderChange") onItemsToRenderChange(_items: IItem[]) {}

  @Watch("itemsToRender", { immediate: true }) handleItemsToRenderChanged() {
    this.onItemsToRenderChange(this.itemsToRender);
  }

  private get shouldShowPagination() {
    return this.hasMoreItems || this.totalRows > this.paginationState.perPage;
  }

  private get itemsToRender() {
    return this.items.slice(
      (this.paginationState.currentPage - 1) * this.paginationState.perPage,
      this.paginationState.currentPage * this.paginationState.perPage
    );
  }

  render() {
    return (
      <div>
        {this.$scopedSlots.content({
          itemsToRender: this.itemsToRender,
          startIndex:
            (this.paginationState.currentPage - 1) *
            this.paginationState.perPage,
        })}
        {this.shouldShowPagination && (
          <TablePagination
            onUpdate={this.onPaginationChange}
            paginationState={this.paginationState}
            totalRows={this.totalRows}
            hasMore={this.hasMoreItems}
          />
        )}
      </div>
    );
  }
}
