import { PropType } from "vue";
import { BSpinner } from "@/lib/typed-bootstrap";
import * as tsx from "vue-tsx-support";
import { UIModule } from "@/store/modules/ui";

export const Loader = tsx.component({
  functional: true,
  props: {
    small: {
      type: Boolean,
    },
    variant: {
      type: String as PropType<"light" | "dark">,
    },
  },
  render(
    _,
    { props: { variant = UIModule.isLightTheme ? "dark" : "light", small } }
  ) {
    return (
      <BSpinner
        variant={variant}
        type="grow"
        label="Loading..."
        small={small}
      />
    );
  },
});
