import { Component, Prop, Watch } from "vue-property-decorator";
import { OrgModule } from "@/store/modules/org";
import { UserModule } from "@/store/modules/user";
import { INavSection, NavModule } from "@/store/modules/nav";
import DropdownsState, {
  DropdownStateEnum,
} from "@/mixins/DropdownsState.mixin";
import { getOrgNames } from "@/models/org";
import { persistanceService } from "@/utils/persistance-service";
import { userDataLoader } from "@/utils/auth-service";
import NavbarDropdownDesktop from "./NavbarDropdownDesktop.component.vue";
import { RouteAuthRequirementEnum } from "@/router/types";
import { NavbarBackButton } from "./NavbarBackButton.component";
import { authManager } from "@/utils/auth-service/auth-manager";
import { envRootDomain, envRootLocalizedDomain } from "@/utils";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import avatarLogoBlack from "@/assets/img/avatar-black.svg";
import avatarLogoWhite from "@/assets/img/avatar-light.svg";
import brandLogoBlack from "@/assets/img/brand-logo-black.svg";
import brandLogoWhite from "@/assets/img/brand-logo-white.svg";
import docsLinkArrowBlack from "@/assets/img/graphapi-docs.svg";
import docsLinkArrowWhite from "@/assets/img/graphapi-docs-white.svg";
import homeButtonBlack from "@/assets/img/home.svg";
import homeButtonWhite from "@/assets/img/home-white.svg";
import * as tsx from "vue-tsx-support";

@Component({
  components: {
    NavbarDropdownDesktop,
    NavbarBackButton,
    ThemedImage,
  },
})
export default class Navbar extends DropdownsState {
  _tsx!: tsx.DeclareProps<{
    showSecondaryNav?: Navbar["showSecondaryNav"];
  }>;

  @Prop({ default: false }) showSecondaryNav: boolean;

  $scopedSlots!: tsx.InnerScopedSlots<{
    secondaryNavbar: {
      onLinkClick: () => void;
      toggleMobileDropdown: () => void;
    };
  }>;

  isLoading = false;
  mobileToggleId = "mobileNavDropdown";
  isSmallScreen = false;
  userOrgs: { id: string; name: string }[] = [];

  avatarSrc = {
    light: avatarLogoBlack,
    dark: avatarLogoWhite,
  };

  brandLogoSrc = {
    light: brandLogoBlack,
    dark: brandLogoWhite,
  };

  docsSrc = {
    light: docsLinkArrowBlack,
    dark: docsLinkArrowWhite,
  };

  homeSrc = {
    light: homeButtonBlack,
    dark: homeButtonWhite,
  };

  get isMobileDropdownShown() {
    return this.dropDownState[this.mobileToggleId] === DropdownStateEnum.shown;
  }

  get docsUrl() {
    return `${envRootDomain()}/docs`;
  }

  get homepageUrl() {
    return envRootLocalizedDomain();
  }

  get orgId(): string {
    return OrgModule.id;
  }

  get orgName(): string {
    return OrgModule.name ?? "Unknown organization";
  }

  get userEmail(): string {
    return UserModule.email;
  }

  get isAuthOnlyRoute() {
    return (
      this.$route.name &&
      (!this.$route.meta?.authMode ||
        this.$route.meta.authMode === RouteAuthRequirementEnum.authOnly)
    );
  }

  get hideLoginButton(): boolean {
    const name = this.$route.name || "";
    return (
      name.trim().toLowerCase() !== "signup" &&
      name.trim().toLowerCase() !== "password-reset"
    );
  }

  get hideSignupButton(): boolean {
    const name = this.$route.name || "";
    return name.trim().toLowerCase() !== "login";
  }

  get showHomeButton(): boolean {
    return this.$route.params.orgId === undefined && this.userIsAuthenticated;
  }

  get userIsAuthenticated(): boolean {
    return UserModule.isAuthenticated;
  }

  get navItems(): INavSection[] {
    return NavModule.apiDetailsNavItems;
  }

  get showApiNavItems(): boolean {
    return this.$route.params.apiId !== undefined;
  }

  get showWorkspaceItems(): boolean {
    return this.$route.params.orgId !== undefined && this.userOrgs.length > 0;
  }

  get apiId(): string {
    return this.$route.params.apiId;
  }

  get apiName(): string {
    return NavModule.currentApiName;
  }

  get activeNavItem(): string {
    return this.$route.meta?.localizedName ?? "";
  }

  get hasSecondaryNavbar(): boolean {
    return this.$scopedSlots.secondaryNavbar !== undefined;
  }

  get userOrgIds() {
    return Object.keys(UserModule.roles);
  }

  logout() {
    authManager.logout();
  }

  @Watch("userIsAuthenticated")
  async onUserAuthChange(): Promise<void> {
    if (!this.userIsAuthenticated) {
      this.hideDropdown(this.mobileToggleId);
    }
  }

  async onLinkClick(): Promise<void> {
    try {
      this.hideDropdown(this.mobileToggleId);
    } catch (err) {
      console.log(err);
    }
  }

  @Watch("userOrgIds", { immediate: true })
  async getOrgNames() {
    if (this.userOrgIds.length > 0) {
      this.userOrgs = await getOrgNames(this.userOrgIds);
    } else {
      this.userOrgs = [];
    }
  }

  async onSelectOrg(orgId: string) {
    await UserModule.onUserAction(async () => {
      userDataLoader.switchOrg();
    });
    this.$router
      .push({
        name: "org-api-dashboard",
        params: {
          orgId,
        },
      })
      .catch(() => undefined)
      .finally(() => this.hideDropdown(this.mobileToggleId));
  }

  toggleMobileDropdown(): void {
    this.toggleDropdown(this.mobileToggleId);
  }

  onLogoClick() {
    persistanceService.removeActiveRouteKey();
    this.$router.push({ name: "home" }).catch(() => undefined);
  }
}
