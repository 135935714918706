import { Vue, Component } from "vue-property-decorator";
import FormEmail from "@/components/forms/inputs/FormEmail.component.vue";
import FormPassword from "@/components/forms/inputs/FormPassword.component.vue";
import { UserModule } from "@/store/modules/user";
import { auth } from "@/utils/auth-service";

@Component({
  components: {
    FormEmail,
    FormPassword,
  },
})
export default class AccountSettings extends Vue {
  form = null;

  userEmail: string = UserModule.email;
  userPassword: string = "not-required";

  onSubmit(): void {
    console.log(this.$refs.form);
  }

  async onChangePassword(): Promise<void> {
    try {
      await auth.forgotPassword(UserModule.email);
      this.$router.push({ name: "account-change-password" });
    } catch (err) {
      console.log(err);
    }
  }

  async onChangeEmail(): Promise<void> {
    try {
      this.$router.push({ name: "account-change-email" });
    } catch (err) {
      console.log(err);
    }
  }
}
