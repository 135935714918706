import { BCard } from "@/lib/typed-bootstrap";
import { Component, Prop, Vue } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import style from "./Card.component.module.scss";
import iconWidgetSelectedDark from "@/assets/img/icon-widget-selected.svg";
import iconWidgetSelectedLight from "@/assets/img/icon-widget-selected-dark.svg";
import { ThemedImage } from "./ThemedImage.component";

const iconWidgetSelected = {
  dark: iconWidgetSelectedDark,
  light: iconWidgetSelectedLight,
};

@Component
export default class Card extends Vue {
  _tsx!: tsx.DeclareProps<{
    selected: Card["selected"];
  }> &
    tsx.DeclareOnEvents<{
      onClick: MouseEvent;
    }>;

  @Prop({ required: true }) selected: boolean;

  render() {
    return (
      <BCard
        bodyClass={[
          style.cardBody,
          this.selected ? style.active : style.inactive,
        ].join(" ")}
        class={style.card}
        on={this.$listeners}
      >
        {this.selected && (
          <div class={style.activeIconPosition}>
            <ThemedImage src={iconWidgetSelected} alt="Widget selection icon" />
          </div>
        )}
        {this.$slots.default}
      </BCard>
    );
  }
}
