import { Vue, Component } from "vue-property-decorator";

export enum DropdownStateEnum {
  hidden = "hidden",
  shown = "shown",
}

@Component
export default class DropdownsState extends Vue {
  dropDownState: Record<string, DropdownStateEnum> = {};

  showDropdown(id: string) {
    this.$set(this.dropDownState, id, DropdownStateEnum.shown);
  }
  hideDropdown(id: string) {
    this.$set(this.dropDownState, id, DropdownStateEnum.hidden);
  }
  toggleDropdown(id: string) {
    this.$set(
      this.dropDownState,
      id,
      this.dropDownState[id] === DropdownStateEnum.shown
        ? DropdownStateEnum.hidden
        : DropdownStateEnum.shown
    );
  }
}
