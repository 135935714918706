import { Vue, Component, Prop } from "vue-property-decorator";

import Input, { IInputProps } from "./Input.component";
import InputLabel, {
  IInputLabelWrapperProps,
} from "./InputLabelWrapper.component";
import * as tsx from "vue-tsx-support";
import style from "./LabeledTextInput.component.module.scss";

@Component
export default class LabeledTextInput extends Vue {
  _tsx!: IInputProps &
    IInputLabelWrapperProps &
    tsx.DeclareProps<{
      error?: LabeledTextInput["error"];
    }>;

  @Prop({ required: true }) labelText: string;
  @Prop({ required: true }) id: string;
  @Prop({ default: false }) autoFocus: boolean;
  @Prop({ required: false, default: undefined, type: Function })
  formatter: (value: string) => string | undefined;
  @Prop({ default: null }) error: string | null;

  private get errorId() {
    return this.id ? `${this.id}-error` : undefined;
  }

  render() {
    return (
      <InputLabel labelText={this.labelText} id={this.id} class={style.input}>
        <Input
          id={this.id}
          autoFocus={this.autoFocus}
          on={this.$listeners}
          attrs={this.$attrs}
          formatter={this.formatter}
          data-testid={`${this.id}Input`}
          aria-describedby={this.errorId}
        />
        {this.error && (
          <div class={style.error} id={this.errorId} role="alert">
            {this.error}
          </div>
        )}
      </InputLabel>
    );
  }
}
