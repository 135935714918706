import { Component } from "vue-property-decorator";
import { BAlert, BForm, BRow, BSpinner } from "@/lib/typed-bootstrap";
import ErrorHandling from "@/mixins/ErrorHandling.mixin";
import * as tsx from "vue-tsx-support";
import styles from "./ProjectSetupName.view.module.scss";
import { ApiSetupInitBasicInfo } from "@/components/project-setup/types";
import { SyntheticEvent } from "vue-tsx-support/types/dom";
import {
  Button,
  ButtonShapeEnum,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "@/components/shared/Button.component";
import { envRootLocalizedDomain, getErrorMessage } from "@/utils";
import ShortUniqueId from "short-unique-id";
import { ISchemaTemplate } from "@/models/schema-template";
import { IApiState, declarationSchemaForInput, updateApi } from "@/models";
import { createApiMutation } from "@/api/api";
import { OrgModule } from "@/store";
import { IApiInput } from "@/generated/types";
import { AuthMethodType } from "@graphapi-io/api-declaration";
import { Checkbox } from "@/components/shared/Checkbox.component";
import LabeledTextInput from "@/components/shared/LabeledTextInput.component";
import WizardStep from "@/components/wizard/WizardStep.component";

const { randomUUID } = new ShortUniqueId({ length: 6 });

@Component
export default class ProjectSetupName extends ErrorHandling {
  private projectName: ApiSetupInitBasicInfo["apiName"] = "";
  private termsAccepted = false;
  private isLoading = false;

  private getApiInput() {
    const input: IApiInput = {
      orgId: OrgModule.id,
      name: this.projectName.trim(),
      defaultAuthMode: AuthMethodType.AWS_API_KEY,
      region: "eu-central-1",
      usePrefix: true,
    };
    const types = this.emptyTemplate.schema.objectTypes;
    const enums = this.emptyTemplate.schema.enumTypes;

    return {
      input,
      types,
      enums,
    };
  }

  private async onSubmit(): Promise<void> {
    try {
      if (this.projectName?.trim().length <= 0) {
        throw new Error(this.$t("error_api_create_requires_name").toString());
      }

      if (!this.termsAccepted) {
        throw new Error(this.$t("signup_error_terms_required").toString());
      }

      this.errorMessage = "";
      this.isLoading = true;
      const { input, types, enums } = this.getApiInput();
      const result = await createApiMutation({ input });
      const apiData = result.data?.createApi;

      if (!apiData) {
        throw new Error("Could not create API");
      }

      // TODO: Make it typesafe
      const apiState = {
        ...apiData,
        types,
        enums,
      } as unknown as IApiState;

      const schema = declarationSchemaForInput(apiState);

      await updateApi({
        ...apiState,
        schema,
      });

      this.$router
        .push({
          name: "api-details-data",
          params: { apiId: apiState.id },
          query: { showTemplates: "true" },
        })
        .catch(() => {
          // Ignore
        });
    } catch (err) {
      this.errorMessage = getErrorMessage(err);
    } finally {
      this.isLoading = false;
    }
  }

  private onNameInput(value: string) {
    this.projectName = value ?? "";
  }

  private onCheckboxChange(ev: SyntheticEvent<HTMLInputElement, Event>) {
    this.termsAccepted = ev.target?.checked ?? false;
  }

  private get termsUrl(): string {
    return `${envRootLocalizedDomain()}/terms`;
  }

  private get emptyTemplate(): ISchemaTemplate {
    return {
      id: randomUUID(),
      name: this.$t("api_setup_templated_empty").toString(),
      description: this.$t("api_setup_templated_empty_description").toString(),
      schema: {
        authProvidersConfig: {},
        enumTypes: [],
        objectTypes: [
          {
            id: randomUUID(),
            name: "Table1",
            targets: [],
            parents: { first: null, second: null },
            directives: [],
            fields: [
              {
                id: randomUUID(),
                directives: ["@connector"],
                name: "id",
                type: "ID",
              },
              {
                id: randomUUID(),
                directives: [],
                name: "createdAt",
                type: "String",
              },
              {
                id: randomUUID(),
                directives: [],
                name: "updatedAt",
                type: "String",
              },
            ],
            resolvers: [],
            selected: false,
          },
        ],
      },
    };
  }

  render() {
    return (
      <WizardStep showCloseButton={true}>
        <template slot="wizardName">
          {this.$t("api_setup_wizard_name", { step: 1, stepCount: 1 })}
        </template>
        <template slot="header">{this.$t("api_setup_init_headline")}</template>
        <template slot="description">
          {this.$t("api_setup_init_subline")}
        </template>
        <BRow class="default__section">
          <BAlert
            show={this.showErrorMessage}
            variant="danger"
            data-testid="apiSetupFormError"
          >
            {this.errorMessage}
          </BAlert>

          <BForm
            ref="form"
            class="default__inline-form"
            accept-charset="UTF-8"
            role="form"
            novalidate
            onSubmit={tsx.modifiers.stop.prevent(this.onSubmit)}
          >
            <LabeledTextInput
              id="apiName"
              labelText={this.$t("form_input_label_api_name").toString()}
              placeholder={this.$t("form_placeholder_api_name").toString()}
              value={this.projectName}
              onInput={this.onNameInput}
              type="text"
              autocomplete="off"
              autoFocus
            />
            <Checkbox
              class={styles.termsAndConditions}
              checked={this.termsAccepted}
              onChange={this.onCheckboxChange}
              id="terms"
            >
              <i18n path="project_setup_terms">
                <template slot="terms">
                  <a
                    href={this.termsUrl}
                    target="_blank"
                    class={styles.termsLink}
                  >
                    {this.$t("global_terms")}
                  </a>
                </template>
              </i18n>
            </Checkbox>
            <Button
              type="submit"
              onClick={tsx.modifiers.prevent(this.onSubmit)}
              data-testid="continue-button"
              class={styles.submitButton}
              size={ButtonSizeEnum.lg}
              theme={ButtonThemeEnum.primary}
              shape={ButtonShapeEnum.square}
            >
              {this.isLoading ? (
                <div class="d-flex default__spinner" role="status">
                  <BSpinner
                    class="ml-auto"
                    type="grow"
                    label="Spinning"
                    show={false}
                  ></BSpinner>
                </div>
              ) : (
                <div class="button__text">{this.$t("form_cta_continue")}</div>
              )}
            </Button>
          </BForm>
        </BRow>
      </WizardStep>
    );
  }
}
