import { DocumentNode, MutationUpdaterFn } from "apollo-boost";
import _set from "lodash/fp/set";
import _uniqBy from "lodash/uniqBy";
import { WithNil } from "./types";

type ValidKey = string | number | symbol;

type IBatchMutationResult<IKey extends ValidKey> = Partial<
  Record<IKey, WithNil<WithNil<{ id: string }>[]>>
>;

type IMutationResult<IKey extends ValidKey> = Partial<
  Record<IKey, WithNil<{ id: string }>>
>;

type IListQueryResult<IKey extends ValidKey> = Partial<
  Record<IKey, WithNil<{ items?: WithNil<WithNil<{ id: string }>[]> }>>
>;

export const addToListFrontAfterCreation = <
  IMutationType extends IMutationResult<IMutationName>,
  IMutationName extends keyof IMutationType,
  IQueryType extends IListQueryResult<IQueryName>,
  IQueryName extends keyof IQueryType,
  IQueryVariables
>(
  mutationName: IMutationName,
  variables: IQueryVariables,
  queryName: IQueryName,
  listQuery: DocumentNode
): MutationUpdaterFn<IMutationType> => {
  return (cache, mutationResult) => {
    try {
      const newItem = mutationResult.data?.[mutationName];

      if (!newItem) {
        return;
      }

      const cachedQuery = cache.readQuery<IQueryType>({
        query: listQuery,
        variables,
      });

      if (!cachedQuery) {
        return;
      }

      const newItems = [newItem, ...(cachedQuery[queryName]?.items ?? [])];

      cache.writeQuery({
        query: listQuery,
        variables,
        data: _set([queryName, "items"], newItems, cachedQuery),
      });
    } catch (e) {
      // Ignore
    }
  };
};

export const addToListFrontAfterBatchCreation = <
  IMutationType extends IBatchMutationResult<IMutationName>,
  IMutationName extends keyof IMutationType,
  IQueryType extends IListQueryResult<IQueryName>,
  IQueryName extends keyof IQueryType,
  IQueryVariables
>(
  mutationName: IMutationName,
  variables: IQueryVariables,
  queryName: IQueryName,
  listQuery: DocumentNode
): MutationUpdaterFn<IMutationType> => {
  return (cache, mutationResult) => {
    try {
      const newItems = mutationResult?.data?.[mutationName];

      if (!newItems) {
        return;
      }

      const cachedQuery = cache.readQuery<IQueryType>({
        query: listQuery,
        variables,
      });

      if (!cachedQuery) {
        return;
      }

      const combinedItems = _uniqBy(
        newItems.concat(cachedQuery[queryName]?.items ?? []),
        "id"
      );

      cache.writeQuery({
        query: listQuery,
        variables,
        data: _set([queryName, "items"], combinedItems, cachedQuery),
      });
    } catch (e) {
      // Ignore
    }
  };
};

export const clearObjectCacheAfterDeletion =
  <
    IMutationType extends IMutationResult<IMutationName>,
    IMutationName extends keyof IMutationType
  >(
    mutationName: IMutationName,
    objectQuery: DocumentNode
  ): MutationUpdaterFn<IMutationType> =>
  (cache, mutationResult) => {
    try {
      const id = mutationResult.data?.[mutationName]?.id;

      if (!id) {
        return;
      }

      cache.writeQuery({
        query: objectQuery,
        variables: { id },
        data: null,
      });
    } catch (e) {
      // Ignore
    }
  };

export const clearObjectsCacheAfterBatchDeletion =
  <
    IMutationType extends IBatchMutationResult<IMutationName>,
    IMutationName extends keyof IMutationType
  >(
    mutationName: IMutationName,
    objectQuery: DocumentNode
  ): MutationUpdaterFn<IMutationType> =>
  (cache, mutationResult) => {
    try {
      const deletedItems: WithNil<{ id: string }>[] =
        mutationResult.data?.[mutationName] ?? [];

      const ids = deletedItems.map((item) => item?.id);

      ids.forEach((id) => {
        cache.writeQuery({
          query: objectQuery,
          variables: { id },
          data: null,
        });
      });
    } catch (e) {
      // Ignore
    }
  };

export const removeFromListAfterBatchDeletion =
  <
    IMutationType extends IBatchMutationResult<IMutationName>,
    IMutationName extends keyof IMutationType,
    IQueryType extends IListQueryResult<IQueryName>,
    IQueryName extends keyof IQueryType,
    IQueryVariables
  >(
    mutationName: IMutationName,
    variables: IQueryVariables,
    queryName: IQueryName,
    listQuery: DocumentNode
  ): MutationUpdaterFn<IMutationType> =>
  (cache, mutationResult) => {
    try {
      const deletedItems = mutationResult.data?.[mutationName] ?? [];

      const cachedQuery = cache.readQuery<IQueryType>({
        query: listQuery,
        variables: variables,
      });

      if (!cachedQuery) {
        return;
      }

      const newItems = (cachedQuery[queryName]?.items ?? []).filter(
        (item) =>
          !deletedItems.some((deletedItem) => deletedItem?.id === item?.id)
      );

      cache.writeQuery({
        query: listQuery,
        variables: variables,
        data: _set([queryName, "items"], newItems, cachedQuery),
      });
    } catch (e) {
      // Ignore
    }
  };

export const removeFromListAfterDeletion =
  <
    IMutationType extends IMutationResult<IMutationName>,
    IMutationName extends keyof IMutationType,
    IQueryType extends IListQueryResult<IQueryName>,
    IQueryName extends keyof IQueryType,
    IQueryVariables
  >(
    mutationName: IMutationName,
    variables: IQueryVariables,
    queryName: IQueryName,
    listQuery: DocumentNode
  ): MutationUpdaterFn<IMutationType> =>
  (cache, mutationResult) => {
    try {
      const deletedItem = mutationResult.data?.[mutationName];

      const cachedQuery = cache.readQuery<IQueryType>({
        query: listQuery,
        variables: variables,
      });

      if (!cachedQuery || !deletedItem) {
        return;
      }

      const newItems = (cachedQuery[queryName]?.items ?? []).filter(
        (item) => deletedItem.id !== item?.id
      );

      cache.writeQuery({
        query: listQuery,
        variables: variables,
        data: _set([queryName, "items"], newItems, cachedQuery),
      });
    } catch (e) {
      // Ignore
    }
  };
