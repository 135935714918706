import { ArchiveFormatTypeEnum } from "@/api/common-types";
import { AccessControlType } from "@/generated/types";
import { DedicatedApi } from "./dedicated-api";
import { ICustomerListVariables } from "../shared/types";
import { FieldNameComposer } from "../shared/fieldname-composer";
import _set from "lodash/fp/set";
import { QueryTypeEnum, RuntimeEnum } from "@graphapi-io/api-declaration";
import { GqlDedicatedQueryComposer } from "./gql-dedicated-query-composer";
import { IObjectTypeState } from "@/models";
import { DedicatedApiCacheUpdates } from "./dedicated-api-cache-updates";

export const CLOUD_FUNCTION_TYPE = "CloudFunction";

export interface ICloudFunction {
  mimeType?: string | null;
  url?: string | null;
  name: string;
  id: string;
  createdAt: string;
  updatedAt?: string | null;
  runtime?: string | null;
}

const cloudFunctionType = (): IObjectTypeState => {
  return {
    id: "",
    name: CLOUD_FUNCTION_TYPE,
    targets: [],
    parents: { first: null, second: null },
    fields: [],
    directives: [],
    resolvers: [],
    selected: false,
  };
};

export class CustomerApiCloudFunctions {
  constructor(private customerApi: DedicatedApi) {}

  watchCloudFunctions(variables: ICustomerListVariables) {
    return this.customerApi.watchListObjects<ICloudFunction>(
      cloudFunctionType(),
      variables
    );
  }

  private normalizeName(name: string) {
    return name.replace(/[^a-zA-Z0-9/.]/g, "_");
  }

  batchDeleteCloudFunctions(
    input: Record<string, unknown>[],
    initialVariables: ICustomerListVariables
  ) {
    return this.customerApi.batchDeleteObjects(
      cloudFunctionType(),
      {
        input,
      },
      this.customerApi.cacheUpdates.clearAllAfterBatchDeletionFactory(
        cloudFunctionType(),
        initialVariables
      )
    );
  }

  async uploadCloudFunction(
    initialVariables: ICustomerListVariables,
    fileString: string,
    fileName: string,
    fileType?: string,
    runtime?: RuntimeEnum
  ) {
    const normalizedName = this.normalizeName(fileName);

    return this.customerApi.uploadInlineFile(
      {
        input: {
          key: normalizedName,
          mimeType: fileType || undefined,
          accessControl: AccessControlType.PRIVATE,
          archiveFormat: ArchiveFormatTypeEnum.ZIP,
          body: fileString,
          isCloudFunction: true,
        },
      },
      (this.customerApi.cacheUpdates as DedicatedApiCacheUpdates)
        .customCacheUpdateFactory &&
        (
          this.customerApi.cacheUpdates as DedicatedApiCacheUpdates
        ).customCacheUpdateFactory(() => (cache, mutationResult) => {
          try {
            const listQueryName = FieldNameComposer.fieldNameForQueryType(
              QueryTypeEnum.LIST,
              CLOUD_FUNCTION_TYPE
            );
            const listQuery = GqlDedicatedQueryComposer.buildListQuery(
              CLOUD_FUNCTION_TYPE,
              this.customerApi.customerApiFields
            );
            const fileUploadResult = mutationResult.data?.uploadInlineFile;
            if (!fileUploadResult) {
              return;
            }
            const cachedQuery = cache.readQuery<
              Record<string, { items: ICloudFunction[] }>
            >({
              query: listQuery,
              variables: initialVariables,
            });

            if (!cachedQuery) {
              return;
            }

            const cachedCloudfunctionItems =
              cachedQuery[listQueryName]?.items.filter(Boolean);
            const wasUpdated = cachedCloudfunctionItems.some(
              (item: ICloudFunction) => item?.id === fileUploadResult?.id
            );

            if (wasUpdated) {
              return;
            }

            const newCloudFunction = {
              ...fileUploadResult,
              code: "...",
              functionUrl: "...",
              runtime: runtime ?? RuntimeEnum.NODEJS_18_X,
              __typename: CLOUD_FUNCTION_TYPE,
            };

            const newData = _set(
              `${listQueryName}.items`,
              [newCloudFunction, ...cachedCloudfunctionItems],
              cachedQuery
            );

            cache.writeQuery({
              query: listQuery,
              variables: initialVariables,
              data: newData,
            });
          } catch (e) {
            // Ignore
          }
        })
    );
  }
}
