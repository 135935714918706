import { ApolloClient, MutationOptions } from "apollo-boost";
import { ApiFields } from "./api-fields";
import { ApiMutation } from "./api-mutation";
import { FieldNameComposer } from "../shared/fieldname-composer";
import { MutationTypeEnum } from "@graphapi-io/api-declaration";
import { GqlMutationComposer } from "./gql-mutation-composer";

export class ApiUpdateMutations {
  private customerApiMutation: ApiMutation;

  constructor(
    private apolloClient: ApolloClient<unknown>,
    private customerApiFields: ApiFields
  ) {
    this.customerApiMutation = new ApiMutation(
      MutationTypeEnum.UPDATE,
      this.apolloClient
    );
  }

  public async updateObject(
    typeName: string,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.customerApiMutation.mutate(
      GqlMutationComposer.buildUpdateMutation(typeName, this.customerApiFields),
      variables,
      FieldNameComposer.fieldNameForMutationType(
        MutationTypeEnum.UPDATE,
        typeName
      ),
      updateCache
    );
  }
}
