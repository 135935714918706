import { Vue, Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";

@Component
export default class FormTags extends Vue {
  _tsx: tsx.DeclareProps<{
    value?: FormTags["value"];
    label?: FormTags["label"];
    labelAmendment?: FormTags["labelAmendment"];
    placeholderText: FormTags["placeholderText"];
    upperCaseLabel?: FormTags["upperCaseLabel"];
    shouldValidate?: FormTags["shouldValidate"];
  }> &
    tsx.DeclareOnEvents<{
      onInput: any;
    }>;

  @Prop() value: (string | number | boolean)[];
  @Prop({ required: false }) readonly label: string;
  @Prop({ required: false }) readonly labelAmendment: string;
  @Prop({ required: true }) readonly placeholderText: string;
  @Prop({ default: true }) readonly upperCaseLabel: boolean;
  @Prop({ default: false }) readonly shouldValidate: boolean;

  get internalValue(): any {
    return this.value ?? [];
  }

  set internalValue(value: any) {
    this.$emit("input", value);
  }

  get isValidValue(): boolean {
    return this.internalValue.length > 0;
  }

  onTagState(valid: string[], invalid: string[], duplicate: string[]) {
    console.log(valid, invalid, duplicate);
    return true;
  }
}
