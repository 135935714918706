import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";
import { Store } from "vuex";
import {
  LanguageCodeEnum,
  persistLocale,
  updateLocaleByQueryParam,
} from "./i18n";
import { removeQueryParam } from "./router/utils";
import VueI18n from "vue-i18n";

export const createVueInstance = <T extends unknown>(
  i18n: VueI18n,
  router: VueRouter,
  store: Store<T>
) =>
  new Vue({
    i18n,
    router,
    store,
    methods: {
      toggleBodyScrollbar(visible: boolean, mobileOnly = false) {
        const body = document.getElementsByTagName("body")[0];
        const className = mobileOnly
          ? "overflow-hidden-mobile"
          : "overflow-hidden";
        visible
          ? body.classList.add(className)
          : body.classList.remove(className);
      },
    },
    watch: {
      "$route.query.lang": {
        immediate: true,
        handler() {
          updateLocaleByQueryParam(i18n);
        },
      },
      "$i18n.locale": {
        handler(newVal: LanguageCodeEnum) {
          persistLocale(newVal);
          removeQueryParam(router, "lang");
        },
      },
    },
    render() {
      return <App />;
    },
  });
