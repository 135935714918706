import { MutationOptions, MutationUpdaterFn } from "apollo-boost";
import { ApiFields } from "../shared/api-fields";
import { ICacheUpdatesHandler } from "@/api-client/shared/types";
import { GqlDedicatedQueryComposer } from "./gql-dedicated-query-composer";
import { CacheUpdateWrapper } from "../shared/cache-update-wrapper";
import { IObjectTypeState } from "@/models";

export class DedicatedApiCacheUpdates implements ICacheUpdatesHandler {
  constructor(private customerApiFields: ApiFields) {}

  public addToListFrontAfterCreationFactory(
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ): MutationUpdaterFn<any> {
    return CacheUpdateWrapper.addToListFrontAfterCreationFactory(
      objectType.name,
      variables,
      GqlDedicatedQueryComposer.buildListQuery(
        objectType.name,
        this.customerApiFields
      )
    );
  }

  public addToListFrontAfterBatchCreationFactory(
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ): MutationUpdaterFn<any> {
    return CacheUpdateWrapper.addToListFrontAfterBatchCreationFactory(
      objectType.name,
      variables,
      GqlDedicatedQueryComposer.buildListQuery(
        objectType.name,
        this.customerApiFields
      )
    );
  }

  public clearObjectsAfterBatchDeletionFactory(
    objectType: IObjectTypeState
  ): MutationUpdaterFn<any> {
    return CacheUpdateWrapper.clearObjectsAfterBatchDeletionFactory(
      objectType.name,
      GqlDedicatedQueryComposer.buildObjectQuery(
        objectType.name,
        this.customerApiFields
      )
    );
  }

  public clearListAfterBatchDeletionFactory(
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ): MutationUpdaterFn<any> {
    return CacheUpdateWrapper.clearListAfterBatchDeletionFactory(
      objectType.name,
      variables,
      GqlDedicatedQueryComposer.buildListQuery(
        objectType.name,
        this.customerApiFields
      )
    );
  }

  public clearAllAfterBatchDeletionFactory(
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ): MutationUpdaterFn<any> {
    return CacheUpdateWrapper.callBoth(
      this.clearObjectsAfterBatchDeletionFactory(objectType),
      this.clearListAfterBatchDeletionFactory(objectType, variables)
    );
  }

  public customCacheUpdateFactory(
    callback: (
      apiQueryComposer: GqlDedicatedQueryComposer
    ) => MutationOptions<any>["update"]
  ) {
    return callback(GqlDedicatedQueryComposer);
  }
}
