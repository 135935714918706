import {
  mutationFunctionFactory,
  queryFunctionFactory,
} from "../internal-utils";
import CREATE_CHECKOUT_SESSION from "./create_checkout_session.graphql";
import UPDATE_CHECKOUT_SESSION from "./update_checkout_session.graphql";
import LIST_CHECKOUT_SESSIONS_BY_API from "./list_checkout_sessions.graphql";
import LIST_CHECKOUT_SESSIONS_BY_API_BY_SESSION_ID from "./query_checkout_session.graphql";
import {
  ICheckoutSession,
  ICreateCheckoutSessionMutation,
  ICreateCheckoutSessionMutationVariables,
  IUpdateCheckoutSessionMutation,
  IUpdateCheckoutSessionMutationVariables,
} from "@/generated/types";

export interface IListCheckoutSessionQueryByApiResponse {
  listCheckoutSessionsByApi: {
    items: ICheckoutSession[];
  };
}

export const createCheckoutSessionMutation = mutationFunctionFactory<
  ICreateCheckoutSessionMutation,
  ICreateCheckoutSessionMutationVariables
>(CREATE_CHECKOUT_SESSION);

export const updateCheckoutSessionMutation = mutationFunctionFactory<
  IUpdateCheckoutSessionMutation,
  IUpdateCheckoutSessionMutationVariables
>(UPDATE_CHECKOUT_SESSION);

export const listCheckoutSessionsByApiQuery = queryFunctionFactory<
  IListCheckoutSessionQueryByApiResponse,
  { apiId: string }
>(LIST_CHECKOUT_SESSIONS_BY_API);

export const checkoutSessionsByApiBySessionIdQuery = queryFunctionFactory<
  IListCheckoutSessionQueryByApiResponse,
  { apiId: string; sessionId: string }
>(LIST_CHECKOUT_SESSIONS_BY_API_BY_SESSION_ID);
