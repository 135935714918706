import type { IGraphapiModule } from "@/store";
import { Auth } from "@graphapi-io/customer-components";

const UNAUTHORIZED = 401;

export class FetchClient {
  private apiUrl: string;
  private auth: Auth;
  private apiId: string;
  private graphapiModule: IGraphapiModule;
  private apiKey?: string;
  private accessToken?: string;

  constructor(
    apiUrl: string,
    apiId: string,
    auth: Auth,
    graphapiModule: IGraphapiModule,
    apiKey?: string,
    accessToken?: string
  ) {
    this.apiUrl = apiUrl;
    this.auth = auth;
    this.apiId = apiId;
    this.apiKey = apiKey;
    this.graphapiModule = graphapiModule;
    this.accessToken = accessToken;
  }

  private getHeaders() {
    const token =
      this.accessToken ?? this.graphapiModule.accessTokens[this.apiId];
    return {
      "Content-Type": "application/json",
      authorization: token ? `${token}` : "",
      ...(token || !this.apiKey ? {} : { "x-api-key": this.apiKey }),
    };
  }

  private async fetchFn(
    input: string,
    init?: RequestInit | undefined
  ): Promise<Response> {
    const originalResponse = await fetch(input, init);

    if (originalResponse.status === UNAUTHORIZED) {
      try {
        const token = await this.refreshToken();
        if (!token) {
          return originalResponse;
        }

        const retriedResponse = await fetch(input, {
          ...init,
          headers: {
            ...init?.headers,
            authorization: `${token}`,
          },
        });
        return retriedResponse;
      } catch (err) {
        return originalResponse;
      }
    }

    return originalResponse;
  }

  public async fetch(
    config: {
      query: string;
      variables?: Record<string, unknown>;
      operationName?: string | null;
    },
    allowErrors = false
  ) {
    const response = await this.fetchFn(this.apiUrl, {
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify({
        operationName: config.operationName,
        query: config.query,
        variables: config.variables,
      }),
    });
    if (!response.ok) {
      throw new Error("API request failed");
    }
    const json = await response.json();

    if (!allowErrors && json.errors) {
      throw new Error(json.errors[0].message);
    }

    return json;
  }

  private async refreshToken() {
    if (!this.auth) return null;

    try {
      const { session } = await this.auth.refreshToken();
      const newToken = session.getAccessToken().getJwtToken();

      this.graphapiModule.updateToken({ apiId: this.apiId, newToken });

      return newToken;
    } catch (err) {
      return null;
    }
  }
}
