import { CellSelectionState } from "./cell-selection-state";
import { RowSelectionState } from "./row-selection-state";
import { ICellIndex, ITableRange } from "./types";
import { ColumnSelectionState } from "./column-selection-state";
import { ActivityState } from "./activity-state";
import { getDataColumnIndexOffset } from "./utils";

export enum ModifierEnum {
  ADD = "ADD",
  RANGE = "RANGE",
  ADD_RANGE = "ADD_RANGE",
}

export type IArrowKey = "ArrowUp" | "ArrowDown" | "ArrowLeft" | "ArrowRight";

export class TableSelectionState {
  private rowSelectionState: RowSelectionState;
  private cellSelectionState: CellSelectionState;
  private columnSelectionState: ColumnSelectionState;
  private activityState: ActivityState;
  private ranges: ITableRange;

  constructor(
    private hasSelectionColumn: boolean,
    private hasEditColumn: boolean
  ) {
    const minColIndex = getDataColumnIndexOffset(
      hasSelectionColumn,
      hasEditColumn
    );
    const ranges = {
      minRowIndex: 0,
      maxRowIndex: 0,
      minColIndex,
      maxColIndex: minColIndex,
    };

    this.activityState = new ActivityState(ranges);
    this.rowSelectionState = new RowSelectionState(ranges, this.activityState);
    this.columnSelectionState = new ColumnSelectionState(
      ranges,
      this.activityState
    );
    this.cellSelectionState = new CellSelectionState(
      ranges,
      this.activityState
    );
    this.ranges = ranges;
  }

  public get selectedCells() {
    return this.cellSelectionState.selectedCells;
  }

  public get activeCellIndex() {
    return this.activityState.activeCellIndex;
  }

  public get activeRangeLastCell() {
    return this.activityState.activeRangeLastCell;
  }

  public get selectedRows() {
    return this.rowSelectionState.selectedRows;
  }

  public get selectedColumns() {
    return this.columnSelectionState.selectedColumns;
  }

  public get hasAnyRowSelection() {
    return this.rowSelectionState.selectedRows.size > 0;
  }

  public get hasAnyColumnSelection() {
    return this.columnSelectionState.selectedColumns.size > 0;
  }

  public get hasAnyCellSelection() {
    return this.cellSelectionState.selectedCells.size > 0;
  }

  public get isFullTableSelected() {
    return (
      this.rowSelectionState.allSelected &&
      this.columnSelectionState.allSelected
    );
  }

  public get hasAnySelection() {
    return (
      this.hasAnyRowSelection ||
      this.hasAnyColumnSelection ||
      this.hasAnyCellSelection
    );
  }

  public get hasOnlyFullRowsSelected() {
    return (
      (this.hasAnyRowSelection &&
        !this.hasAnyColumnSelection &&
        !this.hasAnyCellSelection) ||
      this.isFullTableSelected
    );
  }

  public updateRanges({
    dataColumnsLength,
    maxRowIndex,
  }: {
    dataColumnsLength?: number;
    maxRowIndex?: number;
  }) {
    this.deselectAll();
    this.ranges = {
      ...this.ranges,
      maxColIndex: dataColumnsLength
        ? dataColumnsLength + this.ranges.minColIndex - 1
        : this.ranges.maxColIndex,
      maxRowIndex: maxRowIndex ?? this.ranges.maxRowIndex,
    };
    this.rowSelectionState.updateRanges(this.ranges);
    this.cellSelectionState.updateRanges(this.ranges);
    this.columnSelectionState.updateRanges(this.ranges);
    this.activityState.updateRanges(this.ranges);
  }

  public onArrowKey(arrow: IArrowKey, modifier?: ModifierEnum) {
    const direction =
      arrow === "ArrowDown"
        ? "BOTTOM"
        : arrow === "ArrowUp"
        ? "TOP"
        : arrow === "ArrowLeft"
        ? "LEFT"
        : "RIGHT";

    switch (modifier) {
      case ModifierEnum.ADD:
        this.deselectAll();
        this.cellSelectionState.selectLastCellByDirection(direction);
        break;
      case ModifierEnum.ADD_RANGE:
      case ModifierEnum.RANGE: {
        const isRowSelected = this.isRowSelected(this.activeCellIndex.row);
        const isColumnSelected = this.isColumnSelected(
          this.activeCellIndex.column
        );
        const currentRangeHeight = this.activityState.rangeHeight;
        const currentRangeWidth = this.activityState.rangeWidth;
        const onlyCellsSelected = !isRowSelected && !isColumnSelected;
        const isDirectionVertical =
          direction === "TOP" || direction === "BOTTOM";
        const isDirectionHorizontal =
          direction === "LEFT" || direction === "RIGHT";

        if (
          onlyCellsSelected ||
          (isRowSelected && isDirectionVertical) ||
          (isColumnSelected && isDirectionHorizontal)
        ) {
          if (modifier === ModifierEnum.ADD_RANGE) {
            this.activityState.adjustRangeToEndByDirection(direction);
          } else {
            this.activityState.adjustRangeByDirection(direction);
          }
          if (
            this.activityState.rangeWidth === currentRangeWidth &&
            this.activityState.rangeHeight === currentRangeHeight
          ) {
            break;
          }
          this.deselectAll();

          if (onlyCellsSelected) {
            this.cellSelectionState.selectCellsByActiveRange();
            break;
          }
          if (isRowSelected && isDirectionVertical) {
            this.rowSelectionState.selectRowsByActiveRange();
            break;
          }
          if (isColumnSelected && isDirectionHorizontal) {
            this.columnSelectionState.selectColumnsByActiveRange();
            break;
          }
          break;
        }
        break;
      }
      case undefined:
      default:
        this.deselectAll();
        this.activityState.resetRange();
        this.cellSelectionState.selectAdjustedCell(direction);
    }
  }

  public toggleRowSelection(index: number, modifier?: ModifierEnum) {
    switch (modifier) {
      case ModifierEnum.ADD:
        this.activityState.resetRange();
        this.cellSelectionState.deselectCellsInRow(index);
        if (this.rowSelectionState.isRowSelected(index)) {
          this.columnSelectionState.selectedColumns
            .values()
            .forEach((columnValue) => {
              this.cellSelectionState.selectAllCellsInColumnExcept(
                { row: index, column: columnValue },
                this.selectedRows.values()
              );
              this.columnSelectionState.deselectColumn(columnValue);
            });
        }
        this.rowSelectionState.toggleRowSelection(index);
        this.activityState.updateActiveCell({
          row: index,
          column: this.ranges.minColIndex,
        });
        break;
      case ModifierEnum.ADD_RANGE:
      case ModifierEnum.RANGE: {
        this.deselectAll();
        this.activityState.updateRangeByTargetRow(index);
        this.rowSelectionState.selectRowsByActiveRange();
        break;
      }
      case undefined:
      default:
        this.deselectAll();
        this.activityState.resetRange();
        this.rowSelectionState.selectRow(index);
        this.activityState.updateActiveCell({
          row: index,
          column: this.ranges.minColIndex,
        });
    }
  }

  public toggleColumnSelection(index: number, modifier?: ModifierEnum) {
    if (index === 0 && this.hasSelectionColumn) {
      this.toggleAll();
      this.activityState.updateActiveCell({
        row: 0,
        column: this.ranges.minColIndex,
      });
      return;
    } else if (
      (index === 1 && this.hasSelectionColumn && this.hasEditColumn) ||
      index === this.ranges.maxColIndex + 1
    ) {
      return;
    }

    switch (modifier) {
      case ModifierEnum.ADD:
        this.activityState.resetRange();
        this.cellSelectionState.deselectCellsInColumn(index);
        if (this.columnSelectionState.isColumnSelected(index)) {
          this.rowSelectionState.selectedRows.values().forEach((rowValue) => {
            this.cellSelectionState.selectAllCellsInRowExcept(
              { row: rowValue, column: index },
              this.selectedColumns.values()
            );
            this.rowSelectionState.deselectRow(rowValue);
          });
        }
        this.columnSelectionState.toggleColumnSelection(index);
        this.activityState.updateActiveCell({ row: 0, column: index });
        break;
      case ModifierEnum.ADD_RANGE:
      case ModifierEnum.RANGE: {
        this.deselectAll();
        this.activityState.updateRangeByTargetColumn(index);
        this.columnSelectionState.selectColumnsByActiveRange();
        break;
      }
      case undefined:
      default:
        this.deselectAll();
        this.activityState.resetRange();
        this.columnSelectionState.selectColumn(index);
        this.activityState.updateActiveCell({ row: 0, column: index });
    }
  }

  public toggleCellSelection(cellIndex: ICellIndex, modifier?: ModifierEnum) {
    switch (modifier) {
      case ModifierEnum.ADD: {
        this.activityState.resetRange();
        const isRowSelected = this.rowSelectionState.isRowSelected(
          cellIndex.row
        );
        const isColumnSelected = this.columnSelectionState.isColumnSelected(
          cellIndex.column
        );
        if (isRowSelected) {
          this.rowSelectionState.deselectRow(cellIndex.row);
          this.cellSelectionState.selectAllCellsInRowExcept(
            cellIndex,
            this.selectedColumns.values()
          );
        }
        if (isColumnSelected) {
          this.columnSelectionState.deselectColumn(cellIndex.column);
          this.cellSelectionState.selectAllCellsInColumnExcept(
            cellIndex,
            this.selectedRows.values()
          );
        }

        if (!isRowSelected && !isColumnSelected) {
          this.cellSelectionState.toggleCell(cellIndex);
        }
        return;
      }
      case ModifierEnum.ADD_RANGE:
      case ModifierEnum.RANGE: {
        this.deselectAll();
        this.activityState.updateRangeByTargetCell(cellIndex);
        this.cellSelectionState.selectCellsByActiveRange();
        return;
      }
      case undefined: {
        this.activityState.resetRange();
        this.deselectAll();
        this.cellSelectionState.selectCell(cellIndex);
      }
    }
  }

  public setActiveCell(cellIndex: ICellIndex) {
    this.activityState.updateActiveCell(cellIndex);
  }

  public setSelectionRange(height: number, width: number) {
    this.activityState.rangeWidth = width;
    this.activityState.rangeHeight = height;
  }

  public selectRow(rowIndex: number) {
    this.rowSelectionState.selectRow(rowIndex);
  }

  public selectColumn(columnIndex: number) {
    this.columnSelectionState.selectColumn(columnIndex);
  }

  public selectCell(cellIndex: ICellIndex) {
    this.cellSelectionState.selectCell(cellIndex);
  }

  public isRowSelected(rowIndex: number) {
    return this.rowSelectionState.isRowSelected(rowIndex);
  }

  public isColumnSelected(columnIndex: number) {
    return this.columnSelectionState.isColumnSelected(columnIndex);
  }

  public isCellSelected(rowIndex: number, columnIndex: number) {
    return this.cellSelectionState.isCellSelected(rowIndex, columnIndex);
  }

  private toggleAll() {
    if (this.isFullTableSelected) {
      this.deselectAll();
    } else {
      this.selectAll();
    }
  }

  public selectAll() {
    this.deselectAll();
    this.rowSelectionState.selectAllRows();
    this.columnSelectionState.selectAllColumns();
  }

  public deselectAll() {
    this.rowSelectionState.deselectAll();
    this.cellSelectionState.deselectAllCells();
    this.columnSelectionState.deselectAll();
  }

  public selectCellBelow() {
    this.cellSelectionState.selectCellBelow();
  }
}
