import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import {
  IObjectTypeState,
  nonSortableFieldTypes,
  toggleFieldRequiredDirective,
  toggleFieldRequiredOnCreateDirective,
  toggleFieldRequiredOnDeleteDirective,
  toggleFieldRequiredOnUpdateDirective,
  extendFieldWithSortByDirective,
  excludeSortByFromFieldDirectives,
} from "@/models/object-type";
import {
  FormNestedCheckbox,
  IFormNestedCheckboxState,
} from "../forms/inputs/FormNestedCheckbox.component";
import style from "./ApiDataObjectTypeFieldSettingsForm.component.module.scss";
import ApiTypesUpdater from "../object-type/ApiTypesUpdater.mixin";
import { IApiState } from "@/models";
import Dropdown from "../shared/Dropdown.component";
import { Button, ButtonThemeEnum } from "../shared/Button.component";
import { Loader } from "../shared/Loader.component";
import { BForm } from "@/lib/typed-bootstrap";
import _cloneDeep from "lodash/cloneDeep";
import { FieldDirectiveEnum } from "@/api/common-types";
import Modal from "../shared/Modal.component";

@Component
export class ApiDataObjectTypeFieldSettingsForm extends ApiTypesUpdater {
  _tsx!: tsx.DeclareProps<{
    apiState?: ApiDataObjectTypeFieldSettingsForm["apiState"];
    typeIndex: ApiDataObjectTypeFieldSettingsForm["typeIndex"];
    fieldIndex: ApiDataObjectTypeFieldSettingsForm["fieldIndex"];
  }> &
    tsx.DeclareOnEvents<{
      onCancel: void;
      onSubmit: IObjectTypeState;
    }>;
  $scopedSlots!: tsx.InnerScopedSlots<{ action?: void; input?: string }>;

  @Prop({
    default: () => ({}),
  })
  apiState: IApiState;
  @Prop({ required: true }) typeIndex: number;
  @Prop({ required: true }) fieldIndex: number;

  @Emit("cancel") private onCancel() {}
  @Emit("submit") private onSubmit(_objectType: IObjectTypeState) {}

  @Watch("typeIndex", { immediate: true }) onTypeIndexChange() {
    this.type = _cloneDeep(this.apiState.types[this.typeIndex]);
  }

  private type: IObjectTypeState | null = null;

  private get showErrors() {
    return !!this.serverSideErrors;
  }

  private get field() {
    return this.type?.fields[this.fieldIndex];
  }

  private async handleSubmit(ev: Event) {
    ev.preventDefault();
    if (!this.type) return;

    const newObjectTypes = this.apiState.types.map((objectType) => {
      if (this.type && objectType.name === this.type.name) {
        return this.type;
      }
      return objectType;
    });

    const newApiState = {
      ...this.apiState,
      types: newObjectTypes,
    };

    await this.updateApiDeclarationFromState(newApiState);

    if (!this.serverSideErrors && this.type) {
      this.onSubmit(this.type);
      this.apiState.types = newObjectTypes;
    }
  }

  private get sortByField() {
    return (
      this.type?.fields.find((field) =>
        field?.directives?.includes(FieldDirectiveEnum.SORTBY)
      )?.name ?? "updatedAt"
    );
  }

  private get isSortByField() {
    return this.field?.name === this.sortByField;
  }

  private get sortFieldState(): IFormNestedCheckboxState {
    return {
      name: "Sort field",
      selected: this.isSortByField,
      disabled: this.isSortByField && this.field?.name === "updatedAt",
    };
  }

  private get requiredFieldState() {
    const hasRequiredDirective =
      this.field?.directives?.includes(FieldDirectiveEnum.REQUIRED) ?? false;
    const hasRequiredOnCreateDirective =
      this.field?.directives?.includes(FieldDirectiveEnum.REQUIRED_ON_CREATE) ??
      false;
    const hasRequiredOnUpdateDirective =
      this.field?.directives?.includes(FieldDirectiveEnum.REQUIRED_ON_UPDATE) ??
      false;
    const hasRequiredOnDeleteDirective =
      this.field?.directives?.includes(FieldDirectiveEnum.REQUIRED_ON_DELETE) ??
      false;

    return {
      name: this.$t("model_field_required").toString(),
      selected: hasRequiredDirective,
      children: [
        {
          name: this.$t("model_field_required_on_create").toString(),
          directive: FieldDirectiveEnum.REQUIRED_ON_CREATE,
          selected: hasRequiredDirective || hasRequiredOnCreateDirective,
        },
        {
          name: this.$t("model_field_required_on_update").toString(),
          directive: FieldDirectiveEnum.REQUIRED_ON_UPDATE,
          selected: hasRequiredDirective || hasRequiredOnUpdateDirective,
        },
        {
          name: this.$t("model_field_required_on_delete").toString(),
          directive: FieldDirectiveEnum.REQUIRED_ON_DELETE,
          selected: hasRequiredDirective || hasRequiredOnDeleteDirective,
        },
      ],
    };
  }

  private get isNotSortable() {
    return !!(
      (this.field?.readonly && this.field.name !== "updatedAt") ||
      (this.field?.type &&
        (nonSortableFieldTypes as readonly string[]).includes(this.field.type))
    );
  }

  private get isRequiredStateImmutable() {
    return this.field?.readonly;
  }

  private handleSortByChange(value: IFormNestedCheckboxState) {
    if (!this.type) {
      return;
    }

    this.type.fields = this.type.fields.map((field, index) => {
      if (index === this.fieldIndex && value.selected) {
        return extendFieldWithSortByDirective(field);
      }

      return excludeSortByFromFieldDirectives(field);
    });
  }

  private toggleRequiredDirective(directive: FieldDirectiveEnum) {
    const { field } = this;

    if (!this.type || !field) {
      return;
    }

    this.type.fields = this.type.fields.map((field, index) => {
      if (index !== this.fieldIndex) {
        return field;
      }

      switch (directive) {
        case FieldDirectiveEnum.REQUIRED:
          return toggleFieldRequiredDirective(field);
        case FieldDirectiveEnum.REQUIRED_ON_CREATE:
          return toggleFieldRequiredOnCreateDirective(field);
        case FieldDirectiveEnum.REQUIRED_ON_DELETE:
          return toggleFieldRequiredOnDeleteDirective(field);
        case FieldDirectiveEnum.REQUIRED_ON_UPDATE:
          return toggleFieldRequiredOnUpdateDirective(field);
      }
      return field;
    });
  }

  private handleRequiredChange(value: IFormNestedCheckboxState) {
    const { requiredFieldState } = this;

    if (value.selected !== requiredFieldState.selected) {
      this.toggleRequiredDirective(FieldDirectiveEnum.REQUIRED);
      return;
    }

    if (!requiredFieldState.children || !value.children) return;

    for (let i = 0; i < requiredFieldState.children.length; i++) {
      if (
        requiredFieldState.children[i].selected !== value.children[i].selected
      ) {
        this.toggleRequiredDirective(requiredFieldState.children[i].directive);
        return;
      }
    }
  }

  render() {
    return (
      <BForm
        onSubmit={this.handleSubmit}
        role="form"
        novalidate
        class={style.form}
      >
        <p class={style.subHeader} role="presentation">
          {this.$t("model_field_settings").toString()}
        </p>
        {!this.isNotSortable && (
          <FormNestedCheckbox
            id={`${this.field?.type}-sortAction`}
            class={style.action}
            value={this.sortFieldState}
            onChange={this.handleSortByChange}
          />
        )}
        {!this.isRequiredStateImmutable && (
          <FormNestedCheckbox
            id={`${this.field?.type}-requiredAction`}
            class={style.action}
            value={this.requiredFieldState}
            onChange={this.handleRequiredChange}
          />
        )}
        <Dropdown.Divider />
        <Modal.ActionButtons
          class={[style.actionButtons, { [style.withErrors]: this.showErrors }]}
        >
          <Button
            onClick={this.onCancel}
            class={style.button}
            theme={ButtonThemeEnum.naked}
            type="button"
          >
            {this.$t("global_cta_cancel")}
          </Button>
          <Button
            theme={ButtonThemeEnum.primary}
            class={style.button}
            data-testid="uploadCloudFunction"
            type="submit"
            disabled={this.showErrors}
          >
            {this.isLoading ? (
              <Loader />
            ) : this.type?.name === undefined ? (
              this.$t("global_cta_create")
            ) : (
              this.$t("global_cta_save")
            )}
          </Button>
        </Modal.ActionButtons>
        <p class={style.disclaimer}>
          {this.$t("model_widget_access_disclaimer").toString()}
        </p>
      </BForm>
    );
  }
}
