import { publishRunsByApiQuery, publishRunQuery } from "@/api/publish_runs";
import { FetchPolicy } from "apollo-boost";
import { extractData, extractItems, extractPropOrThrow } from "./utils";

export interface IPublishRunResponseMappableProps {
  id: string;
  createdAt: string;
}

export const mapResponseToPublishRun = <
  T extends IPublishRunResponseMappableProps
>(
  data: T
) => {
  return {
    ...data,
    createdAt: new Date(data.createdAt),
  };
};

export async function getPublishRunsForApiId(apiId: string) {
  const response = await publishRunsByApiQuery({ apiId });
  return extractItems(
    extractPropOrThrow(extractData(response), "listPublishRunsByApi")
  ).map((publishRun) => mapResponseToPublishRun(publishRun));
}

export async function getPublishRun(id: string, fetchPolicy?: FetchPolicy) {
  const response = await publishRunQuery({ id }, fetchPolicy);

  return mapResponseToPublishRun(
    extractPropOrThrow(extractData(response), "publishRun")
  );
}
