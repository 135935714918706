import { Component, Emit, Prop } from "vue-property-decorator";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import * as tsx from "vue-tsx-support";
import Dropdown from "../shared/Dropdown.component";
import styles from "./ApiDataNewTypeDropdown.component.module.scss";
import { IApiState, IObjectTypeState } from "@/models";
import ApiTypesUpdater from "../object-type/ApiTypesUpdater.mixin";
import { mixins } from "vue-class-component";
import { ObjectTypeNameValidator } from "../object-type/ObjectTypeNameValidator.mixin";
import ApiDataObjectTypeForm from "./ApiDataObjectTypeForm.component";
import plusIconBlack from "@/assets/img/plus-icon.svg";
import plusIconWhite from "@/assets/img/plus-icon-light.svg";

const plusIcon = {
  dark: plusIconWhite,
  light: plusIconBlack,
};

@Component
export default class ApiDataNewTypeDropdown extends mixins(
  ObjectTypeNameValidator,
  ApiTypesUpdater
) {
  _tsx!: tsx.DeclareProps<{
    isCompact?: ApiDataNewTypeDropdown["isCompact"];
    apiState?: ApiDataNewTypeDropdown["apiState"];
  }> &
    tsx.DeclareOnEvents<{
      onUpdateSelectedObjectType: IObjectTypeState;
    }>;
  @Prop({ default: false }) isCompact: boolean;
  @Prop({
    default: () => ({}),
  })
  apiState: IApiState;

  @Emit("updateSelectedObjectType") onUpdateSelectedObjectType(
    _objectType: IObjectTypeState
  ) {}

  $refs!: {
    dropdown: Dropdown;
  };

  private handleOpen() {
    this.isOpen = true;
  }

  private isOpen = false;

  private cancel() {
    this.$refs.dropdown?.close();
  }

  private handleHide() {
    this.isOpen = false;
  }

  private handleSubmit(newType: IObjectTypeState) {
    this.onUpdateSelectedObjectType(newType);
    this.cancel();
  }

  public render() {
    return (
      <Dropdown
        toggleClass={[
          styles.toggleButton,
          this.isOpen ? styles.open : "",
          this.isCompact ? styles.compact : "",
        ]
          .join(" ")
          .trim()}
        ref="dropdown"
        onOpen={this.handleOpen}
        onClose={this.handleHide}
        topPlateOffset={3}
        leftPlateOffset={0}
      >
        <template slot="buttonContent">
          <ThemedImage
            src={plusIcon}
            class={styles.icon}
            alt={this.$t("global_cta_add_model").toString()}
          />
          {!this.isCompact && this.$t("global_cta_add_model")}
        </template>
        <Dropdown.Element>
          <ApiDataObjectTypeForm
            apiState={this.apiState}
            onCancel={this.cancel}
            onSubmit={this.handleSubmit}
            class={styles.content}
          />
        </Dropdown.Element>
      </Dropdown>
    );
  }
}
