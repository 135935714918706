import { IObjectTypeState, isObjectTypeNameReserved } from "@/models";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export class ObjectTypeNameValidator extends Vue {
  protected objectTypes: IObjectTypeState[] = [];

  protected serverSideErrors: string | undefined = undefined;

  private get objectTypeWithDuplicate() {
    const seen = new Set();
    const duplicate = this.objectTypes?.find((objectType) => {
      if (seen.has(objectType.name)) {
        return true;
      }
      seen.add(objectType.name);

      return false;
    });

    return duplicate;
  }

  private get objectTypeWithReservedType() {
    return this.objectTypes?.find((objectType) =>
      isObjectTypeNameReserved(objectType.name)
    );
  }

  private get objectTypeWithMinimumLength() {
    return this.objectTypes?.find((objectType) => objectType.name?.length <= 1);
  }

  public get errorMessage() {
    if (this.serverSideErrors) {
      return this.serverSideErrors;
    }

    if (this.objectTypeWithReservedType) {
      return this.$t("form_feedback_type_name_reserved", {
        name: this.objectTypeWithReservedType.name,
      }).toString();
    }

    if (this.objectTypeWithDuplicate) {
      return this.$t("form_feedback_type_name_uniq", {
        name: this.objectTypeWithDuplicate.name,
      }).toString();
    }

    if (this.objectTypeWithMinimumLength) {
      return this.$t("form_feedback_name_minlength").toString();
    }

    return undefined;
  }
}
