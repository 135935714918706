import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import { OrgModule } from "@/store/modules/org";
import { IOrgState } from "@/models/org";
import { SUPPORTED_COUNTRIES } from "@/utils";
import { IBillingAddressInputState } from "./types";
import { validateBillingAddress, validateVatId } from "./utils";
import UpdateOrg from "./UpdateOrg.mixin";
import _isEqual from "lodash/isEqual";
import { IAddressState } from "@/models/org";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import FormSelect from "@/components/forms/inputs/FormSelect.component";
import BillingAccount from "@/mixins/BillingAccount.mixin";
import FormInputWithLabel from "../forms/inputs/FormInputWithLabel.component";

interface IBillingAccountInputState {
  id: string;
  name: string;
  billingEmail: string;
  billingAddress: IBillingAddressInputState;
  paymentProviderId: string;
  vatId: string;
  orgId: string;
}

const getDefaultAddressValues = (): IBillingAddressInputState => ({
  country: null,
  city: "",
  line1: "",
  line2: "",
  postalCode: "",
});

const mapAddressStateToInput = (
  state?: IAddressState | null
): IBillingAddressInputState =>
  state
    ? {
        ...state,
        line2: state.line2 || "",
      }
    : getDefaultAddressValues();

export const mapOrgBillingAccountToInput = (
  state: IOrgState["billingAccount"] | null
): IBillingAccountInputState => ({
  id: state?.id ?? "",
  orgId: OrgModule.id,
  name: state?.name ?? "",
  vatId: state?.vatId ?? "",
  paymentProviderId: state?.paymentProviderId ?? "",
  billingEmail: state?.billingEmail ?? "",
  billingAddress: mapAddressStateToInput(state?.billingAddress),
});

@Component({
  components: {
    FormInputWithLabel,
    FormSelect,
  },
})
export default class BillingDataForm extends mixins(
  BillingAccount,
  UpdateOrg,
  UserPermissions
) {
  form = null;
  input = mapOrgBillingAccountToInput(OrgModule.synth().billingAccount);
  confirmName = "";

  get showCancelSaveButton(): boolean {
    if (!this.canManageOrg) return false;

    const newInput = mapOrgBillingAccountToInput(
      OrgModule.synth().billingAccount
    );

    return !_isEqual(newInput, this.input);
  }

  get countries(): { text: string; value: string }[] {
    return SUPPORTED_COUNTRIES;
  }

  get orgId() {
    return OrgModule.id;
  }

  @Watch("orgId", { immediate: true })
  onOrgChange() {
    this.input = mapOrgBillingAccountToInput(OrgModule.synth().billingAccount);
  }

  onCancel() {
    this.input = mapOrgBillingAccountToInput(OrgModule.synth().billingAccount);
    this.errorMessage = "";
  }

  async onSave() {
    try {
      this.isLoading = true;
      this.errorMessage = "";
      await this.upsertBillingAccount(
        this.validatedBillingAccountInput(this.input)
      );

      this.input = mapOrgBillingAccountToInput(
        OrgModule.synth().billingAccount
      );

      this.$emit("billingAccountUpdated");
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isLoading = false;
    }
  }

  validatedBillingAccountInput(input: IBillingAccountInputState) {
    if (input.name?.length <= 0) {
      throw new Error(
        this.$t("error_billing_requires_company_name").toString()
      );
    }

    if (input.billingEmail?.length <= 0) {
      throw new Error(this.$t("error_billing_requires_email").toString());
    }

    validateBillingAddress(input.billingAddress, this.$t.bind(this));

    validateVatId(
      input.billingAddress.country || "",
      input.vatId,
      this.$t.bind(this)
    );

    return {
      ...input,
      orgId: OrgModule.id,
    };
  }

  validatedBillingAddress() {
    validateBillingAddress(this.input.billingAddress, this.$t.bind(this));

    return this.input.billingAddress;
  }

  validatedVatId() {
    validateVatId(
      this.input.billingAddress.country || "",
      this.input.vatId,
      this.$t.bind(this)
    );

    return this.input.vatId;
  }
}
