import Vue from "vue";
import * as tsx from "vue-tsx-support";
import { Prop, Component } from "vue-property-decorator";
import style from "./ChildConnectionItem.component.module.scss";
import { formattedTimeString } from "@/utils";

const formatterMap: { [key: string]: (value: any) => {} } = {
  createdAt: formattedTimeString,
  updatedAt: formattedTimeString,
};

@Component
export default class ChildConnectionItem extends Vue {
  $scopedSlots!: tsx.InnerScopedSlots<{ action?: string; input?: string }>;

  _tsx!: tsx.DeclareProps<{
    data: ChildConnectionItem["data"];
  }>;

  @Prop({ required: true }) data: any;

  fieldBlacklist: string[] = ["__typename", "updatedAt", "id"];

  get fields() {
    const filteredFields = Object.entries(this.data).reduce(
      (acc: { [key: string]: any }, [key, value]) => {
        if (
          !this.fieldBlacklist.includes(key) &&
          !key.match(/.*Id/g) &&
          !!value
        ) {
          acc[key] = value;
        }

        return acc;
      },
      {}
    );

    return Object.entries(filteredFields).slice(
      0,
      Math.min(Object.keys(filteredFields).length, 5)
    );
  }

  formattedValue(name: string, value: any) {
    const formatter = formatterMap[name];
    return formatter ? formatter(value) : value;
  }

  render() {
    return (
      <div class={style.item}>
        {this.$scopedSlots.action?.(style.action)}
        {this.fields.map(([key, value]) => {
          return (
            <div class={style.field}>
              <div class={style.fieldName}>{key}</div>
              <div class={style.fieldValue}>
                {this.formattedValue(key, value)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
