import { MutationTypeEnum } from "@graphapi-io/api-declaration";

export const hasCreateMutationType = (types: MutationTypeEnum[]) =>
  types.includes(MutationTypeEnum.CREATE);

export const hasUpdateMutationType = (types: MutationTypeEnum[]) =>
  types.includes(MutationTypeEnum.UPDATE);

export const hasDeleteMutationType = (types: MutationTypeEnum[]) =>
  types.includes(MutationTypeEnum.DELETE);

export const toggleResolverTypeAll = (types: MutationTypeEnum[]) => {
  const hasCreate = hasCreateMutationType(types);
  const hasUpdate = hasUpdateMutationType(types);
  const hasDelete = hasDeleteMutationType(types);
  const hasAll = hasCreate && hasUpdate && hasDelete;

  if (hasAll) {
    return [MutationTypeEnum.CREATE];
  }

  return [
    MutationTypeEnum.CREATE,
    MutationTypeEnum.UPDATE,
    MutationTypeEnum.DELETE,
  ];
};

export const togglResolverType = (
  types: MutationTypeEnum[],
  typeToToggle: MutationTypeEnum
) => {
  const hasToggledType = types.includes(typeToToggle);

  if (hasToggledType) {
    return types.filter((type) => type !== typeToToggle);
  }

  return [...types, typeToToggle];
};
