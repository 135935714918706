import { ApolloClient, DocumentNode } from "apollo-boost";
import Observable from "zen-observable";
import {
  ICustomerListByVariables,
  ICustomerListVariables,
  IResultParser,
  IVariablesParser,
} from "../shared/types";

export class ApiQueries {
  constructor(
    private apolloClient: ApolloClient<unknown>,
    private resultParser: IResultParser,
    private variablesParser: IVariablesParser
  ) {}

  public async queryObject(typeName: string, id: string, query: DocumentNode) {
    const result = await this.apolloClient.query({
      query,
      variables: {
        id,
      },
    });

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    return this.resultParser.parseObjectQueryResult(result.data, typeName);
  }

  public watchQueryObject(typeName: string, id: string, query: DocumentNode) {
    const { observable } = this.watchQuery(query, { id }, (data) =>
      this.resultParser.parseObjectQueryResult(data, typeName)
    );

    return observable;
  }

  public watchListObjects<T = object>(
    typeName: string,
    variables: ICustomerListVariables,
    query: DocumentNode,
    queryName: string
  ) {
    const { observable, rawObservable } = this.watchQuery<{
      items: T[];
      nextToken: string | null;
    }>(query, variables, (data) => ({
      __typename: data[queryName]?.__typename,
      nextToken: data[queryName]?.nextToken ?? null,
      items: this.resultParser.parseListQueryResult(
        data[queryName].items,
        typeName
      ),
    }));

    return {
      observable,
      refetch: (variables: ICustomerListVariables) =>
        rawObservable.refetch({
          variables,
        }),
      fetchMore: (variables: ICustomerListVariables) =>
        rawObservable.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const previousItems = previousResult?.[queryName]?.items ?? [];
            const newItems = fetchMoreResult?.[queryName]?.items ?? [];
            const allItems = [...previousItems, ...newItems];

            return {
              [queryName]: {
                __typename: previousResult?.[queryName]?.__typename ?? typeName,
                nextToken: fetchMoreResult[queryName]?.nextToken ?? null,
                items: allItems,
              },
            };
          },
        }),
    };
  }

  public watchListConnections<T = object>(
    typeName: string,
    parentTypeName: string,
    variables: ICustomerListByVariables,
    query: DocumentNode,
    queryName: string
  ) {
    const { observable, rawObservable } = this.watchQuery<{
      items: T[];
      nextToken: string | null;
    }>(
      query,
      this.variablesParser.parseVariablesForListByQuery(
        variables,
        typeName,
        parentTypeName
      ),
      (data) => ({
        __typename: data[queryName]?.__typename,
        nextToken: data[queryName]?.nextToken ?? null,
        items: this.resultParser.parseListQueryResult(
          data[queryName].items,
          typeName
        ),
      })
    );

    return {
      observable,
      refetch: (variables: ICustomerListByVariables) =>
        rawObservable.refetch({
          variables,
        }),
      fetchMore: (variables: ICustomerListByVariables) =>
        rawObservable.fetchMore({
          variables: this.variablesParser.parseVariablesForListByQuery(
            variables,
            typeName,
            parentTypeName
          ) as any,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const previousItems = previousResult[queryName]?.items ?? [];
            const newItems = fetchMoreResult[queryName]?.items ?? [];
            const allItems = [...previousItems, ...newItems];

            return {
              [queryName]: {
                __typename: previousResult[queryName]?.__typename,
                nextToken: fetchMoreResult[queryName]?.nextToken ?? null,
                items: allItems,
              },
            };
          },
        }),
    };
  }

  private watchQuery<T extends object>(
    query: DocumentNode,
    variables: Record<string, unknown>,
    dataGetter: (data: any) => any
  ) {
    const queryObservable = this.apolloClient.watchQuery({
      query,
      variables,
      errorPolicy: "ignore",
    });

    return {
      rawObservable: queryObservable,
      observable: new Observable<T>((observer) => {
        const subscription = queryObservable.subscribe({
          next: (result) => {
            if (result.errors) {
              observer.error(new Error(result.errors[0].message));
              return;
            }
            const data = dataGetter(result.data);
            if (data === null) {
              observer.error(new Error("Object not found"));
              return;
            }

            observer.next(data);
          },
          error: (error) => {
            observer.error(error);
          },
          complete: () => {
            observer.complete();
          },
        });

        queryObservable.refetch();

        return () => {
          subscription.unsubscribe();
        };
      }),
    };
  }
}
