import { MutationTypeEnum } from "@graphapi-io/api-declaration";
import { ICustomerListByVariables, IVariablesParser } from "../shared/types";

export class DedicatedApiVariableParser implements IVariablesParser {
  public parseVariablesForListByQuery(variables: ICustomerListByVariables) {
    return variables;
  }

  public buildMutationVariables(
    variant: MutationTypeEnum,
    variables: { input: Record<string, unknown> }
  ) {
    return variables;
  }
}
