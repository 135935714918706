import {
  createSchemaPromptMutation,
  querySchemaPrompt,
} from "@/api/schema-prompt";
import { ISchemaPromptInput } from "@/generated/types";
import { extractPropOrThrow } from "./utils";
import { IObjectTypeState } from "./object-type";
import { IEnumState, parseDeclarationSchema } from "./api";

type ISchema = {
  objectTypes: IObjectTypeState[];
  enumTypes: IEnumState[];
};

type ISchemaPromptMappableProps = {
  schema?: string | null;
};

export const parseSchemaPromptResult = <T extends ISchemaPromptMappableProps>(
  schemaPrompt: T
): Omit<T, "schema"> & { schema: ISchema } => {
  const { schema, ...rest } = schemaPrompt;

  return {
    ...rest,
    schema: parseDeclarationSchema(undefined, schema ?? undefined),
  };
};

export const createSchemaPrompt = async (input: ISchemaPromptInput) => {
  const response = await createSchemaPromptMutation({ input });
  const data = extractPropOrThrow(response, "data");
  const schemaPrompt = extractPropOrThrow(data, "createSchemaPrompt");

  return parseSchemaPromptResult(schemaPrompt);
};

export const getSchemaPrompt = async (id: string) => {
  const response = await querySchemaPrompt({ id });
  const data = extractPropOrThrow(response, "data");
  const schemaPrompt = extractPropOrThrow(data, "schemaPrompt");

  return parseSchemaPromptResult(schemaPrompt);
};
