import {
  checkoutSessionsByApiBySessionIdQuery,
  createCheckoutSessionMutation,
  listCheckoutSessionsByApiQuery,
  updateCheckoutSessionMutation,
} from "@/api/checkout_session";
import {
  ICreateCheckoutSessionMutationVariables,
  IUpdateCheckoutSessionMutationVariables,
} from "@/generated/types";
import { UnwrappedPromise, SimplifyType } from "@/utils/types";
import {
  extractAndFilterItemsOrProvideDefault,
  extractData,
  extractItems,
  extractPropOrThrow,
  extractQueryname,
} from "./utils";

export const createCheckoutSession = async (
  variables: ICreateCheckoutSessionMutationVariables
) => {
  const response = await createCheckoutSessionMutation(variables);
  const data = extractPropOrThrow(response, "data");
  return extractPropOrThrow(data, "createCheckoutSession");
};

export const updateCheckoutSession = async (
  variables: IUpdateCheckoutSessionMutationVariables
) => {
  const response = await updateCheckoutSessionMutation(variables);
  const data = extractPropOrThrow(response, "data");
  return extractPropOrThrow(data, "updateCheckoutSession");
};

export const getCheckoutSessionForApi = async (apiId: string) => {
  const { __typename, ...restOfCheckoutSession } =
    (await getCheckoutSessionsByApi(apiId))[0] ?? {};
  return restOfCheckoutSession;
};

export const getCheckoutSessionsByApi = async (apiId: string) => {
  const response = await listCheckoutSessionsByApiQuery(
    { apiId },
    "network-only"
  );
  return extractAndFilterItemsOrProvideDefault(
    extractQueryname(extractData(response), "listCheckoutSessionsByApi")
  );
};

export const getCheckoutSessionsByApiForSessionId = async (
  apiId: string,
  sessionId: string
) => {
  const response = await checkoutSessionsByApiBySessionIdQuery({
    apiId,
    sessionId,
  });

  return extractItems(
    extractQueryname(extractData(response), "listCheckoutSessionsByApi")
  );
};

export type ICheckoutSessions = SimplifyType<
  UnwrappedPromise<ReturnType<typeof getCheckoutSessionsByApi>>
>;

export type ICheckoutSessionState = SimplifyType<ICheckoutSessions[number]>;
