import { Vue, Component, Prop } from "vue-property-decorator";
import style from "./FormDropdown.component.module.scss";
import {
  BDropdown,
  BDropdownForm,
  BDropdownHeader,
} from "@/lib/typed-bootstrap";
import {
  IThemedSrc,
  ThemedImage,
} from "@/components/shared/ThemedImage.component";

@Component
export class FormDropdown extends Vue {
  _tsx!: {
    props: Pick<
      FormDropdown,
      | "id"
      | "iconSrc"
      | "titleContent"
      | "disclaimerContent"
      | "disabled"
      | "buttonClass"
    >;
  };

  @Prop({ required: true }) id: string;
  @Prop({ required: true }) iconSrc: IThemedSrc;
  @Prop({ required: true }) titleContent: string;
  @Prop({ required: true }) disclaimerContent: string;
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ default: "" }) buttonClass?: string;

  render() {
    const {
      id,
      iconSrc,
      titleContent,
      disclaimerContent,
      disabled,
      buttonClass,
    } = this;

    return (
      <BDropdown
        id={id}
        no-caret
        toggle-class={buttonClass}
        menu-class={style.actionsDropdown}
        disabled={disabled}
      >
        <ThemedImage slot="button-content" src={iconSrc} />
        <BDropdownHeader class={style.actionsTitle} role="presentation">
          {titleContent}
        </BDropdownHeader>

        <BDropdownForm class={style.actionsForm}>
          {this.$slots.default}
          <div class={style.disclaimer}>{disclaimerContent}</div>
        </BDropdownForm>
      </BDropdown>
    );
  }
}
