import { Component, Prop, Watch, Emit } from "vue-property-decorator";
import _cloneDeep from "lodash/cloneDeep";
import _isEqualWith from "lodash/isEqualWith";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
import { getErrorMessage, getTypesDiff } from "@/utils";
import * as tsx from "vue-tsx-support";
import style from "./DataEntryForm.component.module.scss";
import DataEntryFormActionButtons from "./DataEntryFormActionButtons.component";
import DataEntryFormPrimitiveFields from "./DataEntryFormPrimitiveFields.component";
import DataEntryFormParentFields from "./DataEntryFormParentFields.component";
import DataEntryFormTargetFields from "./DataEntryFormTargetFields.component";
import { IObjectStackPushEvent } from "./types";
import { ICustomerListVariables } from "@/api-client";
import Vue from "vue";
import { IObjectTypeState } from "@/models";
import FieldsConfiguration from "./fields-configuration";
import { IGraphqlApi } from "@/api-client/shared/types";

@Component
export default class DataEntryForm extends Vue {
  _tsx!: tsx.DeclareProps<{
    dataEntry: DataEntryForm["dataEntry"];
    prevObjectTypeName: DataEntryForm["prevObjectTypeName"];
    prevObjectId: DataEntryForm["prevObjectId"];
    shouldCreateNewEntry: DataEntryForm["shouldCreateNewEntry"];
    objectType: DataEntryForm["objectType"];
    customerApi: DataEntryForm["customerApi"];
    initialListVariables: DataEntryForm["initialListVariables"];
    fieldsConfiguration: DataEntryForm["fieldsConfiguration"];
    objectTypes: DataEntryForm["objectTypes"];
  }> &
    tsx.DeclareOnEvents<{
      onConnectionClick: IObjectStackPushEvent;
      onCancelChildCreation: void;
      onCancelDataCreation: void;
      onCreatedDataEntry: IObjectStackPushEvent;
      onDataEntryError: Event;
    }>;
  @Prop({ required: true }) dataEntry: any;
  @Prop({ required: true }) prevObjectTypeName: string | null;
  @Prop({ required: true }) prevObjectId: string | null;
  @Prop({ required: true }) initialListVariables: ICustomerListVariables;
  @Prop({ required: true }) readonly shouldCreateNewEntry: boolean;
  @Prop({ required: true }) readonly customerApi: IGraphqlApi;
  @Prop({ default: null }) objectType: IObjectTypeState;
  @Prop({ required: true }) readonly fieldsConfiguration: FieldsConfiguration;
  @Prop({ required: true }) readonly objectTypes: IObjectTypeState[];

  @Emit("connectionClick") onConnectionClick(_params: IObjectStackPushEvent) {}
  @Emit("dataEntryError") onDataEntryError(_params: string) {}

  private isLoading = false;
  private dataInput: any = null;

  private get isValidDataEntry(): boolean {
    return this.dataInput !== null;
  }

  private get showCancelSaveButton(): boolean {
    return (
      !_isEqualWith(this.dataInput, this.dataEntry, (j, k, key) => {
        return key === "updatedAt" || key === "selected" ? true : undefined;
      }) || this.shouldCreateNewEntry
    );
  }

  private get updatedFieldKeys(): string[] {
    const diffResult = getTypesDiff(
      this.dataInput as Record<string, string>,
      this.dataEntry as Record<string, string>
    );
    if (diffResult._type === "leaf") {
      return [];
    }

    return Object.keys(diffResult.diffs);
  }

  get updatedFieldValues(): string[] {
    const diffResult = getTypesDiff(
      this.dataInput as Record<string, string>,
      this.dataEntry as Record<string, string>
    );
    if (diffResult._type === "leaf") {
      return [];
    }
    return Object.values(diffResult.diffs).map((diff) => diff?.to ?? "");
  }

  private get createMutationInput(): any {
    const readonlyKeysToOmit = this.dataInput.id
      ? this.fieldsConfiguration.readonlyKeys
      : [
          this.fieldsConfiguration.idKey,
          ...this.fieldsConfiguration.readonlyKeys,
        ];

    const targetConnectionKeys =
      this.fieldsConfiguration.targetConnectionFields.map(
        (field) => field.name
      );

    return _omit(this.dataInput, [
      ...targetConnectionKeys,
      ...readonlyKeysToOmit,
      ...this.fieldsConfiguration.blacklistedKeys,
    ]);
  }

  private get updateMutationInput(): any {
    return _pick(this.createMutationInput, [
      ...this.updatedFieldKeys,
      ...this.fieldsConfiguration.mandatoryFieldsConfiguration
        .updateInputFieldKeys,
    ]);
  }

  created() {
    this.initializeDataInput();
  }

  @Watch("dataEntry", { immediate: true })
  initializeDataInput() {
    this.dataInput = _cloneDeep(this.dataEntry);
  }

  @Watch("updatedFieldValues")
  resetErrorMessage() {
    this.onDataEntryError("");
  }

  private onCancelSettingsChange() {
    this.dataInput = _cloneDeep(this.dataEntry);

    if (this.shouldCreateNewEntry) {
      if (!this.prevObjectId) {
        this.$emit("cancelDataCreation");
      } else {
        this.$emit("cancelChildCreation");
      }
    }
  }

  private async onSaveSettingsChange() {
    try {
      this.isLoading = true;
      if (this.shouldCreateNewEntry) {
        await this.createData();
      } else {
        await this.udpateData();
      }
      this.isLoading = false;
    } catch (err) {
      this.onDataEntryError("");
    } finally {
      this.isLoading = false;
    }
  }

  private async createData() {
    try {
      const data = await this.customerApi.createObject(
        this.objectType,
        {
          input: this.createMutationInput,
        },
        this.customerApi.cacheUpdates.addToListFrontAfterCreationFactory(
          this.objectType,
          this.initialListVariables
        )
      );

      this.$emit("createdDataEntry", {
        recordId: data.id,
        objectTypeName: this.objectType?.name,
        objectTypeId: this.objectType?.id,
      } as IObjectStackPushEvent);
    } catch (err) {
      this.onDataEntryError(getErrorMessage(err));
    } finally {
      this.isLoading = false;
    }
  }

  private async udpateData(): Promise<any> {
    try {
      await this.customerApi.updateObject(this.objectType, {
        input: { id: this.dataInput.id, ...this.updateMutationInput },
      });
    } catch (err) {
      this.onDataEntryError(getErrorMessage(err));
    } finally {
      this.isLoading = false;
    }
  }

  render() {
    return (
      <div class={style.content}>
        {this.isValidDataEntry && (
          <div class={style.contentSection}>
            {this.fieldsConfiguration.parentConnectionFields.length > 0 && (
              <DataEntryFormParentFields
                prevObjectTypeName={this.prevObjectTypeName}
                prevObjectId={this.prevObjectId}
                parentConnectionFields={
                  this.fieldsConfiguration.parentConnectionFields
                }
                dataInput={this.dataInput}
                shouldCreateNewEntry={this.shouldCreateNewEntry}
                objectType={this.objectType}
                onParentClick={this.onConnectionClick}
                objectTypes={this.objectTypes}
              />
            )}
            {!this.shouldCreateNewEntry &&
              this.fieldsConfiguration.targetConnectionFields.length > 0 && (
                <DataEntryFormTargetFields
                  customerApi={this.customerApi}
                  objectTypes={this.objectTypes}
                  parentObjectType={this.objectType}
                  targetFields={this.fieldsConfiguration.targetConnectionFields}
                  dataInput={this.dataInput}
                  onChildClick={this.onConnectionClick}
                />
              )}
            <DataEntryFormPrimitiveFields
              dataInput={this.dataInput}
              blacklistedKeys={this.fieldsConfiguration.blacklistedKeys}
              fields={this.fieldsConfiguration.primitiveFields}
              objectType={this.objectType}
            />
          </div>
        )}
        {this.showCancelSaveButton && (
          <DataEntryFormActionButtons
            isLoading={this.isLoading}
            shouldCreateNewEntry={this.shouldCreateNewEntry}
            onCancelSettingsChange={this.onCancelSettingsChange}
            onSaveSettingsChange={this.onSaveSettingsChange}
          />
        )}
      </div>
    );
  }
}
