import { SelectionVector } from "@/utils/selection-vector";
import { ITableRange } from "./types";
import { ActivityState } from "./activity-state";

export class ColumnSelectionState {
  private minColIndex: number;
  private maxColIndex: number;
  private activityState: ActivityState;
  public selectedColumns = new SelectionVector();

  constructor(
    { minColIndex, maxColIndex }: ITableRange,
    activityState: ActivityState
  ) {
    this.minColIndex = minColIndex;
    this.maxColIndex = maxColIndex;
    this.activityState = activityState;
  }

  public updateRanges({ minColIndex, maxColIndex }: ITableRange) {
    this.minColIndex = minColIndex;
    this.maxColIndex = maxColIndex;
  }

  public deselectAll() {
    this.selectedColumns.clear();
  }

  public selectAllColumns() {
    for (let i = this.minColIndex; i <= this.maxColIndex; i++) {
      this.selectedColumns.add(i);
    }
  }

  public toggleColumnSelection(key: number) {
    if (this.selectedColumns.has(key)) {
      this.selectedColumns.delete(key);
    } else {
      this.selectedColumns.add(key);
    }
  }

  public selectColumn(key: number) {
    this.selectedColumns.add(key);
  }

  public isColumnSelected(key: number) {
    return this.selectedColumns.has(key);
  }

  public deselectColumn(key: number) {
    this.selectedColumns.delete(key);
  }

  public selectColumnsByActiveRange() {
    const { rangeWidth } = this.activityState;
    const { column } = this.activityState.activeCellIndex;
    const columnStart = Math.min(column, column + rangeWidth);
    const columnEnd = Math.max(column, column + rangeWidth);

    for (let i = columnStart; i <= columnEnd; i++) {
      this.selectedColumns.add(i);
    }
  }

  public get allSelected() {
    if (this.maxColIndex <= 0) return false;

    for (let i = this.minColIndex; i <= this.maxColIndex; i++) {
      if (!this.selectedColumns.has(i)) {
        return false;
      }
    }
    return true;
  }
}
