import { updateApiMutation } from "@/api/api";
import { IApiUpdateInput } from "@/generated/types";
import { IApiState, getApiDetails } from "@/models";

type IAuthInput = Pick<
  IApiUpdateInput,
  "defaultAuthMode" | "additionalAuthModes" | "oidc" | "authProviderClientId"
>;

export default class ApiDataManager {
  public apiState: IApiState | null = null;
  public isUpdating = false;
  public isFetching = false;
  public errorMessage = "";

  public get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  public async onApiIdChange(id: string) {
    try {
      this.isFetching = true;
      this.apiState = await getApiDetails({ id });
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isFetching = false;
    }
  }

  private getAuthInput({
    defaultAuthMode,
    additionalAuthModes,
    oidc,
    authProviderClientId,
  }: IAuthInput) {
    if (!this.apiState) {
      throw new Error("Api state is not initialized");
    }

    return {
      id: this.apiState.id,
      defaultAuthMode,
      additionalAuthModes,
      oidc,
      authProviderClientId,
    };
  }

  private getSchemaInput(schema: string) {
    if (!this.apiState) {
      throw new Error("Api state is not initialized");
    }

    return {
      id: this.apiState.id,
      schema,
      ...(this.apiState.awsAccountId && {
        awsAccountId: this.apiState.awsAccountId,
      }),
    };
  }

  public async updateApiAuth(params: IAuthInput) {
    if (!this.apiState) {
      return;
    }
    try {
      this.isUpdating = true;
      this.errorMessage = "";
      await updateApiMutation(this.getAuthInput(params));
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isUpdating = false;
    }
  }

  public async updateApiRegion({ region }: Pick<IApiUpdateInput, "region">) {
    if (!this.apiState) {
      return;
    }
    try {
      this.isUpdating = true;
      this.errorMessage = "";
      await updateApiMutation({
        id: this.apiState.id,
        region,
      });
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isUpdating = false;
    }
  }

  public async updateSchema(schema: string) {
    if (!this.apiState) {
      return;
    }

    return updateApiMutation(this.getSchemaInput(schema));
  }

  public updateAuthAndSchema(params: IAuthInput & { schema: string }) {
    if (!this.apiState) {
      return;
    }

    return updateApiMutation({
      ...this.getAuthInput(params),
      ...this.getSchemaInput(params.schema),
    });
  }

  public async updateQueryString(query: string) {
    if (!this.apiState) {
      return;
    }

    return updateApiMutation({
      id: this.apiState.id,
      queryExplorerString: query,
    });
  }
}
