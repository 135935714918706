import { ICellIndex, ITableRange } from "./types";

export class ActivityState {
  private minRowIndex: number;
  private minColIndex: number;
  private maxRowIndex: number;
  private maxColIndex: number;
  public activeCellIndex: ICellIndex;
  public rangeWidth: number = 0;
  public rangeHeight: number = 0;

  constructor({
    minRowIndex,
    maxRowIndex,
    minColIndex,
    maxColIndex,
  }: ITableRange) {
    this.minRowIndex = minRowIndex;
    this.minColIndex = minColIndex;
    this.maxRowIndex = maxRowIndex;
    this.maxColIndex = maxColIndex;
    this.activeCellIndex = { row: minRowIndex, column: minColIndex };
  }

  public updateRanges({
    minRowIndex,
    maxRowIndex,
    minColIndex,
    maxColIndex,
  }: ITableRange) {
    this.minRowIndex = minRowIndex;
    this.minColIndex = minColIndex;
    this.maxRowIndex = maxRowIndex;
    this.maxColIndex = maxColIndex;
  }

  public updateActiveCell(cellIndex: ICellIndex) {
    this.activeCellIndex = cellIndex;
  }

  public updateRangeByTargetCell(cellIndex: ICellIndex) {
    this.rangeWidth = cellIndex.column - this.activeCellIndex.column;
    this.rangeHeight = cellIndex.row - this.activeCellIndex.row;
  }

  public updateRangeByTargetColumn(columnIndex: number) {
    this.rangeWidth = columnIndex - this.activeCellIndex.column;
    this.rangeHeight = 0;
  }

  public updateRangeByTargetRow(rowIndex: number) {
    this.rangeWidth = 0;
    this.rangeHeight = rowIndex - this.activeCellIndex.row;
  }

  public resetRange() {
    this.rangeWidth = 0;
    this.rangeHeight = 0;
  }

  public adjustRangeByDirection(
    direction: "TOP" | "BOTTOM" | "LEFT" | "RIGHT"
  ): void {
    switch (direction) {
      case "TOP": {
        const newRangeHeight = this.rangeHeight - 1;
        if (this.activeCellIndex.row + newRangeHeight >= this.minRowIndex) {
          this.rangeHeight = newRangeHeight;
        }
        break;
      }
      case "BOTTOM": {
        const newRangeHeight = this.rangeHeight + 1;
        if (this.activeCellIndex.row + newRangeHeight <= this.maxRowIndex) {
          this.rangeHeight = newRangeHeight;
        }
        break;
      }
      case "LEFT": {
        const newRangeWidth = this.rangeWidth - 1;
        if (this.activeCellIndex.column + newRangeWidth >= this.minColIndex) {
          this.rangeWidth = newRangeWidth;
        }
        break;
      }
      case "RIGHT": {
        const newRangeWidth = this.rangeWidth + 1;
        if (this.activeCellIndex.column + newRangeWidth <= this.maxColIndex) {
          this.rangeWidth = newRangeWidth;
        }
        break;
      }
    }
  }

  public adjustRangeToEndByDirection(
    direction: "TOP" | "BOTTOM" | "LEFT" | "RIGHT"
  ): void {
    switch (direction) {
      case "TOP": {
        this.rangeHeight = -this.activeCellIndex.row;
        break;
      }
      case "BOTTOM": {
        this.rangeHeight = this.maxRowIndex - this.activeCellIndex.row;
        break;
      }
      case "LEFT": {
        this.rangeWidth = -this.activeCellIndex.column + this.minColIndex;
        break;
      }
      case "RIGHT": {
        this.rangeWidth = this.maxColIndex - this.activeCellIndex.column;
        break;
      }
    }
  }

  public get activeRangeLastCell(): ICellIndex {
    return {
      row: this.activeCellIndex.row + this.rangeHeight,
      column: this.activeCellIndex.column + this.rangeWidth,
    };
  }
}
