import _cloneDeep from "lodash/cloneDeep";
import _difference from "lodash/difference";
import sortBy from "lodash/sortBy";
import i18n from "@/i18n";

export * from "./objects-diff";
export * from "./type-guards";
export * from "./csv-reader";

export function currentEnvStage(): string {
  return process.env.VUE_APP_STAGE ?? "alpha";
}

export function envRootDomain(): string {
  return currentEnvStage() === "alpha"
    ? "https://alpha.graphapi.com"
    : "https://graphapi.com";
}

export function envRootLocalizedDomain(): string {
  return i18n.locale === i18n.fallbackLocale
    ? envRootDomain()
    : `${envRootDomain()}/${i18n.locale}`;
}

export function slugFromName(
  name: string,
  domain: string = ".graphapi.com"
): string {
  return `${name
    .trim()
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[-]+/g, "-")
    .replace(/[^\w-]+/g, "")}${domain}`;
}

export function idFromObjectName(
  objectName: string,
  fieldName: string = "id"
): string {
  return `${objectName?.[0].toLowerCase()}${objectName?.slice(
    1
  )}${upperCaseFirstLetter(fieldName)}`;
}

export function inputTypeFromFieldName(
  fieldName: string,
  inputType: string
): string {
  return `${fieldName[0].toLowerCase()}${fieldName.slice(1)}${inputType}`;
}

export function omitInitialDigits(text: string) {
  return text.replace(/^\d+/, "");
}

/**
 * Utility Function to upperCase only first letter of text
 *
 * @param text the object field name
 */
export function upperCaseFirstLetter(text: string): string {
  if (!text || text.length === 0) return text;
  return `${text[0]?.toUpperCase()}${text.slice(1)}`;
}

export function lowerCaseFirstLetter(text: string): string {
  if (text.length === 0) return text;
  return `${text[0]?.toLowerCase()}${text.slice(1)}`;
}

export function removeSpecialCharacters(text: string): string {
  return text.replace(/[^\w]+/g, "");
}

export function addSpacesToCamelCase(input: string): string {
  return input.replace(/([A-Z])/g, " $1").trim();
}

export function emailFormatter(text: string): string {
  return text.replace(/[^\w.@_+-]+/g, "").toLowerCase();
}

export function fieldFormatter(field: string): string {
  return omitInitialDigits(removeSpecialCharacters(field?.trim()));
}

export function floatFormatter(text: string): string {
  return text
    .replace(/[^\d.-]+/g, "")
    .replace(/(?!^)-/g, "")
    .replace(/[.]+/g, ".");
}

export function intFormatter(text: string, maxLength: number = 16): string {
  return text
    .replace(/[^\d-]+/g, "")
    .replace(/(?!^)-/g, "")
    .substring(0, maxLength);
}

export function locationFormatter(text: string): string {
  return text.replace(/[^\w, ]+/g, "");
}

export function phoneFormatter(text: string): string {
  return text.replace(/[^\d.+()-]+/g, "").toLowerCase();
}

export function websiteFormatter(text: string): string {
  return text.toLowerCase();
}

export function formattedTimeString(value: string | number | Date): string {
  return new Date(value).toLocaleString();
}

export function isMoreTimeAgo(dateString: string, minutes: number) {
  const date = new Date(dateString);
  date.setMinutes(date.getMinutes() + minutes);

  return date < new Date();
}

export function escapeCodeSnippet(code: string) {
  return code
    .replace(new RegExp("&", "g"), "&")
    .replace(new RegExp("<", "g"), "<");
}

export function batchesFromCsvEntries(
  entries: string[][],
  headerFields: string[],
  allFields: string[],
  requiredFields: string[],
  batchSize: number
) {
  const batches = [];
  let batch: {
    [key: string]: unknown;
  }[] = [];
  entries.forEach((values) => {
    const entry: { [key: string]: unknown } = {};
    values.forEach((value, index) => {
      const key = fieldFormatter(headerFields[index]);
      const normalizedValue = value.replace(/^"(.+(?="$))"$/, "$1");
      if (normalizedValue) {
        if (allFields.includes(key)) {
          entry[key] = normalizedValue;
        }
      } else if (requiredFields.includes(key)) {
        entry[key] = "";
      }
    });
    if (Object.keys(entry).length > 0) {
      batch.push(entry);
    }

    if (batch.length === batchSize) {
      batches.push(_cloneDeep(batch));
      batch = [];
    }
  });

  // If batch still contains value append it
  if (batch.length > 0) {
    batches.push(_cloneDeep(batch));
    batch = [];
  }

  return batches;
}

export function exportToCsv(
  filename: string,
  rows: object[],
  headers?: string[]
): void {
  if (!rows || !rows.length) {
    return;
  }

  const separator: string = ",";
  const keys: string[] = Object.keys(rows[0]);
  const columnHeaders: string[] = headers ? headers : keys;
  const pickHeaders = headers ?? [];
  const keysToOmit = headers ? _difference(keys, pickHeaders) : [];
  const csvContent =
    columnHeaders.join(separator) +
    "\n" +
    rows
      .map((row: any) => {
        return keys
          .map((k) => {
            if (!keysToOmit.includes(k)) {
              let cell = row[k] === null || row[k] === undefined ? "" : row[k];
              cell =
                cell instanceof Date
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, '""');

              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }

              return cell;
            }

            return undefined;
          })
          .filter((v) => v !== undefined)
          .join(separator);
      })
      .join("\n");

  exportString(filename, csvContent);
}

export function escapeCsvValue(value: string) {
  if (/[",\n]/.test(value)) {
    return `"${value.replace(/"/g, '""')}"`;
  } else {
    return value;
  }
}

export function exportString(filename: string, data: string) {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function sortFieldsArray(fields: string[]) {
  return fields.sort((el1: string, el2: string) => {
    if (el1.toLowerCase() === "id") {
      return -1;
    } else if (el2.toLowerCase() === "id") {
      return 1;
    }

    if (el1.toLowerCase() === "createdat") {
      return -1;
    } else if (el2.toLowerCase() === "createdat") {
      return 1;
    }

    if (el1.toLowerCase() === "updatedat") {
      return -1;
    } else if (el2.toLowerCase() === "updatedat") {
      return 1;
    }

    return 1;
  });
}

export function sortObjectArrayByKey<T>(objects: T[], sortKey: keyof T) {
  return objects.sort((el1: any, el2: any) => {
    if (el1[sortKey].toLowerCase() === "id") {
      return -1;
    } else if (el2[sortKey].toLowerCase() === "id") {
      return 1;
    }

    if (el1[sortKey].toLowerCase() === "createdat") {
      return -1;
    } else if (el2[sortKey].toLowerCase() === "createdat") {
      return 1;
    }

    if (el1[sortKey].toLowerCase() === "updatedat") {
      return -1;
    } else if (el2[sortKey].toLowerCase() === "updatedat") {
      return 1;
    }

    return el1[sortKey] < el2[sortKey] ? -1 : 1;
  });
}

export const EU_CONTRIES = [
  { value: "AT", text: i18n.t("country_name_AT").toString() },
  { value: "BE", text: i18n.t("country_name_BE").toString() },
  { value: "BG", text: i18n.t("country_name_BG").toString() },
  { value: "HR", text: i18n.t("country_name_HR").toString() },
  { value: "CY", text: i18n.t("country_name_CY").toString() },
  { value: "CZ", text: i18n.t("country_name_CZ").toString() },
  { value: "DK", text: i18n.t("country_name_DK").toString() },
  { value: "EE", text: i18n.t("country_name_EE").toString() },
  { value: "FI", text: i18n.t("country_name_FI").toString() },
  { value: "FR", text: i18n.t("country_name_FR").toString() },
  { value: "DE", text: i18n.t("country_name_DE").toString() },
  { value: "GR", text: i18n.t("country_name_GR").toString() },
  { value: "HU", text: i18n.t("country_name_HU").toString() },
  { value: "IE", text: i18n.t("country_name_IE").toString() },
  { value: "IT", text: i18n.t("country_name_IT").toString() },
  { value: "LV", text: i18n.t("country_name_LV").toString() },
  { value: "LT", text: i18n.t("country_name_LT").toString() },
  { value: "LU", text: i18n.t("country_name_LU").toString() },
  { value: "MT", text: i18n.t("country_name_MT").toString() },
  { value: "NL", text: i18n.t("country_name_NL").toString() },
  { value: "PL", text: i18n.t("country_name_PL").toString() },
  { value: "PT", text: i18n.t("country_name_PT").toString() },
  { value: "RO", text: i18n.t("country_name_RO").toString() },
  { value: "SK", text: i18n.t("country_name_SK").toString() },
  { value: "SI", text: i18n.t("country_name_SI").toString() },
  { value: "ES", text: i18n.t("country_name_ES").toString() },
  { value: "SE", text: i18n.t("country_name_SE").toString() },
];

export const NON_GERMANY_EU_COUNTRIES = EU_CONTRIES.filter(
  (country) => country.value !== "DE"
);

export const SUPPORTED_COUNTRIES = sortBy(
  [
    ...EU_CONTRIES,
    { value: "AR", text: i18n.t("country_name_AR").toString() },
    { value: "AU", text: i18n.t("country_name_AU").toString() },
    { value: "BO", text: i18n.t("country_name_BO").toString() },
    { value: "BR", text: i18n.t("country_name_BR").toString() },
    { value: "BZ", text: i18n.t("country_name_BZ").toString() },
    { value: "CA", text: i18n.t("country_name_CA").toString() },
    { value: "CH", text: i18n.t("country_name_CH").toString() },
    { value: "CL", text: i18n.t("country_name_CL").toString() },
    { value: "CN", text: i18n.t("country_name_CN").toString() },
    { value: "CO", text: i18n.t("country_name_CO").toString() },
    { value: "CR", text: i18n.t("country_name_CR").toString() },
    { value: "EC", text: i18n.t("country_name_EC").toString() },
    { value: "FK", text: i18n.t("country_name_FK").toString() },
    { value: "GB", text: i18n.t("country_name_GB").toString() },
    { value: "GF", text: i18n.t("country_name_GF").toString() },
    { value: "GT", text: i18n.t("country_name_GT").toString() },
    { value: "GY", text: i18n.t("country_name_GY").toString() },
    { value: "HK", text: i18n.t("country_name_HK").toString() },
    { value: "HN", text: i18n.t("country_name_HN").toString() },
    { value: "IL", text: i18n.t("country_name_IL").toString() },
    { value: "IN", text: i18n.t("country_name_IN").toString() },
    { value: "IS", text: i18n.t("country_name_IS").toString() },
    { value: "JM", text: i18n.t("country_name_JM").toString() },
    { value: "JP", text: i18n.t("country_name_JP").toString() },
    { value: "LI", text: i18n.t("country_name_LI").toString() },
    { value: "LK", text: i18n.t("country_name_LK").toString() },
    { value: "MC", text: i18n.t("country_name_MC").toString() },
    { value: "ME", text: i18n.t("country_name_ME").toString() },
    { value: "MK", text: i18n.t("country_name_MK").toString() },
    { value: "MX", text: i18n.t("country_name_MX").toString() },
    { value: "NI", text: i18n.t("country_name_NI").toString() },
    { value: "NO", text: i18n.t("country_name_NO").toString() },
    { value: "NZ", text: i18n.t("country_name_NZ").toString() },
    { value: "PA", text: i18n.t("country_name_PA").toString() },
    { value: "PE", text: i18n.t("country_name_PE").toString() },
    { value: "PK", text: i18n.t("country_name_PK").toString() },
    { value: "PR", text: i18n.t("country_name_PR").toString() },
    { value: "PY", text: i18n.t("country_name_PY").toString() },
    { value: "RS", text: i18n.t("country_name_RS").toString() },
    { value: "SR", text: i18n.t("country_name_SR").toString() },
    { value: "SV", text: i18n.t("country_name_SV").toString() },
    { value: "TN", text: i18n.t("country_name_TN").toString() },
    { value: "TR", text: i18n.t("country_name_TR").toString() },
    { value: "TW", text: i18n.t("country_name_TW").toString() },
    { value: "UA", text: i18n.t("country_name_UA").toString() },
    { value: "US", text: i18n.t("country_name_US").toString() },
    { value: "UY", text: i18n.t("country_name_UY").toString() },
    { value: "VE", text: i18n.t("country_name_VE").toString() },
    { value: "XK", text: i18n.t("country_name_XK").toString() },
    { value: "ZA", text: i18n.t("country_name_ZA").toString() },
  ],
  ["text"]
);

export const convertDaysToSeconds = (days: number) => days * 24 * 60 * 60;
export const convertTimestampToDaysFromNow = (timestamp: number) =>
  Math.floor((timestamp - Date.now() / 1000) / (24 * 60 * 60));
export const getCurrentTimestampSeconds = () => Math.floor(Date.now() / 1000);

export const getErrorMessage = (err: unknown) => {
  if (err instanceof Error) {
    return err.message;
  }
  if (typeof err === "string") {
    return err;
  }
  return "";
};

export const readFileToString = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        resolve(e.target.result.toString());
      } else {
        reject(new Error("Could not read file"));
      }
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsText(file);
  });
};

export const withNumberInput =
  (fn: (value: number) => void) => (value: number | string) =>
    fn(
      typeof value === "number"
        ? value
        : isNaN(parseFloat(value))
        ? NaN
        : parseFloat(value)
    );

export const copyToClipboard = (data?: string) => {
  if (!data) {
    return;
  }
  navigator.clipboard.writeText(data).catch(() => {
    // ignore
  });
};

export const isAlpha = () =>
  process.env.VUE_APP_GRAPHQL_ENDPOINT?.includes("alpha");
