import { getTypesDiff, sortObjectArrayByKey } from "@/utils";
import { IFieldState, IObjectTypeState, IParentState } from "./types";
import _pick from "lodash/pick";
import { IAnyArray, IAnyFunction } from "@/utils/types";

type IObjectTypeDiffableKeys = keyof Omit<
  IObjectTypeState,
  "selected" | "state" | "apiId" | "connector" | "usePrefix"
>;

export type IFieldDiffableProps = Omit<IFieldState, "selected" | "state">;

export type IParentDiffableProps = Omit<IParentState, "connectionIndex">;

export type IObjectTypeDiffableProps = {
  [Key in IObjectTypeDiffableKeys]: Key extends
    | "name"
    | "targets"
    | "directives"
    ? IObjectTypeState[Key] | undefined
    : Key extends "fields"
    ? IFieldDiffableProps[]
    : Key extends "parents"
    ? {
        first: IParentDiffableProps | null;
        second: IParentDiffableProps | null;
      }
    : IObjectTypeState[Key];
};

const getEmptyObjectTypeWithId = (id: string): IObjectTypeDiffableProps => ({
  id,
  name: undefined,
  fields: [],
  parents: { first: null, second: null },
  resolvers: [],
  directives: undefined,
  targets: undefined,
});

const getEmptyFieldWithId = (id: string): IFieldDiffableProps => ({
  id,
  name: "",
  type: null,
  directives: undefined,
});

const mapObjectTypeFieldToDiffable = (
  field: IFieldState
): IFieldDiffableProps => ({
  id: field.id,
  name: field.name,
  type: field.type,
  typeId: field.typeId,
  directives: field.directives,
  readonly: field.readonly,
});

const mapParentFieldToDiffable = (
  parent: IParentState | null
): IParentDiffableProps | null =>
  parent === null
    ? null
    : {
        parentId: parent.parentId,
      };

export const mapObjectTypeToDiffable = (
  obj: IObjectTypeState
): IObjectTypeDiffableProps => ({
  ..._pick(obj, ["id", "name", "resolvers", "directives", "targets"]),
  fields: sortObjectArrayByKey(
    obj.fields.map(mapObjectTypeFieldToDiffable),
    "id"
  ),
  parents: {
    first: mapParentFieldToDiffable(obj.parents.first),
    second: mapParentFieldToDiffable(obj.parents.second),
  },
});

export function fillUpMissingEntries<
  From extends IAnyArray,
  To extends IAnyArray,
  With extends IAnyFunction
>(oldEntries: From, newEntries: To, emptyObjectWithId: With) {
  const uniqueInOldTypes = oldEntries.filter((obj) => {
    return !newEntries.some((obj2) => {
      return obj.id == obj2.id;
    });
  });

  const uniqueInNewTypes = newEntries.filter((obj) => {
    return !oldEntries.some((obj2) => {
      return obj.id == obj2.id;
    });
  });

  const oldEntriesFilled = sortObjectArrayByKey(
    [
      ...uniqueInNewTypes.map((entry) => emptyObjectWithId(entry.id)),
      ...oldEntries,
    ],
    "id"
  );

  const newEntriesFilled = sortObjectArrayByKey(
    [
      ...uniqueInOldTypes.map((entry) => emptyObjectWithId(entry.id)),
      ...newEntries,
    ],
    "id"
  );

  return { oldEntriesFilled, newEntriesFilled };
}

export function getObjectTypeArraysDiff(
  oldObjectTypes: IObjectTypeState[],
  newObjectTypes: IObjectTypeState[]
) {
  const oldDiffable = oldObjectTypes.map(mapObjectTypeToDiffable);
  const newDiffable = newObjectTypes.map(mapObjectTypeToDiffable);

  const filledUpTypes = fillUpMissingEntries<
    IObjectTypeDiffableProps[],
    IObjectTypeDiffableProps[],
    (id: string) => IObjectTypeDiffableProps
  >(oldDiffable, newDiffable, getEmptyObjectTypeWithId);

  (filledUpTypes.oldEntriesFilled as IObjectTypeDiffableProps[]).forEach(
    (oldType, index) => {
      const newType = (
        filledUpTypes.newEntriesFilled as IObjectTypeDiffableProps[]
      )[index];
      const filledUpFields = fillUpMissingEntries<
        IFieldDiffableProps[],
        IFieldDiffableProps[],
        (id: string) => IFieldDiffableProps
      >(oldType.fields, newType.fields, getEmptyFieldWithId);

      oldType.fields = [
        ...(filledUpFields.oldEntriesFilled as IFieldDiffableProps[]),
      ];
      newType.fields = [
        ...(filledUpFields.newEntriesFilled as IFieldDiffableProps[]),
      ];
    }
  );

  return getTypesDiff<IObjectTypeDiffableProps[], IObjectTypeDiffableProps[]>(
    filledUpTypes.oldEntriesFilled,
    filledUpTypes.newEntriesFilled
  );
}
