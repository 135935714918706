import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { getTypedBFormSelect } from "@/lib/typed-bootstrap";
import * as tsx from "vue-tsx-support";
import { ConnectionCardinalityEnum } from "@/models/object-type";

const BFormSelect = getTypedBFormSelect<ConnectionCardinalityEnum>();

@Component
export default class ObjectTypeConnectionTypeSelect extends Vue {
  _tsx!: tsx.DeclareProps<
    Pick<
      ObjectTypeConnectionTypeSelect,
      "value" | "canHaveMoreParents" | "canHaveMoreTargets"
    >
  > &
    tsx.DeclareOnEvents<{
      onInput: ConnectionCardinalityEnum;
    }>;

  @Prop({ required: true }) value: ConnectionCardinalityEnum;
  @Prop({ required: true }) canHaveMoreParents: boolean;
  @Prop({ required: true }) canHaveMoreTargets: boolean;

  get options() {
    return [
      {
        value: ConnectionCardinalityEnum.IS_PARENT_OF,
        text: "has many",
        disabled: !this.canHaveMoreTargets,
      },
      {
        value: ConnectionCardinalityEnum.IS_CHILD_OF,
        text: "belongs to",
        disabled: !this.canHaveMoreParents,
      },
    ];
  }

  render() {
    return (
      <BFormSelect
        options={this.options}
        value={this.value}
        class="default__form-input form-select__input"
        on={this.$listeners}
      ></BFormSelect>
    );
  }
}
