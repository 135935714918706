import { IRenderFn } from "@/utils/types";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import editIconLightMode from "@/assets/img/edit-black.svg";
import editIconDarkMode from "@/assets/img/edit-white.svg";
import style from "./EditCell.component.module.scss";
import { renderCell, ICellEvents, ICellProps } from "./Cell.component";

const iconSrc = {
  light: editIconLightMode,
  dark: editIconDarkMode,
};

type IEditCellProps = Pick<
  ICellProps,
  "isCellSelected" | "isBottomCellSelected" | "isTopCellSelected"
> & { index: number; isFirstColumn: boolean };

type NewType = ICellEvents;

export const renderEditCell: IRenderFn<IEditCellProps, NewType> = (
  h,
  { props, listeners, data }
) =>
  renderCell(h, {
    props: {
      ...props,
      dataTestId: `row${props.index}Edit`,
      isLeftCellSelected: true,
      isRightCellSelected: true,
      isFirstDataColumn: false,
      isPrevCellSelection: false,
    },
    children: <ThemedImage src={iconSrc} class={style.icon} />,
    listeners,
    data: {
      ...data,
      class: {
        [style.cell]: true,
        [style.selected]: props.isCellSelected,
        [style.topSelected]: props.isTopCellSelected,
        [style.bottomSelected]: props.isBottomCellSelected,
        [style.isFirstColumn]: props.isFirstColumn,
      },
    },
  });
