import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import { OrgModule, IOrgModuleState } from "@/store/modules/org";
import UpdateOrg from "./UpdateOrg.mixin";
import _isEqual from "lodash/isEqual";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import ErrorHandling from "@/mixins/ErrorHandling.mixin";
import FormInputWithLabel from "../forms/inputs/FormInputWithLabel.component";

export interface IOrgInputState {
  id: string;
  name: string;
}

export const mapOrgStateToInput = (state: IOrgModuleState): IOrgInputState => ({
  id: state.id,
  name: state.name ?? "",
});

@Component({
  components: {
    FormInputWithLabel,
  },
})
export default class OrgDataForm extends mixins(
  UpdateOrg,
  UserPermissions,
  ErrorHandling
) {
  form = null;
  input = mapOrgStateToInput(OrgModule.synth());
  confirmName = "";
  isLoading = false;

  get showCancelSaveButton(): boolean {
    if (!this.canManageOrg) return false;

    const newInput = mapOrgStateToInput(OrgModule.synth());

    return !_isEqual(newInput, this.input);
  }

  get orgId() {
    return OrgModule.id;
  }

  @Watch("orgId", { immediate: true })
  onOrgChange() {
    this.input = mapOrgStateToInput(OrgModule.synth());
  }

  onCancelSettingsChange() {
    this.input = mapOrgStateToInput(OrgModule.synth());
  }

  async onSaveSettingsChange() {
    try {
      if (!this.input.name) {
        throw new Error(this.$t("error_org_requires_name").toString());
      }

      this.isLoading = true;
      await this.updateOrg(() => ({
        id: this.input.id,
        name: this.input.name,
      }));

      if (!this.errorMessage) {
        this.input = mapOrgStateToInput(OrgModule.synth());
      }
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isLoading = false;
    }
  }
}
