import { escapeCsvValue, isNotNullable } from "@/utils";
import { IItem } from "./types";
import { SelectionVector } from "@/utils/selection-vector";
import { SelectionMatrix } from "@/utils/selection-matrix";
import { getDataColumnIndexOffset } from "./utils";

export class SelectedDataExtractor {
  constructor(
    private data: IItem[],
    private fields: string[],
    private hasSelectionColumn: boolean,
    private hasEditColumn: boolean
  ) {
    this.data = data;
    this.fields = fields;
  }

  public updateData(data: IItem[], fields: string[]) {
    this.data = data;
    this.fields = fields;
  }

  public extractDataToCopy(
    selectedRows: SelectionVector,
    selectedColumns: SelectionVector,
    selectedCells: SelectionMatrix,
    enforceHeaders: boolean = false
  ) {
    const headerRow = [
      ...(this.hasSelectionColumn ? ["select"] : []),
      ...(this.hasEditColumn ? ["edit"] : []),
      ...this.fields,
    ];
    const dataRows = this.data.map((row) => {
      return [
        ...(this.hasSelectionColumn ? [undefined] : []),
        ...(this.hasEditColumn ? [undefined] : []),
        ...this.fields.map((field) => row[field]),
      ];
    });
    const nonEmptyRows = this.filterOutEmptyRows(
      dataRows,
      selectedRows,
      selectedColumns,
      selectedCells
    );
    const includeHeaders = selectedColumns.size > 0 || enforceHeaders;

    if (selectedRows.size === 0) {
      const { headers, data } = this.filterOutEmptyColumns(
        headerRow,
        nonEmptyRows,
        selectedColumns,
        selectedCells
      );

      const filteredData = includeHeaders ? [headers, ...data] : data;
      return this.formatData(filteredData);
    }

    const rowsExceptDummyColumns = (
      includeHeaders ? [headerRow, ...nonEmptyRows] : nonEmptyRows
    ).map((row) =>
      row.slice(
        getDataColumnIndexOffset(this.hasSelectionColumn, this.hasEditColumn)
      )
    );
    return this.formatData(rowsExceptDummyColumns);
  }

  private filterOutEmptyRows(
    data: unknown[][],
    selectedRows: SelectionVector,
    selectedColumns: SelectionVector,
    selectedCells: SelectionMatrix
  ) {
    return data
      .map((row, rowIndex) => {
        if (selectedRows.has(rowIndex)) {
          return row;
        }
        const hasAnyCellSelectedInRow =
          selectedCells.hasAnySelectionInRow(rowIndex);
        const hasAnyColumnSelected = selectedColumns.size > 0;
        if (!hasAnyCellSelectedInRow && !hasAnyColumnSelected) {
          return null;
        }

        return row.map((fieldData, colIndex) => {
          const isCellSelected =
            selectedCells.has({ x: colIndex, y: rowIndex }) ||
            selectedColumns.has(colIndex);
          return isCellSelected ? fieldData : "";
        });
      })
      .filter(isNotNullable);
  }

  private filterOutEmptyColumns(
    headers: string[],
    data: unknown[][],
    selectedColumns: SelectionVector,
    selectedCells: SelectionMatrix
  ) {
    const columnsToPick: Set<number> = new Set();

    headers.forEach((_, colIndex) => {
      if (selectedColumns.has(colIndex)) {
        columnsToPick.add(colIndex);
      }
      const hasAnySelection = selectedCells.hasAnySelectionInColumn(colIndex);
      if (hasAnySelection) {
        columnsToPick.add(colIndex);
      }
    });

    const filteredData = data.map((row) => {
      return row.filter((_, colIndex) => columnsToPick.has(colIndex));
    });

    const filteredHeaderRow = headers.filter((_, colIndex) =>
      columnsToPick.has(colIndex)
    );

    return {
      data: filteredData,
      headers: filteredHeaderRow,
    };
  }

  private formatData(data: unknown[][]) {
    if (data.length === 1 && data[0].length <= 1) {
      return data.map((row) => row.join(",")).join("\n");
    }

    return data
      .map((row) => row.map((col) => escapeCsvValue(col as string)).join(","))
      .join("\n");
  }
}
