import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import styles from "./ApiDataObjectTypeTabButton.component.module.scss";
import * as tsx from "vue-tsx-support";
import Dropdown from "../shared/Dropdown.component";
import { IApiState, IObjectTypeState } from "@/models";
import { ApiDataObjectTypeDropdown } from "./ApiDataObjectTypeDropdown.component";
import Vue from "vue";
import { PublishingStateEnum } from "@/api/common-types";

@Component
export class ApiDataObjectTypeTabButton extends Vue {
  _tsx!: tsx.DeclareProps<{
    typeName: ApiDataObjectTypeTabButton["typeName"];
    isSelected: ApiDataObjectTypeTabButton["isSelected"];
    role: ApiDataObjectTypeTabButton["role"];
    apiState?: ApiDataObjectTypeTabButton["apiState"];
  }> &
    tsx.DeclareOnEvents<{
      onClick: void;
      onUpdateSelectedObjectType: IObjectTypeState;
    }>;
  $refs!: {
    container: HTMLDivElement;
    dropdown?: Dropdown;
  };

  @Prop({
    default: () => ({}),
  })
  apiState: IApiState;
  @Prop({ required: true }) private typeName: string;
  @Prop({ default: false }) private isSelected: boolean;
  @Prop({ required: true }) private role: string;

  @Emit("click") onClick() {}
  @Emit("updateSelectedObjectType") onUpdateSelectedObjectType(
    _objectType: IObjectTypeState
  ) {}

  private containerElement: HTMLDivElement | null = null;

  mounted() {
    this.containerElement = this.$refs.container;
  }

  updated() {
    this.containerElement = this.$refs.container;
  }

  public render() {
    return this.isSelected ? (
      <div
        class={[styles.selectedButtonContainer]}
        role={this.role}
        ref="container"
        data-testid={`objectTypeTable${this.typeName}`}
      >
        {this.typeName}
        {this.apiState.state !== PublishingStateEnum.IMPORTED && (
          <ApiDataObjectTypeDropdown
            containerElement={this.containerElement}
            apiState={this.apiState}
            typeName={this.typeName}
            onUpdateSelectedObjectType={this.onUpdateSelectedObjectType}
          />
        )}
      </div>
    ) : (
      <button
        class={styles.unselectedButton}
        onClick={this.onClick}
        role={this.role}
      >
        {this.typeName}
      </button>
    );
  }
}
