import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Modal from "../shared/Modal.component";
import Tabs from "../shared/Tabs.component";
import style from "./ApiManagementCloudFunctionsModal.component.module.scss";
import { FormDropzone } from "../forms/inputs/FormDropzone.component";
import * as tsx from "vue-tsx-support";
import { Button, ButtonThemeEnum } from "../shared/Button.component";
import { BAlert, IBModalEvents } from "@/lib/typed-bootstrap";
import { getErrorMessage, readFileToString } from "@/utils";
import { Loader } from "../shared/Loader.component";
import {
  CustomerApiCloudFunctions,
  ICustomerListVariables,
} from "@/api-client";
import { RuntimeEnum } from "@graphapi-io/api-declaration";

export const CLOUD_FUNCTION_MODAL_ID = "cloudFuntionUploadModal";
export const NODEJS_RUNTIME_DEFINITION = {
  text: "Node.js v18",
  runtime: RuntimeEnum.NODEJS_18_X,
};
export const CLOUD_FUNCTION_RUNTIMES: {
  [key: string]: { text: string; runtime: RuntimeEnum };
} = {
  cjs: NODEJS_RUNTIME_DEFINITION,
  mjs: NODEJS_RUNTIME_DEFINITION,
  js: NODEJS_RUNTIME_DEFINITION,
  py: {
    text: "Python v3.10",
    runtime: RuntimeEnum.PYTHON_3_10,
  },
  rb: {
    text: "Ruby v3.2",
    runtime: RuntimeEnum.RUBY_3_2,
  },
  go: {
    text: "Go v1",
    runtime: RuntimeEnum.GO_1_X,
  },
};

@Component
export default class ApiManagementCloudFunctionsModal extends Vue {
  _tsx!: tsx.DeclareProps<{
    initialFiles?: ApiManagementCloudFunctionsModal["initialFiles"];
    customerApiCloudFunctions: ApiManagementCloudFunctionsModal["customerApiCloudFunctions"];
    initialVariables: ApiManagementCloudFunctionsModal["initialVariables"];
  }> &
    tsx.DeclareOnEvents<IBModalEvents>;
  @Prop({ default: null }) initialFiles: File[] | null;
  @Prop({ required: true })
  customerApiCloudFunctions: CustomerApiCloudFunctions;
  @Prop({ required: true }) initialVariables: ICustomerListVariables;

  private files: File[] | null = null;
  private isUploadingFiles = false;
  private errorMessage = "";

  @Watch("initialFiles", { immediate: true })
  private onFilesChange(files: File[] | null) {
    this.files = files;
    this.errorMessage = "";
  }

  private handleHidden() {
    this.files = null;
    this.$emit("hidden");
    this.errorMessage = "";
  }

  private getRuntimeForFileName(fileName: string) {
    const fileNameElements = fileName.split(".");
    const fileExtension = fileNameElements[fileNameElements.length - 1];
    return (
      CLOUD_FUNCTION_RUNTIMES[fileExtension] ?? CLOUD_FUNCTION_RUNTIMES["js"]
    );
  }

  private async handleSubmit() {
    if (!this.files) return;

    try {
      this.isUploadingFiles = true;
      await Promise.all(
        this.files.map(async (inputFile) => {
          const fileString = await readFileToString(inputFile);
          const result =
            await this.customerApiCloudFunctions.uploadCloudFunction(
              this.initialVariables,
              fileString,
              inputFile.name,
              inputFile.type,
              this.getRuntimeForFileName(inputFile.name).runtime
            );

          return result;
        })
      );

      this.$bvModal.hide(CLOUD_FUNCTION_MODAL_ID);
    } catch (e) {
      this.errorMessage = getErrorMessage(e);
    } finally {
      this.isUploadingFiles = false;
    }
  }

  private onInvalidFormat() {
    this.errorMessage = this.$t("form_dropzone.invalid_format").toString();
  }

  render() {
    return (
      <Modal
        id={CLOUD_FUNCTION_MODAL_ID}
        on={{
          ...this.$listeners,
          hidden: this.handleHidden,
        }}
        scopedSlots={{
          body: ({ cancel }) => (
            <Tabs currentTabIndex={0} class={style.tabs}>
              <Tabs.Tab
                id="upload"
                title={this.$t("global_cta_upload").toString()}
              >
                <BAlert
                  show={!!this.errorMessage}
                  variant="danger"
                  data-testid="apiSettingsFormError"
                >
                  {this.errorMessage}
                </BAlert>
                {!this.files ? (
                  <FormDropzone
                    id="cloudFunctionUpload"
                    onFileUpload={this.onFilesChange}
                    onInvalidFormat={this.onInvalidFormat}
                    accept=".js,.mjs,.cjs,.py,.rb"
                  />
                ) : (
                  <div class={style.filesContainer}>
                    <div class={style.filesHeader}>
                      {this.$t("api_details_cloud_functions_title")}
                    </div>
                    <div class={style.files}>
                      {this.files.map((file) => (
                        <div>
                          <div key={file.name} class={style.fileName}>
                            {file.name}
                          </div>
                          <div class={style.runtime}>
                            {this.getRuntimeForFileName(file.name).text}
                          </div>
                        </div>
                      ))}
                    </div>
                    <Modal.ActionButtons class={style.actionButtons}>
                      <Button
                        onClick={cancel}
                        class={style.button}
                        theme={ButtonThemeEnum.outline}
                      >
                        {this.$t("global_cta_cancel")}
                      </Button>
                      <Button
                        theme={ButtonThemeEnum.primary}
                        class={style.button}
                        onClick={this.handleSubmit}
                        data-testid="uploadCloudFunction"
                      >
                        {this.isUploadingFiles ? (
                          <Loader />
                        ) : (
                          this.$t("global_cta_upload")
                        )}
                      </Button>
                    </Modal.ActionButtons>
                  </div>
                )}
              </Tabs.Tab>
            </Tabs>
          ),
        }}
      />
    );
  }
}
