import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import FormGroupWithLabel from "../forms/inputs/FormGroupWithLabel.component";
import { FormInputWithAction } from "../forms/inputs/FormInputWithAction.component";
import plusIcon from "@/assets/img/plus-icon-placeholder.png";
import FormInput from "../forms/inputs/FormInput.component";
import { BButton, IBFormInputEvents } from "@/lib/typed-bootstrap";
import style from "./DataEntryFormParentInput.component.module.scss";
import { upperCaseFirstLetter } from "@/utils";
@Component
export class DataEntryFormParentInput extends Vue {
  _tsx!: tsx.DeclareProps<
    Pick<
      DataEntryFormParentInput,
      | "value"
      | "label"
      | "id"
      | "labelAmendment"
      | "inputType"
      | "placeholderText"
      | "buttonDisabled"
      | "shouldValidate"
    >
  > &
    tsx.DeclareOnEvents<
      {
        onParentClick: void;
      } & IBFormInputEvents
    >;

  @Prop({ required: true }) value: string;
  @Prop({ required: true }) label: string;
  @Prop({ required: true }) id: string;
  @Prop({ required: true }) labelAmendment: string;
  @Prop({ required: true }) inputType: string;
  @Prop({ required: true }) placeholderText: string;
  @Prop({ default: false }) buttonDisabled: boolean;
  @Prop({ default: false }) shouldValidate: boolean;

  @Emit("parentClick")
  handleParentClick() {}

  render() {
    const {
      label,
      value,
      id,
      inputType,
      labelAmendment,
      placeholderText,
      buttonDisabled,
      shouldValidate,
    } = this;

    return (
      <FormGroupWithLabel
        label={label}
        labelAmendment={labelAmendment}
        id={id}
        inputType={inputType}
        upperCaseLabel={false}
      >
        <FormInputWithAction
          scopedSlots={{
            action: (className) => (
              <BButton
                onClick={this.handleParentClick}
                class={className}
                disabled={buttonDisabled}
                data-testid={`formInput${upperCaseFirstLetter(id)}-parent`}
              >
                <div class={style.linkIcon}>
                  <img src={plusIcon} alt="Parent object link" />
                </div>
              </BButton>
            ),
            input: (className) => (
              <FormInput
                id={id}
                on={this.$listeners}
                value={value}
                placeholderText={placeholderText}
                class={className}
                inputType={inputType}
                shouldValidate={shouldValidate}
              />
            ),
          }}
        ></FormInputWithAction>
      </FormGroupWithLabel>
    );
  }
}
