import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import {
  CognitoUser,
  CognitoUserSession,
  ISignUpResult,
} from "amazon-cognito-identity-js";
import store from "@/store/store";
import _omit from "lodash/omit";
import Vue from "vue";
import { IRoleState } from "@/models/user";
import {
  RoleTypeEnum,
  PermissionTypeEnum,
  TrustedEntityTypeEnum,
} from "@/api/common-types";

export interface UserAttributes {
  sub: string;
  email: string;
  email_verified: string;
  name: string;
  updated_at: string;
}

export interface IPermissionState {
  type: PermissionTypeEnum;
  entity: TrustedEntityTypeEnum;
}

export interface IUserState {
  email: string;
  isAuthenticated: boolean;
  userId: string;
  confirmed: boolean;
  accessToken: string;
  roles: { [orgId: string]: IRoleState };
}
@Module({ dynamic: true, store, name: "user", namespaced: true })
class User extends VuexModule implements IUserState {
  public email = "";
  public isAuthenticated = false;
  public userId = "";
  public confirmed = false;
  public accessToken = "";
  public roles: { [orgId: string]: IRoleState } = {};
  public isFirstTimeLoading = true;
  public isLoading = true;
  public authError = "";

  @Mutation
  private SET_SESSION(session: CognitoUserSession | null): void {
    const cognitoAccessToken = session?.getAccessToken();
    this.accessToken = cognitoAccessToken?.getJwtToken() ?? "";
    this.isAuthenticated = this.accessToken.length > 0;
  }

  @Mutation
  private SET_USER_ID(userId: string): void {
    this.userId = userId;
  }

  @Mutation
  private SET_CONFIRMED(confirmed: boolean): void {
    this.confirmed = confirmed;
  }

  @Mutation
  private SET_IS_AUTHENTICATED(authenticated: boolean): void {
    this.isAuthenticated = authenticated;
  }

  @Mutation
  private SET_EMAIL(email: string): void {
    this.email = email;
  }

  @Mutation
  private SET_ROLE(role: IRoleState): void {
    Vue.set(this.roles, role.orgId ?? "", role);
  }

  @Mutation
  private REMOVE_ROLE_FOR_ORG_ID(orgId: string): void {
    this.roles = _omit(this.roles, [orgId]);
  }

  @Mutation
  private SET_ROLES(roles: { [orgId: string]: IRoleState }) {
    this.roles = roles;
  }

  @Mutation
  private SET_ACCESS_TOKEN(token: string): void {
    this.accessToken = token;
  }

  @Mutation
  private SET_IS_LOADING(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private SET_IS_FIRST_TIME_LOADING(isFirstTimeLoading: boolean) {
    this.isFirstTimeLoading = isFirstTimeLoading;
  }

  @Mutation
  private SET_AUTH_ERROR(authError: string) {
    this.authError = authError;
  }

  @Action
  handleUserDataLoadingStarted() {
    this.SET_IS_LOADING(true);
  }

  @Action
  handleUserDataLoadingFinished() {
    this.SET_IS_FIRST_TIME_LOADING(false);
    this.SET_IS_LOADING(false);
  }

  @Action
  handleValidCredentials(credentials: {
    username: string;
    password: string;
  }): void {
    this.SET_EMAIL(credentials.username);
  }

  @Action
  handleSignupResponse(response: {
    credentials: { username: string; password: string };
    result: ISignUpResult;
  }): void {
    this.SET_EMAIL(response.credentials.username);
    this.SET_USER_ID(response.result.userSub ?? "");
    this.SET_CONFIRMED(response.result.userConfirmed);
    this.SET_AUTH_ERROR("");
  }

  @Action
  handleSignInResponse(response: {
    user: CognitoUser;
    session: CognitoUserSession;
    userAttributes: Record<string, string>;
  }) {
    this.SET_EMAIL(response.userAttributes.email);
    this.SET_USER_ID(response.userAttributes.sub);
    this.SET_SESSION(response.session);
  }

  @Action
  handleSignOut() {
    this.SET_EMAIL("");
    this.SET_ROLES({});
    this.SET_IS_AUTHENTICATED(false);
    this.SET_ACCESS_TOKEN("");
    this.SET_AUTH_ERROR("");
  }

  @Action
  handleRoleConnections(roles: IRoleState[]) {
    roles.forEach((role: IRoleState) => {
      this.SET_ROLE(role);
    });
  }

  @Action
  handleEmailUpdate(email: string) {
    this.SET_EMAIL(email);
  }

  @Action
  handleDeletionForOrgWithId(orgId: string) {
    this.REMOVE_ROLE_FOR_ORG_ID(orgId);
  }

  @Action
  async onUserAction(handler: () => Promise<void>) {
    this.handleUserDataLoadingStarted();
    await handler();
    this.handleUserDataLoadingFinished();
  }

  @Action
  handleSessionRefresh({
    session,
    userAttributes,
  }: {
    user: CognitoUser | null;
    session: CognitoUserSession | null;
    userAttributes: Record<string, string>;
  }) {
    this.SET_EMAIL(userAttributes.email ?? "");
    this.SET_USER_ID(userAttributes.sub ?? "");
    this.SET_SESSION(session);
    this.SET_AUTH_ERROR("");
  }

  get hasAdminPermissions() {
    return (orgId: string): boolean =>
      [RoleTypeEnum.ADMIN, RoleTypeEnum.OWNER, RoleTypeEnum.SUPERUSER].includes(
        this.roles[orgId]?.type
      );
  }

  get isSuperUser() {
    return (orgId: string): boolean =>
      [RoleTypeEnum.SUPERUSER].includes(this.roles[orgId]?.type);
  }

  @Action
  handleAuthError(authError: string) {
    this.SET_AUTH_ERROR(authError);
    this.SET_IS_AUTHENTICATED(false);
  }

  @Action
  resetAuthError() {
    this.SET_AUTH_ERROR("");
  }
}

export const UserModule = getModule(User);

export type IUserModule = typeof UserModule;
