import { ITableField } from "./types";

export class TableEditingState {
  public isEditing: boolean = false;
  public shouldReplaceContent: boolean = false;
  private readOnlyFieldsSet: Record<number, boolean> = {};

  constructor(fields: ITableField[]) {
    fields.forEach((field, index) => {
      this.readOnlyFieldsSet[index] = field.readOnly ?? false;
    });
  }

  public isReadOnly(index: number) {
    return this.readOnlyFieldsSet[index];
  }
}
