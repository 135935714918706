import { Vue, Component, Prop } from "vue-property-decorator";
import { PublishingStateEnum } from "@/api/common-types";
import { IEnumState, IEnumValueState } from "@/models";
import { v4 as uuid4 } from "uuid";
import * as tsx from "vue-tsx-support";

import FormEnumWidget from "@/components/forms/FormEnumWidget.component.vue";
import { IEnumNameBlurEvent } from "./FormEnumWidget.component";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import plusIconBlack from "@/assets/img/plus-icon.svg";
import plusIconWhite from "@/assets/img/plus-icon-light.svg";

@Component({
  components: { FormEnumWidget, ThemedImage },
})
export default class FormEnumList extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: FormEnumList["value"];
    allowManagingTypes?: FormEnumList["allowManagingTypes"];
  }> &
    tsx.DeclareOnEvents<{
      onSelectEnumType: void;
      onEnumNameChange: IEnumNameBlurEvent;
    }>;
  @Prop({ required: true }) value: IEnumState[];
  @Prop({ required: false, default: true }) allowManagingTypes: boolean;

  errorMessage = "";

  plusIconSrc = {
    light: plusIconBlack,
    dark: plusIconWhite,
  };

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  get selectedEnumType(): IEnumState | null {
    return this.value?.filter((enumType) => {
      return enumType.selected;
    })[0];
  }

  resetSelection() {
    this.value.map((enumType) => {
      enumType.selected = false;
    });
  }

  createNewEnumType(): IEnumState {
    const uuid = `${uuid4()}`;
    const defaultValues: IEnumValueState[] = [
      {
        name: "",
        selected: false,
      },
    ];

    const newEnumType: IEnumState = {
      id: uuid,
      name: "",
      selected: true,
      values: defaultValues,
      state: PublishingStateEnum.INITIALIZED,
    };

    return newEnumType;
  }

  onSelectEnumType(enumType: IEnumState) {
    this.resetSelection();
    enumType.selected = true;
    this.$emit("selectEnumType");
  }

  selectedEnumTypeIsUnique(): boolean | null {
    const isDuplicate = this.value?.some((enumType) => {
      return (
        enumType.id !== this.selectedEnumType?.id &&
        enumType.name === this.selectedEnumType?.name
      );
    });

    this.errorMessage = isDuplicate ? "duplicate value" : "";

    return isDuplicate ? false : null;
  }

  onAddEnumType() {
    try {
      const isEmpty = this.value.length === 0;
      if (isEmpty || this.value[0]?.name?.length > 0) {
        this.resetSelection();
        this.value.unshift(this.createNewEnumType());
      }
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }
  }
}
