import { Vue, Component } from "vue-property-decorator";
import { envRootLocalizedDomain } from "@/utils";
import LangSelect from "./LangSelect.component.vue";
import { UIModule } from "@/store/modules/ui";

@Component({
  components: {
    LangSelect,
  },
})
export default class DefaultFooter extends Vue {
  get isLightTheme() {
    return UIModule.isLightTheme;
  }

  get year(): number {
    const date = new Date();
    return date.getFullYear();
  }

  get imprintUrl(): string {
    return `${envRootLocalizedDomain()}/imprint`;
  }

  get privacyUrl(): string {
    return `${envRootLocalizedDomain()}/privacy`;
  }

  get termsUrl(): string {
    return `${envRootLocalizedDomain()}/terms`;
  }

  toggleTheme() {
    UIModule.toggleTheme();
  }
}
