import {
  rolesByOrgQuery,
  roleIdsByOrgQuery,
  deleteRoleMutation,
} from "@/api/role";
import { SimplifyType, UnwrappedPromise } from "@/utils/types";
import {
  extractData,
  extractAndFilterItemsOrProvideDefault,
  extractPropOrThrow,
} from "./utils";
import _chunk from "lodash/chunk";

export interface IRolePermissionMappableProperty {
  permissionConnection?: { items?: (unknown | null)[] | null } | null;
}

export type IRoleByOrgState = SimplifyType<
  UnwrappedPromise<ReturnType<typeof getRolesByOrg>>[number]
>;

export const getRolesByOrg = async (orgId: string) => {
  const response = await rolesByOrgQuery({ orgId });

  return extractAndFilterItemsOrProvideDefault(
    extractPropOrThrow(extractData(response), "listRolesByOrg")
  ).map(mapRolePermissionConnection);
};

export const getAllRoleIdsByOrg = async (orgId: string) => {
  const result: string[] = [];
  let nextToken: string | undefined | null;

  do {
    const response = await roleIdsByOrgQuery({ orgId, nextToken });
    const data = extractPropOrThrow(extractData(response), "listRolesByOrg");
    nextToken = data.nextToken;

    result.push(
      ...extractAndFilterItemsOrProvideDefault(data).map((role) => role.id)
    );
  } while (nextToken);

  return result;
};

export const deleteAllRoles = async (roleIds: string[]) => {
  const batches: string[][] = _chunk(roleIds, 1);

  while (batches.length > 0) {
    const nextBatch = batches.pop();
    if (!nextBatch) return;
    await deleteRoleMutation({ input: { id: nextBatch[0] } });
  }
};

export const mapRolePermissionConnection = <
  T extends IRolePermissionMappableProperty
>({
  permissionConnection,
  ...rest
}: T) => {
  type IPermissionConnection = T["permissionConnection"];
  type IPermissionConnectionItem = NonNullable<
    NonNullable<T["permissionConnection"]>["items"]
  >[number];

  return {
    ...rest,
    permissions: extractAndFilterItemsOrProvideDefault<
      IPermissionConnection,
      IPermissionConnectionItem
    >(permissionConnection),
  };
};
