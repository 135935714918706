import {
  ICreateSubscriptionItemMutation,
  ICreateSubscriptionItemMutationVariables,
} from "@/generated/types";
import { mutationFunctionFactory } from "../internal-utils";
import CREATE_BUNDLE_SUBSCRIPTION from "./create_subscription_item.graphql";

export const createSubscriptionItemsMutation = mutationFunctionFactory<
  ICreateSubscriptionItemMutation,
  ICreateSubscriptionItemMutationVariables
>(CREATE_BUNDLE_SUBSCRIPTION);
