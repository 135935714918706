import { gql } from "apollo-boost";
import {
  FieldNameComposer,
  FieldParameterTypeEnum,
} from "../shared/fieldname-composer";
import { GenericApiVariableParser } from "./generic-api-variables-parser";

export abstract class GqlGenericQueryComposer {
  public static buildObjectQuery(typeName: string, namespace: string) {
    const ofType = GenericApiVariableParser.normalizedTypeName(typeName);
    return gql`
      query type($id: ID!) {
        type(id: $id, ofType: "${ofType}", namespace: "${namespace}") {
          id
          createdAt
          updatedAt
          fields
        }
      }
    `;
  }

  public static buildListQuery(typeName: string, namespace: string) {
    return gql`
      query listTypes($limit: Int, $nextToken: String, $filter: ${FieldNameComposer.inputNameForType(
        FieldParameterTypeEnum.FILTER,
        "Type"
      )}, $sortOrder: SortOrderType) {
        listTypes(ofType: "${GenericApiVariableParser.normalizedTypeName(
          typeName
        )}", namespace: "${namespace}", limit: $limit, nextToken: $nextToken, filter: $filter, sortOrder: $sortOrder) {
          nextToken
          items {
            id
            createdAt
            updatedAt
            fields
          }
        }
      }
    `;
  }

  public static buildListByQuery(
    typeName: string,
    parentName: string,
    namespace: string
  ) {
    return gql`
      query listTypesByParentType($limit: Int, $nextToken: String, $filter: ${FieldNameComposer.inputNameForType(
        FieldParameterTypeEnum.FILTER,
        "Type"
      )}, $parentId: String!, $parentKey: ParentKey!, $sortOrder: SortOrderType) {
        listTypesByParentType(ofType: "${GenericApiVariableParser.normalizedTypeName(
          typeName
        )}", namespace: "${namespace}", limit: $limit, nextToken: $nextToken, filter: $filter, parentType: "${GenericApiVariableParser.normalizedTypeName(
      parentName
    )}", parentId: $parentId, parentKey: $parentKey, sortOrder: $sortOrder) {
          nextToken
          items {
            id
            createdAt
            updatedAt
            fields
          }
        }
      }
    `;
  }
}
