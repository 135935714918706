import { render, staticRenderFns } from "./DefaultFooter.component.html?vue&type=template&id=3495e396&scoped=true&external"
import script from "./DefaultFooter.component.ts?vue&type=script&lang=ts&external"
export * from "./DefaultFooter.component.ts?vue&type=script&lang=ts&external"
import style0 from "./DefaultFooter.component.scss?vue&type=style&index=0&id=3495e396&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3495e396",
  null
  
)

export default component.exports