import { Component, Prop, Vue } from "vue-property-decorator";
import style from "./TableActionButton.component.module.scss";
import * as tsx from "vue-tsx-support";
import { ElementAttrs } from "vue-tsx-support/types/base";
import {
  ButtonHTMLAttributes,
  ElementType,
  SyntheticEvent,
} from "vue-tsx-support/types/dom";

@Component
export default class TableActionButton extends Vue {
  _tsx!: tsx.DeclareProps<
    ElementAttrs<ElementType<HTMLButtonElement, ButtonHTMLAttributes>> & {
      isActive?: TableActionButton["isActive"];
    }
  > &
    tsx.DeclareOnEvents<{
      onClick: SyntheticEvent<HTMLButtonElement, MouseEvent>;
    }>;

  @Prop({ default: false }) isActive: boolean;

  public get buttonRef(): HTMLButtonElement {
    return this.$refs.button as HTMLButtonElement;
  }

  render() {
    return (
      <button
        on={this.$listeners}
        class={[style.actionButton, { [style.active]: this.isActive }]}
        ref="button"
      >
        {this.$slots.default}
      </button>
    );
  }
}
