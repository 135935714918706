import { Component, Emit, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";

import { HeadlineSeparator } from "../shared/HeadlineSeparator.component";
import { IFieldConfig } from "./fields-configuration";
import DataEntryFormChildInput from "./DataEntryFormChildInput.component";
import { IObjectStackPushEvent } from "./types";
import Vue from "vue";
import { IGraphqlApi } from "@/api-client/shared/types";
import { IObjectTypeState } from "@/models";

export interface ITargetClickEvent {
  id: string;
  typeName: string;
}

@Component
export default class DataEntryFormTargetFields extends Vue {
  _tsx!: tsx.DeclareProps<{
    parentObjectType: DataEntryFormTargetFields["parentObjectType"];
    targetFields: DataEntryFormTargetFields["targetFields"];
    dataInput: DataEntryFormTargetFields["dataInput"];
    customerApi: DataEntryFormTargetFields["customerApi"];
    objectTypes: DataEntryFormTargetFields["objectTypes"];
  }> &
    tsx.DeclareOnEvents<{
      onChildClick: IObjectStackPushEvent;
    }>;
  @Prop({ required: true }) parentObjectType: IObjectTypeState;
  @Prop({ required: true }) dataInput: any;
  @Prop({ required: true }) targetFields: IFieldConfig[];
  @Prop({ required: true }) customerApi: IGraphqlApi;
  @Prop({ required: true }) readonly objectTypes: IObjectTypeState[];

  @Emit("childClick") onChildClick(_ev: IObjectStackPushEvent) {}

  private childObjectTypeFromFieldName(fieldName: string) {
    const typeName = fieldName.replace(/Connection/gi, "");
    return this.objectTypes.find(
      (t) => t.name.toLowerCase() === typeName.toLowerCase()
    );
  }

  private get parentId() {
    return this.dataInput[this.parentObjectType.connector ?? "id"];
  }

  render() {
    return (
      <div>
        <div class="right-sidebar__headline">
          {this.$t("object_view_children_fields")}
        </div>
        <HeadlineSeparator />
        {this.targetFields.map((field) => (
          <div>
            {this.childObjectTypeFromFieldName(field.name) && (
              <DataEntryFormChildInput
                id={`data-${field.name}`}
                childObjectType={this.childObjectTypeFromFieldName(field.name)!}
                parentObjectType={this.parentObjectType}
                parentId={this.parentId}
                key={field.key}
                onConnectionClick={this.onChildClick}
                customerApi={this.customerApi}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}
