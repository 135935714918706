import { PublishingStateEnum } from "@/api/common-types";
import { idFromObjectName } from "@/utils";
import {
  ArchiveTypeEnum,
  MutationTypeEnum,
  PipelineStepEnum,
  RuntimeEnum,
} from "@graphapi-io/api-declaration";

export interface IFieldState {
  id: string;
  name: string;
  type: string | null;
  directives?: string[];
  typeId?: string;
  readonly?: boolean;
  selected?: boolean;
  state?: PublishingStateEnum;
}

export interface ITargetState {
  targetId: string | null;
  connectionIndex: number;
}

export interface IParentState {
  parentId: string | null;
  connectionIndex: number;
}

export interface IResolverState {
  name: string;
  archive: string;
  archiveType: ArchiveTypeEnum.S3BUCKET;
  handler: string;
  step: PipelineStepEnum;
  types: MutationTypeEnum[];
  actions: string[];
  runtime?: RuntimeEnum;
}

export interface IObjectTypeState {
  id: string;
  name: string;
  usePrefix?: boolean;
  connector?: string;
  targets: ITargetState[];
  parents: {
    first: IParentState | null;
    second: IParentState | null;
  };
  fields: IFieldState[];
  selected: boolean;
  apiId?: string;
  state?: PublishingStateEnum;
  directives: string[];
  resolvers: IResolverState[];
}

export interface IConnectedTypeState {
  id: string | null;
  cardinality: ConnectionCardinalityEnum;
  index: number;
}

export enum ConnectionCardinalityEnum {
  IS_PARENT_OF = "parent",
  IS_CHILD_OF = "child",
}

export const isObjectTypeNameReserved = (name: string) =>
  ["Activity", "ApiKey", "Subscription", "Query", "Mutation"].includes(name);

export enum BaseFieldTypeEnum {
  Boolean = "Boolean",
  Float = "Float",
  ID = "ID",
  Integer = "Integer",
  String = "String",
}

export enum ListFieldTypeEnum {
  ListBoolean = "ListBoolean",
  ListFloat = "ListFloat",
  ListId = "ListId",
  ListInteger = "ListInteger",
  ListString = "ListString",
}

export enum ComplexFieldTypeEnum {
  Email = "Email",
  IpAddress = "IpAddress",
  Json = "Json",
  Phone = "Phone",
  Url = "Url",
}

export enum DateFieldTypeEnum {
  Date = "Date",
  DateTime = "DateTime",
  Time = "Time",
  Timestamp = "Timestamp",
}

export type IFieldType =
  | BaseFieldTypeEnum
  | ListFieldTypeEnum
  | ComplexFieldTypeEnum
  | DateFieldTypeEnum;

export const nonSortableFieldTypes = [
  BaseFieldTypeEnum.Float,
  ListFieldTypeEnum.ListFloat,
] as const;

export const isListFieldType = (type: string) =>
  Object.values(ListFieldTypeEnum).includes(type as ListFieldTypeEnum);

export const isBooleanFieldType = (type: string) =>
  type === BaseFieldTypeEnum.Boolean;

export function connectorFieldStateFromParent(parent: IObjectTypeState) {
  const connectorFieldName = parent.connector ?? "id";
  const connectorType =
    parent.fields.find((field) => field.name === connectorFieldName)?.type ??
    "ID";

  return {
    type: connectorType,
    name: idFromObjectName(parent.name, connectorFieldName),
  };
}
