import { OrgModule } from "@/store/modules/org";
import { v4 as uuid4 } from "uuid";
import QUERY_ROLES_BY_ORG from "./query_roles_by_org.graphql";
import QUERY_ROLE_IDS_BY_ORG from "./query_role_ids_by_org.graphql";
import CREATE_ROLE from "./create_role.graphql";
import UPDATE_ROLE from "./update_role.graphql";
import DELETE_ROLE from "./delete_role.graphql";
import _get from "lodash/get";
import _set from "lodash/set";
import _remove from "lodash/remove";
import {
  apolloClient,
  queryFunctionFactory,
  mutationFunctionFactory,
} from "../internal-utils";
import { MetadataObj } from "../common-types";
import {
  IDeleteRoleMutation,
  IDeleteRoleMutationVariables,
  IQueryRoleIdsByOrgQuery,
  IQueryRoleIdsByOrgQueryVariables,
  IQueryRolesByOrgQuery,
  IQueryRolesByOrgQueryVariables,
  IUpdateRoleMutation,
  IUpdateRoleMutationVariables,
} from "@/generated/types";
import { isNotNullable } from "@/utils";

export const rolesByOrgQuery = queryFunctionFactory<
  IQueryRolesByOrgQuery,
  IQueryRolesByOrgQueryVariables
>(QUERY_ROLES_BY_ORG);

export const roleIdsByOrgQuery = queryFunctionFactory<
  IQueryRoleIdsByOrgQuery,
  IQueryRoleIdsByOrgQueryVariables
>(QUERY_ROLE_IDS_BY_ORG);

export const deleteRoleMutation = mutationFunctionFactory<
  IDeleteRoleMutation,
  IDeleteRoleMutationVariables
>(DELETE_ROLE, undefined, undefined, (cache, _, input) => {
  try {
    const data = cache.readQuery({
      query: QUERY_ROLES_BY_ORG,
      variables: { orgId: OrgModule.id },
    });

    const items = _get(data, "listRolesByOrg.items");
    if (items) {
      _remove(items, { id: input?.input.id });
    }

    cache.writeQuery({
      query: QUERY_ROLES_BY_ORG,
      variables: { orgId: OrgModule.id },
      data: data,
    });
  } catch (err) {
    console.log(err);
  }
});

export const createRoleMutation = (input: any) => {
  input.orgId = input.orgId ?? OrgModule.id;
  const dateNow = new Date(Date.now()).toISOString();

  return apolloClient.mutate({
    mutation: CREATE_ROLE,
    variables: { input },
    update: (cache, mutationResult) => {
      try {
        const newRole = mutationResult?.data?.createRole;
        const data = cache.readQuery({
          query: QUERY_ROLES_BY_ORG,
          variables: { orgId: input.orgId },
        });
        const items = _get(data, "listRolesByOrg.items", []);
        const extendedData = _set(data as MetadataObj, "listRolesByOrg.items", [
          newRole,
          ...items,
        ]);
        cache.writeQuery({
          query: QUERY_ROLES_BY_ORG,
          variables: { orgId: input.orgId },
          data: extendedData,
        });
      } catch (err) {
        console.log(err);
      }
    },
    optimisticResponse: {
      __typename: "Mutation",
      createRole: {
        __typename: "Role",
        id: `${uuid4()}`,
        type: input.type,
        userEmail: input.userEmail,
        orgId: input.orgId,
        createdAt: dateNow,
        updatedAt: dateNow,
        lastSignInAt: dateNow,
        permissionConnection: [],
      },
    },
  });
};

export const updateRoleMutation = (
  variables: IUpdateRoleMutationVariables,
  orgId?: string,
  updateRolesByOrgCache = false
) => {
  const { input } = variables;

  return apolloClient.mutate<IUpdateRoleMutation>({
    mutation: UPDATE_ROLE,
    variables,
    update: (cache, mutationResult) => {
      if (!orgId || !updateRolesByOrgCache) return;
      const updatedRole = mutationResult?.data?.updateRole;

      try {
        const data = cache.readQuery<IQueryRolesByOrgQuery>({
          query: QUERY_ROLES_BY_ORG,
          variables: { orgId },
        });

        if (!data) {
          throw new Error(
            `corrupted cache for QUERY_ROLES_BY_ORG for org: ${orgId}`
          );
        }

        const updatedItems = (data.listRolesByOrg?.items ?? [])
          .filter(isNotNullable)
          .map((item) => (item.id === input.id ? updatedRole : item));

        const updatedData = _set(data, "listRolesByOrg.items", updatedItems);

        cache.writeQuery({
          query: QUERY_ROLES_BY_ORG,
          variables: { orgId },
          data: updatedData,
        });
      } catch (err) {
        console.log(err);
      }
    },
  });
};
