import { updateApiMutation } from "@/api/api";
import { IApiState, declarationSchemaForInput } from "@/models";
import Vue from "vue";
import { getErrorMessage } from "@/utils";
import Component from "vue-class-component";

@Component
export default class ApiTypesUpdater extends Vue {
  protected serverSideErrors: string = "";
  protected isLoading: boolean = false;

  protected async updateApiDeclarationFromState(
    apiData: IApiState
  ): Promise<void> {
    try {
      this.isLoading = true;
      this.serverSideErrors = "";
      apiData.schema = declarationSchemaForInput(apiData);
      await updateApiMutation({ id: apiData.id, schema: apiData.schema });
    } catch (err) {
      this.serverSideErrors = getErrorMessage(err);
    } finally {
      this.isLoading = false;
    }
  }
}
