import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import _get from "lodash/get";
import { lowerCaseFirstLetter, upperCaseFirstLetter } from "@/utils";
import * as tsx from "vue-tsx-support";

import { DataEntryFormParentInput } from "./DataEntryFormParentInput.component";

import { HeadlineSeparator } from "../shared/HeadlineSeparator.component";
import DataEntryFormFields, { IFieldConfig } from "./fields-configuration";
import { IObjectStackPushEvent } from "./types";
import Vue from "vue";
import { BaseFieldTypeEnum, IObjectTypeState } from "@/models";

@Component
export default class DataEntryFormParentFields extends Vue {
  _tsx!: tsx.DeclareProps<{
    dataInput: DataEntryFormParentFields["dataInput"];
    parentConnectionFields: DataEntryFormParentFields["parentConnectionFields"];
    prevObjectTypeName: DataEntryFormParentFields["prevObjectTypeName"];
    prevObjectId: DataEntryFormParentFields["prevObjectId"];
    objectType: DataEntryFormFields["objectType"];
    shouldCreateNewEntry: DataEntryFormParentFields["shouldCreateNewEntry"];
    objectTypes: DataEntryFormParentFields["objectTypes"];
  }> &
    tsx.DeclareOnEvents<{
      onParentClick: IObjectStackPushEvent;
    }>;
  @Prop({ required: true }) dataInput: any;
  @Prop({ required: true }) parentConnectionFields: IFieldConfig[];
  @Prop({ required: true }) prevObjectTypeName: string | null;
  @Prop({ required: true }) prevObjectId: string | null;
  @Prop({ default: false }) shouldCreateNewEntry: boolean;
  @Prop({ required: true }) objectTypes: IObjectTypeState[];

  @Emit("parentClick") onParentClick(_ev: IObjectStackPushEvent) {}

  private handleParentLinkClick(key: string) {
    const recordId = _get(this.dataInput, key);
    const parentName = upperCaseFirstLetter(key.replace("Id", ""));
    const parentId =
      this.objectTypes.find((object) => object.name === parentName)?.id ?? "";
    this.onParentClick({
      recordId,
      objectTypeName: parentName,
      objectTypeId: parentId,
    });
  }

  get parentInitProps() {
    return {
      shouldCreateNewEntry: this.shouldCreateNewEntry,
      prevObjectTypeName: this.prevObjectTypeName,
      prevObjectId: this.prevObjectId,
    };
  }

  @Watch("parentInitProps", { immediate: true }) onParentInitPropsChange() {
    if (this.prevObjectTypeName && this.prevObjectId) {
      const fieldName = `${lowerCaseFirstLetter(this.prevObjectTypeName)}Id`;
      if (fieldName in this.dataInput) {
        this.dataInput[fieldName] = this.prevObjectId;
      }
    }
  }

  render() {
    return (
      <div>
        <div class="right-sidebar__headline">
          {this.$t("object_view_parentid_fields")}
        </div>
        <HeadlineSeparator />
        {this.parentConnectionFields.map((field) => (
          <div>
            <DataEntryFormParentInput
              buttonDisabled={
                !this.dataInput[field.name] ||
                field.type !== BaseFieldTypeEnum.ID
              }
              onParentClick={() => this.handleParentLinkClick(field.name)}
              value={this.dataInput[field.name]}
              onInput={(newVal) => {
                this.dataInput[field.name] = newVal;
              }}
              label={field.name}
              labelAmendment={field.labelAmendment}
              id={`data-${field.name}`}
              key={field.key}
              shouldValidate={field.isMandatory}
              placeholderText={field.placeholder}
              inputType={field.inputType}
            ></DataEntryFormParentInput>
          </div>
        ))}
      </div>
    );
  }
}
