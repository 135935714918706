import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import FormTextarea from "../forms/inputs/FormTextarea.component";
import { OrgModule } from "@/store/modules/org";
import UpdateOrg from "./UpdateOrg.mixin";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import ErrorHandling from "@/mixins/ErrorHandling.mixin";

@Component({
  components: {
    FormTextarea,
  },
})
export default class DataProcessingAgreementForm extends mixins(
  UpdateOrg,
  UserPermissions,
  ErrorHandling
) {
  dataCategories: string = "";
  subjectCategories: string = "";
  isLoading = false;

  get dataProcessingCategories() {
    return OrgModule.dataProcessingCategories
      ? {
          ...OrgModule.dataProcessingCategories,
        }
      : {
          dataCategories: "",
          subjectCategories: "",
        };
  }

  @Watch("dataProcessingCategories", { immediate: true })
  onDataProcessingCategories() {
    this.reset();
  }

  get showAuthCancelSaveButtons() {
    if (!this.canManageOrg) return false;

    return (
      this.dataCategories !== this.dataProcessingCategories.dataCategories ||
      this.subjectCategories !== this.dataProcessingCategories.subjectCategories
    );
  }

  reset() {
    this.dataCategories = this.dataProcessingCategories.dataCategories || "";
    this.subjectCategories =
      this.dataProcessingCategories.subjectCategories || "";
  }

  async onSave() {
    try {
      this.isLoading = true;
      await this.updateOrg(() => ({
        id: OrgModule.id,
        dataProcessingCategories: JSON.stringify({
          dataCategories: this.dataCategories,
          subjectCategories: this.subjectCategories,
        }),
      }));
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isLoading = false;
    }
  }
}
