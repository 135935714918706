import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { ICellProps, renderCell } from "./Cell.component";
import SelectInput from "../../SelectInput.component";
import styles from "./OptionsCell.component.module.scss";
import { ICellDataContent } from "../types";
import { IFormSelectEntryState } from "@/components/object-type/ObjectTypeField.component";
import { Element } from "vue-tsx-support/types/base";

@Component
export default class OptionsCell extends Vue {
  _tsx!: tsx.DeclareProps<{
    cellState: OptionsCell["cellState"];
    value: OptionsCell["value"];
    rawValue: OptionsCell["rawValue"];
    options?: OptionsCell["options"];
  }> &
    tsx.DeclareOnEvents<{
      onCellChange: string | number;
    }>;

  @Prop({ required: true }) cellState: ICellProps;
  @Prop({ required: true, default: null }) value: Element | string | undefined;
  @Prop({ required: true }) rawValue: ICellDataContent;
  @Prop({ default: () => [] }) options: IFormSelectEntryState[];

  private internalValue: string | undefined = "";

  @Watch("value", { immediate: true })
  onValueChange() {
    this.internalValue = this.rawValue?.toString();
  }

  private handleChange(value: string) {
    this.internalValue = value;
    const newValue = this.internalValue;
    this.$emit("cellChange", newValue);
  }

  private get hackySizePlaceholder() {
    return this.value ?? "A";
  }

  render() {
    return renderCell(this.$createElement, {
      props: {
        ...this.cellState,
        contentClass: styles.content,
      },
      data: {
        class: {
          [styles.editCell]: true,
        },
      },
      children: (
        <span class={styles.wrapper}>
          <span class={styles.widthPlaceholder} aria-hidden="true">
            {this.hackySizePlaceholder}
          </span>
          <SelectInput
            class={styles.input}
            value={this.internalValue}
            options={this.options}
            autoFocus={true}
            onInput={this.handleChange}
          />
        </span>
      ),
    });
  }
}
