import ApolloClient, {
  ApolloQueryResult,
  DocumentNode,
  ErrorPolicy,
  FetchPolicy,
  MutationUpdaterFn,
  OperationVariables,
  PresetConfig,
} from "apollo-boost";
import Observable from "zen-observable";

type Config = Required<Pick<PresetConfig, "onError" | "request">>;

const config: Config = {
  onError(): void {
    throw new Error("Apollo Client onError handler not configured");
  },
  request() {
    throw new Error("Apollo Client onRequest handler not configured");
  },
};

export const updateApiClientConfig = (newConfig: Config) => {
  config.onError = newConfig.onError;
  config.request = newConfig.request;
};

export const apolloClient = new ApolloClient({
  uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
  request: (...args) => {
    return config.request(...args);
  },
  onError: (...args) => {
    return config.onError(...args);
  },
});

export const queryFunctionFactory =
  <T, K = undefined>(
    graphqlQuery: DocumentNode,
    errorPolicy?: ErrorPolicy,
    defaultFetchPolicy?: FetchPolicy
  ) =>
  (variables?: K, fetchPolicy?: FetchPolicy) =>
    apolloClient.query<T>({
      query: graphqlQuery,
      ...(variables && { variables }),
      ...((fetchPolicy || defaultFetchPolicy) && {
        fetchPolicy: fetchPolicy || defaultFetchPolicy,
      }),
      ...(errorPolicy && { errorPolicy }),
    });

export const mutationFunctionFactory =
  <T, K = undefined>(
    graphqlMutation: DocumentNode,
    errorPolicy?: ErrorPolicy,
    defaultFetchPolicy?: FetchPolicy,
    updateCache?: (
      ...params: [...Parameters<MutationUpdaterFn<T>>, K | undefined]
    ) => ReturnType<MutationUpdaterFn<T>> | undefined
  ) =>
  (variables?: K, fetchPolicy?: FetchPolicy) =>
    apolloClient.mutate<T>({
      mutation: graphqlMutation,
      ...(variables && { variables }),
      ...((fetchPolicy || defaultFetchPolicy) && {
        fetchPolicy: fetchPolicy || defaultFetchPolicy,
      }),
      ...(errorPolicy && { errorPolicy }),
      update: (proxy, mutationResult) =>
        updateCache?.(proxy, mutationResult, variables),
    });

export const watchQueryFunctionFactory = <T, K extends OperationVariables>(
  query: DocumentNode,
  variables: K
) => {
  const queryObservable = apolloClient.watchQuery<T>({
    query,
    variables,
  });

  return {
    rawObservable: queryObservable,
    observable: new Observable<ApolloQueryResult<T>>((observer) => {
      const subscription = queryObservable.subscribe({
        next: (result) => {
          if (result.errors) {
            observer.error(new Error(result.errors[0].message));
          }

          observer.next(result);
        },
        error: (error) => {
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });

      queryObservable.refetch();

      return () => {
        subscription.unsubscribe();
      };
    }),
  };
};

export const watchListObjectsFactory =
  <IQueryResult, IQueryVariables extends OperationVariables, IQueryItem>(
    query: DocumentNode,
    resultGetter: (data: IQueryResult) => {
      items: IQueryItem[];
      nextToken: string | null;
    },
    resultSetter: (updatedResult: {
      items: IQueryItem[];
      nextToken: string | null;
    }) => IQueryResult
  ) =>
  (variables: IQueryVariables) => {
    const { observable, rawObservable } = watchQueryFunctionFactory<
      IQueryResult,
      IQueryVariables
    >(query, variables);

    return {
      observable,
      refetch: (variables: IQueryVariables) =>
        rawObservable.refetch({ variables }),
      fetchMore: (variables: IQueryVariables) =>
        rawObservable.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            const previousItems = resultGetter(previousResult).items;
            const newItems = resultGetter(fetchMoreResult).items;
            const nextToken = resultGetter(fetchMoreResult).nextToken;

            return resultSetter({
              items: [...previousItems, ...newItems],
              nextToken,
            });
          },
        }),
    };
  };
