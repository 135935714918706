import { Component, Emit, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import styles from "./ApiDataObjectTypeDataImport.component.module.scss";
import FileUploadModal from "@/components/shared/FileUploadModal.component";
import CsvSeparatorSelect from "@/views/apis/CsvSeparatorSelect.component";
import { Button } from "../shared/Button.component";
import { Loader } from "../shared/Loader.component";
import {
  batchesFromCsvEntries,
  csvReader,
  fieldFormatter,
  isNotNullable,
} from "@/utils";
import { ApiDataModule } from "@/store/modules/api-data";
import TableDataImport from "../shared/table/TableDataImport.component";
import Vue from "vue";
import FieldsConfiguration from "../object-view/fields-configuration";
import {
  FormNestedCheckbox,
  IFormNestedCheckboxState,
} from "../forms/inputs/FormNestedCheckbox.component";
import {
  BaseFieldTypeEnum,
  IFieldState,
  IObjectTypeState,
  connectorFieldStateFromParent,
} from "@/models/object-type/types";
import ShortUniqueId from "short-unique-id";
import ApiTypesUpdater from "../object-type/ApiTypesUpdater.mixin";
import ApiData from "../api-management/ApiData.mixin";
import { mixins } from "vue-class-component";
import _cloneDeep from "lodash/cloneDeep";
import { IGraphqlApi } from "@/api-client/shared/types";
import { SortOrderType } from "@/generated/types";

const FILE_MODAL_ID = "fileUploadModal";
const { randomUUID } = new ShortUniqueId({ length: 6 });

@Component
export default class ApiDataObjectTypeDataImport extends mixins(
  ApiData,
  ApiTypesUpdater
) {
  _tsx!: tsx.DeclareProps<{
    requiredCreateInputParams: ApiDataObjectTypeDataImport["requiredCreateInputParams"];
    customerApi?: ApiDataObjectTypeDataImport["customerApi"];
    objectType: ApiDataObjectTypeDataImport["objectType"];
    fieldsConfiguration: ApiDataObjectTypeDataImport["fieldsConfiguration"];
  }> &
    tsx.DeclareOnEvents<{ onFileSelected: File | File[] }>;
  $scopedSlots!: tsx.InnerScopedSlots<{
    actions: { onImport: () => void };
  }>;

  @Prop() private requiredCreateInputParams: string[];
  @Prop() private customerApi?: IGraphqlApi;
  @Prop() private objectType: IObjectTypeState;
  @Prop() private fieldsConfiguration: FieldsConfiguration;

  private unknownFields: IFormNestedCheckboxState[] = [];
  private fieldsToBeAdded: { [key: string]: IFieldState } = {};
  private defaultCsvSeparator = ",";
  private selectedCsvSeparator = this.defaultCsvSeparator;
  private csvSeparatorOptions = [
    { value: ",", text: "Comma" },
    { value: ";", text: "Semicolon" },
  ];
  private isImportingData = false;
  private uploadProgress = 0;

  file: File | null = null;

  @Emit("fileSelected") async onFileSelected(_file: File | File[]) {}

  private get activeObjectType() {
    return this.apiData?.types.find(
      (type) => type.name === this.objectType?.name
    );
  }

  private get allowedFields() {
    return [
      ...Object.values(this.activeObjectType?.parents ?? {})
        .map((parent) => {
          const parentType = this.apiData?.types.find(
            (type) => type.id === parent?.parentId
          );

          return parentType
            ? connectorFieldStateFromParent(parentType).name
            : undefined;
        })
        .filter(isNotNullable),
      ...(this.activeObjectType?.fields
        .filter(
          (field) =>
            !this.fieldsConfiguration.blacklistedKeys.includes(field.name) &&
            !this.fieldsConfiguration.readonlyKeys.includes(field.name)
        )
        .map((field) => {
          return field.name;
        }) ?? []),
    ];
  }

  private get forbiddenFields() {
    return [
      ...this.fieldsConfiguration.readonlyKeys,
      ...this.fieldsConfiguration.blacklistedKeys,
    ];
  }

  private async extractUnknownFields(_file: File | File[]) {
    try {
      this.file = Array.isArray(_file) ? _file[0] : _file;
      const csvFields = await this.getFieldsFromCsvFile(this.file);
      this.unknownFields = csvFields
        .filter(
          (e) =>
            this.forbiddenFields.indexOf(e) === -1 &&
            this.allowedFields.indexOf(e) === -1
        )
        .map((field) => {
          return {
            name: field,
            selected: false,
          };
        });
    } catch (err) {
      //
    }
  }

  private async getFieldsFromCsvFile(file: File) {
    const { fields } = await csvReader(file, this.selectedCsvSeparator, true);
    return fields;
  }

  private get activeFilters() {
    return ApiDataModule.activeFilters;
  }

  private onSelectSeparator(value: string) {
    this.selectedCsvSeparator = value;

    if (this.file) {
      this.extractUnknownFields(this.file);
    }
  }

  private async handleUnknownFieldSelectionChange(
    value: IFormNestedCheckboxState
  ) {
    if (value.selected) {
      this.fieldsToBeAdded[value.name] = {
        id: randomUUID(),
        name: fieldFormatter(value.name),
        type: BaseFieldTypeEnum.String,
      };
    } else {
      delete this.fieldsToBeAdded[value.name];
    }
  }

  private async updateApiDeclarationIfNecessary() {
    if (!this.apiData) return;

    const newState = _cloneDeep(this.apiData);
    const activeObjectType = newState.types.find(
      (type) => type.name === this.objectType?.name
    );
    if (!activeObjectType) {
      return;
    }

    Vue.set(activeObjectType, "fields", [
      ...activeObjectType.fields,
      ...Object.values(this.fieldsToBeAdded),
    ]);

    await this.updateApiDeclarationFromState(newState);
    if (!this.serverSideErrors) {
      this.fieldsToBeAdded = {};
      this.apiData.types = newState.types;
      if (this.customerApi && this.customerApi.apiSchema.apiDeclaration) {
        this.customerApi.apiSchema.apiDeclaration.types = newState.types;
      }
    }
  }

  private async handleSubmit(
    files: File[] | null,
    onSuccess: () => void,
    onError: (error: unknown) => void
  ) {
    const file = files?.[0];

    if (!file || !this.customerApi) {
      return;
    }

    try {
      await this.updateApiDeclarationIfNecessary();
      this.isImportingData = true;
      this.uploadProgress = 0;
      const { fields, entries } = await csvReader(
        file,
        this.selectedCsvSeparator
      );

      const batches = batchesFromCsvEntries(
        entries,
        fields,
        this.allowedFields,
        this.requiredCreateInputParams,
        25
      );
      const uploadErrors = [];
      while (batches.length > 0 && uploadErrors.length < 5) {
        const nextBatch = batches.pop();
        try {
          const progress = ((nextBatch?.length ?? 0) / entries.length) * 100;
          await this.customerApi.batchCreateObjects(
            this.objectType,
            { input: nextBatch ?? [] },
            this.customerApi.cacheUpdates.addToListFrontAfterBatchCreationFactory(
              this.objectType,
              {
                nextToken: null,
                limit: 100,
                filter: this.activeFilters,
                sortOrder: SortOrderType.DESC,
              }
            )
          );
          this.uploadProgress += progress;
        } catch (err) {
          if (
            (err as any).message.includes("provisioned throughput") &&
            (err as any).message.includes("exceeded") &&
            nextBatch
          ) {
            batches.push(nextBatch);
          } else {
            uploadErrors.push(err);
            break;
          }
        }
      }

      if (uploadErrors.length > 0) {
        throw uploadErrors[0];
      }
      onSuccess();
    } catch (err) {
      onError(err);
    } finally {
      this.isImportingData = false;
      this.uploadProgress = 0;
    }
  }

  render() {
    return (
      <TableDataImport
        modalId={FILE_MODAL_ID}
        accept=".csv"
        uploadProgress={this.uploadProgress}
        scopedSlots={{
          actions: (props) => this.$scopedSlots.actions?.(props),
          modal: ({ pastedSupportedFiles }) => (
            <FileUploadModal
              initialFiles={pastedSupportedFiles}
              accept=".csv"
              modalId={FILE_MODAL_ID}
              onFileSelected={this.extractUnknownFields}
              scopedSlots={{
                file: (props) => (
                  <div class={styles.file}>
                    <div class={styles.fileTitle}>
                      <div class={props.titleClassName}>{props.file.name}</div>
                      <div class={props.subtitleClassName}>
                        {this.$t("global_size").toString()}: {props.file.size}{" "}
                        bytes
                      </div>
                    </div>
                    <CsvSeparatorSelect
                      selectedAction={this.selectedCsvSeparator}
                      actions={this.csvSeparatorOptions}
                      onChange={this.onSelectSeparator}
                    />
                  </div>
                ),
                mappings: (props) => (
                  <div>
                    {this.unknownFields.length > 0 && (
                      <div>
                        <div class={props.alertClassName}>
                          {this.$t("form_file_mappings_title", {
                            type: this.objectType?.name,
                          })}
                        </div>
                        <div class={styles.mappings}>
                          {this.unknownFields.map((field) => {
                            return (
                              <div class={styles.field}>
                                <FormNestedCheckbox
                                  id={`${field.name}-Action`}
                                  value={field}
                                  onChange={
                                    this.handleUnknownFieldSelectionChange
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                ),
                submitButton: (props) => (
                  <Button
                    theme={props.theme}
                    class={props.className}
                    onClick={() =>
                      this.handleSubmit(
                        props.files,
                        props.onSuccess,
                        props.onError
                      )
                    }
                    data-testid="uploadCsvFile"
                  >
                    {this.isImportingData ? (
                      <Loader />
                    ) : (
                      this.$t("global_cta_upload")
                    )}
                  </Button>
                ),
              }}
            ></FileUploadModal>
          ),
        }}
      >
        {this.$slots.default}
      </TableDataImport>
    );
  }
}
