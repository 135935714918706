import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import style from "./ApiManagementSectionHeader.component.module.scss";
import { BRow } from "@/lib/typed-bootstrap";

@Component
export default class ApiManagementSectionHeader extends Vue {
  _tsx!: tsx.DeclareProps<{
    title?: ApiManagementSectionHeader["title"];
    description: ApiManagementSectionHeader["description"];
  }>;

  @Prop({ required: false }) title: string;
  @Prop({ required: true }) description: string;

  render() {
    return (
      <BRow class={style.headerSection}>
        {this.title && (
          <div class={style.header}>
            <span class={style.title}>{this.title}</span>
          </div>
        )}
        <div>
          <span class={style.description}>{this.description}</span>
        </div>
        {this.$slots.default}
      </BRow>
    );
  }
}
