import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store/store";
import type { IDataProcessingCategories, IOrgState } from "@/models/org";
import { persistanceService } from "@/utils/persistance-service";

export interface IOrgModuleState
  extends Omit<IOrgState, "billingAccount" | "dataProcessingCategories"> {
  billingAccount?: IOrgState["billingAccount"] | null;
  dataProcessingCategories?: IDataProcessingCategories | null;
}

@Module({ dynamic: true, store, name: "org", namespaced: true })
class Org extends VuexModule implements IOrgModuleState {
  id = persistanceService.getActiveOrg() ?? "";
  name = "";
  awsAccountId = "";

  billingAccount: IOrgState["billingAccount"] | null = {
    id: "",
    name: "",
    billingEmail: "",
    billingAddress: {
      country: "",
      city: "",
      line1: "",
      postalCode: "",
    },
    paymentProviderId: "",
  };

  dataProcessingCategories: IDataProcessingCategories | null = {
    dataCategories: "",
    subjectCategories: "",
  };

  isLoading = true;

  get isReady() {
    return this.id?.length > 0;
  }

  get synth() {
    return (): IOrgModuleState => ({
      id: this.id,
      name: this.name,
      awsAccountId: this.awsAccountId,
      dataProcessingCategories: this.dataProcessingCategories,
      billingAccount: this.billingAccount,
    });
  }

  @Mutation
  SET_ID(id: string): void {
    this.id = id;
    persistanceService.setActiveOrg(id);
  }

  @Mutation
  SET_NAME(name: string): void {
    this.name = name;
  }

  @Mutation
  SET_BILLING_ACCOUNT(
    billingAccount: IOrgState["billingAccount"] | null
  ): void {
    this.billingAccount = billingAccount;
  }

  @Mutation
  SET_AWS_ACCOUNT_ID(awsAccountId: string): void {
    this.awsAccountId = awsAccountId;
  }

  @Mutation
  SET_DATA_PROCESSING_CATEGORIES(
    dataProcessingCategories: IDataProcessingCategories | null
  ): void {
    this.dataProcessingCategories = dataProcessingCategories;
  }

  @Mutation
  SET_IS_LOADING(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Action
  initializeOrg(orgId: string) {
    this.SET_ID(orgId);
  }

  @Action
  handleBillingAccountUpdate(billingAccount: IOrgState["billingAccount"]) {
    this.SET_BILLING_ACCOUNT(billingAccount);
  }

  @Action
  handleOrgLoadingStarted() {
    this.SET_IS_LOADING(true);
  }

  @Action
  handleOrgCreation(data: IOrgState) {
    this.SET_ID(data.id);
    this.SET_AWS_ACCOUNT_ID(data.awsAccountId ?? "");
    this.SET_NAME(data.name ?? "");
    this.SET_DATA_PROCESSING_CATEGORIES(data.dataProcessingCategories ?? null);
    this.SET_BILLING_ACCOUNT(data.billingAccount ?? null);
    this.SET_IS_LOADING(false);
  }

  @Action
  handleQueryResponse(data: IOrgState) {
    this.SET_ID(data.id);
    this.SET_AWS_ACCOUNT_ID(data.awsAccountId ?? "");
    this.SET_NAME(data.name ?? "");
    this.SET_DATA_PROCESSING_CATEGORIES(data.dataProcessingCategories ?? null);
    this.SET_BILLING_ACCOUNT(data.billingAccount ?? null);
    this.SET_IS_LOADING(false);
  }

  @Action
  reset() {
    this.SET_ID("");
    this.SET_AWS_ACCOUNT_ID("");
    this.SET_NAME("");
    this.SET_BILLING_ACCOUNT(null);
    this.SET_DATA_PROCESSING_CATEGORIES(null);
    this.SET_IS_LOADING(false);
  }
}

export const OrgModule = getModule(Org);

export type IOrgModule = typeof OrgModule;
