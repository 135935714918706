import * as tsx from "vue-tsx-support";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import styles from "./Plate.component.module.scss";

@Component
export default class Plate extends Vue {
  _tsx!: tsx.DeclareProps<{
    anchor: Plate["anchor"];
    topOffset?: Plate["topOffset"];
    leftOffset?: Plate["leftOffset"];
    horizontalPaddingMedia?: Plate["horizontalPaddingMedia"];
    plateClassName?: Plate["plateClassName"];
  }>;
  $refs!: {
    plate: HTMLDivElement | undefined;
  };

  private top: number = 0;
  private left: number = 0;
  private minWidth: number = 320;
  private minLeftPadding: number = 0;

  @Prop({ default: undefined }) anchor: HTMLElement | undefined;
  @Prop({ default: 0 }) topOffset: number;
  @Prop({ default: 0 }) leftOffset: number;
  @Prop({ default: () => ({}) }) horizontalPaddingMedia: Record<string, number>;
  @Prop() plateClassName?: string | (string | object)[] | undefined;

  mounted() {
    window.addEventListener("resize", this.onWindowResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  private updatePaddings() {
    for (const key in this.horizontalPaddingMedia) {
      if (window.matchMedia(key).matches) {
        this.minLeftPadding = this.horizontalPaddingMedia[key];
      }
    }
  }

  private onWindowResize() {
    this.onAnchorUpdate();
  }

  @Watch("anchor", { immediate: true })
  onAnchorUpdate() {
    if (this.anchor) {
      this.updatePaddings();
      const rects = this.anchor.getBoundingClientRect();
      this.top = rects.top + rects.height + this.topOffset;
      this.left = Math.min(
        window.innerWidth - this.minWidth - this.minLeftPadding,
        rects.left + this.leftOffset
      );
    }
  }

  render() {
    const className = this.plateClassName;

    return (
      <portal to="modals">
        <div
          ref="plate"
          class={[
            styles.plate,
            ...(Array.isArray(className) ? className : [className]),
          ]}
          style={{ transform: `translate(${this.left}px,${this.top}px)` }}
        >
          {this.$slots.default}
        </div>
      </portal>
    );
  }
}
