import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { ICellProps, renderCell } from "./Cell.component";
import Input, { InputSizeEnum, InputThemeEnum } from "../../Input.component";
import styles from "./CellInEditMode.component.module.scss";
import { ICellDataContent } from "../types";
import { Element } from "vue-tsx-support/types/base";

@Component
export default class CellInEditMode extends Vue {
  _tsx!: tsx.DeclareProps<{
    cellState: CellInEditMode["cellState"];
    value: CellInEditMode["value"];
    rawValue: CellInEditMode["rawValue"];
    inputType?: CellInEditMode["inputType"];
    replaceModeEnabled?: CellInEditMode["replaceModeEnabled"];
  }> &
    tsx.DeclareOnEvents<{
      onCellChange: string | number;
    }>;

  @Prop({ required: true }) cellState: ICellProps;
  @Prop({ required: true }) value: Element | string | undefined;
  @Prop({ required: true }) rawValue: ICellDataContent;
  @Prop({ default: "text" }) inputType: string;
  @Prop({ default: false }) replaceModeEnabled: boolean;

  private internalValue: string = "";

  mounted() {
    if (this.replaceModeEnabled) {
      this.handleChange("");
    }
  }

  @Watch("value", { immediate: true })
  onValueChange() {
    const value = Array.isArray(this.rawValue)
      ? this.rawValue.join(", ")
      : this.rawValue?.toString() ?? "";
    this.internalValue = value;
  }

  private handleKeyDown(ev: KeyboardEvent) {
    if (ev.key === "Enter") {
      ev.stopPropagation();
      const newValue =
        Array.isArray(this.rawValue) && typeof this.internalValue === "string"
          ? this.internalValue.split(",").map((v) => v.trim())
          : this.internalValue;
      this.$emit("cellChange", newValue);
    }
  }

  private handleChange(value: string) {
    this.internalValue = value;
  }

  private get hackySizePlaceholder() {
    return this.value ?? "A";
  }

  render() {
    return renderCell(this.$createElement, {
      props: {
        ...this.cellState,
        contentClass: styles.content,
      },
      data: {
        class: {
          [styles.editCell]: true,
        },
      },
      children: (
        <span class={styles.wrapper}>
          <span class={styles.widthPlaceholder} aria-hidden="true">
            {this.hackySizePlaceholder}
          </span>
          <Input
            class={styles.input}
            inputSize={InputSizeEnum.sm}
            inputTheme={InputThemeEnum.transparent}
            inputType={this.inputType}
            value={this.internalValue}
            onInput={this.handleChange}
            autoFocus={true}
            onKeydown={this.handleKeyDown}
          />
        </span>
      ),
    });
  }
}
