import {
  createCustomerAppMutation,
  updateCustomerAppMutation,
  deleteCustomerAppMutation,
  watchCustomerAppQuery,
  watchCustomerAppsByApiIdQuery,
} from "@/api/customer-app";
import {
  extractAndFilterItemsOrProvideDefault,
  extractData,
  extractPropOrThrow,
  parseJsonStringProp,
} from "./utils";
import {
  ICreateCustomerAppMutationVariables,
  IDeleteCustomerAppMutationVariables,
  IListCustomerAppsByApiIdQuery,
  IQueryCustomerAppQueryVariables,
  IQueryListCustomerAppsByApiArgs,
  IUpdateCustomerAppMutationVariables,
} from "@/generated/types";
import { ApolloQueryResult } from "apollo-boost";
import Observable from "zen-observable";
import { IAppDeclaration } from "@graphapi-io/customer-components";

const parseCustomerApp = <T extends { schema?: string }>(customerApp: T) =>
  parseJsonStringProp<T, "schema", IAppDeclaration>(customerApp, "schema");

const parseCustomerAppList = (
  value: ApolloQueryResult<IListCustomerAppsByApiIdQuery>
) => {
  const customerAppsByApi = extractPropOrThrow(
    extractData(value),
    "listCustomerAppsByApi"
  );
  return {
    ...customerAppsByApi,
    items: extractAndFilterItemsOrProvideDefault(customerAppsByApi),
  };
};

export function watchCustomerAppsByApiId(
  variables: IQueryListCustomerAppsByApiArgs
) {
  const { observable, ...rest } = watchCustomerAppsByApiIdQuery(variables);

  return {
    observable: observable.map(parseCustomerAppList),
    ...rest,
  };
}

export async function createCustomerApp(
  input: ICreateCustomerAppMutationVariables["input"]
) {
  const result = await createCustomerAppMutation({ input });
  const mutationResult = extractPropOrThrow(
    extractPropOrThrow(result, "data"),
    "createCustomerApp"
  );

  return mutationResult;
}

export async function updateCustomerApp(
  input: Omit<IUpdateCustomerAppMutationVariables["input"], "schema"> & {
    schema?: IAppDeclaration;
  }
) {
  const result = await updateCustomerAppMutation({
    input: {
      ...input,
      schema: input.schema ? JSON.stringify(input.schema) : undefined,
    },
  });
  const mutationResult = extractPropOrThrow(
    extractPropOrThrow(result, "data"),
    "updateCustomerApp"
  );

  return mutationResult;
}

export async function deleteCustomerApp(
  input: Omit<IDeleteCustomerAppMutationVariables["input"], "schema"> & {
    schema?: IAppDeclaration;
  }
) {
  const result = await deleteCustomerAppMutation({ input });
  const mutationResult = extractPropOrThrow(
    extractPropOrThrow(result, "data"),
    "deleteCustomerApp"
  );

  return mutationResult;
}

export function watchCustomerApp(variables: IQueryCustomerAppQueryVariables) {
  return watchCustomerAppQuery(variables)
    .observable.map(extractData)
    .map((value) => extractPropOrThrow(value, "customerApp"))
    .map((value) => parseCustomerApp(value));
}

export type ICustomerApp = ReturnType<
  typeof watchCustomerApp
> extends Observable<infer T>
  ? T
  : never;
