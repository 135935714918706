import { UIModule } from "@/store/modules/ui";
import { Component, Prop, Vue } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";

export interface IThemedSrc {
  light: string;
  dark: string;
}

@Component
export class ThemedImage extends Vue {
  _tsx!: tsx.DeclareProps<{
    src: ThemedImage["src"];
    alt?: ThemedImage["alt"];
    title?: ThemedImage["title"];
  }>;

  @Prop({ required: true }) src: IThemedSrc;
  @Prop({ default: "" }) alt: string;
  @Prop({ default: "" }) title: string;

  get resolvedSrc() {
    return UIModule.isLightTheme ? this.src?.light : this.src?.dark;
  }

  render() {
    return <img src={this.resolvedSrc} alt={this.alt} title={this.title} />;
  }
}
