import { BSpinner } from "@/lib/typed-bootstrap";
import { Vue, Component, Prop } from "vue-property-decorator";
import style from "./ProgressStep.component.module.scss";
import * as tsx from "vue-tsx-support";

export interface IProgressStepState {
  label?: string;
  labelKey: string;
  inProgress: boolean;
  done: boolean;
  failed: boolean;
}

@Component
export default class ProgressStep extends Vue {
  _tsx!: tsx.DeclareProps<{
    step: ProgressStep["step"];
    stepIndex: ProgressStep["stepIndex"];
    numOfSteps: ProgressStep["numOfSteps"];
    showSpinner?: ProgressStep["showSpinner"];
  }>;
  @Prop({ required: true }) step: IProgressStepState;
  @Prop({ required: true }) stepIndex: number;
  @Prop({ required: true }) numOfSteps: number;
  @Prop({ required: false, default: false }) showSpinner: boolean;

  get label() {
    return this.$t(this.step.labelKey) ?? "";
  }

  get inProgress(): boolean {
    return this.step.inProgress;
  }

  get done(): boolean {
    return this.step.done;
  }

  get failed(): boolean {
    return this.step.failed;
  }

  get isLastStep(): boolean {
    return this.stepIndex === this.numOfSteps - 1;
  }

  render() {
    return (
      <div class={style.progressStep}>
        <div class={style.progressStepInfo}>
          <div
            class={{
              [style.progressStepBg]: true,
              [style.inProgress]: this.inProgress,
              [style.showSpinner]: this.showSpinner,
              [style.done]: this.done,
              [style.failed]: this.failed,
            }}
          >
            {!this.showSpinner && (
              <div class={style.progressStepIndex}>{this.stepIndex + 1}</div>
            )}
            {this.inProgress && !this.failed && (
              <div class={["d-flex", style.progressSpinner]} role="status">
                {this.showSpinner && (
                  <BSpinner
                    class="ml-auto"
                    label="Prepare..."
                    small
                    show={false}
                  />
                )}
              </div>
            )}
          </div>
          <span
            class={{
              [style.progressStepCaption]: true,
              [style.inProgress]: this.inProgress,
              [style.done]: this.done,
              [style.failed]: this.failed,
            }}
          >
            {this.label}
          </span>
        </div>
        <div
          class={{
            [style.progressFill]: true,
            [style.done]: this.done,
            [style.failed]: this.failed,
            [style.hidden]: this.isLastStep,
          }}
        ></div>
      </div>
    );
  }
}
