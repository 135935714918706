import { IObjectTypeState } from "../object-type";

export interface IActivityResponseMappableProps {
  id: string;
  eventName?: string | null;
  eventCreatedAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  ofType?: string | null;
  namespace?: string | null;
}

export const getTablenameForType = (
  typeId: string | null | undefined,
  objectTypes: IObjectTypeState[]
) => {
  const type = objectTypes.find(
    (t) =>
      t.id?.toLocaleLowerCase() === typeId || t.name?.toLowerCase() === typeId
  );
  return type?.name ?? "";
};

const i18nPathForActivity = <T extends IActivityResponseMappableProps>(
  activity: T
) => {
  const activityTypeLocalizationMap: { [key: string]: string } = {
    ["INSERT"]: "activity_insert_string",
    ["MODIFY"]: "activity_modify_string",
    ["REMOVE"]: "activity_remove_string",
  };

  return activity.eventName
    ? activityTypeLocalizationMap[activity.eventName]
    : "activity_modify_string";
};

export const parseActivity = <T extends IActivityResponseMappableProps>(
  activity: T
) => ({
  ...activity,
  activity: i18nPathForActivity(activity),
});

export const mapItemsResponseToActivities = <
  T extends IActivityResponseMappableProps
>(
  activities: T[]
) => {
  return activities.map((a) => parseActivity(a));
};

export type IActivityState = ReturnType<typeof parseActivity>;
