import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import styles from "./Tabs.component.module.scss";
import * as tsx from "vue-tsx-support";

interface IButtonProps {
  role: string;
  index: number;
  "aria-controls": string;
  "aria-selected": string;
  class: string;
  onClick: () => void;
  title?: string;
}

@Component
export class Tab extends Vue {
  _tsx!: tsx.DeclareProps<{
    title: Tab["title"];
    id: Tab["id"];
  }>;

  @Prop({ required: true }) title: string;
  @Prop({ required: true }) id: string;

  render() {
    return (
      <div
        role="tabpanel"
        aria-labeldby={`tab-${this.id}`}
        class={styles.tabPanel}
      >
        {this.$slots.default}
      </div>
    );
  }
}

@Component
export default class Tabs extends Vue {
  _tsx!: tsx.DeclareProps<{
    currentTabIndex: Tabs["currentTabIndex"];
    buttonsScrollContainerClassName?: string;
    outerContainerClassName?: string;
    lazy?: boolean;
  }> &
    tsx.DeclareOnEvents<{
      onTabIndexChange: number;
    }>;

  static Tab = Tab;

  $scopedSlots: tsx.InnerScopedSlots<{
    button?: IButtonProps;
  }>;

  @Prop({ required: true }) currentTabIndex: number;
  @Prop({ default: false }) lazy: boolean;
  @Prop({ default: "" }) buttonsScrollContainerClassName: string;
  @Prop({ default: "" }) outerContainerClassName: string;
  @Emit("tabIndexChange") onTabIndexChange(_index: number) {}

  public get buttonsContainer() {
    return this.$refs.buttonsContainer as HTMLElement | undefined;
  }

  private renderDefaultButton(props: IButtonProps) {
    return (
      <button
        role={props.role}
        key={props.index}
        aria-controls={props["aria-controls"]}
        aria-selected={props["aria-selected"]}
        class={styles.button}
        onClick={props.onClick}
      >
        {props.title}
      </button>
    );
  }

  render() {
    return (
      <div class={styles.tabs}>
        <div class={[styles.outerContainer, this.outerContainerClassName]}>
          <div class={styles.innerContainer}>
            <div
              class={[
                styles.buttonsScrollContainer,
                this.buttonsScrollContainerClassName,
              ]}
              role="tablist"
              ref="buttonsContainer"
            >
              {this.$slots.beforeButtons}
              {this.$slots.default?.map((tab, index) => {
                const propsData = (tab.componentOptions?.propsData ?? {}) as {
                  id?: string;
                  title?: string;
                };
                const onClick = () => this.onTabIndexChange(index);
                const ariaControls = `panel-${propsData.id}`;
                const ariaSelected = `${index === this.currentTabIndex}`;
                const props: IButtonProps = {
                  role: "tab",
                  index,
                  "aria-controls": ariaControls,
                  "aria-selected": ariaSelected,
                  class: styles.button,
                  onClick,
                  title: propsData.title,
                };

                return this.$scopedSlots.button
                  ? this.$scopedSlots.button(props)
                  : this.renderDefaultButton(props);
              })}
              {this.$slots.afterButtons}
            </div>
          </div>
          {this.$slots.afterInnerContainer}
        </div>
        {this.$slots.default?.map((tab, index) => {
          if (tab.componentOptions) {
            const isActive = index === this.currentTabIndex;
            if (!isActive && this.lazy) {
              return null;
            }
            return this.$createElement(
              tab.componentOptions.Ctor,
              {
                ...tab.data,
                props: {
                  ...tab.componentOptions.propsData,
                  active: isActive,
                },
                attrs: {
                  ...tab.data?.attrs,
                  "aria-hidden": !isActive,
                },
                on: {
                  ...tab.componentOptions.listeners,
                },
              },
              tab.componentOptions.children
            );
          } else {
            return tab;
          }
        })}
      </div>
    );
  }
}
