import { Component, Emit, Prop } from "vue-property-decorator";
import Vue from "vue";
import style from "./PrevObjectView.component.module.scss";
import * as tsx from "vue-tsx-support";
import rightArrowDark from "@/assets/img/prev-icon-close.svg";
@Component
export class PrevObjectView extends Vue {
  _tsx!: tsx.DeclareProps<
    Pick<PrevObjectView, "typeName" | "name" | "onClick">
  >;

  @Prop({ default: false }) visible?: boolean;
  @Prop() typeName?: string;
  @Prop() name?: string;

  @Emit("click") onClick() {}
  @Emit("hidden") onHidden() {}

  render() {
    const { name, typeName } = this;

    return (
      <div class={style.prevObject}>
        <button class={style.bar} onClick={this.onClick}>
          <div class={style.header}>
            <img src={rightArrowDark} alt="back" class={style.backArrow}></img>
          </div>
          <div class={style.barContent}>
            <div class={style.type} data-testid="prevObjectTypeName">
              {typeName}
            </div>
            <div class={style.name} data-testid="prevObjectName">
              {name}
            </div>
          </div>
        </button>
      </div>
    );
  }
}
