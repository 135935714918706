import { SelectionVector } from "./selection-vector";
import _sortBy from "lodash/sortBy";
import _omit from "lodash/fp/omit";
import _set from "lodash/fp/set";
import _isEmpty from "lodash/isEmpty";

export class SelectionMatrix {
  private matrix: Record<number, SelectionVector> = {};

  public clear(): void {
    if (!_isEmpty(this.matrix)) {
      this.matrix = {};
    }
  }

  public deselect({ x, y }: { x: number; y: number }): void {
    this.matrix[y]?.delete(x);
    if (this.matrix[y]?.size === 0) {
      this.matrix = _omit(y, this.matrix);
    }
  }

  public deselectRow(y: number): void {
    this.matrix = _omit(y, this.matrix);
  }

  public deselectColumn(x: number): void {
    Object.values(this.matrix).forEach((vector) => vector.delete(x));
  }

  public has({ x, y }: { x: number; y: number }): boolean {
    return this.matrix[y]?.has(x) ?? false;
  }

  public getRow(y: number) {
    return this.matrix[y];
  }

  public hasAnySelectionInRow(y: number): boolean {
    return this.matrix[y] !== undefined;
  }

  public hasAnySelectionInColumn(x: number): boolean {
    return Object.values(this.matrix).some((vector) => vector.has(x));
  }

  public select({ x, y }: { x: number; y: number }): void {
    if (!this.matrix[y]) {
      this.matrix = _set(y, new SelectionVector(), this.matrix);
    }
    this.matrix[y].add(x);
  }

  public get size() {
    return Object.values(this.matrix).length;
  }

  public values() {
    return _sortBy(
      Object.entries(this.matrix).map(
        ([index, row]) => [parseInt(index), row] as const
      ),
      0
    ).flatMap(([rowIndex, row]) =>
      [...row.values()].map((colIndex) => [rowIndex, colIndex])
    );
  }
}
