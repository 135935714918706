import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store/store";
import { IFilterValueState } from "@/models/filters";

export interface IApiDataState {
  activeFilters: IFilterValueState | null;
}

@Module({ dynamic: true, store, name: "apiData", namespaced: true })
class ApiData extends VuexModule implements IApiDataState {
  activeFilters: IFilterValueState | null = null;

  @Mutation
  private SET_APPLIED_FILTERS(activeFilters: IFilterValueState) {
    this.activeFilters = activeFilters;
  }

  @Action
  setActiveFilters(activeFilters: IFilterValueState) {
    this.SET_APPLIED_FILTERS(activeFilters);
  }
}

export const ApiDataModule = getModule(ApiData);
