import { Vue, Component, Prop } from "vue-property-decorator";
import styles from "./InputLabelWrapper.component.module.scss";
import * as tsx from "vue-tsx-support";

export type IInputLabelWrapperProps = tsx.DeclareProps<{
  labelText: InputLabelWrapper["labelText"];
  id: InputLabelWrapper["id"];
}>;

@Component
export default class InputLabelWrapper extends Vue {
  _tsx!: IInputLabelWrapperProps;

  @Prop({ required: true }) labelText: string;
  @Prop({ required: true }) id: string;

  render() {
    return (
      <div>
        <label class={styles.label} for={this.id}>
          {this.labelText}
        </label>
        {this.$slots.default}
      </div>
    );
  }
}
