import { Vue, Component, Prop } from "vue-property-decorator";

import { BvModalEvent } from "bootstrap-vue";
import FormInputWithLabel from "../forms/inputs/FormInputWithLabel.component";
import Modal from "../shared/Modal.component";

export enum FormModalEditingType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

export enum FormModalInputTypeEnum {
  INT = "Int",
  FLOAT = "Float",
  STRING = "String",
}
export interface IFormModalInputState {
  type: FormModalInputTypeEnum;
  label: string;
  placeholderText: string;
  value: any;
  key: string;
  min?: number;
  max?: number;
}
@Component({
  components: {
    FormInputWithLabel,
    ModalButtonsSection: Modal.ActionButtons,
  },
})
export default class FormModal extends Vue {
  @Prop({ required: false, default: FormModalEditingType.CREATE })
  editMode: FormModalEditingType;
  @Prop({ required: true }) title: string;
  @Prop({ required: true }) formData: IFormModalInputState[];

  errorMessage = "";
  isLoading = false;
  modalId = "form-modal";

  get editingModeButtonLabel() {
    return this.editMode === FormModalEditingType.UPDATE
      ? this.$t("global_cta_save")
      : this.$t("global_cta_create");
  }

  get formTitle() {
    return this.$t("form_modal_title", {
      cta:
        this.editMode === FormModalEditingType.UPDATE
          ? this.$t("global_cta_save")
          : this.$t("global_cta_create"),
      title: this.title,
    });
  }

  get showErrorMessage() {
    return !!this.errorMessage;
  }

  isNumberField(inputType: FormModalInputTypeEnum): boolean {
    return (
      inputType === FormModalInputTypeEnum.INT ||
      inputType === FormModalInputTypeEnum.FLOAT
    );
  }

  async onSubmit(): Promise<void> {
    try {
      this.isLoading = true;
      this.$bvModal.hide(this.modalId);

      if (this.editMode === FormModalEditingType.UPDATE) {
        this.$emit("onUpdate");
      } else {
        this.$emit("onCreate");
      }
    } catch (error) {
      this.isLoading = false;
    }
  }

  resetModal(event: BvModalEvent): void {
    this.isLoading = false;
    this.errorMessage = "";

    if (event.type === "hidden") {
      this.$emit("onHidden");
    }
  }
}
