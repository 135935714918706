import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import style from "./TablePagination.component.module.scss";
import {
  BFormGroup,
  BPagination,
  getTypedBFormSelect,
} from "@/lib/typed-bootstrap";
import * as tsx from "vue-tsx-support";
import { IPaginationState } from "./types";

const BFormSelect = getTypedBFormSelect<number>();

const PER_PAGE_SELECT_ID = "perPageSelect";

@Component
export default class TablePagination extends Vue {
  _tsx!: tsx.DeclareProps<{
    paginationState: TablePagination["paginationState"];
    totalRows?: TablePagination["totalRows"];
    hasMore?: TablePagination["hasMore"];
  }> &
    tsx.DeclareOnEvents<{
      onUpdate: IPaginationState;
    }>;

  @Prop({ required: true }) paginationState: IPaginationState;
  @Prop({ required: true }) totalRows: number;
  @Prop({ required: true }) hasMore: boolean;

  @Emit("update") onUpdate(_state: IPaginationState) {}

  private get pageOptions() {
    return this.paginationState.pageSizes.map((size) => ({
      value: size,
      text: size.toString(),
    }));
  }

  private onPerPageChange(perPage: number) {
    this.onUpdate({
      perPage,
      currentPage: 1,
      pageSizes: this.paginationState.pageSizes,
    });
  }

  private onPageChange(currentPage: number) {
    this.onUpdate({
      perPage: this.paginationState.perPage,
      currentPage,
      pageSizes: this.paginationState.pageSizes,
    });
  }

  protected get expectedRows(): number {
    return this.totalRows + (this.hasMore ? this.paginationState.perPage : 0);
  }

  render() {
    return (
      <div class={style.container}>
        <BFormGroup
          label={this.$t("form_page_select_label").toString()}
          labelCols="auto"
          labelFor={PER_PAGE_SELECT_ID}
          class={style.perPageWrapper}
        >
          <BFormSelect
            class={style.perPageSelect}
            value={this.paginationState.perPage}
            onChange={this.onPerPageChange}
            id={PER_PAGE_SELECT_ID}
            options={this.pageOptions}
          />
        </BFormGroup>
        <BPagination
          value={this.paginationState.currentPage}
          onChange={this.onPageChange}
          totalRows={this.expectedRows}
          perPage={this.paginationState.perPage}
          hideGotoEndButtons
          class={style.pagination}
          prevText={this.$t("form_page_select_prev").toString()}
          nextText={this.$t("form_page_select_next").toString()}
        ></BPagination>
      </div>
    );
  }
}
