import { Vue, Component } from "vue-property-decorator";
import FormEmail from "@/components/forms/inputs/FormEmail.component.vue";
import { auth } from "@/utils/auth-service";

@Component({
  components: {
    FormEmail,
  },
})
export default class ResetPasswordForm extends Vue {
  email = "";
  errorMessage = "";
  signupCode = null;
  placeholderText = this.$t("global_email_placeholder") as string;
  isLoading = false;

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  async onSubmit(): Promise<void> {
    this.isLoading = true;

    try {
      await auth.forgotPassword(this.email);
      this.$router.push({
        name: "account-change-password",
        query: { email: this.email },
      });
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }

    this.isLoading = false;
  }
}
