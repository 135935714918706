import { LanguageCodeEnum, SchemaTemplateStateEnum } from "@/api/common-types";
import { schemaTemplatesQuery } from "@/api/schema-template";
import { UnwrappedPromise } from "@/utils/types";
import { parseDeclarationSchema } from "./api";
import { extractAndFilterItemsOrProvideDefault } from "./utils";

export const getSchemaTemplates = async (lang: LanguageCodeEnum) => {
  const response = await schemaTemplatesQuery({
    limit: 50,
    filter: {
      state: {
        eq: SchemaTemplateStateEnum.PUBLISHED,
      },
    },
    lang,
  });

  return extractAndFilterItemsOrProvideDefault(
    response.data?.listSchemaTemplates
  ).map((template) => ({
    ...template,
    schema: parseDeclarationSchema(undefined, template.schema),
    name: template.schemaTemplateLocalizationConnection?.items?.[0]?.name,
    description:
      template.schemaTemplateLocalizationConnection?.items?.[0]?.description,
  }));
};

export type ISchemaTemplate = UnwrappedPromise<
  ReturnType<typeof getSchemaTemplates>
>[number];
