import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import FormInputsRow from "../forms/inputs/FormInputsRow.component";
import {
  BFormGroup,
  getTypedBFormSelect,
  getTypedBFormSelectOption,
} from "@/lib/typed-bootstrap";
import style from "./ObjectTypeTrigger.component.module.scss";
import {
  IExtendedMutationType,
  ObjectTypeTriggerStepSelect,
} from "./ObjectTypeTriggerStepSelect.component";
import {
  IResolverState,
  toggleResolverTypeAll,
  togglResolverType,
} from "@/models";
import { ICloudFunction } from "@/api-client";
import _camelCase from "lodash/camelCase";
import {
  MutationTypeEnum,
  PipelineStepEnum,
} from "@graphapi-io/api-declaration";
import { CLOUD_FUNCTION_RUNTIMES } from "@/components/api-management/ApiManagementCloudFunctionsModal.component";
import { extractFunctionFileName } from "./utils";

const BFormSelect = getTypedBFormSelect<ICloudFunction>();
const BFormSelectOption = getTypedBFormSelectOption<ICloudFunction>();

@Component
export class ObjectTypeTrigger extends Vue {
  _tsx!: tsx.DeclareProps<{
    resolver: ObjectTypeTrigger["resolver"];
    cloudFunctions: ObjectTypeTrigger["cloudFunctions"];
    index: ObjectTypeTrigger["index"];
  }> &
    tsx.DeclareOnEvents<{
      onDelete: void;
    }>;

  @Prop({ required: true }) resolver: IResolverState;
  @Prop({ required: true }) index: number;
  @Prop({ required: true }) cloudFunctions: ICloudFunction[];

  @Emit("delete") onDelete() {}

  private onStepInput(value: PipelineStepEnum) {
    this.resolver.step = value;
  }

  private handleMutationTypesChange(change: IExtendedMutationType) {
    switch (change) {
      case "All":
        this.resolver.types = toggleResolverTypeAll(this.resolver.types);
        break;
      case MutationTypeEnum.CREATE:
      case MutationTypeEnum.UPDATE:
      case MutationTypeEnum.DELETE:
        this.resolver.types = togglResolverType(this.resolver.types, change);
        break;
      default:
        break;
    }
  }

  private handleCloudFunctionChange(cloudFunction: ICloudFunction) {
    const { name, extension } = extractFunctionFileName(cloudFunction.id);
    this.resolver.name = _camelCase(`${name}_${extension}`);
    this.resolver.archive = cloudFunction.id ?? "";
    this.resolver.handler = `${name}.handler`;
    this.resolver.runtime = (
      CLOUD_FUNCTION_RUNTIMES[extension] ?? CLOUD_FUNCTION_RUNTIMES["js"]
    ).runtime;
  }

  private get cloudFunctionsOptions() {
    return this.cloudFunctions.map((cloudFunction) => ({
      value: cloudFunction,
      text: cloudFunction.name,
    }));
  }

  private get selectedCloudFunction() {
    return (
      this.cloudFunctions.find(
        (cloudFunction) => cloudFunction.id === this.resolver.archive
      ) ?? null
    );
  }

  render() {
    return (
      <BFormGroup class={style.formGroup}>
        <FormInputsRow rightColLg="6">
          <template slot="leftColumn">
            <button onClick={this.onDelete} class={style.delete}></button>
            <BFormGroup class={style.nestedFormGroup}>
              <ObjectTypeTriggerStepSelect
                id={`trigger-${this.index}`}
                stepValue={this.resolver.step}
                onInput={this.onStepInput}
                onMutationTypesChange={this.handleMutationTypesChange}
                mutationTypes={this.resolver.types}
              />
            </BFormGroup>
          </template>
          <template slot="rightColumn">
            <BFormSelect
              class="default__form-input form-select__input"
              options={this.cloudFunctionsOptions}
              onChange={this.handleCloudFunctionChange}
              value={this.selectedCloudFunction}
            >
              <template slot="first">
                <BFormSelectOption value={null} disabled>
                  {this.$t("model_widget_select_cloud_function")}
                </BFormSelectOption>
              </template>
            </BFormSelect>
          </template>
        </FormInputsRow>
      </BFormGroup>
    );
  }
}
