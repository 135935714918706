import { Vue, Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import style from "./ItemChangesList.component.module.scss";
import { BCol, BRow } from "@/lib/typed-bootstrap";
import { IDiffConfig } from "./types";
import ItemChangesListItem from "./ItemChangesListItem.component";

@Component
export default class ItemChangesList extends Vue {
  _tsx!: tsx.DeclareProps<{
    headline?: ItemChangesList["headline"];
    changeItems: ItemChangesList["changeItems"];
  }>;
  @Prop({ required: false, default: "Types changes" })
  readonly headline: string;
  @Prop({ required: true }) changeItems: IDiffConfig;

  render() {
    return (
      <div>
        <BRow class={style.headline}>
          <BCol>
            <h3>{this.headline}</h3>
          </BCol>
        </BRow>
        {this.changeItems.map((changeItem, idx) => (
          <ItemChangesListItem
            diffRows={changeItem.diffRows}
            objectHasBeenCreated={changeItem.objectHasBeenCreated}
            objectHasBeenDeleted={changeItem.objectHasBeenDeleted}
            toTypeName={changeItem.toTypeName}
            fromTypeName={changeItem.fromTypeName}
            typeNameHasChanged={changeItem.typeNameHasChanged}
            key={`changeItem-${idx}`}
          />
        ))}
      </div>
    );
  }
}
