import * as tsx from "vue-tsx-support";
import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { AuthMethodTypeEnum } from "@/api/common-types";
import {
  IApiState,
  IEnumState,
  IObjectTypeState,
  createObjectTypeBase,
} from "@/models";
import ObjectTypeWidget from "@/components/object-type/ObjectTypeWidget.component";
import { ApolloClient } from "apollo-boost";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import styles from "./ObjectTypeList.component.module.scss";
import { BAlert, BCard, BCardText } from "@/lib/typed-bootstrap";
@Component
export default class ObjectTypeList extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: ObjectTypeList["value"];
    apiState: ObjectTypeList["apiState"];
    allEnumTypes: ObjectTypeList["allEnumTypes"];
    allowManagingTypes?: ObjectTypeList["allowManagingTypes"];
    apiAuthMode: ObjectTypeList["apiAuthMode"];
    errorMessage?: ObjectTypeList["errorMessage"];
    objectTypeWithDuplicate?: ObjectTypeList["objectTypeWithDuplicate"];
    objectTypeWithReservedType?: ObjectTypeList["objectTypeWithReservedType"];
    apiClient?: ObjectTypeList["apiClient"];
    apiSchemaHandler?: ObjectTypeList["apiSchemaHandler"];
  }> &
    tsx.DeclareOnEvents<{
      onAddEnumType: void;
      onAddObjectTypeField: void;
      onSelectObjectType: IObjectTypeState;
    }>;
  @Prop({ required: true }) value: IObjectTypeState[];
  @Prop({ required: true }) allEnumTypes: IEnumState[];
  @Prop({ required: true }) apiState: IApiState;
  @Prop({ required: false, default: true }) allowManagingTypes: boolean;
  @Prop({ required: true }) apiAuthMode: AuthMethodTypeEnum;
  @Prop() readonly errorMessage?: string;
  @Prop() objectTypeWithDuplicate?: IObjectTypeState;
  @Prop() objectTypeWithReservedType?: IObjectTypeState;
  @Prop({ required: false, default: null })
  apiClient: ApolloClient<unknown> | null;
  @Prop({ required: false, default: null })
  apiSchemaHandler: ApiSchemaHandler | null;

  @Emit("addEnumType") onAddEnumType() {}
  @Emit("addObjectTypeField") onAddObjectTypeField() {}

  $refs!: {
    slider: HTMLElement;
    objectCards: HTMLElement[];
  };

  itemWidth = 160;

  private currentTabIndex = 0;

  get selectedObjectType(): IObjectTypeState | null {
    return this.value?.filter((objectType) => {
      return objectType.selected;
    })[0];
  }

  get widgetComponentKey(): string {
    return `${this.selectedObjectType?.id}`;
  }

  get showErrorMessage() {
    return !!this.errorMessage;
  }

  @Watch("value")
  updateScrollPosition() {
    const selectedIndex = this.value?.findIndex((t) => t.selected);
    this.scrollToTypeWithIndex(selectedIndex);
  }

  mounted() {
    if (this.value?.length === 0) {
      this.value.push(createObjectTypeBase("", this.apiState.usePrefix));
    } else {
      this.updateScrollPosition();
    }
  }

  resetSelection() {
    this.value.map((objectType) => {
      objectType.selected = false;
    });
  }

  onSelectObjectType(objectType: IObjectTypeState) {
    this.resetSelection();
    objectType.selected = true;
    this.$emit("selectObjectType", objectType);
  }

  get selectedObjectTypeIsUnique() {
    return this.selectedObjectType?.name !== this.objectTypeWithDuplicate?.name;
  }

  get selectedObjectTypeIsReserved() {
    return (
      this.selectedObjectType?.name === this.objectTypeWithReservedType?.name
    );
  }

  scrollToTypeWithIndex(index?: number) {
    const cardElement = this.$refs.objectCards?.[index ?? 0]?.offsetLeft;
    const scrollOffset = cardElement ?? (index ?? 1) * this.itemWidth;
    const isVisible =
      scrollOffset >= this.$refs.slider.scrollLeft &&
      scrollOffset <=
        this.$refs.slider.scrollLeft + this.$refs.slider.offsetWidth;

    if (!isVisible) {
      setTimeout(() => {
        this.$refs.slider.scrollTo({
          top: 0,
          left: scrollOffset,
          behavior: "smooth",
        });
      }, 100);
    }
  }

  private onTabIndexChange(index: number) {
    this.currentTabIndex = index;
  }

  render() {
    return (
      <div class={styles.objectList}>
        <BAlert show={this.showErrorMessage} variant="danger">
          {this.errorMessage}
        </BAlert>
        <div class={styles.objectTypeCollection} ref="slider">
          {this.value?.map((objectType) => (
            <BCard
              class={["mr-2", styles.slider]}
              bodyClass={[
                styles.objectTypeBody,
                objectType.selected ? styles.isActive : "",
              ].join(" ")}
              key={`object-c-${objectType.id}`}
              onClick={() => this.onSelectObjectType(objectType)}
              ref="objectCards"
              data-testid={`objectTypeCard${objectType.name}`}
            >
              <BCardText class={styles.objectTypeText}>
                {objectType.name ? (
                  <div>{objectType.name}</div>
                ) : (
                  <div class={styles.placeholder}>
                    {this.$t("model_widget_model_tmp_name")}
                  </div>
                )}
              </BCardText>
              <div class={styles.objectTypeSummary}>
                {objectType.fields.length}{" "}
                {this.$t("model_widget_fields_label")}
              </div>
            </BCard>
          ))}
          <BCard class={[styles.scrollSpacer, "col-3", "col-md-2"]}></BCard>
        </div>
        {this.allowManagingTypes && this.selectedObjectType != null && (
          <ObjectTypeWidget
            value={this.selectedObjectType}
            onAddEnumType={this.onAddEnumType}
            onAddObjectTypeField={this.onAddObjectTypeField}
            class="p-1"
            key={`widget-${this.widgetComponentKey}`}
            isUnique={this.selectedObjectTypeIsUnique}
            isReserved={this.selectedObjectTypeIsReserved}
            allTypes={this.value}
            allEnumTypes={this.allEnumTypes}
            apiAuthMode={this.apiAuthMode}
            apiClient={this.apiClient}
            apiSchemaHandler={this.apiSchemaHandler}
            currentTabIndex={this.currentTabIndex}
            onTabIndexChange={this.onTabIndexChange}
          ></ObjectTypeWidget>
        )}
      </div>
    );
  }
}
