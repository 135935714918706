import { UserModule } from "@/store/modules/user";
import { UserRedirector } from "@/utils/auth-service/user-redirector";
import { OrgModule } from "@/store";
import { UserDataLoader } from "./user-data-loader";
import router, { resetRouter } from "@/router";
import { userDataLoader } from "@/utils/auth-service";
import { ICredentials } from "@graphapi-io/customer-components";

export class AuthManager {
  private userRedirector = new UserRedirector(OrgModule);
  private userDataLoader: UserDataLoader;

  constructor(userDataLoader: UserDataLoader) {
    this.userDataLoader = userDataLoader;
  }

  public initialize() {
    this.userDataLoader.initialize();
    this.userDataLoader.loading.then(() => {
      UserModule.handleUserDataLoadingFinished();
    });
  }

  public async login(credentials: ICredentials) {
    await this.onUserAction(async () => {
      this.userDataLoader.signIn(credentials);
      await this.redirectUserBasedOnState();
    });
  }

  public async logout() {
    await this.onUserAction(async () => {
      this.userDataLoader.signOut();
      resetRouter();
      await router.replace({ name: "login" }).catch(() => {});
    });
  }

  public async refreshToken() {
    await this.onUserAction(async () => {
      this.userDataLoader.refreshToken();
      await this.redirectUserBasedOnState();
    });
  }

  public async handleCodeConfirmed(credentials: ICredentials) {
    await this.onUserAction(async () => {
      this.userDataLoader.confirmCode(credentials);
      await this.redirectUserBasedOnState();
    });
  }

  private async onUserAction(handler: () => Promise<void>) {
    UserModule.handleUserDataLoadingStarted();
    await handler();
    UserModule.handleUserDataLoadingFinished();
  }

  private async redirectUserBasedOnState() {
    const newState = await this.userDataLoader.loading;
    const redirect = this.userRedirector.getRedirectBasedOnState(
      newState,
      router.currentRoute
    );

    if (redirect) {
      router.push(redirect).catch(() => {});
    }
  }
}

export type IAuthManager = Required<AuthManager>;
export const authManager = new AuthManager(userDataLoader);
