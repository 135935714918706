import { Vue, Component, Prop } from "vue-property-decorator";
import { BillingIntervalEnum } from "@/api/common-types";
import { IProductState, IPriceState } from "@/models/product-bundle";
import { ThemedImage } from "@/components/shared/ThemedImage.component";

@Component({
  components: {
    ThemedImage,
  },
})
export default class ProductWidget extends Vue {
  @Prop({ required: true }) product: IProductState;
  @Prop() billingInterval: BillingIntervalEnum;

  iconNames = {
    default: {
      name: "graphql",
    },
  };

  get billingIntervalString() {
    return {
      [BillingIntervalEnum.DAY]: `${this.$t("billing_interval_day")}`,
      [BillingIntervalEnum.MONTH]: `${this.$t("billing_interval_month")}`,
      [BillingIntervalEnum.WEEK]: `${this.$t("billing_interval_week")}`,
      [BillingIntervalEnum.YEAR]: `${this.$t("billing_interval_year")}`,
      [BillingIntervalEnum.ONETIME]: "",
    };
  }

  get sliderComponentWidth(): number {
    return 100 / this.sliderMaxValue;
  }

  get cssProps() {
    return {
      "--slider-fill-width": this.sliderComponentWidth * 0 + "%",
      "--slider-component-width": this.sliderComponentWidth + "%",
    };
  }

  get selectedPricings() {
    return (
      this.product.pricings[this.billingInterval] ??
      this.product.pricings[BillingIntervalEnum.MONTH] ??
      []
    );
  }

  get selectedPricing(): IPriceState {
    return this.selectedPricings[0];
  }

  get priceTiers() {
    return this.selectedPricing?.tiers;
  }

  get price() {
    return this.priceTiers?.[1]
      ? (+this.priceTiers?.[1]?.unit_amount_decimal ?? 0) / 100
      : 0;
  }

  get priceString(): string {
    return this.price.toLocaleString(undefined, {
      style: "currency",
      currency: "EUR",
    });
  }

  get priceTimeframe(): string {
    return this.selectedPricing?.recurring &&
      this.selectedPricing?.billingInterval
      ? ` / ${
          this.billingIntervalString[this.selectedPricing?.billingInterval]
        }`
      : "";
  }

  get unitType(): string {
    return this.selectedPricing?.unitType ?? "";
  }

  get unitAmountLocaleString(): string {
    return (this.selectedPricing?.unitAmount ?? 0).toLocaleString();
  }

  get sliderMaxValue(): number {
    return 0;
  }

  get iconSrc() {
    return {
      light: require(`@/assets/img/icon-${this.product.icon}-black.svg`),
      dark: require(`@/assets/img/icon-${this.product.icon}.svg`),
    };
  }
}
