import { IEnumValueState } from "@/models";
import { ComparisonResultEnum, isNotNullable, IValueOf } from "@/utils";
import { IDiffRowState, IEnumDiffState, IEnumItemDiffState } from "./types";

const getValues = (
  diffState: IEnumItemDiffState,
  objectHasBeenCreated: boolean
): IDiffRowState[] => {
  const getValueResult = (field: IEnumValueState) => ({
    _type: "leaf" as const,
    from: objectHasBeenCreated ? undefined : field,
    to: objectHasBeenCreated ? field : undefined,
    type: objectHasBeenCreated
      ? ComparisonResultEnum.CREATED
      : ComparisonResultEnum.DELETED,
  });
  if (diffState._type === "leaf") {
    const { from, to } = diffState;

    return (
      from && "values" in from
        ? from.values
        : to && "values" in to
        ? to.values
        : []
    ).map(getValueResult);
  }

  if ("values" in diffState.diffs && diffState.diffs.values) {
    const valuesDiff = diffState.diffs.values;

    if (valuesDiff._type === "leaf") {
      const { to, from } = valuesDiff;
      return (to || from || []).map(getValueResult);
    }

    const diffs = valuesDiff.diffs;

    return Object.values(diffs)
      .map((value: IValueOf<typeof diffs>) => {
        if (value._type === "leaf") {
          return value;
        }

        if ("name" in value.diffs && value.diffs.name) {
          return {
            _type: "leaf" as const,
            from: { name: value.diffs.name.from },
            to: { name: value.diffs.name.to },
            type: value.diffs.name.type,
          };
        }
      })
      .filter(isNotNullable);
  }

  return [];
};

const getEnumDiffState = (diffState: IEnumItemDiffState) => {
  if (diffState._type === "leaf") {
    const nameLeaf = diffState;
    const typeNameHasChanged = nameLeaf?.type === ComparisonResultEnum.UPDATED;

    return {
      fromTypeName: diffState.from?.name,
      toTypeName: diffState.to?.name,
      typeNameHasChanged,
      objectHasBeenCreated: diffState.type === ComparisonResultEnum.CREATED,
      objectHasBeenDeleted: diffState.type === ComparisonResultEnum.DELETED,
    };
  }

  return {
    fromTypeName: diffState.diffs.name?.from ?? diffState._fullFrom.name,
    toTypeName: diffState.diffs.name?.to ?? diffState._fullFrom.name,
    typeNameHasChanged:
      diffState.diffs.name?.type === ComparisonResultEnum.UPDATED,
    objectHasBeenCreated: false,
    objectHasBeenDeleted: false,
  };
};

const enumDiffToChangeItem = (diffState: IEnumItemDiffState) => {
  const { objectHasBeenCreated, ...rest } = getEnumDiffState(diffState);

  const values = getValues(diffState, objectHasBeenCreated);

  return {
    ...rest,
    diffRows: values,
    objectHasBeenCreated,
  };
};

export const enumsDiffToChangesList = (diff: IEnumDiffState) => {
  if (diff._type === "leaf") {
    return [];
  }
  const diffs = diff.diffs;

  return Object.values(diffs).map((diff) => {
    return enumDiffToChangeItem(diff);
  });
};
