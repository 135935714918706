import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import styles from "./FormJsonInput.component.module.scss";
import JsonEditorVue from "json-editor-vue";
import FormGroupWithLabel from "./FormGroupWithLabel.component";

type JSONValue =
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>
  | null;

@Component
export default class FormJsonInput extends Vue {
  _tsx!: tsx.DeclareProps<{
    id?: FormJsonInput["id"];
    value: FormJsonInput["value"];
    label: FormJsonInput["label"];
    labelAmendment: FormJsonInput["labelAmendment"];
    upperCaseLabel?: FormJsonInput["upperCaseLabel"];
    placeholderText: FormJsonInput["placeholderText"];
    readonly?: FormJsonInput["readonly"];
  }> &
    tsx.DeclareOnEvents<{
      onInput: FormJsonInput["onInput"];
    }>;
  $refs!: {
    wrapper?: HTMLElement;
  };

  @Prop() id?: string;
  @Prop({ required: true }) value: JSONValue;
  @Prop() readonly label: string;
  @Prop() readonly labelAmendment: string;
  @Prop({ default: true }) readonly upperCaseLabel?: boolean;
  @Prop({ required: true }) readonly placeholderText: string;
  @Prop({ default: false }) readonly readonly?: boolean;
  @Prop({ default: "Json" }) readonly inputType?: string;

  @Emit("input") onInput(_ev: string) {}

  private internalValue: JSONValue = {};

  @Watch("value", { immediate: true })
  onValueChange() {
    this.internalValue = JSON.parse(this.value?.toString() ?? "");
  }

  private handleKeyDown(ev: KeyboardEvent) {
    if (ev.key === "Enter" && ev.metaKey) {
      ev.stopPropagation();
      ev.stopImmediatePropagation();
      ev.preventDefault();
      this.$emit("cellChange", this.internalValue);
    }
  }

  private handleChange(value: string) {
    this.internalValue = value;
  }

  render() {
    return (
      <FormGroupWithLabel
        label={this.label}
        labelAmendment={this.labelAmendment}
        upperCaseLabel={this.upperCaseLabel}
        inputType={this.inputType}
        id={this.id}
      >
        <div
          tabindex={0}
          onKeydown={this.handleKeyDown}
          style={{ outline: "none" }}
          class={styles.wrapper}
        >
          <JsonEditorVue
            value={this.internalValue}
            style={{ maxHeight: "400px", overflow: "auto" }}
            onInput={this.onInput}
            onKeyDown={this.handleKeyDown}
            {...{
              props: {
                mainMenuBar: false,
                navigationBar: false,
                mode: "text",
              },
            }}
          />
        </div>
      </FormGroupWithLabel>
    );
  }
}
