import Vue from "vue";
import VueI18n from "vue-i18n";
import { persistanceService } from "./utils/persistance-service";

Vue.use(VueI18n);

export enum LanguageCodeEnum {
  en = "en",
  de = "de",
}

const config = {
  [LanguageCodeEnum.de]: {
    name: "Deutsch",
    code: LanguageCodeEnum.de,
    file: require("@/locales/de.json"),
  },
  [LanguageCodeEnum.en]: {
    name: "English",
    code: LanguageCodeEnum.en,
    file: require("@/locales/en.json"),
  },
};

export const languagesList = Object.values(config).map(
  ({ file, ...rest }) => rest
);

const messages = Object.fromEntries(
  Object.entries(config).map(([key, val]) => [key, val.file])
);

export const isSupportedLang = (lang: string): lang is LanguageCodeEnum =>
  languagesList.find((langDef) => langDef.code === lang) !== undefined;

const getBrowserLocale = (): LanguageCodeEnum => {
  const navigatorLang = navigator.language.split("-")[0];

  if (isSupportedLang(navigatorLang)) {
    return navigatorLang;
  }

  return LanguageCodeEnum.en;
};

const getPersistedLocale = (): LanguageCodeEnum | undefined => {
  const savedLang = persistanceService.getUserLang();

  if (savedLang && isSupportedLang(savedLang)) {
    return savedLang;
  }
};

const getQueryParamLocale = () => {
  const lang = new URLSearchParams(location.search).get("lang");
  if (lang && isSupportedLang(lang)) {
    return lang;
  }
};

const getAndPersistQueryParamLocale = () => {
  const lang = getQueryParamLocale();
  if (lang) {
    persistLocale(lang);
  }
  return lang;
};

export const persistLocale = (lang: LanguageCodeEnum) => {
  persistanceService.setUserLang(lang);
};

export const updateLocaleByQueryParam = (i18n: VueI18n) => {
  const lang = getQueryParamLocale();
  if (lang) {
    i18n.locale = lang;
  }
};

const getLocale = (): LanguageCodeEnum =>
  getAndPersistQueryParamLocale() ?? getPersistedLocale() ?? getBrowserLocale();

export const createI18n = () =>
  new VueI18n({
    locale: getLocale(),
    fallbackLocale:
      process.env.VUE_APP_I18N_FALLBACK_LOCALE || LanguageCodeEnum.en,
    messages,
  });

export default createI18n();
