import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { ElementAttrs } from "vue-tsx-support/types/base";
import {
  ElementType,
  InputHTMLAttributes,
  SyntheticEvent,
} from "vue-tsx-support/types/dom";
import styles from "./Input.component.module.scss";

export type IInputProps = tsx.DeclareProps<
  Omit<
    ElementAttrs<ElementType<HTMLInputElement, InputHTMLAttributes>>,
    "onInput" | "value"
  > & {
    value?: Input["value"];
    autoFocus?: Input["autoFocus"];
    selectAllInitially?: Input["selectAllInitially"];
    inputTheme?: Input["inputTheme"];
    inputSize?: Input["inputSize"];
    inputType?: Input["inputType"];
    formatter?: Input["formatter"];
  }
> &
  tsx.DeclareOnEvents<{
    onInput: string;
  }>;

export enum InputThemeEnum {
  primary = "primary",
  transparent = "transparent",
}

export enum InputSizeEnum {
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

@Component
export default class Input extends Vue {
  _tsx!: IInputProps;

  @Prop({ default: "" }) value: string;
  @Prop({ default: false }) autoFocus: boolean;
  @Prop({ default: false }) selectAllInitially: boolean;
  @Prop({ default: InputThemeEnum.primary }) inputTheme: InputThemeEnum;
  @Prop({ default: InputSizeEnum.xl }) inputSize: InputSizeEnum;
  @Prop({ default: "text" }) inputType: string;
  @Prop({ required: false, default: undefined, type: Function })
  formatter: (value: string) => string | undefined;

  private internalValue: string = "";

  get className() {
    return {
      [styles.input]: true,
      [styles.primary]: this.inputTheme === InputThemeEnum.primary,
      [styles.transparent]: this.inputTheme === InputThemeEnum.transparent,
      [styles.sizeSM]: this.inputSize === InputSizeEnum.sm,
      [styles.sizeMD]: this.inputSize === InputSizeEnum.md,
      [styles.sizeLG]: this.inputSize === InputSizeEnum.lg,
      [styles.sizeXL]: this.inputSize === InputSizeEnum.xl,
    };
  }

  get hasFormatter() {
    return this.formatter !== undefined && typeof this.formatter === "function";
  }

  formatValue(value: string): string {
    return this.hasFormatter ? this.formatter(value) ?? "" : value;
  }

  handleInput(event: SyntheticEvent<HTMLInputElement, InputEvent>) {
    const { value } = event.target;
    this.internalValue = this.formatValue(value);
    this.$emit("input", this.internalValue);
  }

  mounted() {
    if (this.autoFocus) {
      this.$nextTick(() => {
        const inputElement = this.$el as HTMLInputElement;
        inputElement.focus();

        if (this.inputType === "text") {
          inputElement.selectionStart = inputElement.selectionEnd =
            this.internalValue.length;
        }

        if (this.selectAllInitially) {
          inputElement.selectionStart = 0;
        }
      });
    }
  }

  @Watch("value", { immediate: true })
  onValueChange() {
    this.internalValue = this.formatValue(this.value);
  }

  render() {
    return (
      <input
        class={this.className}
        on={{ ...this.$listeners, input: this.handleInput }}
        autocomplete="off"
        value={this.internalValue}
        type={this.inputType}
      ></input>
    );
  }
}
