import { Vue, Component, Prop } from "vue-property-decorator";
import FormGroupWithLabel from "./FormGroupWithLabel.component";
import FormInput from "./FormInput.component";
import * as tsx from "vue-tsx-support";
import { IBFormInputEvents } from "@/lib/typed-bootstrap";

@Component
export default class FormInputWithLabel extends Vue {
  _tsx!: tsx.DeclareProps<{
    id?: FormInputWithLabel["id"];
    label?: FormInputWithLabel["label"];
    labelAmendment?: FormInputWithLabel["labelAmendment"];
    upperCaseLabel?: FormInputWithLabel["upperCaseLabel"];
    inputType?: FormInputWithLabel["inputType"];
    value?: FormInputWithLabel["value"];
    placeholderText: FormInputWithLabel["placeholderText"];
    shouldValidate?: FormInputWithLabel["shouldValidate"];
    readonly?: FormInputWithLabel["readonly"];
    autocomplete?: FormInputWithLabel["autocomplete"];
    min?: FormInputWithLabel["min"];
    max?: FormInputWithLabel["max"];
    maxLength?: FormInputWithLabel["maxLength"];
  }> &
    tsx.DeclareOnEvents<IBFormInputEvents>;

  @Prop() id?: string;
  @Prop() readonly label: string;
  @Prop() readonly labelAmendment: string;
  @Prop({ default: true }) readonly upperCaseLabel?: boolean;
  @Prop({ default: "unknown" }) readonly inputType?: string;
  @Prop() value: any;
  @Prop({ required: true }) readonly placeholderText: string;
  @Prop({ default: false }) readonly shouldValidate: boolean;
  @Prop({ default: false }) readonly readonly: boolean;
  @Prop({ default: "off" }) readonly autocomplete: string;
  @Prop() readonly min?: number;
  @Prop() readonly max?: number;
  @Prop({ default: 65536 }) readonly maxLength: number;

  $refs!: {
    formInputComponent: FormInput;
  };

  public onBeginEditing() {
    this.$refs.formInputComponent.onBeginEditing();
  }

  public render() {
    const {
      id,
      label,
      labelAmendment,
      upperCaseLabel,
      inputType,
      value,
      placeholderText,
      shouldValidate,
      readonly,
      autocomplete,
      min,
      max,
      maxLength,
    } = this;

    return (
      <FormGroupWithLabel
        label={label}
        labelAmendment={labelAmendment}
        upperCaseLabel={upperCaseLabel}
        inputType={inputType}
        id={id}
      >
        <FormInput
          ref="formInputComponent"
          id={id}
          value={value}
          placeholderText={placeholderText}
          shouldValidate={shouldValidate}
          readonly={readonly}
          autocomplete={autocomplete}
          min={min}
          max={max}
          maxLength={maxLength}
          inputType={inputType}
          on={this.$listeners}
        />
      </FormGroupWithLabel>
    );
  }
}
