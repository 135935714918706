import {
  IBillingAccountInput,
  IBillingAccountUpdateInput,
} from "@/generated/types";
import {
  createBillingAccount,
  updateBillingAccount,
} from "@/models/billing-account";
import { OrgModule } from "@/store/modules/org";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class BillingAccount extends Vue {
  isLoading = false;
  async createBillingAccount(input: IBillingAccountInput) {
    try {
      this.isLoading = true;
      const { billingAddress, ...rest } = input;
      return await createBillingAccount({
        input: {
          ...rest,
          ...(billingAddress && {
            billingAddress: JSON.stringify(billingAddress),
          }),
        },
      });
    } finally {
      this.isLoading = false;
    }
  }

  async updateBillingAccount(input: IBillingAccountUpdateInput) {
    try {
      this.isLoading = true;
      const { billingAddress, ...rest } = input;
      return await updateBillingAccount({
        input: {
          ...rest,
          ...(billingAddress && {
            billingAddress: JSON.stringify(billingAddress),
          }),
        },
      });
    } finally {
      this.isLoading = false;
    }
  }

  async upsertBillingAccount(
    input: IBillingAccountInput | IBillingAccountUpdateInput
  ) {
    try {
      this.isLoading = true;
      let billingAccount = undefined;
      if (input.id && input.paymentProviderId) {
        billingAccount = await this.updateBillingAccount(
          input as IBillingAccountUpdateInput
        );
      } else {
        const { id, ...rest } = input;
        billingAccount = await this.createBillingAccount(
          rest as IBillingAccountInput
        );
      }

      OrgModule.handleBillingAccountUpdate(billingAccount);
      return billingAccount;
    } finally {
      this.isLoading = false;
    }
  }
}
