import ShortUniqueId from "short-unique-id";
import { IFieldState, IObjectTypeState } from "./types";
import { PublishingStateEnum } from "@/api/common-types";

export * from "./types";
export * from "./connection-operations";
export * from "./field-directives";
export * from "./object-directives";
export * from "./object-resolvers";
export * from "./object-type-diff";

export const isIdField = (field: IFieldState) =>
  field.name.toLowerCase() === "id";

export const createObjectTypeBase = (
  name: string,
  usePrefix?: boolean | null
) => {
  const { randomUUID } = new ShortUniqueId({ length: 6 });
  const uuid = `${randomUUID()}`;
  const defaultFields = [
    {
      id: randomUUID(),
      typeId: uuid,
      type: "ID",
      name: "id",
      readonly: true,
      directives: [],
      state: PublishingStateEnum.INITIALIZED,
    },
    {
      id: randomUUID(),
      typeId: uuid,
      type: "String",
      name: "createdAt",
      readonly: true,
      directives: [],
      state: PublishingStateEnum.INITIALIZED,
    },
    {
      id: randomUUID(),
      typeId: uuid,
      type: "String",
      name: "updatedAt",
      readonly: true,
      directives: [],
      state: PublishingStateEnum.INITIALIZED,
    },
  ];

  const newObjectType: IObjectTypeState = {
    id: uuid,
    name,
    selected: true,
    connector: "id",
    state: PublishingStateEnum.INITIALIZED,
    fields: defaultFields,
    targets: [],
    parents: { first: null, second: null },
    directives: [],
    resolvers: [],
    ...(usePrefix && { usePrefix }),
  };

  return newObjectType;
};
