import { Auth } from "@graphapi-io/customer-components";
import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";

@Component
export default class CodeEntryForm extends Vue {
  _tsx!: tsx.DeclareProps<{
    email: CodeEntryForm["email"];
    withHint?: CodeEntryForm["withHint"];
    upperCaseLabel?: CodeEntryForm["upperCaseLabel"];
    auth: CodeEntryForm["auth"];
  }> &
    tsx.DeclareOnEvents<{
      onConfirmed: void;
    }>;
  $scopedSlots!: tsx.InnerScopedSlots<{
    "form-content"?: {
      isLoading: boolean;
      resendConfirmation: () => Promise<void>;
    };
    "form-footer"?: { resendConfirmation: () => Promise<void> };
  }>;

  @Prop({ required: true }) email: string;
  @Prop({ default: false }) withHint: boolean;
  @Prop({ default: false }) upperCaseLabel: boolean;
  @Prop({ required: true }) auth: Auth;

  public isLoading = false;

  errorMessage = "";
  form = null;
  signupCode = "";

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  @Emit("confirmed") onConfirmed() {}

  async onSubmit(): Promise<void> {
    try {
      this.isLoading = true;
      await this.auth.confirmSignUp(this.email, this.signupCode);
      this.onConfirmed();
    } catch (err) {
      const errorMessage = (err as any).message;
      if (errorMessage.toLowerCase().includes("status is confirmed")) {
        this.onConfirmed();
      } else {
        this.errorMessage = (err as any).message;
      }
    } finally {
      this.isLoading = false;
    }
  }

  async resendConfirmation(): Promise<void> {
    try {
      await this.auth.resendSignUp(this.email);
    } catch (err) {
      this.errorMessage = (err as any).message;
    }
  }
}
