import CREATE_ORG_WITH_OWNER from "./create_org_with_owner.graphql";
import QUERY_ORG from "./query_org.graphql";
import BATCH_QUERY_ORG_NAMES from "./batch_query_org_names.graphql";
import UPDATE_ORG from "./update_org.graphql";
import UPDATE_ORG_WITH_BILLING_ACOUNT from "./update_org_with_billing_account.graphql";
import DELETE_ORG from "./delete_org.graphql";
import {
  apolloClient,
  mutationFunctionFactory,
  queryFunctionFactory,
} from "../internal-utils";
import {
  IBatchQueryOrgNamesQuery,
  IBatchQueryOrgNamesQueryVariables,
  ICreateOrgWithOwnerMutation,
  IQueryOrgQuery,
  IQueryOrgQueryVariables,
  IOrgDeleteInput,
  IBillingAccountDeleteInput,
  IUpdateOrgWithBillingAcountMutationVariables,
  IUpdateOrgMutationVariables,
  ICreateOrgWithOwnerMutationVariables,
  IUpdateOrgMutation,
  IUpdateOrgWithBillingAcountMutation,
} from "@/generated/types";

export const workSpaceWithOwnerMutation = mutationFunctionFactory<
  ICreateOrgWithOwnerMutation,
  ICreateOrgWithOwnerMutationVariables
>(CREATE_ORG_WITH_OWNER);

export const orgQuery = queryFunctionFactory<
  IQueryOrgQuery,
  IQueryOrgQueryVariables
>(QUERY_ORG);

export const batchQueryOrgNames = queryFunctionFactory<
  IBatchQueryOrgNamesQuery,
  IBatchQueryOrgNamesQueryVariables
>(BATCH_QUERY_ORG_NAMES, "all", "cache-first");

export const deleteOrgMutation = (
  orgDeleteInput: IOrgDeleteInput,
  billingAccountDeleteInput: IBillingAccountDeleteInput
) => {
  return apolloClient.mutate({
    mutation: DELETE_ORG,
    variables: { orgDeleteInput, billingAccountDeleteInput },
  });
};

export const updateOrgMutation = mutationFunctionFactory<
  IUpdateOrgMutation,
  IUpdateOrgMutationVariables
>(UPDATE_ORG);

export const updateOrgWithBillingAccountMutation = mutationFunctionFactory<
  IUpdateOrgWithBillingAcountMutation,
  IUpdateOrgWithBillingAcountMutationVariables
>(UPDATE_ORG_WITH_BILLING_ACOUNT);
