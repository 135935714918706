import { Vue, Component } from "vue-property-decorator";
import { ISignUpResult } from "amazon-cognito-identity-js";
import { FormModule } from "@/store/modules/form";
import { UserModule } from "@/store/modules/user";
import SignupForm from "@/components/signup/SignupForm.component.vue";
import { SignupFormCredentials } from "@/components/signup/SignupForm.component";
import { envRootLocalizedDomain } from "@/utils";
import { auth } from "@/utils/auth-service";
import { persistanceService } from "@/utils/persistance-service";
import { Loader } from "@/components/shared/Loader.component";
import { UIModule } from "@/store/modules/ui";

@Component({
  components: {
    Loader,
    SignupForm,
  },
})
export default class Signup extends Vue {
  initialEmail = "";
  optInConfirmed = "";
  state = null;
  form = false;

  get auth() {
    return auth;
  }

  get isStatusValid(): boolean {
    return this.optInConfirmed === "accepted";
  }

  get loaderVariant() {
    return UIModule.isLightTheme ? "light" : "dark";
  }

  get privacyUrl(): string {
    return `${envRootLocalizedDomain()}/privacy`;
  }

  get termsUrl(): string {
    return `${envRootLocalizedDomain()}/terms`;
  }

  created() {
    this.initialEmail = (this.$route.query.email as string) ?? "";
  }

  async onSubmit(submitResult: {
    credentials: SignupFormCredentials;
    result: ISignUpResult;
  }): Promise<void> {
    UserModule.handleSignupResponse(submitResult);
    FormModule.handleValidCredentials(submitResult.credentials);
    persistanceService.setCredentials(submitResult.credentials);
    this.$router.push({ name: "signup-code-entry" });
  }
}
