import {
  IFieldDiffableProps,
  IObjectTypeState,
  IParentDiffableProps,
  IResolverState,
} from "@/models";
import {
  ComparisonResultEnum,
  idFromObjectName,
  isNotNullable,
  IValueOf,
  upperCaseFirstLetter,
} from "@/utils";
import {
  IDiffRowState,
  IObjectTypeDiffState,
  IObjectTypeItemDiffState,
} from "./types";

const getParents = (
  diffState: IObjectTypeItemDiffState,
  objectHasBeenCreated: boolean,
  allTypes: IObjectTypeState[]
): IDiffRowState[] => {
  const getParentName = (parentId: string | null | undefined) => {
    const name = allTypes.find((t) => t.id === parentId)?.name ?? "";

    return name.length > 0 ? idFromObjectName(name) : "";
  };

  const buildParentLeafDiff = (
    fromParentId: string | undefined | null,
    toParentId: string | undefined | null,
    comparisonResult: ComparisonResultEnum
  ) =>
    buildLeafDiff(
      fromParentId
        ? { name: getParentName(fromParentId), type: "ID!" }
        : undefined,
      toParentId ? { name: getParentName(toParentId), type: "ID!" } : undefined,
      comparisonResult
    );

  const buildParentExistanceLeafDiff = (parent: IParentDiffableProps) =>
    buildParentLeafDiff(
      objectHasBeenCreated ? undefined : parent?.parentId,
      objectHasBeenCreated ? parent?.parentId : undefined,
      objectHasBeenCreated
        ? ComparisonResultEnum.CREATED
        : ComparisonResultEnum.DELETED
    );

  if (diffState._type === "leaf") {
    const { from, to } = diffState;

    const parents =
      from && "parents" in from
        ? from.parents
        : to && "parents" in to
        ? to.parents
        : [];

    return Object.values(parents)
      .filter(isNotNullable)
      .map(buildParentExistanceLeafDiff);
  }

  if ("parents" in diffState.diffs && diffState.diffs.parents) {
    const parentsDiff = diffState.diffs.parents;

    if (parentsDiff._type === "leaf") {
      const { to, from } = parentsDiff;
      return Object.values(to || from || {})
        .filter(isNotNullable)
        .map(buildParentExistanceLeafDiff);
    }

    const diffs = parentsDiff.diffs;

    return [diffs.first, diffs.second].filter(isNotNullable).map((value) => {
      if (value?._type === "leaf") {
        return buildParentLeafDiff(
          value.from?.parentId,
          value.to?.parentId,
          value.type
        );
      }

      return buildParentLeafDiff(
        value?.diffs.parentId?.from,
        value?.diffs.parentId?.to,
        ComparisonResultEnum.UPDATED
      );
    });
  }

  return [];
};

const getFields = (
  diffState: IObjectTypeItemDiffState,
  objectHasBeenCreated: boolean
): IDiffRowState[] => {
  const getFieldResult = (field: IFieldDiffableProps) =>
    buildLeafDiff(
      objectHasBeenCreated ? undefined : field,
      objectHasBeenCreated ? field : undefined,
      objectHasBeenCreated
        ? ComparisonResultEnum.CREATED
        : ComparisonResultEnum.DELETED
    );

  if (diffState._type === "leaf") {
    const { from, to } = diffState;

    return (
      from && "fields" in from
        ? from.fields
        : to && "fields" in to
        ? to.fields
        : []
    ).map(getFieldResult);
  }

  if ("fields" in diffState.diffs && diffState.diffs.fields) {
    const fieldsDiff = diffState.diffs.fields;

    if (fieldsDiff._type === "leaf") {
      const { to, from } = fieldsDiff;
      return (to || from || []).map(getFieldResult);
    }
    const diffs = fieldsDiff.diffs;

    return Object.values(fieldsDiff.diffs)
      .map((value: IValueOf<typeof diffs>) => {
        if (value._type === "leaf") {
          return value;
        }

        const fullFrom = value._fullFrom;
        if ("name" in value.diffs && value.diffs.name) {
          const fromType =
            "type" in value.diffs ? value.diffs.type?.from : fullFrom.type;
          const toType =
            "type" in value.diffs ? value.diffs.type?.to : fullFrom.type;

          return buildLeafDiff(
            { name: value.diffs.name.from, type: fromType },
            { name: value.diffs.name.to, type: toType },
            value.diffs.name.type
          );
        }
        if ("type" in value.diffs && value.diffs.type) {
          const fromName =
            "name" in value.diffs ? value.diffs.name?.from : fullFrom.name;
          const toName =
            "name" in value.diffs ? value.diffs.name?.to : fullFrom.name;

          return buildLeafDiff(
            { name: fromName, type: value.diffs.type.from },
            { name: toName, type: value.diffs.type.to },
            value.diffs.type.type
          );
        }
      })
      .filter(isNotNullable);
  }

  return [];
};

const getResolvers = (
  diffState: IObjectTypeItemDiffState,
  objectHasBeenCreated: boolean
): IDiffRowState[] => {
  const buildResolverCreateOrDeleteLeafDiff = (resolver: IResolverState) =>
    buildResolverLeafDiff(
      objectHasBeenCreated ? undefined : resolver,
      objectHasBeenCreated ? resolver : undefined,
      objectHasBeenCreated
        ? ComparisonResultEnum.CREATED
        : ComparisonResultEnum.DELETED
    );

  if (diffState._type === "leaf") {
    const { from, to } = diffState;

    return (
      from && "resolvers" in from
        ? from.resolvers
        : to && "resolvers" in to
        ? to.resolvers
        : []
    ).map(buildResolverCreateOrDeleteLeafDiff);
  }

  if ("resolvers" in diffState.diffs && diffState.diffs.resolvers) {
    const resolversDiff = diffState.diffs.resolvers;

    if (resolversDiff._type === "leaf") {
      const { to, from } = resolversDiff;
      return (to || from || []).map(buildResolverCreateOrDeleteLeafDiff);
    }

    const diffs = resolversDiff.diffs;

    return Object.values(resolversDiff.diffs)
      .map((value: IValueOf<typeof diffs>) => {
        if (value._type === "leaf") {
          return buildResolverLeafDiff(value.from, value.to, value.type);
        }
        const fullFrom = value._fullFrom;

        if ("name" in value.diffs && value.diffs.name) {
          return buildResolverLeafDiff(
            fullFrom,
            {
              ...fullFrom,
              name: value.diffs.name.to ?? "",
            },
            value.diffs.name.type
          );
        }
      })
      .filter(isNotNullable);
  }

  return [];
};

const buildResolverLeafDiff = (
  from: IResolverState | undefined,
  to: IResolverState | undefined,
  type: ComparisonResultEnum
) => buildLeafDiff(getResolverDiffRow(from), getResolverDiffRow(to), type);

const getResolverDiffRow = (resolver?: IResolverState) => {
  if (!resolver) return undefined;

  const stepString = upperCaseFirstLetter(resolver.step.toLowerCase());
  const typesString =
    resolver.types.length === 3 ? "all" : resolver.types.join(",");

  return {
    name: `${stepString} ${typesString} trigger ${resolver.name}`,
  };
};

const buildLeafDiff = <IFrom, ITo>(
  from: IFrom,
  to: ITo,
  type: ComparisonResultEnum
) => ({
  _type: "leaf" as const,
  from,
  to,
  type,
});

const getObjectDiffState = (diffState: IObjectTypeItemDiffState) => {
  if (diffState._type === "leaf") {
    const nameLeaf = diffState;
    const typeNameHasChanged = nameLeaf?.type === ComparisonResultEnum.UPDATED;

    return {
      fromTypeName: diffState.from?.name,
      toTypeName: diffState.to?.name,
      typeNameHasChanged,
      objectHasBeenCreated: diffState.type === ComparisonResultEnum.CREATED,
      objectHasBeenDeleted: diffState.type === ComparisonResultEnum.DELETED,
    };
  }

  return {
    fromTypeName: diffState.diffs.name?.from ?? diffState._fullFrom.name,
    toTypeName: diffState.diffs.name?.to ?? diffState._fullFrom.name,
    typeNameHasChanged:
      diffState.diffs.name?.type === ComparisonResultEnum.UPDATED,
    objectHasBeenCreated:
      diffState.diffs.name?.type === ComparisonResultEnum.CREATED,
    objectHasBeenDeleted:
      diffState.diffs.name?.type === ComparisonResultEnum.DELETED,
  };
};

const objectTypeDiffTodiffState = (
  diffState: IObjectTypeItemDiffState,
  allTypes: IObjectTypeState[]
) => {
  const { objectHasBeenCreated, ...rest } = getObjectDiffState(diffState);

  const diffRows = [
    ...getFields(diffState, objectHasBeenCreated),
    ...getParents(diffState, objectHasBeenCreated, allTypes),
    ...getResolvers(diffState, objectHasBeenCreated),
  ];

  return {
    ...rest,
    diffRows,
    objectHasBeenCreated,
  };
};

export const objectTypesDiffToChangesList = (
  diff: IObjectTypeDiffState,
  allTypes: IObjectTypeState[]
) => {
  if (diff._type === "leaf") {
    return [];
  }
  const diffs = diff.diffs;

  return Object.values(diffs)
    .map((diff) => {
      return objectTypeDiffTodiffState(diff, allTypes);
    })
    .filter(
      (diff) =>
        diff.diffRows.length > 0 ||
        diff.objectHasBeenCreated ||
        diff.objectHasBeenDeleted ||
        diff.typeNameHasChanged
    );
};
