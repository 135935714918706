import CREATE_COUPON_REDEMPTION from "./create_coupon_redemption.graphql";
import LIST_ACCOUNT_COUPONS_BY_REGION from "./list_account_coupons_by_region.graphql";
import { apolloClient, queryFunctionFactory } from "../internal-utils";
import { AwsRegionEnum } from "../common-types";

export enum RedemptionStatus {
  VALID = "VALID",
  USED = "USED",
  INVALID = "INVALID",
}

export interface IAccountCouponUpdateInput {
  id: string;
  status: RedemptionStatus;
}

export interface IAccountCouponResponse {
  id: string;
  awsAccountId?: string;
  status?: RedemptionStatus;
}

export interface ICouponRedemptionInput {
  accountCouponConfigType: string;
  couponCode: string;
  redeemedById: string;
  redeemedByType: string;
}

export interface IListAccountCouponsByRegionResponse {
  listAccountCouponsByConfig: {
    items: IAccountCouponResponse[];
  };
}

export const createCouponRedemption = (createInput: ICouponRedemptionInput) => {
  return apolloClient.mutate({
    mutation: CREATE_COUPON_REDEMPTION,
    variables: { createInput },
  });
};

export const listAccountCouponsByRegion = queryFunctionFactory<
  IListAccountCouponsByRegionResponse,
  { configRegion: AwsRegionEnum }
>(LIST_ACCOUNT_COUPONS_BY_REGION);
