import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { ICustomerApp, watchCustomerApp } from "@/models/customer-apps";
import { getErrorMessage } from "@/utils";
import { Dictionary } from "vue-router/types/router";
import { IApiState, watchApiDetails } from "@/models";
import { ApiSchema } from "@/models/api-schema";
import BreadcrumbsLayout from "./Breadcrumbs.layout";

@Component
export default class AppManagement extends Vue {
  private appSubscription: ZenObservable.Subscription | undefined;
  private apiSubscription: ZenObservable.Subscription | undefined;
  private app: ICustomerApp | null = null;
  private api: IApiState | null = null;
  private error: string | null = null;

  private get apiId() {
    return this.$route.params.apiId;
  }

  private get appId() {
    return this.$route.params.appId;
  }

  @Watch("appId", { immediate: true })
  onAppIdChanged() {
    if (!this.appId) {
      return;
    }

    this.appSubscription?.unsubscribe();

    this.appSubscription = watchCustomerApp({ id: this.appId }).subscribe({
      next: (app) => {
        this.app = app;
      },
      error: (error) => {
        this.error = getErrorMessage(error);
      },
    });
  }

  @Watch("apiId", { immediate: true })
  onApiIdChanged() {
    if (!this.apiId) {
      return;
    }

    this.apiSubscription?.unsubscribe();

    this.apiSubscription = watchApiDetails({ id: this.apiId }).subscribe({
      next: (api) => {
        this.api = api;
      },
      error: (error) => {
        this.error = getErrorMessage(error);
      },
    });
  }

  private get links(): {
    name: string;
    params: Dictionary<string>;
    label: string;
  }[] {
    return [
      {
        name: "api-details-data",
        params: { apiId: this.app?.apiId ?? "" },
        label: this.api?.name ?? "",
      },
      {
        name: "api-details-apps",
        params: { apiId: this.app?.apiId ?? "", appId: this.appId },
        label: this.$t("nav_sidebar_item_apps").toString(),
      },
      {
        name: "api-details-app-editor",
        params: { apiId: this.app?.apiId ?? "", appId: this.appId },
        label: this.app?.name ?? "",
      },
    ];
  }

  private get apiSchema() {
    if (!this.api) return null;

    return new ApiSchema(this.api);
  }

  render() {
    return (
      <BreadcrumbsLayout links={this.links}>
        <router-view app={this.app} apiSchema={this.apiSchema} api={this.api} />
      </BreadcrumbsLayout>
    );
  }
}
