import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { ISignUpResult } from "amazon-cognito-identity-js";
import * as tsx from "vue-tsx-support";

import FormEmail from "@/components/forms/inputs/FormEmail.component.vue";
import FormPassword from "@/components/forms/inputs/FormPassword.component.vue";
import { Auth } from "@graphapi-io/customer-components";

export type SignupFormCredentials = {
  username: string;
  password: string;
};

export type ISignupSubmitResult = {
  credentials: SignupFormCredentials;
  result: ISignUpResult;
};

@Component({
  components: {
    FormEmail,
    FormPassword,
  },
})
export default class SignupForm extends Vue {
  _tsx!: tsx.DeclareProps<{
    isParentStateValid?: SignupForm["isParentStateValid"];
    initialEmail?: SignupForm["initialEmail"];
    auth: SignupForm["auth"];
  }> &
    tsx.DeclareOnEvents<{
      onSubmit: ISignupSubmitResult;
    }>;
  $scopedSlots!: tsx.InnerScopedSlots<{
    "form-content": { isLoading: boolean; formVariant: string };
  }>;

  @Prop({ default: true }) isParentStateValid: boolean;
  @Prop({ default: "" }) initialEmail: string;
  @Prop({ required: true }) auth: Auth;

  email = "";
  password = "";

  isLoading = false;
  errorMessage = "";
  formVariant = "email-signup";
  form = false;

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  get isFormValid(): boolean {
    return (
      this.isParentStateValid && this.isEmailValid() && this.isPasswordValid()
    );
  }

  @Emit("submit") onSuccessfulSubmit(_submitResult: ISignupSubmitResult) {}

  isPasswordValid(): boolean {
    return this.password?.length >= 12;
  }

  isEmailValid(): boolean {
    return this.email?.length >= 4;
  }

  created() {
    this.email = this.initialEmail;
  }

  async onSubmit(): Promise<void> {
    if (this.isFormValid) {
      try {
        this.isLoading = true;
        const credentials = {
          username: this.email,
          password: this.password,
        };

        const result = await this.auth.signUp(credentials);
        this.onSuccessfulSubmit({ credentials, result });
      } catch (err) {
        this.errorMessage = this.extractCognitoErrorMessage(err as any);
        this.isLoading = false;
      }
    } else {
      this.errorMessage = this.$t("signup_error_forminvalid") as string;
    }
  }

  extractCognitoErrorMessage(error: { [key: string]: string }): string {
    let key = null;

    if (error.code === "InvalidParameterException") {
      key = "signup_error_invalid_parameter";

      if (error.message.includes("email")) {
        key = "signup_error_invalid_email";
      } else if (error.message.includes("password")) {
        key = "signup_error_invalid_password";
      }
    } else if (error.code === "UsernameExistsException") {
      key = "signup_error_username_exists";
    }

    return (key && (this.$t(key) as string)) || error.message;
  }
}
