import {
  IListFlowRunsByFlowQuery,
  IListFlowRunsByFlowQueryVariables,
} from "@/generated/types";
import { queryFunctionFactory } from "../internal-utils";
import LIST_FLOW_RUNS_BY_FLOW from "./list_flow_runs_by_flow.graphql";

export const queryFlowRunsByFlow = queryFunctionFactory<
  IListFlowRunsByFlowQuery,
  IListFlowRunsByFlowQueryVariables
>(LIST_FLOW_RUNS_BY_FLOW);
