import Vue from "vue";
import { Component } from "vue-property-decorator";
import styles from "./NoNav.layout.module.scss";

@Component
export default class Wizard extends Vue {
  render() {
    return (
      <div class={styles.container}>
        <router-view />
      </div>
    );
  }
}
