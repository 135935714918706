import {
  createBillingAccountMutation,
  listBillingAccountsByOrgQuery,
  updateBillingAccountMutation,
} from "@/api/billing-account";
import {
  ICreateBillingAccountMutationVariables,
  IListBillingAccountsByOrgQueryVariables,
  IUpdateBillingAccountMutationVariables,
} from "@/generated/types";
import { SimplifyType, UnwrappedPromise } from "@/utils/types";
import { IAddressState } from "./org";
import {
  extractData,
  extractPropOrThrow,
  extractAndFilterItemsOrProvideDefault,
  parseJsonStringProp,
} from "./utils";

export async function createBillingAccount(
  variables: ICreateBillingAccountMutationVariables
) {
  const response = await createBillingAccountMutation(variables);
  const data = extractPropOrThrow(response, "data");
  const billingAccount = extractPropOrThrow(data, "createBillingAccount");
  const billingAccountWithAddress = parseJsonStringProp<
    typeof billingAccount,
    "billingAddress",
    IAddressState
  >(billingAccount, "billingAddress");

  return billingAccountWithAddress;
}

export async function updateBillingAccount(
  variables: IUpdateBillingAccountMutationVariables
) {
  const response = await updateBillingAccountMutation(variables);
  const data = extractPropOrThrow(response, "data");
  const billingAccount = extractPropOrThrow(data, "updateBillingAccount");
  const billingAccountWithAddress = parseJsonStringProp<
    typeof billingAccount,
    "billingAddress",
    IAddressState
  >(billingAccount, "billingAddress");

  return billingAccountWithAddress;
}

export async function orgBillingAccount(
  variables: IListBillingAccountsByOrgQueryVariables
) {
  const response = await listBillingAccountsByOrgQuery(variables);
  const billingAccounts = extractAndFilterItemsOrProvideDefault(
    extractPropOrThrow(extractData(response), "listBillingAccountsByOrg")
  );

  return parseJsonStringProp<
    (typeof billingAccounts)[number],
    "billingAddress",
    IAddressState
  >(billingAccounts[0], "billingAddress");
}

export type IBillingAccountState = SimplifyType<
  UnwrappedPromise<ReturnType<typeof orgBillingAccount>>
>;
