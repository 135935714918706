import { Component } from "vue-property-decorator";
import DeleteModal from "@/components/modal/DeleteModal.component";
import { OrgModule } from "@/store/modules/org";
import { UserModule } from "@/store/modules/user";
import DataProcessingAgreementForm from "@/components/org-management/DataProcessingAgreementForm.component.vue";
import OrgDataForm from "@/components/org-management/OrgDataForm.component.vue";
import { persistanceService } from "@/utils/persistance-service";
import BillingDataForm from "@/components/org-management/BillingDataForm.component.vue";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import { clearApiClientCache } from "@/api";
import { deleteOrg } from "@/models/org";
import { userDataLoader } from "@/utils/auth-service";

@Component({
  components: {
    BillingDataForm,
    DeleteModal,
    DataProcessingAgreementForm,
    OrgDataForm,
  },
})
export default class OrgSettings extends UserPermissions {
  form = null;
  errorMessage = "";
  confirmName = "";
  isLoading = false;

  get orgName() {
    return OrgModule.name;
  }

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  get deleteTitle(): string {
    return this.$t("warning_delete_org_title", {
      name: this.orgName,
    }).toString();
  }

  get deleteInfo(): string {
    return this.$t("warning_delete_org_info", {
      name: this.orgName,
    }).toString();
  }

  get deleteInstruction(): string {
    return this.$t("warning_delete_org_instruction").toString();
  }

  async onDelete(): Promise<void> {
    try {
      if (!this.canManageOrg) {
        throw new Error(this.$t("error_org_deletion_requires_role").toString());
      }
      if (this.orgName === this.confirmName) {
        await deleteOrg(
          OrgModule.id,
          OrgModule.billingAccount?.id ?? "",
          OrgModule.billingAccount?.paymentProviderId ?? ""
        );
        this.clearCache();
        await UserModule.onUserAction(async () => {
          userDataLoader.refreshToken();
          await userDataLoader.loading;
        });

        const nextOrgId = Object.keys(UserModule.roles)[0];
        if (nextOrgId) {
          this.$router.push({
            name: "org-api-dashboard",
            params: { orgId: nextOrgId },
          });
        } else {
          OrgModule.reset();
          this.$router.push({
            name: "workspace-setup",
          });
        }
      } else {
        throw new Error(this.$t("error_org_deletion_confirm").toString());
      }
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }
  }

  clearCache() {
    persistanceService.clearWorkspace();
    clearApiClientCache();
    UserModule.handleDeletionForOrgWithId(OrgModule.id);
  }
}
