import { ISchemaExplorerType } from "@/api/schema";
import { ApolloClient, MutationOptions } from "apollo-boost";
import { DedicatedApiCacheUpdates } from "./dedicated-api-cache-updates";
import { ApiCreateMutations } from "../shared/api-create-mutations";
import { ApiDeleteMutations } from "../shared/api-delete-mutations";
import { ApiFields } from "../shared/api-fields";
import {
  DedicatedApiFileMutations,
  IUploadFileIntentVariables,
  IUploadInlineFileVariables,
} from "./dedicated-api-file-mutations";
import { ApiQueries } from "../shared/api-queries";
import {
  ICacheUpdatesHandler,
  ICustomerListByVariables,
  ICustomerListVariables,
  IGraphqlApi,
} from "../shared/types";
import { ApiUpdateMutations } from "../shared/api-update-mutations";
import { ApiSchema } from "@/models/api-schema";
import { GqlDedicatedQueryComposer } from "./gql-dedicated-query-composer";
import { DedicatedApiResultParser } from "./dedicated-api-result-parser";
import { DedicatedApiVariableParser } from "./dedicated-api-variables-parser";
import { FieldNameComposer } from "../shared/fieldname-composer";
import { QueryTypeEnum } from "@graphapi-io/api-declaration";
import { IObjectTypeState } from "@/models";

export class DedicatedApi implements IGraphqlApi {
  private queries: ApiQueries;
  private deleteMutations: ApiDeleteMutations;
  private createMutations: ApiCreateMutations;
  private updateMutations: ApiUpdateMutations;
  private fileUploadMutations: DedicatedApiFileMutations;
  private variableParser: DedicatedApiVariableParser;
  public customerApiFields: ApiFields;
  public schemaFieldTypes: Record<string, ISchemaExplorerType>;
  public cacheUpdates: ICacheUpdatesHandler;

  constructor(
    apolloClient: ApolloClient<unknown>,
    schemaFieldTypes: Record<string, ISchemaExplorerType>,
    public apiSchema: ApiSchema
  ) {
    this.customerApiFields = new ApiFields(schemaFieldTypes);
    this.schemaFieldTypes = schemaFieldTypes;
    this.variableParser = new DedicatedApiVariableParser();
    this.queries = new ApiQueries(
      apolloClient,
      new DedicatedApiResultParser(),
      this.variableParser
    );
    this.deleteMutations = new ApiDeleteMutations(apolloClient);
    this.createMutations = new ApiCreateMutations(
      apolloClient,
      this.customerApiFields
    );
    this.updateMutations = new ApiUpdateMutations(
      apolloClient,
      this.customerApiFields
    );
    this.fileUploadMutations = new DedicatedApiFileMutations(
      schemaFieldTypes,
      apolloClient
    );
    this.cacheUpdates = new DedicatedApiCacheUpdates(this.customerApiFields);
  }

  public async queryObject(objectType: IObjectTypeState, id: string) {
    return this.queries.queryObject(
      objectType?.name,
      id,
      GqlDedicatedQueryComposer.buildObjectQuery(
        objectType?.name,
        this.customerApiFields
      )
    );
  }

  public watchQueryObject(objectType: IObjectTypeState, id: string) {
    return this.queries.watchQueryObject(
      objectType?.name,
      id,
      GqlDedicatedQueryComposer.buildObjectQuery(
        objectType?.name,
        this.customerApiFields
      )
    );
  }

  public watchListObjects<T = object>(
    objectType: IObjectTypeState,
    variables: ICustomerListVariables
  ) {
    return this.queries.watchListObjects<T>(
      objectType?.name,
      variables,
      GqlDedicatedQueryComposer.buildListQuery(
        objectType?.name,
        this.customerApiFields
      ),
      FieldNameComposer.fieldNameForQueryType(
        QueryTypeEnum.LIST,
        objectType?.name
      )
    );
  }

  public watchListConnections<T = object>(
    objectType: IObjectTypeState,
    parentObjectType: IObjectTypeState,
    variables: ICustomerListByVariables,
    fields?: string[]
  ) {
    const query = GqlDedicatedQueryComposer.buildListByQuery(
      objectType?.name,
      parentObjectType?.name,
      fields
    );
    return this.queries.watchListConnections<T>(
      objectType?.name,
      parentObjectType?.name,
      variables,
      query,
      FieldNameComposer.fieldNameForListByParentType(
        objectType?.name,
        parentObjectType?.name
      )
    );
  }

  public async createObject(
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.createMutations.createObject(
      objectType?.name,
      variables,
      updateCache
    );
  }

  public async batchCreateObjects(
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown>[] },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.createMutations.batchCreateObjects(
      objectType?.name,
      variables,
      updateCache
    );
  }

  public async updateObject(
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.updateMutations.updateObject(
      objectType?.name,
      variables,
      updateCache
    );
  }

  public async uploadFileIntent(variables: IUploadFileIntentVariables) {
    return this.fileUploadMutations.uploadFileIntent(variables);
  }

  public async uploadInlineFile(
    variables: IUploadInlineFileVariables,
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.fileUploadMutations.uploadInlineFile(variables, updateCache);
  }

  public deleteObject(
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.deleteMutations.deleteObject(
      objectType?.name,
      variables,
      updateCache
    );
  }

  public batchDeleteObjects(
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown>[] },
    updateCache?: MutationOptions<any>["update"]
  ) {
    return this.deleteMutations.batchDeleteObjects(
      objectType?.name,
      variables,
      updateCache
    );
  }
}
