import {
  ConnectionCardinalityEnum,
  getAvailableConnectionTypes,
  getParentConnectionFieldName,
  getTypesWithTwoParents,
  hasUniqueDirective,
  IConnectedTypeState,
  IEnumState,
  IFieldState,
  IObjectTypeState,
  isIdField,
  updateConnectorDirective,
} from "@/models";
import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import { ObjectTypeSelect } from "./ObjectTypeSelect.component";
import style from "./ObjectTypeConnection.component.module.scss";
import { BFormGroup } from "@/lib/typed-bootstrap";
import FormInputsRow from "../forms/inputs/FormInputsRow.component";
import * as tsx from "vue-tsx-support";
import pluralize from "pluralize";
import ObjectTypeConnectionTypeSelect from "./ObjectTypeConnectionTypeSelect.component";

@Component({
  components: { ObjectTypeSelect },
})
export default class ObjectTypeConnection extends Vue {
  _tsx!: tsx.DeclareProps<
    Pick<
      ObjectTypeConnection,
      "value" | "selectedObjectType" | "allTypes" | "allEnumTypes"
    > &
      Partial<
        Pick<
          ObjectTypeConnection,
          | "onDelete"
          | "onInput"
          | "onCardinalityChange"
          | "canHaveMoreParents"
          | "canHaveMoreTargets"
        >
      >
  >;
  @Prop({ required: true }) value: IConnectedTypeState;
  @Prop({ required: true }) selectedObjectType: IObjectTypeState;
  @Prop({ required: true }) allTypes: IObjectTypeState[];
  @Prop({ required: true }) allEnumTypes: IEnumState[];
  @Prop({ default: true }) canHaveMoreParents: boolean;
  @Prop({ default: true }) canHaveMoreTargets: boolean;

  @Emit("delete") onDelete() {}
  @Emit("input") onInput(_value: string | null) {}
  @Emit("cardinalityChange") onCardinalityChange(
    _value: ConnectionCardinalityEnum
  ) {}

  get typesWithTwoParents() {
    return getTypesWithTwoParents(this.allTypes);
  }

  get availableSelectOptions(): {
    value: string;
    text: string;
    disabled?: boolean;
  }[] {
    return getAvailableConnectionTypes(
      this.selectedObjectType,
      this.allTypes,
      this.value.cardinality
    ).map(({ disabled, type }) => {
      const text =
        type.name === ""
          ? this.$t("model_widget_model_tmp_name").toString()
          : type.name;
      const pluralizedText =
        this.value.cardinality === ConnectionCardinalityEnum.IS_PARENT_OF
          ? pluralize(text)
          : text;

      return {
        value: type.id,
        text: pluralizedText,
        disabled,
      };
    });
  }

  get parentObjectType() {
    return this.value.cardinality === ConnectionCardinalityEnum.IS_CHILD_OF
      ? this.selectedConnectionObjectType
      : this.selectedObjectType;
  }
  get parentFields() {
    return this.parentObjectType?.fields ?? [];
  }

  get connectorOptions(): { value: string; text: string }[] {
    return this.parentFields
      ?.filter(
        (field) =>
          isIdField(field) ||
          hasUniqueDirective(field) ||
          this.isFieldEnum(field)
      )
      .map(this.mapFieldToOption);
  }

  get connectorValue() {
    return getParentConnectionFieldName(this.parentObjectType);
  }

  get selectedConnection(): string | null {
    return (
      this.availableSelectOptions.find(
        (option) => option.value === this.value.id
      )?.value ?? null
    );
  }

  get selectedConnectionObjectType() {
    return this.allTypes.find((t) => t.id === this.selectedConnection);
  }

  private onConnectorChange(value: string) {
    if (this.parentObjectType) {
      this.parentObjectType.fields = updateConnectorDirective(
        value,
        this.parentObjectType?.fields
      );
    }
  }

  private isFieldEnum(field: IFieldState): boolean {
    return this.allEnumTypes.some((e) => e.name === field.type);
  }

  private mapFieldToOption(field: IFieldState) {
    return {
      value: field.name,
      text: field.name,
    };
  }

  render() {
    return (
      <BFormGroup class={style.formGroup}>
        <FormInputsRow>
          <template slot="leftColumn">
            <button onClick={this.onDelete} class={style.delete}></button>
            <BFormGroup class={style.nestedFormGroup}>
              <ObjectTypeConnectionTypeSelect
                value={this.value.cardinality}
                onInput={this.onCardinalityChange}
                canHaveMoreParents={this.canHaveMoreParents}
                canHaveMoreTargets={this.canHaveMoreTargets}
              />
            </BFormGroup>
          </template>
          <template slot="rightColumn">
            <BFormGroup class={style.nestedFormGroup}>
              <ObjectTypeSelect
                typesOptions={this.availableSelectOptions}
                value={this.selectedConnection}
                connectorOptions={this.connectorOptions}
                connectorValue={this.connectorValue}
                onInput={this.onInput}
                onConnectorChange={this.onConnectorChange}
              />
            </BFormGroup>
          </template>
        </FormInputsRow>
      </BFormGroup>
    );
  }
}
