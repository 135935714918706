import { ICognitoUserPoolData } from "amazon-cognito-identity-js";

export enum ConfigurationType {
  DEFAULT,
  CUSTOMER_API,
}

export const getDefaultAuthConfig = (): ICognitoUserPoolData => ({
  UserPoolId: process.env.VUE_APP_USER_POOL_ID ?? "unknown",
  ClientId: process.env.VUE_APP_CLIENT_ID ?? "unknown",
});
