import { ProductNameEnum, SubscribedProductEnum } from "@graphapi-io/products";
import { apiQuery, updateApiMutation, watchApiQuery } from "@/api/api";
import { publishApiMutation } from "@/api/publish_runs";
import {
  ArchiveFormatTypeEnum,
  AuthMethodTypeEnum,
  FieldDirectiveEnum,
  PublishingStateEnum,
  PublishRunEnum,
} from "@/api/common-types";
import {
  extendObjectTypeWithConnectionIndexes,
  IFieldState,
  IObjectTypeState,
  IResolverState,
  ITargetState,
} from "../object-type";
import { isNotNullable, sortObjectArrayByKey } from "@/utils";
import ShortUniqueId from "short-unique-id";
import { GraphapiModule } from "@/store/modules/graphapi";
import _uniq from "lodash/uniq";
import {
  extractData,
  parseJsonStringProp,
  extractPropOrThrow,
  extractAndFilterItemsOrProvideDefault,
} from "../utils";
import { SimplifyType, UnwrappedPromise } from "@/utils/types";
import { mapResponseToPublishRun } from "../publish-run";
import {
  IApiPublishRunsConnection,
  IApiUpdateInput,
  IPublishRunInput,
  IInlineS3ObjectInput,
  ICheckoutSession,
  Maybe,
  IQueryApiQueryVariables,
} from "@/generated/types";
import {
  ArchiveTypeEnum,
  MutationTypeEnum,
  PipelineStepEnum,
  IApiDeclaration,
  IEnumTypeDeclaration,
  IFieldDeclaration,
  IObjectTypeDeclaration,
  IResolverDeclaration,
  RuntimeEnum,
  FieldTypeEnum,
} from "@graphapi-io/api-declaration";
import { UserModule } from "@/store";

const readonlyKeys = ["id", "createdAt", "updatedAt"];
const { randomUUID } = new ShortUniqueId({ length: 6 });

export interface IEnumValueState {
  name: string;
  selected?: boolean;
}
export interface IApiKeyConfigState {
  key: string | null;
}
export interface ICognitoConfigState {
  clientId: string | null;
  poolId: string | null;
}
export interface IOidcConfigState {
  issuerUrl: string | null;
  clientId?: string | null;
}

export interface IEnumState {
  id: string;
  name: string;
  values: IEnumValueState[];
  selected?: boolean;
  state?: PublishingStateEnum;
}

export interface IApiKeyState {
  createdAt: Date;
  expires: Date;
  description: string;
}

export type IApiState = SimplifyType<
  UnwrappedPromise<ReturnType<typeof getApiDetails>>
>;

export type IPublishRunState = SimplifyType<IApiState["publishRuns"][number]>;

export interface IApiResponseMappableProps {
  id: string;
  schema?: string | null;
  region?: string | null;
  state?: PublishingStateEnum | null;
  oidc?: string | null;
  additionalAuthModes?: Maybe<Array<Maybe<AuthMethodTypeEnum>>>;
  publishRunConnection?: Maybe<IApiPublishRunsConnection>;
  getApiDetails?: Maybe<IApiState>;
  bundleSubscriptionConnection?: Maybe<{
    items?: Maybe<Maybe<{ products?: Maybe<Maybe<string>[]> }>[]>;
  }>;
}

export enum ApiRegionEnum {
  AsiaPacificMumbai = "ap-south-1",
  AsiaPacificSeoul = "ap-northeast-2",
  AsiaPacificSingapore = "ap-northeast-1",
  AsiaPacificSydney = "ap-southeast-2",
  AsiaPacificTokyo = "ap-southeast-1",
  Canada = "ca-central-1",
  EuropeFrankfurt = "eu-central-1",
  EuropeIreland = "eu-west-1",
  EuropeLondon = "eu-west-2",
  EuropeParis = "eu-west-3",
  EuropeStockholm = "eu-north-1",
  MiddleEast = "me-south-1",
  SouthAmerica = "sa-east-1",
  USEast = "us-east-2",
  USWestNCalifornia = "us-west-1",
  USWestOregon = "us-west-2",
}

export const authHeadersForAuthMode = (apiUpdateInput: IApiState) => {
  if (apiUpdateInput.apiGatewayUrl) {
    return {
      authorization: UserModule.accessToken,
    };
  }
  switch (apiUpdateInput.defaultAuthMode) {
    case AuthMethodTypeEnum.AWS_OIDC:
      GraphapiModule.initializeTokenEntry(apiUpdateInput.id);
      return {
        authorization: `Bearer ${
          GraphapiModule.accessTokens[apiUpdateInput.id]
        }`,
      };

    case AuthMethodTypeEnum.AWS_COGNITO_USER_POOLS:
      GraphapiModule.initializeTokenEntry(apiUpdateInput.id);
      return { authorization: UserModule.accessToken };

    default:
    case AuthMethodTypeEnum.AWS_API_KEY:
      return { ["x-api-key"]: apiUpdateInput.apiKey ?? "" };
  }
};

export async function getApiDetails(
  variables: IQueryApiQueryVariables,
  fetchPolicy: any = "cache-first"
) {
  const response = await apiQuery(variables, fetchPolicy);
  return mapResponseToApi(extractPropOrThrow(extractData(response), "api"));
}

export function watchApiDetails(variables: IQueryApiQueryVariables) {
  return watchApiQuery(variables).observable.map((value) =>
    mapResponseToApi(extractPropOrThrow(extractData(value), "api"))
  );
}

export function mapResponseToApi<T extends IApiResponseMappableProps>(api: T) {
  const apiWithTypes = extendWithTypes<T>(api);
  const apiWithProducts = parseProducts<typeof apiWithTypes>(apiWithTypes);
  return parsePublishRuns<typeof apiWithProducts>(apiWithProducts);
}

const extendWithTypes = <T extends IApiResponseMappableProps>(api: T) => {
  const { enumTypes, objectTypes, authProvidersConfig } =
    parseDeclarationSchema(api.id, api.schema ?? undefined);
  const apiWithResolvedOidc = parseJsonStringProp<T, "oidc", IOidcConfigState>(
    api,
    "oidc"
  );

  return {
    ...apiWithResolvedOidc,
    enums: enumTypes,
    types: objectTypes,
    apiKeys: [] as string[],
    checkoutSessions: [] as ICheckoutSession[],
    state: api.state ?? PublishingStateEnum.PUBLISHED,
    ...(authProvidersConfig && {
      authProvidersConfig,
    }),
    ...(!apiWithResolvedOidc.additionalAuthModes && {
      additionalAuthModes: [] as AuthMethodTypeEnum[],
    }),
  };
};

const parseProducts = <
  T extends Pick<IApiResponseMappableProps, "bundleSubscriptionConnection">
>(
  api: T
) => ({
  ...api,
  products: _uniq(
    api.bundleSubscriptionConnection?.items
      ?.flatMap((bundleSubscription) => bundleSubscription?.products)
      .filter(isNotNullable)
  ),
});

const parsePublishRuns = <
  T extends Pick<IApiResponseMappableProps, "publishRunConnection">
>({
  publishRunConnection,
  ...rest
}: T) => ({
  ...rest,
  publishRuns: extractAndFilterItemsOrProvideDefault(publishRunConnection).map(
    mapResponseToPublishRun
  ),
});

const mapEnumsToDeclarationSchema = (
  enums: IEnumState[]
): IEnumTypeDeclaration[] =>
  enums?.map(({ name, values }) => ({
    name: name,
    values:
      values?.map((enumValueType) => enumValueType.name).filter(Boolean) ?? [],
  }));

const mapTypesToDeclarationSchema = (
  types: IObjectTypeState[]
): IObjectTypeDeclaration[] => {
  // Add connector directives and omit redundant fields
  const mapFields = (type: IObjectTypeState): IFieldDeclaration[] =>
    type.fields
      .map((field) => {
        const directives = [
          ...(field.directives?.filter(
            (directive) => directive !== FieldDirectiveEnum.CONNECTOR
          ) ?? []),
        ];
        if (field.name === type.connector) {
          directives.push(FieldDirectiveEnum.CONNECTOR);
        }

        return field.type
          ? {
              id: field.id ?? randomUUID(),
              name: field.name,
              type: field.type as FieldTypeEnum,
              directives: _uniq(directives),
            }
          : null;
      })
      .filter(isNotNullable);

  const mapResolvers = (
    resolvers: IResolverState[] = []
  ): IResolverDeclaration[] =>
    resolvers.map((resolver) => ({
      ...resolver,
    }));

  // Check if types are targets of their parents and omit redundant fields
  const newTypes = types?.map((type: IObjectTypeState) => {
    const firstParentType = types.find(
      (t) => t.id === type.parents.first?.parentId
    );
    const secondParentType = types.find(
      (t) => t.id === type.parents.second?.parentId
    );
    const isTargetOfFirstParent = !!firstParentType?.targets.some(
      (t) => t.targetId === type.id
    );
    const isTargetOfSecondParent = !!secondParentType?.targets.some(
      (t) => t.targetId === type.id
    );
    const firstParent = isTargetOfFirstParent
      ? { parentId: firstParentType?.id }
      : null;
    const secondParent = isTargetOfSecondParent
      ? { parentId: secondParentType?.id }
      : null;

    return {
      id: type.id ?? randomUUID(),
      name: type.name,
      directives: type.directives,
      targets: type.targets,
      parents: {
        first: firstParent,
        second: secondParent,
      },
      fields: mapFields(type),
      resolvers: mapResolvers(type.resolvers),
    };
  });

  // Add self to targets parents if posible
  newTypes.forEach((type) => {
    type.targets.forEach((target: ITargetState) => {
      const targetType = newTypes.find((type) => type.id === target.targetId);

      if (
        targetType &&
        targetType?.parents.first?.parentId !== type.id &&
        targetType?.parents.second?.parentId !== type.id
      ) {
        if (!targetType?.parents.first || !targetType?.parents.first.parentId) {
          targetType.parents.first = { parentId: type.id };
        } else if (
          !targetType?.parents.second ||
          !targetType?.parents.second.parentId
        ) {
          targetType.parents.second = { parentId: type.id };
        }
      }
    });
  });

  // map ids to type names for targets and parents
  const typesWithConnectionNames = newTypes.map((type) => {
    const newTargets = type.targets
      ?.map((target) => {
        const targetType = newTypes.find((t) => t.id === target.targetId);
        if (targetType) {
          return targetType.name;
        }
      })
      .filter(isNotNullable);
    const firstParent = newTypes.find((t) => {
      return t.id === type.parents.first?.parentId;
    });
    const secondParent = newTypes.find((t) => {
      return t.id === type.parents.second?.parentId;
    });

    return {
      ...type,
      targets: newTargets,
      parents: {
        first: firstParent ? firstParent.name : null,
        second: secondParent ? secondParent.name : null,
      },
    };
  });

  return typesWithConnectionNames;
};

export const declarationSchemaForInput = (
  apiData: IApiState,
  version?: number,
  products?: string[]
) => {
  const apiVersionNumber =
    version ?? (apiData.publishRuns ?? [])[0]?.schemaVersion ?? 1;

  const uniqueProductsList = _uniq([
    ...((apiData.products ?? []) as (
      | ProductNameEnum
      | SubscribedProductEnum
      | string
    )[]),
    ...(products ?? []),
  ]);

  const apiDeclarationInput: IApiDeclaration = {
    id: apiData.id,
    version: apiVersionNumber,
    ...(apiData.usePrefix && { usePrefix: true }),
    ...(apiData.oidc && {
      oidc: {
        ...apiData.oidc,
        clientId: apiData.oidc.clientId ?? "",
        issuerUrl: apiData.oidc.issuerUrl ?? "",
      },
    }),
    name: apiData.name ?? "",
    orgId: apiData.orgId ?? "",
    region: apiData.region ?? ApiRegionEnum.EuropeFrankfurt,
    awsAccountId: apiData.awsAccountId ?? "",
    ...(apiData.defaultAuthMode
      ? {
          defaultAuthMode: apiData.defaultAuthMode,
        }
      : {}),
    additionalAuthModes:
      apiData.additionalAuthModes?.filter(isNotNullable) ?? [],
    products: uniqueProductsList,
    retentionInDays: uniqueProductsList.includes(ProductNameEnum.pro_bundle)
      ? "14"
      : "1",
    enums: mapEnumsToDeclarationSchema(apiData.enums),
    types: mapTypesToDeclarationSchema(apiData.types),
    authProvidersConfig: apiData.authProvidersConfig,
  };

  return JSON.stringify(apiDeclarationInput);
};

export const declarationSchemaWithVersion = (
  schemaVersion: number,
  schema?: string,
  products?: string[]
) => {
  if (!schema) return undefined;

  const schemaJSON = JSON.parse(schema);
  schemaJSON.version = schemaVersion;

  if (products) {
    schemaJSON.products = products;
  }

  return JSON.stringify(schemaJSON);
};

export const parseDeclarationSchema = (
  apiId: string | undefined,
  schema: string | undefined
): {
  enumTypes: IEnumState[];
  objectTypes: IObjectTypeState[];
  authProvidersConfig: IApiDeclaration["authProvidersConfig"];
} => {
  const parsedDeclarationSchema: IApiDeclaration | undefined = schema
    ? JSON.parse(schema)
    : undefined;
  const enumTypes: IEnumState[] =
    parsedDeclarationSchema?.enums?.map((enumType, index) => {
      const values = enumType.values?.map((enumTypeValue) => {
        return {
          name: enumTypeValue,
          selected: false,
        };
      });

      return {
        id: enumType.id ?? randomUUID(),
        name: enumType.name,
        selected: index === 0 ? true : false,
        values: values,
      };
    }) ?? [];
  const rawObjectTypes = parsedDeclarationSchema?.types ?? [];
  const objectTypes: IObjectTypeState[] = rawObjectTypes
    .map((objectType, index) => {
      let connector = null;
      const fields = objectType.fields?.map((field) => {
        if (field.directives?.includes(FieldDirectiveEnum.CONNECTOR)) {
          connector = field.name;
        }

        return {
          id: field.id ?? randomUUID(),
          name: field.name,
          type: field.type,
          typeId: objectType.id,
          readonly: readonlyKeys.includes(field.name),
          selected: false,
          directives:
            field.directives?.map((directive) => {
              return directive;
            }) ?? [],
        };
      }, []);

      const targets = objectType.targets
        ?.map((targetName) => {
          const targetType = rawObjectTypes.find(
            (type) => type.name === targetName
          );
          return targetType
            ? {
                targetId: targetType.id,
                connectionIndex: -1,
              }
            : null;
        })
        .filter(isNotNullable);

      const firstParent = rawObjectTypes.find(
        (type) => type.name === objectType.parents?.first
      );
      const secondParent = rawObjectTypes.find((type) => {
        return type.name === objectType.parents?.second;
      });
      const resolvers =
        objectType.resolvers?.map((resolver) => ({
          ...resolver,
          archiveType: ArchiveTypeEnum.S3BUCKET as ArchiveTypeEnum.S3BUCKET,
          runtime: (resolver.runtime as RuntimeEnum) ?? RuntimeEnum.NODEJS_18_X,
          step: resolver.step as PipelineStepEnum,
          types: resolver.types as MutationTypeEnum[],
        })) ?? [];

      return {
        id: objectType.id,
        name: objectType.name,
        connector: connector ?? "id",
        ...(apiId && { apiId }),
        selected: index === 0 ? true : false,
        products: parsedDeclarationSchema?.products,
        usePrefix: parsedDeclarationSchema?.usePrefix
          ? parsedDeclarationSchema?.usePrefix
          : false,
        parents: {
          first: objectType.parents?.first
            ? firstParent
              ? { parentId: firstParent.id, connectionIndex: -1 }
              : { parentId: null, connectionIndex: -1 }
            : null,
          second: objectType.parents?.second
            ? secondParent
              ? { parentId: secondParent.id, connectionIndex: -1 }
              : { parentId: null, connectionIndex: -1 }
            : null,
        },
        targets: targets,
        directives: objectType.directives ?? [],
        fields: sortObjectArrayByKey(fields, "name"),
        resolvers,
      };
    })
    .map((type) => {
      extendObjectTypeWithConnectionIndexes(type);
      return type;
    });

  return {
    enumTypes,
    objectTypes,
    authProvidersConfig: parsedDeclarationSchema?.authProvidersConfig ?? {},
  };
};

export const reinitObjectTypes = (apiData: IApiState): IObjectTypeState[] => {
  return apiData.types?.map((type) => {
    const objectType = {
      id: type.id,
      name: type.name,
      connector: type.connector,
      selected: type.selected,
      apiId: apiData.id,
      state: type.state,
      fields: type.fields?.map((field) => {
        return {
          name: field.name,
          type: field.type,
          readonly: field.readonly,
          selected: field.selected,
          typeId: field.typeId,
          state: field.state,
          directives:
            field.directives?.map((directive) => {
              return directive;
            }) ?? [],
        } as IFieldState;
      }),
      parents: type.parents,
      targets: type.targets?.map((target) => {
        return {
          targetId: target.targetId,
        } as ITargetState;
      }),
      resolvers: type.resolvers,
    } as IObjectTypeState;

    extendObjectTypeWithConnectionIndexes(objectType);

    return objectType;
  });
};

export const publishApi = (
  apiData: IApiState,
  initState: PublishingStateEnum,
  products: string[],
  schema?: string
) => {
  if (!apiData.awsAccountId) {
    throw new Error("Publishing not possible. Please contact support.");
  }

  const apiId = apiData.id;
  const schemaVersion =
    apiData.publishRuns && apiData.publishRuns[0]?.schemaVersion
      ? apiData.publishRuns[0]?.schemaVersion + 1
      : 1;

  const apiSchema =
    declarationSchemaWithVersion(schemaVersion, schema, products) ??
    declarationSchemaForInput(apiData, schemaVersion, products);

  const publishRunId = `${apiId}-v${schemaVersion}`;
  const publishRunInput: IPublishRunInput = {
    id: publishRunId,
    apiId: apiId,
    schema: apiSchema,
    schemaVersion: schemaVersion,
    initState: initState,
    status: PublishRunEnum.IN_PROGRESS,
  };

  const inlineS3ObjectInput: IInlineS3ObjectInput = {
    bucket: process.env.VUE_APP_FILE_BUCKET ?? "",
    key: `${publishRunId}-api-declaration.json`,
    mimeType: "application/json",
    body: apiSchema,
    archiveFormat: ArchiveFormatTypeEnum.ZIP,
  };

  const apiUpdateInput: IApiUpdateInput = {
    id: apiId,
    schema: apiSchema,
    state: PublishingStateEnum.PUBLISHING,
    awsAccountId: apiData.awsAccountId,
  };

  GraphapiModule.initializeStateEntry({
    apiId,
    state: PublishingStateEnum.PUBLISHING,
  });

  return publishApiMutation(
    apiUpdateInput,
    publishRunInput,
    inlineS3ObjectInput
  );
};

export const updateApi = (apiData: IApiState) => {
  const {
    apiKeys,
    oidc,
    enums,
    types,
    checkoutSessions,
    publishRuns,
    bundleSubscriptionConnection,
    products,
    authProvidersConfig,
    ...rest
  } = apiData;
  const apiUpdateInput: IApiUpdateInput = {
    ...rest,
    ...(apiData.oidc && { oidc: JSON.stringify(apiData.oidc) }),
  };
  return updateApiMutation(apiUpdateInput);
};

export interface IAuthModeConfigurationState {
  [AuthMethodTypeEnum.AWS_API_KEY]?: IApiKeyConfigState;
  [AuthMethodTypeEnum.AWS_COGNITO_USER_POOLS]?: ICognitoConfigState;
  [AuthMethodTypeEnum.AWS_OIDC]?: IOidcConfigState;
}
