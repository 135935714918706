import {
  extractData,
  extractAndFilterItemsOrProvideDefault,
  extractPropOrThrow,
} from "./utils";
import { queryFlowRunsLogsByFlowRun } from "@/api/flow-run-log";

export async function getFlowRunLogByFlowRunId(flowRunId: string) {
  const response = await queryFlowRunsLogsByFlowRun({ id: flowRunId });

  return extractAndFilterItemsOrProvideDefault(
    extractPropOrThrow(extractData(response), "listFlowRunLogsByFlowRun")
  );
}
