import { ApiSchema } from "@/models/api-schema";
import { IResultParser } from "../shared/types";
import { ComplexFieldTypeEnum } from "@/models/object-type/types";
import { FieldNameComposer } from "../shared/fieldname-composer";
import { QueryTypeEnum } from "@graphapi-io/api-declaration";

export class GenericApiResultParser implements IResultParser {
  constructor(private apiSchema: ApiSchema) {}

  private flattenFields(
    response: { fields?: string } | null,
    typeName: string
  ) {
    if (response === null) return null;

    const { fields, ...rest } = response;
    const fieldsConfigs = this.apiSchema.types[typeName]?.fields ?? [];

    const fieldsMap = Object.fromEntries(
      fieldsConfigs.map((field) => [field.name, field.type])
    );

    const parsedFields = fields ? JSON.parse(fields) : {};

    for (const key in parsedFields) {
      if (fieldsMap[key] === ComplexFieldTypeEnum.Json) {
        parsedFields[key] = JSON.stringify(parsedFields[key]);
      }
    }

    return {
      ...rest,
      ...parsedFields,
    };
  }

  public parseObjectQueryResult(data: any, typeName: string) {
    const result =
      data[
        FieldNameComposer.fieldNameForQueryType(QueryTypeEnum.OBJECT, "Type")
      ];

    return this.flattenFields(result, typeName);
  }

  public parseListQueryResult(items: any[], typeName: string) {
    return items.map((i: any) => this.flattenFields(i, typeName));
  }
}
