import Vue from "vue";
import { Component } from "vue-property-decorator";
import {
  RouterLinkProps,
  RouterLinkSlotArgument,
} from "vue-router/types/router";
import * as tsx from "vue-tsx-support";

@Component
export default class Link extends Vue {
  _tsx!: tsx.DeclareProps<RouterLinkProps>;

  render() {
    return (
      <router-link
        custom
        on={this.$listeners}
        attrs={this.$attrs}
        scopedSlots={{
          default: ({
            href,
            navigate,
            isActive,
            isExactActive,
          }: RouterLinkSlotArgument) => (
            <a
              href={href}
              onClick={navigate}
              class={{
                [this.$attrs.class]: true,
                [this.$attrs.activeClass]: isActive,
                [this.$attrs.exactActiveClass]: isExactActive,
              }}
            >
              {this.$slots.default}
            </a>
          ),
        }}
      />
    );
  }
}
