import { DedicatedApi } from "./dedicated-api";
import { ICustomerListVariables } from "../shared/types";
import { IObjectTypeState } from "@/models/object-type/types";

export const FILE_DOCUMENT_TYPE = "FileDocument";

const fileDocumentType = (): IObjectTypeState => {
  return {
    id: "",
    name: FILE_DOCUMENT_TYPE,
    targets: [],
    parents: { first: null, second: null },
    fields: [],
    directives: [],
    resolvers: [],
    selected: false,
  };
};

export class CustomerApiForFiles {
  constructor(private dedicatedApi: DedicatedApi) {}

  watchFileDocuments(variables: ICustomerListVariables) {
    return this.dedicatedApi.watchListObjects<{
      [key: string]: string | number | null | undefined;
    }>(fileDocumentType(), variables);
  }

  batchCreateFileDocumentsFromIntents(
    intents: { key: string; mimeType: string; url: string; name: string }[],
    initialVariables: ICustomerListVariables
  ) {
    return this.dedicatedApi.batchCreateObjects(
      fileDocumentType(),
      {
        input: intents.map((intent) => ({
          id: intent.key,
          key: intent.key,
          name: intent.name,
          mimeType: intent.mimeType,
          url: intent.url,
        })),
      },
      this.dedicatedApi.cacheUpdates.addToListFrontAfterBatchCreationFactory(
        fileDocumentType(),
        initialVariables
      )
    );
  }

  batchDeleteFileDocuments(
    input: Record<string, unknown>[],
    initialVariables: ICustomerListVariables
  ) {
    return this.dedicatedApi.batchDeleteObjects(
      fileDocumentType(),
      {
        input,
      },
      this.dedicatedApi.cacheUpdates.clearAllAfterBatchDeletionFactory(
        fileDocumentType(),
        initialVariables
      )
    );
  }

  updateFileDocument(input: Record<string, unknown>) {
    return this.dedicatedApi.updateObject(fileDocumentType(), {
      input,
    });
  }
}
