import { lowerCaseFirstLetter, upperCaseFirstLetter } from "../../utils";
import { MutationTypeEnum, QueryTypeEnum } from "@graphapi-io/api-declaration";
import pluralize from "pluralize";

export enum FieldParameterTypeEnum {
  FILTER = "FILTER",
  CONDITION = "CONDITION",
}

const fieldTypePrefixMapping: {
  [key in MutationTypeEnum | QueryTypeEnum]?: string;
} = {
  [MutationTypeEnum.CREATE]: "create",
  [MutationTypeEnum.BATCH_CREATE]: "batchCreate",
  [MutationTypeEnum.DELETE]: "delete",
  [MutationTypeEnum.BATCH_DELETE]: "batchDelete",
  [MutationTypeEnum.UPDATE]: "update",
  [QueryTypeEnum.OBJECT]: "",
  [QueryTypeEnum.LIST]: "list",
  [QueryTypeEnum.LIST_BY_PARENT]: "list",
  [QueryTypeEnum.BATCH]: "batchGet",
};

const inputTypePostfixMapping: {
  [key in MutationTypeEnum | FieldParameterTypeEnum]?: string;
} = {
  [MutationTypeEnum.CREATE]: "Input!",
  [MutationTypeEnum.BATCH_CREATE]: "Input!",
  [MutationTypeEnum.DELETE]: "DeleteInput!",
  [MutationTypeEnum.BATCH_DELETE]: "DeleteInput!",
  [MutationTypeEnum.UPDATE]: "UpdateInput!",
  [FieldParameterTypeEnum.FILTER]: "ComparisonInput",
};

const pluralizedTypes = [QueryTypeEnum.LIST, QueryTypeEnum.LIST_BY_PARENT];

export abstract class FieldNameComposer {
  public static fieldNameForMutationType(
    type: MutationTypeEnum,
    typeName: string = ""
  ) {
    const prefix = fieldTypePrefixMapping[type] ?? "";
    return `${prefix}${
      prefix.length > 0
        ? upperCaseFirstLetter(typeName)
        : lowerCaseFirstLetter(typeName)
    }`;
  }

  public static fieldNameForQueryType(
    type: QueryTypeEnum,
    typeName: string = ""
  ) {
    const prefix = fieldTypePrefixMapping[type] ?? "";
    const normalizedTypeName = pluralizedTypes.includes(type)
      ? pluralize(typeName)
      : typeName;
    return `${prefix}${
      prefix.length > 0
        ? upperCaseFirstLetter(normalizedTypeName)
        : lowerCaseFirstLetter(normalizedTypeName)
    }`;
  }

  public static fieldNameForListByParentType(
    typeName: string = "",
    parentTypeName: string = ""
  ) {
    const prefix = fieldTypePrefixMapping[QueryTypeEnum.LIST_BY_PARENT] ?? "";
    return `${prefix}${pluralize(
      upperCaseFirstLetter(typeName)
    )}By${upperCaseFirstLetter(parentTypeName)}`;
  }

  public static inputNameForType(
    type: MutationTypeEnum | FieldParameterTypeEnum,
    typeName: string = ""
  ) {
    const postfix = inputTypePostfixMapping[type] ?? "";
    return `${typeName}${postfix}`;
  }
}
