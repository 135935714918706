import { PublishingState } from "@/generated/types";
import { getTypesDiff, sortObjectArrayByKey } from "@/utils";
import { IEnumState } from "../api";
import { v4 as uuidv4 } from "uuid";

const getEmptyEnumWithName = (name: string): IEnumState => ({
  id: uuidv4(),
  name: name,
  values: [],
  state: PublishingState.IMPORTED,
});

export function getEnumArraysDiff(
  oldEnums: IEnumState[],
  newEnums: IEnumState[]
) {
  const uniqueInOldTypes = oldEnums.filter((oldEnum) => {
    return !newEnums.some((newEnum) => {
      return oldEnum.name === newEnum.name;
    });
  });

  const uniqueInNewTypes = newEnums.filter((newEnum) => {
    return !oldEnums.some((oldEnum) => {
      return newEnum.name === oldEnum.name;
    });
  });

  const sortedOldEnums = sortObjectArrayByKey(
    [
      ...uniqueInNewTypes.map((entry) => getEmptyEnumWithName(entry.name)),
      ...oldEnums,
    ],
    "name"
  );

  const sortedNewEnums = sortObjectArrayByKey(
    [
      ...uniqueInOldTypes.map((entry) => getEmptyEnumWithName(entry.name)),
      ...newEnums,
    ],
    "name"
  );

  return getTypesDiff(sortedOldEnums, sortedNewEnums, ["name", "values"]);
}
