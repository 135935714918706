import { Component, Vue, Watch } from "vue-property-decorator";
import WizardStep from "@/components/wizard/WizardStep.component";
import { BAlert, BForm, BFormGroup, BSpinner } from "@/lib/typed-bootstrap";
import * as tsx from "vue-tsx-support";
import styles from "./ProjectPublishAuth.view.module.scss";
import {
  AuthMethodTypeEnum,
  FieldDirectiveEnum,
  ObjectDirectiveEnum,
  PublishingStateEnum,
} from "@/api/common-types";
import {
  BaseFieldTypeEnum,
  ComplexFieldTypeEnum,
  createObjectTypeBase,
} from "@/models/object-type";
import { declarationSchemaForInput } from "@/models";
import ApiDataManager from "@/components/api-data/api-data-manager";
import {
  Button,
  ButtonShapeEnum,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "@/components/shared/Button.component";
import ApiConfigUsersTable from "@/components/api-management/ApiConfigUsersTable.component";
import Card from "@/components/shared/Card.component";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import userMgntIconDark from "@/assets/img/icon-usermgnt-primary.svg";
import userMgntIconLight from "@/assets/img/icon-usermgnt-black.svg";
import userMgntCustomIconDark from "@/assets/img/icon-usermgnt-custom-primary.svg";
import userMgntCustomIconLight from "@/assets/img/icon-usermgnt-custom-black.svg";
import ShortUniqueId from "short-unique-id";

const { randomUUID } = new ShortUniqueId({ length: 6 });

enum ConfigOptionsEnum {
  DefaultUserTable = "DefaultUserTable",
  CustomUserTable = "CustomUserTable",
}

@Component
export default class ProjectPublishAuth extends Vue {
  private apiDataManager = Vue.observable(new ApiDataManager());
  private selectedUserTableConfigOption: ConfigOptionsEnum =
    ConfigOptionsEnum.DefaultUserTable;
  private userTypeConfigured = false;

  get apiId(): string {
    return this.$route.params.apiId;
  }

  private get configOptions() {
    return [
      {
        value: ConfigOptionsEnum.DefaultUserTable,
        text: this.$t("api_publish_wizard_default_table").toString(),
        icon: {
          light: userMgntIconLight,
          dark: userMgntIconDark,
        },
      },
      {
        value: ConfigOptionsEnum.CustomUserTable,
        text: this.$t("api_publish_wizard_custom_table").toString(),
        icon: {
          light: userMgntCustomIconLight,
          dark: userMgntCustomIconDark,
        },
      },
    ];
  }

  private get customUserTableOptionSelected() {
    return (
      this.selectedUserTableConfigOption === ConfigOptionsEnum.CustomUserTable
    );
  }

  @Watch("apiId", { immediate: true }) async onApiIdChange() {
    if (!this.apiId) {
      return;
    }

    await this.apiDataManager.onApiIdChange(this.apiId);
    if (!this.apiDataManager.apiState) {
      return;
    }

    this.selectedUserTableConfigOption = ConfigOptionsEnum.DefaultUserTable;
  }

  private async onSubmit() {
    if (!this.apiDataManager.apiState) {
      return;
    }

    this.apiDataManager.errorMessage = "";
    if (
      this.apiDataManager.apiState &&
      this.selectedUserTableConfigOption === ConfigOptionsEnum.DefaultUserTable
    ) {
      const userType =
        this.apiDataManager.apiState.types?.find((t) =>
          t.directives.includes(ObjectDirectiveEnum.USERS)
        ) ??
        this.apiDataManager.apiState.types?.find((t) => t.name === "User") ??
        createObjectTypeBase("User", this.apiDataManager.apiState.usePrefix);

      userType.directives = Array.from(
        new Set([...userType.directives, ObjectDirectiveEnum.USERS])
      );

      if (
        !userType.fields.find((f) => f.name === "email") &&
        !userType.fields.find((f) =>
          f.directives?.includes(FieldDirectiveEnum.USER_EMAIL)
        )
      ) {
        userType.fields.push({
          id: randomUUID(),
          type: ComplexFieldTypeEnum.Email,
          name: "email",
          typeId: userType.id,
          directives: [FieldDirectiveEnum.USER_EMAIL],
          state: PublishingStateEnum.INITIALIZED,
        });
      }

      if (
        !userType.fields.find((f) => f.name === "password") &&
        !userType.fields.find((f) =>
          f.directives?.includes(FieldDirectiveEnum.USER_PASSWORD)
        )
      ) {
        userType.fields.push({
          id: randomUUID(),
          type: BaseFieldTypeEnum.String,
          name: "password",
          typeId: userType.id,
          directives: [FieldDirectiveEnum.USER_PASSWORD],
          state: PublishingStateEnum.INITIALIZED,
        });
      }

      if (
        this.apiDataManager.apiState.types &&
        !this.apiDataManager.apiState.types.find((t) => t.name === "User") &&
        !this.apiDataManager.apiState.types.find((t) =>
          t.directives.includes(ObjectDirectiveEnum.USERS)
        )
      ) {
        this.apiDataManager.apiState.types.push(userType);
      }

      this.userTypeConfigured = true;
    }

    if (!this.userTypeConfigured) {
      this.apiDataManager.errorMessage = this.$t(
        "error_api_create_requires_usertype"
      ).toString();
      return;
    }

    await this.apiDataManager.updateAuthAndSchema({
      schema: declarationSchemaForInput(this.apiDataManager.apiState),
      defaultAuthMode: AuthMethodTypeEnum.AWS_COGNITO_USER_POOLS,
      additionalAuthModes: [AuthMethodTypeEnum.AWS_API_KEY],
    });

    if (!this.apiDataManager.errorMessage) {
      this.$router.push({ name: "project-publish-region" });
    }
  }

  private onCardClick(configOption: ConfigOptionsEnum) {
    this.selectedUserTableConfigOption = configOption;
  }

  private handleUserTypeConfigured() {
    this.userTypeConfigured = true;
  }

  render() {
    return (
      <WizardStep
        toLocationOnClose={{
          name: "api-details-data",
          params: { apiId: this.apiId },
        }}
      >
        <template slot="wizardName">
          {this.$t("api_publish_wizard_name", { step: 1, stepCount: 3 })}
        </template>
        <template slot="header">
          {this.$t("api_setup_init_auth_headline")}
        </template>
        <template slot="description">
          {this.$t("api_setup_init_auth_subline")}
        </template>
        <BAlert
          show={this.apiDataManager.showErrorMessage}
          variant="danger"
          data-testid="apiSetupFormError"
        >
          {this.apiDataManager.errorMessage}
        </BAlert>
        <BForm
          ref="form"
          class="default__inline-form"
          accept-charset="UTF-8"
          role="form"
          novalidate
          onSubmit={tsx.modifiers.stop.prevent(this.onSubmit)}
        >
          <BFormGroup>
            <div class={styles.cardsContainer}>
              {!this.apiDataManager.isFetching &&
                this.configOptions.map((option) => {
                  return (
                    <Card
                      selected={
                        this.selectedUserTableConfigOption === option.value
                      }
                      onClick={() => this.onCardClick(option.value)}
                      class={styles.card}
                      data-testid={`setup${option.value}`}
                      aria-selected={
                        this.selectedUserTableConfigOption === option.value
                      }
                    >
                      <div class={styles.cardBody}>
                        <div class={styles.iconContainer}>
                          <ThemedImage
                            src={option.icon}
                            alt={`${option.text} icon`}
                          />
                        </div>
                        <span class={styles.cardTitle}>{option.text}</span>
                      </div>
                    </Card>
                  );
                })}
            </div>
            {this.customUserTableOptionSelected && (
              <ApiConfigUsersTable
                objectTypes={this.apiDataManager.apiState?.types ?? []}
                onConfigured={this.handleUserTypeConfigured}
                class={styles.userTableConfig}
              />
            )}
          </BFormGroup>
          <Button
            type="submit"
            onClick={tsx.modifiers.prevent(this.onSubmit)}
            data-testid="continue-button"
            class={[
              styles.submitButton,
              {
                [styles.withSmallerMargin]: true,
              },
            ]}
            size={ButtonSizeEnum.lg}
            theme={ButtonThemeEnum.primary}
            shape={ButtonShapeEnum.square}
          >
            {this.apiDataManager.isUpdating && (
              <div class="d-flex default__spinner" role="status">
                <BSpinner
                  class="ml-auto"
                  type="grow"
                  label="Spinning"
                  show={false}
                ></BSpinner>
              </div>
            )}
            <div class="button__text">{this.$t("form_cta_continue")}</div>
          </Button>
        </BForm>
      </WizardStep>
    );
  }
}
