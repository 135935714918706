import { BSpinner } from "@/lib/typed-bootstrap";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import style from "./DataEntryFormActionButtons.component.module.scss";

@Component
export default class DataEntryFormActionButtons extends Vue {
  _tsx!: tsx.DeclareProps<{
    isLoading?: DataEntryFormActionButtons["isLoading"];
    shouldCreateNewEntry?: DataEntryFormActionButtons["shouldCreateNewEntry"];
  }> &
    tsx.DeclareOnEvents<{
      onCancelSettingsChange: void;
      onSaveSettingsChange: void;
    }>;

  @Prop({ default: false }) isLoading: boolean;
  @Prop({ default: false }) shouldCreateNewEntry: boolean;

  private get saveButtonCopy(): string {
    return this.shouldCreateNewEntry ? "Create" : "Save";
  }

  @Emit("cancelSettingsChange") onCancelSettingsChange() {}

  @Emit("saveSettingsChange") onSaveSettingsChange() {}

  render() {
    return (
      <div class={style.actionButtonsSection}>
        <button
          class="action-button__pill--outline sidebar action-button__sidebar-flex-props"
          onClick={tsx.modifiers.prevent(this.onCancelSettingsChange)}
          type="reset"
          data-testid="cancelDataEntryCreation"
        >
          {this.$t("global_cta_cancel")}
        </button>
        <button
          class="action-button__pill--gradient sidebar action-button__sidebar-flex-props"
          onClick={tsx.modifiers.prevent(this.onSaveSettingsChange)}
          type="submit"
          data-testid="createDataEntry"
        >
          {this.isLoading && (
            <div class="default__spinner--small" role="status">
              <BSpinner
                class="ml-auto"
                type="grow"
                label="Saving..."
                small
                show={false}
              ></BSpinner>
            </div>
          )}
          {this.saveButtonCopy}
        </button>
      </div>
    );
  }
}
