import {
  ICreateSchemaPromptMutation,
  ICreateSchemaPromptMutationVariables,
  ISchemaPromptQuery,
  ISchemaPromptQueryVariables,
} from "@/generated/types";
import {
  mutationFunctionFactory,
  queryFunctionFactory,
} from "../internal-utils";
import SCHEMA_PROMPT_MUTATION from "./create_schema_prompt.graphql";
import SUBSCRIBE_SCHEMA_PROMPT from "./subscribe_schema_prompt.graphql";
import QUERY_SCHEMA_PROMPT from "./query_schema_prompt.graphql";
import { print } from "graphql";

export const createSchemaPromptMutation = mutationFunctionFactory<
  ICreateSchemaPromptMutation,
  ICreateSchemaPromptMutationVariables
>(SCHEMA_PROMPT_MUTATION);

export const querySchemaPrompt = queryFunctionFactory<
  ISchemaPromptQuery,
  ISchemaPromptQueryVariables
>(QUERY_SCHEMA_PROMPT);

const SUBSCRIBE_SCHEMA_PROMPT_STRING = print(SUBSCRIBE_SCHEMA_PROMPT);

export { SUBSCRIBE_SCHEMA_PROMPT_STRING as SUBSCRIBE_SCHEMA_PROMPT };
