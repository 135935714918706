import ApolloClient from "apollo-boost";
import { IApiState } from "@/models";
import { ISchemaState, ISchemaExplorerType } from "@/api/schema";
import { v4 as uuid4 } from "uuid";
import _get from "lodash/get";

import IntrospectionQuery from "@/api/schema/introspection_query.graphql";

interface IDeclarationEnumState {
  name: string;
  values: string[];
}

interface IDeclarationFieldState {
  type: string;
  name: string;
}

interface IDeclarationTypeState {
  id: string;
  name: string;
  fields: IDeclarationFieldState[];
  parents: { first: string | null; second: string | null };
  targets: string[];
}

interface IApiDeclarationState {
  name: string;
  region: string;
  replicationRegions: string[];
  defaultAuthMode: string;
  enums: IDeclarationEnumState[];
  types: IDeclarationTypeState[];
}

export class ApiSchemaHandler {
  private _apiSchema: ISchemaState = {} as ISchemaState;

  private _apiClient: ApolloClient<unknown> | null = null;

  private _rootType = "__root__";

  public inProgress: boolean = false;

  private _schemaExplorerTypes: { [key: string]: ISchemaExplorerType } = {};

  private _schemaFieldTypes: { [key: string]: ISchemaExplorerType } = {};

  get rootType(): string {
    return this._rootType;
  }

  get schemaExplorerTypes(): { [key: string]: ISchemaExplorerType } {
    return this._schemaExplorerTypes;
  }

  get schemaFieldTypes(): { [key: string]: ISchemaExplorerType } {
    return this._schemaFieldTypes;
  }

  constructor(apiClient?: ApolloClient<unknown>) {
    this._apiClient = apiClient ?? null;
  }

  async init(): Promise<any> {
    try {
      this.inProgress = true;
      const data = await this._apiClient?.query({ query: IntrospectionQuery });
      this._apiSchema = _get(data, "data.__schema");
      this._schemaExplorerTypes = this._extractRootTypes(this._apiSchema);
      this._extractFieldTypes(this._apiSchema.types);
    } catch (err) {
      console.log(err);
    } finally {
      this.inProgress = false;
    }
  }

  public extractApiDeclaration(apiData: IApiState): string {
    const apiDeclaration: IApiDeclarationState = {
      name: apiData.name ?? "",
      region: apiData.region ?? "",
      replicationRegions: [],
      defaultAuthMode: apiData.defaultAuthMode ?? "",
      enums: [],
      types: [],
    };

    const rootTypes = [
      this._apiSchema.mutationType?.name,
      this._apiSchema.queryType?.name,
      this._apiSchema.subscriptionType?.name,
    ];

    let objectSubtypes: string[] = [];
    for (const [, value] of Object.entries(this._schemaExplorerTypes)) {
      if (
        value.name[0] === "_" ||
        value.kind === "SCALAR" ||
        rootTypes?.includes(value.name)
      ) {
        continue;
      }

      const valueObjectTypes =
        value.fields?.reduce((acc: string[], field: any) => {
          if (field.type.kind === "OBJECT" && !!field.name) {
            acc.push(field.type.name as string);
          }

          return acc;
        }, []) ?? [];

      objectSubtypes = [...objectSubtypes, ...valueObjectTypes];
    }

    for (const [, value] of Object.entries(this._schemaExplorerTypes)) {
      if (
        value.name[0] === "_" ||
        value.kind === "SCALAR" ||
        rootTypes?.includes(value.name) ||
        objectSubtypes.includes(value.name)
      ) {
        continue;
      }

      if (value.kind === "OBJECT") {
        let hasIdField = false;
        const objectFields = value.fields?.reduce(
          (acc: IDeclarationFieldState[], field: any) => {
            if (field.type.kind !== "OBJECT") {
              hasIdField = hasIdField || field.name.toLowerCase() === "id";
              const type: string =
                field.type?.name ||
                field.type?.ofType?.name ||
                field.type?.ofType?.ofType?.name;

              acc.push({
                name: field.name,
                type: type.replace(/awsjson/gi, "Json"),
              } as IDeclarationFieldState);
            }

            return acc;
          },
          []
        );

        if (hasIdField) {
          apiDeclaration.types.push({
            id: uuid4(),
            name: value.name,
            targets: [],
            parents: { first: null, second: null },
            fields: objectFields ?? [],
          });
        }
      } else if (value.kind === "ENUM") {
        apiDeclaration.enums.push({
          name: value.name,
          values:
            value.enumValues?.map((value) => {
              return value.name;
            }) ?? [],
        });
      }
    }

    return JSON.stringify(apiDeclaration);
  }

  private _extractFieldTypes(schemaTypes: Array<any>): void {
    schemaTypes.forEach((schemaType) => {
      this._schemaFieldTypes[schemaType.name] = schemaType;
    });
  }

  private _extractRootTypes(apiSchema: ISchemaState): {
    [key: string]: ISchemaExplorerType;
  } {
    const rootTypeFields: ISchemaExplorerType[] = [];
    let schemaExplorerTypes: { [key: string]: ISchemaExplorerType } = {};
    apiSchema.types.forEach((schemaType) => {
      schemaExplorerTypes = Object.assign({}, schemaExplorerTypes, {
        [schemaType.name]: schemaType,
      });
    });

    if (apiSchema.mutationType) {
      rootTypeFields.push(
        this._schemaTypeForRootType("mutation", apiSchema.mutationType)
      );
    }

    if (apiSchema.queryType) {
      rootTypeFields.push(
        this._schemaTypeForRootType("query", apiSchema.queryType)
      );
    }

    if (apiSchema.subscriptionType) {
      rootTypeFields.push(
        this._schemaTypeForRootType("subscription", apiSchema.subscriptionType)
      );
    }

    schemaExplorerTypes[this._rootType] = {
      __typename: "__Root",
      description:
        "Each GraphQL schema provides a root type for queries, mutations and subscriptions.",
      kind: "ROOT",
      name: "Root",
      fields: rootTypeFields,
    };

    return schemaExplorerTypes;
  }

  private _schemaTypeForRootType(
    rootTypeName: string,
    rootType: ISchemaExplorerType
  ) {
    return {
      __typename: "__Type",
      kind: "OBJECT",
      name: rootTypeName,
      type: rootType,
    };
  }
}
