import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { ElementAttrs } from "vue-tsx-support/types/base";
import {
  ElementType,
  InputHTMLAttributes,
  SyntheticEvent,
} from "vue-tsx-support/types/dom";
import styles from "./Input.component.module.scss";
import { IFormSelectEntryState } from "../object-type/ObjectTypeField.component";

export type IInputProps = tsx.DeclareProps<
  Omit<
    ElementAttrs<ElementType<HTMLInputElement, InputHTMLAttributes>>,
    "onInput" | "value"
  > & {
    value: SelectInput["value"];
    options?: SelectInput["options"];
    autoFocus?: SelectInput["autoFocus"];
  }
> &
  tsx.DeclareOnEvents<{
    onInput: string;
  }>;

@Component
export default class SelectInput extends Vue {
  _tsx!: IInputProps;

  @Prop({ default: null }) value: string | undefined;
  @Prop({ default: false }) autoFocus: boolean;
  @Prop({ required: true, default: () => [] }) options: IFormSelectEntryState[];

  private internalValue: string | undefined = "";

  get className() {
    return {
      [styles.input]: true,
      [styles.transparent]: true,
    };
  }

  handleInput(event: SyntheticEvent<HTMLSelectElement, InputEvent>) {
    const { value } = event.target.selectedOptions[0];
    this.internalValue = value;
    this.$emit("input", this.internalValue);
  }

  mounted() {
    if (this.autoFocus) {
      this.$nextTick(() => {
        const selectElement = this.$el as HTMLSelectElement;
        selectElement.focus();
      });
    }
  }

  @Watch("value", { immediate: true })
  onValueChange() {
    this.internalValue = this.value;
  }

  render() {
    return (
      <select
        id={`select-input-${this.options.length}`}
        name="options-cell"
        class={this.className}
        on={{ ...this.$listeners, input: this.handleInput }}
        value={this.internalValue}
      >
        {this.options.map((option, idx) => (
          <option value={option.value} key={idx}>
            {option.text}
          </option>
        ))}
      </select>
    );
  }
}
