import {
  BFormGroup,
  BFormInvalidFeedback,
  getTypedBFormSelect,
  getTypedBFormSelectOption,
} from "@/lib/typed-bootstrap";
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";

const BFormSelect = getTypedBFormSelect<string>();
const BFormSelectOption = getTypedBFormSelectOption<string>();

@Component
export default class FormSelect extends Vue {
  _tsx!: tsx.DeclareProps<{
    id?: FormSelect["id"];
    value?: FormSelect["value"];
    label?: FormSelect["label"];
    labelAmendment?: FormSelect["labelAmendment"];
    isReadonly?: FormSelect["isReadonly"];
    selectOptions: FormSelect["selectOptions"];
    upperCaseLabel?: FormSelect["upperCaseLabel"];
    placeholderText?: FormSelect["placeholderText"];
    selectClassName?: FormSelect["selectClassName"];
  }> &
    tsx.DeclareOnEvents<{
      onInput: FormSelect["onInput"];
    }>;

  @Prop({ default: `form-select-${Date.now()}` }) id: string;
  @Prop() value: string | null;
  @Prop({ required: false }) readonly label: string;
  @Prop({ required: false }) readonly labelAmendment: string;
  @Prop({ required: false, default: false }) isReadonly: boolean;
  @Prop({ required: true }) readonly selectOptions: {
    value: string | null;
    text: string;
  }[];
  @Prop({ default: true }) readonly upperCaseLabel: boolean;
  @Prop({ required: false })
  readonly placeholderText: string;
  @Prop() selectClassName: string;

  @Emit("input") onInput(_ev: string) {}

  state = null;

  get hasNullValue(): boolean {
    return !!this.selectOptions.find((o) => o.value === null)?.text;
  }

  get selectedItem(): string | null {
    return this.value;
  }

  get nullSelectorText(): string {
    return (
      this.placeholderText ??
      this.$t("form_select_placeholder", {
        type: this.label,
      })
    );
  }

  get isDisabled(): boolean {
    return this.isReadonly || this.selectOptions.length === 0;
  }

  render() {
    return (
      <BFormGroup
        class={{
          "default__form-group": true,
          uppercase: this.upperCaseLabel,
        }}
      >
        <label class="default__label" for={this.id}>
          {this.labelAmendment && (
            <span class="form-group__label-amendment">
              {this.labelAmendment}
            </span>
          )}
          {this.label}
        </label>
        <BFormSelect
          id={this.id}
          data-testid={this.id}
          class={{
            [this.selectClassName]: true,
            "form-select__input": true,
            "with-value": this.value,
          }}
          disabled={this.isDisabled}
          value={this.selectedItem}
          options={this.selectOptions}
          onInput={this.onInput}
        >
          <template slot="first">
            {!this.hasNullValue && (
              <BFormSelectOption value={null} disabled>
                {this.nullSelectorText}
              </BFormSelectOption>
            )}
          </template>
          <BFormInvalidFeedback state={this.state}>
            {this.$t("form_select_feedback")}
          </BFormInvalidFeedback>
        </BFormSelect>
      </BFormGroup>
    );
  }
}
