import { FieldDirectiveEnum } from "@/api/common-types";
import _without from "lodash/without";
import _uniq from "lodash/uniq";
import { IFieldState } from "./types";

const updateFieldDirectivesFactory =
  (callback: (directives?: string[]) => string[]) => (field: IFieldState) => ({
    ...field,
    directives: callback(field.directives),
  });

const excludeDirectivesFactory = (...directivesToOmit: FieldDirectiveEnum[]) =>
  updateFieldDirectivesFactory((directives) =>
    _without(directives, ...directivesToOmit)
  );

const extendDirectivesFactory = (...directivesToAdd: FieldDirectiveEnum[]) =>
  updateFieldDirectivesFactory((directives) =>
    _uniq([...(directives ?? []), ...directivesToAdd])
  );

export const excludeSortByFromFieldDirectives = excludeDirectivesFactory(
  FieldDirectiveEnum.SORTBY
);

export const extendFieldWithSortByDirective = extendDirectivesFactory(
  FieldDirectiveEnum.SORTBY
);

export const toggleFieldRequiredDirective = updateFieldDirectivesFactory(
  (directives) => {
    const hasRequiredDirective =
      directives?.includes(FieldDirectiveEnum.REQUIRED) ?? false;

    if (hasRequiredDirective) {
      return _without(directives, FieldDirectiveEnum.REQUIRED);
    }

    return _uniq([
      ..._without(
        directives,
        FieldDirectiveEnum.REQUIRED_ON_CREATE,
        FieldDirectiveEnum.REQUIRED_ON_UPDATE,
        FieldDirectiveEnum.REQUIRED_ON_DELETE
      ),
      FieldDirectiveEnum.REQUIRED,
    ]);
  }
);

export const toggleFieldRequiredOnDirective = (directive: FieldDirectiveEnum) =>
  updateFieldDirectivesFactory((directives) => {
    const hasRequiredDirective =
      directives?.includes(FieldDirectiveEnum.REQUIRED) ?? false;
    const hasCurrentDirective = directives?.includes(directive) ?? false;

    if (!hasRequiredDirective) {
      if (!hasCurrentDirective) {
        const newState = [...(directives ?? []), directive];

        if (
          newState.includes(FieldDirectiveEnum.REQUIRED_ON_CREATE) &&
          newState.includes(FieldDirectiveEnum.REQUIRED_ON_DELETE) &&
          newState.includes(FieldDirectiveEnum.REQUIRED_ON_UPDATE)
        ) {
          return [
            ..._without(
              directives,
              FieldDirectiveEnum.REQUIRED_ON_CREATE,
              FieldDirectiveEnum.REQUIRED_ON_DELETE,
              FieldDirectiveEnum.REQUIRED_ON_UPDATE
            ),
            FieldDirectiveEnum.REQUIRED,
          ];
        }

        return newState;
      }

      return _without(directives, directive);
    }

    return _without(
      [
        ...(directives ?? []),
        FieldDirectiveEnum.REQUIRED_ON_CREATE,
        FieldDirectiveEnum.REQUIRED_ON_DELETE,
        FieldDirectiveEnum.REQUIRED_ON_UPDATE,
      ],
      directive,
      FieldDirectiveEnum.REQUIRED
    );
  });

export const toggleFieldRequiredOnUpdateDirective =
  toggleFieldRequiredOnDirective(FieldDirectiveEnum.REQUIRED_ON_UPDATE);

export const toggleFieldRequiredOnCreateDirective =
  toggleFieldRequiredOnDirective(FieldDirectiveEnum.REQUIRED_ON_CREATE);

export const toggleFieldRequiredOnDeleteDirective =
  toggleFieldRequiredOnDirective(FieldDirectiveEnum.REQUIRED_ON_DELETE);

export const hasUniqueDirective = (field: IFieldState) =>
  field.directives?.includes(FieldDirectiveEnum.UNIQUE) ?? false;

const extendFieldWithConnectorDirective = extendDirectivesFactory(
  FieldDirectiveEnum.CONNECTOR
);

const excludeConnectorFromFieldDirectives = excludeDirectivesFactory(
  FieldDirectiveEnum.CONNECTOR
);

export const updateConnectorDirective = (
  fieldName: string,
  fields: IFieldState[]
) => {
  return fields.map((field) => {
    if (fieldName !== "id" && field.name === fieldName) {
      return extendFieldWithConnectorDirective(field);
    }

    return excludeConnectorFromFieldDirectives(field);
  });
};

export const hasConnectorDirective = (field: IFieldState) =>
  field.directives?.includes(FieldDirectiveEnum.CONNECTOR) ?? false;

const excludeUserEmailFromFieldDirectives = excludeDirectivesFactory(
  FieldDirectiveEnum.USER_EMAIL
);

const excludeUserPasswordFromFieldDirectives = excludeDirectivesFactory(
  FieldDirectiveEnum.USER_PASSWORD
);

const extendFieldWithUserEmailDirective = extendDirectivesFactory(
  FieldDirectiveEnum.USER_EMAIL
);

const extendFieldWithUserPasswordDirective = extendDirectivesFactory(
  FieldDirectiveEnum.USER_PASSWORD
);

export const updateUserEmailDirective = (
  fieldId: string,
  fields: IFieldState[]
) => {
  return fields.map((field) => {
    if (field.id === fieldId) {
      return extendFieldWithUserEmailDirective(field);
    }

    return excludeUserEmailFromFieldDirectives(field);
  });
};

export const updateUserPasswordDirective = (
  fieldId: string,
  fields: IFieldState[]
) => {
  return fields.map((field) => {
    if (field.id === fieldId) {
      return extendFieldWithUserPasswordDirective(field);
    }

    return excludeUserPasswordFromFieldDirectives(field);
  });
};
