import { Vue, Component } from "vue-property-decorator";
import style from "./ButtonTextWithHint.component.module.scss";

@Component
export class ButtonTextWithHint extends Vue {
  render() {
    return (
      <div class={style.textWithHint}>
        {this.$slots.default}
        <span class={style.hint}>{this.$slots.hint}</span>
      </div>
    );
  }
}
