import { Vue, Component } from "vue-property-decorator";
import { OrgModule, UserModule } from "@/store";
import { PermissionType, RoleType } from "@/generated/types";
import { RoleTypeEnum, TrustedEntityTypeEnum } from "@/api/common-types";

@Component
export default class UserPermissions extends Vue {
  get roles() {
    return UserModule.roles;
  }

  get orgId() {
    return OrgModule.id;
  }

  get currentRole() {
    return this.roles[this.orgId];
  }

  private get managePermissions() {
    return (
      this.currentRole?.permissions.filter(
        (permission) => permission.type === PermissionType.CAN_MANAGE
      ) ?? []
    );
  }

  get canManageOrg(): boolean {
    return !!this.managePermissions.find(
      (permission) => permission.entity === TrustedEntityTypeEnum.ORG
    );
  }

  get canManageUsers(): boolean {
    return !!this.managePermissions.find(
      (permission) =>
        permission.entity === TrustedEntityTypeEnum.ORG ||
        permission.entity === TrustedEntityTypeEnum.USERS
    );
  }

  get isOwner(): boolean {
    return !!this.managePermissions.find(
      (permission) => permission.roleType === RoleType.OWNER
    );
  }

  get hasAdminPermissions(): boolean {
    return [
      RoleTypeEnum.ADMIN,
      RoleTypeEnum.OWNER,
      RoleTypeEnum.SUPERUSER,
    ].includes(this.currentRole.type);
  }

  get canPublishApi(): boolean {
    return [
      RoleTypeEnum.DEV,
      RoleTypeEnum.ADMIN,
      RoleTypeEnum.OWNER,
      RoleTypeEnum.SUPERUSER,
    ].includes(this.currentRole.type);
  }
}
