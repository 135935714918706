import { ISchemaExplorerType } from "@/api/schema";
import { IApiState } from "./api";
import {
  BaseFieldTypeEnum,
  connectorFieldStateFromParent,
  isBooleanFieldType,
  isListFieldType,
} from "./object-type";
import { lowerCaseFirstLetter, upperCaseFirstLetter } from "@/utils";
import { MandatoryFieldsConfiguration } from "./api-schema/mandatory-fields-configuration";
import ShortUniqueId from "short-unique-id";

export type IFieldConfig = {
  name: string;
  type: string;
  requiredOnCreate: boolean;
  requiredOnUpdate: boolean;
  isTargetConnectionField: boolean;
  isParentConnectionField: boolean;
  isEnum: boolean;
  isBoolean: boolean;
  isList: boolean;
  isConfigurable?: boolean;
  isReadOnlyField?: boolean;
  isSortField?: boolean;
};

type ITypeConfig = {
  id: string;
  fields: IFieldConfig[];
  firstParent?: string;
  secondParent?: string;
  connector: string;
};

const { randomUUID } = new ShortUniqueId({ length: 6 });

export class ApiSchema {
  public types: Record<string, ITypeConfig> = {};

  public idKey = "id";
  public autoGeneratedKeys = ["id", "createdAt", "updatedAt"];
  public blacklistedKeys = ["__typename", "selected"];
  public readonlyKeys = ["createdat", "createdAt", "updatedat", "updatedAt"];
  public nonConfigurableFields = ["id", "createdAt"];

  constructor(
    public apiDeclaration?: Pick<IApiState, "types" | "enums">,
    public graphqlSchemaFieldTypes?: Record<string, ISchemaExplorerType>
  ) {
    this.initialize();
  }

  private initialize() {
    const apiDeclarationTypes = this.apiDeclaration?.types ?? [];
    const apiDeclarationEnums = this.apiDeclaration?.enums ?? [];

    if (apiDeclarationTypes.length > 0) {
      this.initializeTypesFromApiDeclaration(
        apiDeclarationTypes,
        apiDeclarationEnums
      );
    } else {
      this.initializeTypesFromGraphqlSchema();
    }
  }

  private initializeTypesFromApiDeclaration(
    apiDeclarationTypes: IApiState["types"],
    apiDeclarationEnums: IApiState["enums"] = []
  ) {
    this.types = apiDeclarationTypes.reduce((typesAcc, type) => {
      const parentFields: {
        type: string;
        name: string;
      }[] = [];
      const firstParentId = type.parents.first?.parentId;
      const secondParentId = type.parents.second?.parentId;
      const firstParent = apiDeclarationTypes.find(
        (t) => t.id === firstParentId
      );
      const secondParent = apiDeclarationTypes.find(
        (t) => t.id === secondParentId
      );
      [firstParent, secondParent].forEach((parent) => {
        if (parent) {
          parentFields.push(connectorFieldStateFromParent(parent));
        }
      });

      const targetFields = type.targets ?? [];
      const mandatoryFieldsConfiguration = new MandatoryFieldsConfiguration(
        {},
        type
      );

      const fields: IFieldConfig[] = [
        ...parentFields.map((field) => ({
          name: field.name,
          type: (field.type as BaseFieldTypeEnum) ?? BaseFieldTypeEnum.String,
          isParentConnectionField: true,
          isTargetConnectionField: false,
          requiredOnCreate: true,
          requiredOnUpdate: false,
          isEnum: apiDeclarationEnums.some(
            (enumDefinition) => enumDefinition.name === field.type
          ),
          isBoolean: isBooleanFieldType(field.type ?? ""),
          isList: false,
        })),
        ...type.fields.map((field) => {
          return {
            name: field.name,
            type: (field.type as BaseFieldTypeEnum) ?? BaseFieldTypeEnum.String,
            isParentConnectionField: false,
            isTargetConnectionField: false,
            requiredOnCreate:
              mandatoryFieldsConfiguration.createInputFieldKeys.has(field.name),
            requiredOnUpdate:
              mandatoryFieldsConfiguration.updateInputFieldKeys.has(field.name),
            isEnum: apiDeclarationEnums.some(
              (enumDefinition) => enumDefinition.name === field.type
            ),
            isBoolean: isBooleanFieldType(field.type ?? ""),
            isList: isListFieldType(field.type ?? ""),
            isReadOnlyField:
              field.name &&
              this.readonlyKeys.includes(field.name.toLowerCase()),
            isSortField: field.directives?.includes("@sortby"),
            isConfigurable:
              !this.nonConfigurableFields.includes(field.name) &&
              !field.directives?.includes("@user_email") &&
              !field.directives?.includes("@user_password"),
          };
        }),
        ...targetFields.map((target) => {
          const targetType = apiDeclarationTypes.find(
            (type) => type.id === target.targetId
          );

          return {
            name: `${lowerCaseFirstLetter(targetType?.name ?? "")}Connection`,
            type: `${targetType?.name ?? ""}Connection`,
            isParentConnectionField: false,
            isTargetConnectionField: true,
            requiredOnCreate: false,
            requiredOnUpdate: false,
            isEnum: false,
            isBoolean: false,
            isList: false,
          };
        }),
      ];
      const typeConfig: ITypeConfig = {
        id: type.id,
        firstParent: firstParent?.name,
        secondParent: secondParent?.name,
        fields,
        connector: type.connector ?? "id",
      };

      return {
        ...typesAcc,
        [type.name]: typeConfig,
      };
    }, {});
  }

  private initializeTypesFromGraphqlSchema(
    graphqlSchemaFieldTypes: Record<string, ISchemaExplorerType> = {}
  ) {
    this.types = Object.entries(graphqlSchemaFieldTypes).reduce(
      (typesAcc, [typeName, type]) => {
        const mandatoryFieldsConfiguration = new MandatoryFieldsConfiguration(
          graphqlSchemaFieldTypes,
          null
        );

        const fields: IFieldConfig[] =
          type.fields?.map((field) => ({
            name: field.name,
            type: field.type?.name ?? field.type?.ofType?.name ?? "",
            isParentConnectionField:
              this.isParentConnectionBasedOnGraphqlSchema(
                field.name,
                typeName,
                graphqlSchemaFieldTypes
              ),
            isTargetConnectionField: field.name.endsWith("Connection"),
            requiredOnCreate:
              mandatoryFieldsConfiguration.createInputFieldKeys.has(field.name),
            requiredOnUpdate:
              mandatoryFieldsConfiguration.updateInputFieldKeys.has(field.name),
            isList: field.kind === "LIST" || field.ofType?.kind === "LIST",
            isEnum:
              field.type?.kind === "ENUM" || field.ofType?.kind === "ENUM",
            isBoolean:
              field.type?.kind === "BOOLEAN" ||
              field.ofType?.kind === "BOOLEAN",
          })) ?? [];

        const typeConfig: ITypeConfig = {
          id: randomUUID(),
          fields,
          connector: "id",
        };

        return {
          ...typesAcc,
          [typeName]: typeConfig,
        };
      },
      {}
    );
  }

  private isParentConnectionBasedOnGraphqlSchema(
    name: string,
    objectTypeName: string,
    graphqlSchemaFieldTypes: Record<string, ISchemaExplorerType>
  ): boolean {
    if (!name.endsWith("Id")) {
      return false;
    }
    const currentTypeConnectionField = `${lowerCaseFirstLetter(
      objectTypeName
    )}Connection`;

    const potentialParentName = upperCaseFirstLetter(name.replace("Id", ""));
    const potentialParentType = graphqlSchemaFieldTypes[potentialParentName];

    if (!potentialParentType) {
      return false;
    }

    const matchingParentField = potentialParentType.fields?.find(
      (field) => field.name === currentTypeConnectionField
    );

    if (!matchingParentField) {
      return false;
    }

    return false;
  }
}
