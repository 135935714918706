import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import style from "./FormFile.component.module.scss";
import { BBadge, BFormFile } from "@/lib/typed-bootstrap";
import { PasteHandler } from "@/mixins/PasteHandler.mixin";

@Component
export default class FormFile extends PasteHandler {
  _tsx!: tsx.DeclareProps<{
    placeholder: FormFile["placeholder"];
    dropPlaceholder: FormFile["dropPlaceholder"];
    value: FormFile["value"];
    multiple?: FormFile["multiple"];
    uploadProgress?: FormFile["uploadProgress"];
    accept?: FormFile["accept"];
  }> &
    tsx.DeclareOnEvents<{ onInput: File | File[] }>;
  $refs!: {
    fileInput: HTMLFormElement;
  };

  @Prop({ default: 0 }) readonly uploadProgress: number;
  @Prop({ required: true }) readonly placeholder: string;
  @Prop({ required: true }) readonly dropPlaceholder: string;
  @Prop({ required: true }) readonly value: File | File[] | null;
  @Prop({ default: false }) readonly multiple: boolean;
  @Prop({}) id?: string;
  @Prop({ default: true }) readonly withUploadFromClipboard: boolean;

  @Emit("input")
  public onInput(_files: File | File[]) {}

  public reset() {
    this.$refs.fileInput?.reset();
  }

  public click() {
    if (this.id) {
      const el = document.getElementById(this.id);
      if (el) {
        el.click();
      }
    }
  }

  @Watch("pastedSupportedFiles", { immediate: true })
  onPastedData() {
    if (
      !this.withUploadFromClipboard ||
      !this.pastedSupportedFiles ||
      this.pastedSupportedFiles.length === 0
    ) {
      return;
    }

    this.onInput(this.pastedSupportedFiles);
  }

  render() {
    const {
      accept,
      uploadProgress,
      placeholder,
      dropPlaceholder,
      value,
      multiple,
    } = this;

    return (
      <BFormFile
        id={this.id}
        ref="fileInput"
        class={{
          [style.formInput]: true,
          [style.inProgress]: uploadProgress > 0,
        }}
        accept={accept}
        value={value}
        placeholder={placeholder}
        dropPlaceholder={dropPlaceholder}
        on={this.$listeners}
        multiple={multiple}
        scopedSlots={{
          "file-name": ({ names }) => (
            <div>
              <BBadge variant="dark">{names[0]}</BBadge>
              {names.length > 1 && (
                <BBadge variant="dark" class="ml-1">
                  {this.$t("form_file_more_files_caption", {
                    number: names.length - 1,
                  })}
                </BBadge>
              )}
            </div>
          ),
        }}
      />
    );
  }
}
