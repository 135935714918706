import { isNotNullable } from "@/utils";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class PasteHandler extends Vue {
  protected pastedData: DataTransferItem[] | null = null;
  protected isPasteHandlerActive = true;

  @Prop() protected accept?: string;

  protected filterAcceptedFormat(files: File[]) {
    const acceptedFormats = this.accept?.split(",") ?? [];

    return !this.accept
      ? files
      : files.filter((file) => {
          return acceptedFormats
            .map((type) => type.trim())
            .some((type) => file.name.endsWith(type));
        });
  }

  protected get pastedSupportedFiles() {
    if (!this.pastedData) return null;

    const files = this.pastedData
      .filter((data) => data.kind === "file")
      .map((data) => this.renameFileIfFromScreenshot(data.getAsFile()))
      .filter(isNotNullable);

    return this.filterAcceptedFormat(files);
  }

  private onPaste(e: ClipboardEvent) {
    if (!this.isPasteHandlerActive) return;
    const items = e.clipboardData?.items;
    if (items) {
      this.pastedData = Array.from(items);
    }
  }

  protected renameFileIfFromScreenshot(file: File | null) {
    if (!file) {
      return;
    }

    if (file.name === "image.png") {
      const dateTime = new Date().toISOString();
      const [date, time] = dateTime.split("T");
      const [roundedTime] = time.split(".");
      const dateStr = `${date} at ${roundedTime}`;
      return new File([file], `Screenshot ${dateStr}.png`);
    }

    return file;
  }

  created() {
    document.addEventListener("paste", this.onPaste);
  }

  beforeDestroy() {
    document.removeEventListener("paste", this.onPaste);
  }
}
