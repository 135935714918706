import Vue from "vue";
import Navbar from "@/components/navbar/Navbar.component.vue";
import { Component } from "vue-property-decorator";
import style from "./Default.layout.module.scss";

@Component
export default class Default extends Vue {
  render() {
    return (
      <div class={style.container}>
        <Navbar />
        <div class="content">
          <router-view />
        </div>
      </div>
    );
  }
}
