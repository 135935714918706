export const extractFunctionFileName = (functionFilePath: string) => {
  const fileName = functionFilePath.split("/").pop()?.replace(".zip", "") ?? "";
  const fileNameElements = fileName.split(".");
  const name = fileNameElements.slice(0, -1).join(".");
  const extension = fileNameElements[fileNameElements.length - 1];

  return {
    name,
    extension,
  };
};
