import { AwsRegionEnum } from "@/api/common-types";
import {
  createCouponRedemption,
  IAccountCouponResponse,
  listAccountCouponsByRegion,
  RedemptionStatus,
} from "@/api/coupon";
import { ApiRegionEnum } from "./api";
import { extractData, extractItems, extractQueryname } from "./utils";

export const getAccountCoupons = async (region: AwsRegionEnum) => {
  const accountCouponList = await listAccountCouponsByRegion(
    {
      configRegion: region,
    },
    "network-only"
  );

  return extractItems(
    extractQueryname(
      extractData(accountCouponList),
      "listAccountCouponsByConfig"
    )
  );
};

const filterPotentiallyValidCoupons = (coupons: IAccountCouponResponse[]) =>
  coupons.filter(
    (coupon) => coupon.status === RedemptionStatus.VALID && coupon.awsAccountId
  );

const getPotentiallyValidCoupons = async (region: AwsRegionEnum) => {
  const coupons = await getAccountCoupons(region);
  return filterPotentiallyValidCoupons(coupons);
};

export const obtainAwsAccountId = async (
  region: AwsRegionEnum,
  apiId: string
) => {
  const tryReedemingCoupon = async (coupon: IAccountCouponResponse) => {
    if (!coupon.awsAccountId) {
      return undefined;
    }

    try {
      const { data, errors } = await createCouponRedemption({
        accountCouponConfigType: "AWS_ACCOUNT",
        couponCode: coupon.id,
        redeemedById: apiId,
        redeemedByType: "api",
      });

      if (!data || (errors && errors.length > 0)) {
        throw new Error("Failed to redeem coupon");
      }

      return coupon.awsAccountId;
    } catch (err) {
      return undefined;
    }
  };

  /** Valid coupons are always at the beginning of the list, so there is no need to paginate */
  const checkFirstCouponsBatch = async (
    retriesLeft: number
  ): Promise<string> => {
    if (retriesLeft <= 0) {
      throw new Error(`Not able to retriev a coupon for region ${region}`);
    }

    const coupons = await getPotentiallyValidCoupons(region);

    if (coupons.length === 0) {
      throw new Error(`No valid coupons available for region ${region}`);
    }

    for (const coupon of coupons) {
      const awsAccountID = await tryReedemingCoupon(coupon);

      if (awsAccountID) {
        return awsAccountID;
      }
    }

    /**
     * If there where valid coupons available, but none of them was redeemed,
     * then it means there was either network or a race condition with other user.
     * We should retry.
     *  */
    return checkFirstCouponsBatch(retriesLeft - 1);
  };

  const awsAccountId = await checkFirstCouponsBatch(5);

  return awsAccountId;
};

export const mapApiRegionToAWSRegionEnum = (
  apiRegion: ApiRegionEnum
): AwsRegionEnum =>
  ({
    [ApiRegionEnum.AsiaPacificMumbai]: AwsRegionEnum.AP_SOUTH_1,
    [ApiRegionEnum.AsiaPacificSeoul]: AwsRegionEnum.AP_NORTHEAST_2,
    [ApiRegionEnum.AsiaPacificSingapore]: AwsRegionEnum.AP_NORTHEAST_1,
    [ApiRegionEnum.AsiaPacificSydney]: AwsRegionEnum.AP_SOUTHEAST_2,
    [ApiRegionEnum.AsiaPacificTokyo]: AwsRegionEnum.AP_SOUTHEAST_1,
    [ApiRegionEnum.Canada]: AwsRegionEnum.CA_CENTRAL_1,
    [ApiRegionEnum.EuropeFrankfurt]: AwsRegionEnum.EU_CENTRAL_1,
    [ApiRegionEnum.EuropeIreland]: AwsRegionEnum.EU_WEST_1,
    [ApiRegionEnum.EuropeLondon]: AwsRegionEnum.EU_WEST_2,
    [ApiRegionEnum.EuropeParis]: AwsRegionEnum.EU_WEST_3,
    [ApiRegionEnum.EuropeStockholm]: AwsRegionEnum.EU_NORTH_1,
    [ApiRegionEnum.MiddleEast]: AwsRegionEnum.ME_SOUTH_1,
    [ApiRegionEnum.SouthAmerica]: AwsRegionEnum.SA_EAST_1,
    [ApiRegionEnum.USEast]: AwsRegionEnum.US_EAST_2,
    [ApiRegionEnum.USWestNCalifornia]: AwsRegionEnum.US_WEST_1,
    [ApiRegionEnum.USWestOregon]: AwsRegionEnum.US_WEST_2,
  }[apiRegion]);
