import {
  IListSchemaTemplatesQuery,
  IListSchemaTemplatesQueryVariables,
} from "@/generated/types";
import { queryFunctionFactory } from "../internal-utils";
import LIST_SCHEMA_TEMPLATES from "./list_schema_templates.graphql";

export const schemaTemplatesQuery = queryFunctionFactory<
  IListSchemaTemplatesQuery,
  IListSchemaTemplatesQueryVariables
>(LIST_SCHEMA_TEMPLATES);
