import { Vue, Component } from "vue-property-decorator";
import { FormModule } from "@/store/modules/form";
import { UserModule } from "@/store/modules/user";
import CodeEntryForm from "@/components/signup/CodeEntryForm.component.vue";
import { auth } from "@/utils/auth-service";
import { authManager } from "@/utils/auth-service/auth-manager";
import { persistanceService } from "@/utils/persistance-service";
import { ICredentials } from "@graphapi-io/customer-components";

@Component({
  components: {
    CodeEntryForm,
  },
})
export default class SignupCodeEntry extends Vue {
  credentials: ICredentials = { username: "", password: "" };

  get auth() {
    return auth;
  }

  get isLoading() {
    return UserModule.isLoading;
  }

  created() {
    this.credentials = persistanceService.getCredentials() ?? {
      username: FormModule.email,
      password: FormModule.password,
    };
  }

  async handleConfirmed(): Promise<void> {
    await authManager.handleCodeConfirmed(this.credentials);
    persistanceService.removeCredentials();
  }
}
