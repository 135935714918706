import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import { removeSpecialCharacters } from "@/utils";
import { IEnumValueState } from "@/models";

@Component
export default class FormEnumValue extends Vue {
  $refs!: {
    enumValueInput: HTMLFormElement;
  };

  @Prop({ required: true }) value: IEnumValueState;
  @Prop({ required: false, default: null }) isUnique: boolean | null;

  @Emit("onDelete") onDelete() {}

  mounted() {
    if (!this.value.name) {
      this.$refs.enumValueInput.focus();
    }
  }

  inputFieldFormatter(value: string): string {
    return removeSpecialCharacters(value).replace(/(^\d)/g, "");
  }

  update(key: string, value: string) {
    this.$emit("input", { ...this.value, [key]: value });
  }
}
