import { QueryTypeEnum } from "@graphapi-io/api-declaration";
import {
  FieldNameComposer,
  FieldParameterTypeEnum,
} from "../shared/fieldname-composer";
import { ApiFields } from "../shared/api-fields";
import { gql } from "apollo-boost";
import { lowerCaseFirstLetter } from "@/utils";

export abstract class GqlDedicatedQueryComposer {
  public static buildObjectQuery(typeName: string, fields: ApiFields) {
    const fieldName = FieldNameComposer.fieldNameForQueryType(
      QueryTypeEnum.OBJECT,
      typeName
    );

    return gql`
      query ${fieldName}($id: ID!) {
        ${fieldName}(id: $id) {
          ${fields.getNonObjectFieldsForObjectType(typeName).join("\n")}
        }
      }
    `;
  }

  public static buildListQuery(typeName: string, fields: ApiFields) {
    const fieldName = FieldNameComposer.fieldNameForQueryType(
      QueryTypeEnum.LIST,
      typeName
    );

    return gql`
      query ${fieldName}($limit: Int, $nextToken: String, $filter: ${FieldNameComposer.inputNameForType(
      FieldParameterTypeEnum.FILTER,
      typeName
    )}, $sortOrder: SortOrderType) {
        ${fieldName}(limit: $limit, nextToken: $nextToken, filter: $filter, sortOrder: $sortOrder) {
          nextToken
          items {
            ${fields.getNonObjectFieldsForObjectType(typeName).join("\n")}
          }
        }
      }
    `;
  }

  public static buildListByQuery(
    typeName: string,
    parentName: string,
    fields?: string[]
  ) {
    const fieldName = FieldNameComposer.fieldNameForListByParentType(
      typeName,
      parentName
    );
    const parentIdField = `${lowerCaseFirstLetter(parentName)}Id`;

    return gql`
      query ${fieldName}($id: ID!, $limit: Int, $nextToken: String, $filter: ${FieldNameComposer.inputNameForType(
      FieldParameterTypeEnum.FILTER,
      typeName
    )}, $sortOrder: SortOrderType) {
        ${fieldName}(${parentIdField}: $id, limit: $limit, nextToken: $nextToken, filter: $filter, sortOrder: $sortOrder) {
          nextToken
          items {
            id
            ${(fields ?? []).join("\n")}
          }
        }
      }
    `;
  }
}
