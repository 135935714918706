import { Vue, Component, Prop } from "vue-property-decorator";
import {
  IObjectTypeState,
  IEnumState,
  IConnectedTypeState,
  ConnectionCardinalityEnum,
  canObjectTypeHaveMoreParents,
  deleteConnection,
  addConnection,
  getObjectTypeParentsCount,
  updateConnection,
  getObjectTypeConnections,
} from "@/models";
import ObjectTypeConnection from "@/components/object-type/ObjectTypeConnection.component";
import { BButton } from "@/lib/typed-bootstrap";
import plusIcon from "@/assets/img/plus-icon-placeholder.png";
import * as tsx from "vue-tsx-support";

@Component
export class ObjectTypeConnections extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: ObjectTypeConnections["value"];
    allTypes: ObjectTypeConnections["allTypes"];
    allEnumTypes?: ObjectTypeConnections["allEnumTypes"];
  }>;
  @Prop({ required: true }) value: IObjectTypeState;
  @Prop({ required: true }) allTypes: IObjectTypeState[];
  @Prop({ required: false, default: [] }) allEnumTypes: IEnumState[];

  private get canHaveMoreParents() {
    return canObjectTypeHaveMoreParents(this.value);
  }

  private get canHaveMoreTargets() {
    return (
      this.value.targets.length <
      this.allTypes.length - 1 - getObjectTypeParentsCount(this.value)
    );
  }

  private get canHaveMoreConnections() {
    return this.connectedTypes.length < this.allTypes.length - 1;
  }

  private get connectedTypes(): IConnectedTypeState[] {
    return getObjectTypeConnections(this.value);
  }

  private updateConnectedType(newState: IConnectedTypeState) {
    updateConnection(
      this.value,
      this.allTypes,
      this.connectedTypes[newState.index],
      newState
    );
  }

  private onAddConnection(ev: MouseEvent) {
    ev.preventDefault();
    addConnection(this.value, this.allTypes, {
      cardinality: this.canHaveMoreTargets
        ? ConnectionCardinalityEnum.IS_PARENT_OF
        : ConnectionCardinalityEnum.IS_CHILD_OF,
      id: null,
      index: this.connectedTypes.length,
    });
  }

  private onDeleteConnection(index: number) {
    const connection = this.connectedTypes[index];
    deleteConnection(this.value, this.allTypes, connection);
  }

  private onChangeConnectedType(value: string | null, index: number) {
    if (value) {
      this.updateConnectedType({
        id: value,
        index,
        cardinality: this.connectedTypes[index].cardinality,
      });
    }
  }

  private onCardinalityChange(
    newCardinality: ConnectionCardinalityEnum,
    index: number
  ) {
    this.updateConnectedType({
      ...this.connectedTypes[index],
      cardinality: newCardinality,
    });
  }

  render() {
    return (
      <div class="ml-0">
        <div>
          {this.connectedTypes.map((connectedType, index) => (
            <ObjectTypeConnection
              key={`target-${index}`}
              selectedObjectType={this.value}
              allTypes={this.allTypes}
              allEnumTypes={this.allEnumTypes}
              value={connectedType}
              canHaveMoreParents={this.canHaveMoreParents}
              canHaveMoreTargets={this.canHaveMoreTargets}
              onInput={(typeState) =>
                this.onChangeConnectedType(typeState, index)
              }
              onCardinalityChange={(cardinality) =>
                this.onCardinalityChange(cardinality, index)
              }
              onDelete={() => this.onDeleteConnection(index)}
            />
          ))}
        </div>
        <BButton
          class="create__button--shallow mt-2"
          type="button"
          onClick={this.onAddConnection}
          disabled={!this.canHaveMoreConnections}
        >
          <div class="add-button__icon">
            <img src={plusIcon} />
          </div>
          <div class="button__icon-text">
            {this.$t("model_widget_add_relationship")}
          </div>
        </BButton>
      </div>
    );
  }
}
