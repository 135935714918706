import Component from "vue-class-component";
import { ThemedImage } from "../shared/ThemedImage.component";
import editIconWhite from "@/assets/img/icon-edit-white.svg";
import editIconBlack from "@/assets/img/icon-edit-black.svg";
import iconCopyBlack from "@/assets/img/icon-copy-black.svg";
import iconCopyWhite from "@/assets/img/icon-copy-white.svg";
import iconLockBlack from "@/assets/img/locked-cell-black.svg";
import iconLockWhite from "@/assets/img/locked-cell-white.svg";
import trashIconRed from "@/assets/img/icon-trash-red.svg";
import { Emit, Prop } from "vue-property-decorator";
import styles from "./ApiDataObjectTypeOptions.component.module.scss";
import * as tsx from "vue-tsx-support";
import Dropdown from "../shared/Dropdown.component";
import {
  Button,
  ButtonShapeEnum,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "../shared/Button.component";
import { IApiState, IObjectTypeState } from "@/models";
import ApiTypesUpdater from "../object-type/ApiTypesUpdater.mixin";
import ApiDataObjectTypeForm from "./ApiDataObjectTypeForm.component";
import _cloneDeep from "lodash/cloneDeep";
import { v4 as uuid4 } from "uuid";
import { AuthMethodTypeEnum, ObjectDirectiveEnum } from "@/api/common-types";
import { ApiDataObjectTypePermissionsForm } from "./ApiDataObjectTypePermissionsForm.component";

const editIcon = {
  dark: editIconWhite,
  light: editIconBlack,
};

const editIconHover = {
  dark: editIconBlack,
  light: editIconWhite,
};

const copyIcon = {
  dark: iconCopyWhite,
  light: iconCopyBlack,
};

const copyIconHover = {
  dark: iconCopyBlack,
  light: iconCopyWhite,
};

const lockIcon = {
  dark: iconLockWhite,
  light: iconLockBlack,
};

const lockIconHover = {
  dark: iconLockBlack,
  light: iconLockWhite,
};

@Component
export class ApiDataObjectTypeOptions extends ApiTypesUpdater {
  _tsx!: tsx.DeclareProps<{
    typeName: ApiDataObjectTypeOptions["typeName"];
    apiState?: ApiDataObjectTypeOptions["apiState"];
  }> &
    tsx.DeclareOnEvents<{
      onUpdateSelectedObjectType: IObjectTypeState;
    }>;
  $refs!: {
    container: HTMLDivElement;
    dropdown?: Dropdown;
  };

  @Prop({
    default: () => ({}),
  })
  apiState: IApiState;
  @Prop({ required: true }) private typeName: string;

  @Emit("updateSelectedObjectType") onUpdateSelectedObjectType(
    _objectType: IObjectTypeState
  ) {}

  private isEditingType = false;
  private isEditingTypePermissions = false;

  private get objectType() {
    return this.apiState.types.find((type) => type.name === this.typeName);
  }

  private get isUsersTable() {
    return this.objectType?.directives?.includes(ObjectDirectiveEnum.USERS);
  }

  private get isPublishedUsersTable() {
    return this.isUsersTable && this.apiState.graphqlUrl;
  }

  private async deleteType() {
    if (
      window.confirm(
        this.$t("api_details_data_delete_type_confirm", {
          typeName: this.typeName,
        }).toString()
      )
    ) {
      const newObjectTypes = this.apiState.types.filter(
        (type) => type.name !== this.typeName
      );
      const newState = {
        ...this.apiState,
        types: newObjectTypes,
      };
      await this.updateApiDeclarationFromState(newState);
      if (this.serverSideErrors) {
        return;
      }
      this.closeDropdown();
      this.apiState.types = newState.types;
      const newSelectedObjectType =
        this.apiState.types[this.apiState.types.length - 1];
      if (newSelectedObjectType) {
        this.onUpdateSelectedObjectType(newSelectedObjectType);
      }
    } else {
      this.closeDropdown();
    }
  }

  private async cloneType() {
    if (!this.objectType) {
      return;
    }

    const latestCopyX = this.apiState.types.reduce((top, type) => {
      if (type.name.includes(`${this.objectType?.name}Copy`)) {
        const num = parseInt(
          type.name.replace(`${this.objectType?.name}Copy`, ""),
          10
        );
        if (num > top) {
          return num;
        }
      }
      return top;
    }, 0);

    const newType = _cloneDeep(this.objectType);
    newType.id = uuid4();
    newType.name = `${newType.name}Copy${
      latestCopyX === 0 ? "" : latestCopyX + 1
    }`;
    newType.parents = { first: null, second: null };
    newType.targets = [];

    const oldTypeIndex = this.apiState.types.findIndex(
      (type) => type.name === this.typeName
    );
    const newTypes = [
      ...this.apiState.types.slice(0, oldTypeIndex + 1),
      newType,
      ...this.apiState.types.slice(oldTypeIndex + 1),
    ];
    const newState = {
      ...this.apiState,
      types: newTypes,
    };
    await this.updateApiDeclarationFromState(newState);
    if (this.serverSideErrors) {
      return;
    }
    this.closeDropdown();
    this.apiState.types = newState.types;
    this.onUpdateSelectedObjectType(newType);
  }

  private closeDropdown() {
    this.$refs.dropdown?.close();
  }

  private editType() {
    this.isEditingType = true;
  }

  private editTypePermissions() {
    this.isEditingTypePermissions = true;
  }

  private onSubmitEdit(objectType: IObjectTypeState) {
    this.onUpdateSelectedObjectType(objectType);
    this.closeDropdown();
  }

  private onCancelEdit() {
    this.isEditingType = false;
  }

  private onCancelEditPermissions() {
    this.isEditingTypePermissions = false;
  }

  private get canEditPermissions() {
    return (
      [
        AuthMethodTypeEnum.AWS_OIDC,
        AuthMethodTypeEnum.AWS_COGNITO_USER_POOLS,
      ] as string[]
    ).includes(this.apiState.defaultAuthMode ?? "");
  }

  public render() {
    return this.isEditingType ? (
      <ApiDataObjectTypeForm
        apiState={this.apiState}
        typeName={this.typeName}
        onCancel={this.onCancelEdit}
        onSubmit={this.onSubmitEdit}
      />
    ) : this.isEditingTypePermissions ? (
      <ApiDataObjectTypePermissionsForm
        apiState={this.apiState}
        typeName={this.typeName}
        onCancel={this.onCancelEditPermissions}
        onSubmit={this.onCancelEditPermissions}
      />
    ) : (
      <div>
        <Button
          class={styles.actionButton}
          shape={ButtonShapeEnum.square}
          theme={ButtonThemeEnum.naked}
          size={ButtonSizeEnum.sm}
          onClick={this.editType}
          disabled={this.isPublishedUsersTable}
          scopedSlots={{
            content: ({ isHovered }) => [
              <ThemedImage
                src={isHovered ? editIconHover : editIcon}
                class={styles.icon}
              />,
              <span>{this.$t("api_details_data_edit_type")}</span>,
            ],
          }}
        />
        <Button
          class={styles.actionButton}
          shape={ButtonShapeEnum.square}
          theme={ButtonThemeEnum.naked}
          size={ButtonSizeEnum.sm}
          onClick={this.cloneType}
          disabled={this.isPublishedUsersTable}
          scopedSlots={{
            content: ({ isHovered }) => [
              <ThemedImage
                src={isHovered ? copyIconHover : copyIcon}
                class={styles.icon}
              />,
              <span>{this.$t("api_details_data_duplicate_type")}</span>,
            ],
          }}
        />
        {this.canEditPermissions && <Dropdown.Divider />}
        {this.canEditPermissions && (
          <Button
            class={styles.actionButton}
            shape={ButtonShapeEnum.square}
            theme={ButtonThemeEnum.naked}
            size={ButtonSizeEnum.sm}
            onClick={this.editTypePermissions}
            scopedSlots={{
              content: ({ isHovered }) => [
                <ThemedImage
                  src={isHovered ? lockIconHover : lockIcon}
                  class={styles.icon}
                />,
                <span>
                  {this.$t("api_details_data_edit_type_permissions")}
                </span>,
              ],
            }}
          />
        )}
        <Dropdown.Divider />
        <Button
          class={styles.deleteButton}
          shape={ButtonShapeEnum.square}
          theme={ButtonThemeEnum.naked}
          size={ButtonSizeEnum.sm}
          onClick={this.deleteType}
          disabled={this.isPublishedUsersTable}
        >
          <img src={trashIconRed} class={styles.icon} />
          {this.$t("api_details_data_delete_type")}
        </Button>
      </div>
    );
  }
}
