import { Component, Emit, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { BAlert, BButton, BCol, BContainer, BRow } from "@/lib/typed-bootstrap";
import ItemChangesList from "../diff/ItemChangesList.component";
import { IDiffConfig } from "../diff/types";
import UserPermissions from "@/mixins/UserPermissions.mixin";

@Component
export default class ApiPublish extends UserPermissions {
  _tsx!: tsx.DeclareProps<{
    errorMessage?: ApiPublish["errorMessage"];
    isLoading?: ApiPublish["isLoading"];
    ctaButtonText?: ApiPublish["ctaButtonText"];
    cancelButtonText?: ApiPublish["cancelButtonText"];
    enumsChangesList?: ApiPublish["enumsChangesList"];
    objectTypesChangesList?: ApiPublish["objectTypesChangesList"];
  }> &
    tsx.DeclareOnEvents<{
      onSubmit: void;
    }>;

  @Prop({ default: "" }) errorMessage: string;
  @Prop({ default: false }) isLoading: boolean;
  @Prop({ default: "" }) ctaButtonText: string;
  @Prop({ default: "" }) cancelButtonText: string;
  @Prop({ default: null }) enumsChangesList: IDiffConfig;
  @Prop({ default: null }) objectTypesChangesList: IDiffConfig;

  @Emit("submit") onSubmit() {}

  private async onCancel(): Promise<void> {
    this.$router.go(-1);
  }

  render() {
    return (
      <BContainer class="default__view-setup">
        <h1>{this.$slots.header}</h1>
        <p>{this.$t("api_setup_overview_subline")}</p>
        <BContainer class="api-setup__wizard">
          <BRow>
            <BCol />
            <BCol md="8" class="api-setup__data-types-col p-md-4">
              <BAlert show={!!this.errorMessage} variant="danger">
                {this.errorMessage}
              </BAlert>
              {this.enumsChangesList && (
                <ItemChangesList
                  changeItems={this.enumsChangesList}
                  headline="Enum Types changes"
                />
              )}
              {this.objectTypesChangesList && (
                <ItemChangesList
                  changeItems={this.objectTypesChangesList}
                  headline="Model Types changes"
                />
              )}
              <BRow class="buttons__section">
                <BButton
                  type="submit"
                  onClick={tsx.modifiers.prevent(this.onSubmit)}
                  class="default__button mt-3"
                  disabled={!this.canPublishApi || this.isLoading}
                  size="lg"
                  block
                >
                  {this.isLoading ? (
                    <div class="mx-auto d-flex default__spinner" role="status">
                      <b-spinner
                        type="grow"
                        label="Spinning"
                        show="false"
                      ></b-spinner>
                    </div>
                  ) : (
                    <div class="button__text">{this.ctaButtonText}</div>
                  )}
                </BButton>
              </BRow>
              <div class="cta__question mb-5">
                <a href="#" onClick={tsx.modifiers.prevent(this.onCancel)}>
                  {this.cancelButtonText}
                </a>
              </div>
            </BCol>
            <BCol></BCol>
          </BRow>
        </BContainer>
      </BContainer>
    );
  }
}
