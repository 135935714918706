import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import style from "./FormNestedCheckbox.component.module.scss";
import * as tsx from "vue-tsx-support";
import { BFormCheckbox, BFormGroup } from "@/lib/typed-bootstrap";

export interface IFormNestedCheckboxState {
  name: string;
  selected: boolean;
  disabled?: boolean;
  children?: IFormNestedCheckboxState[];
}

@Component
export class FormNestedCheckbox extends Vue {
  _tsx!: {
    props: Pick<FormNestedCheckbox, "value" | "id">;
  } & tsx.DeclareOnEvents<{ onChange: IFormNestedCheckboxState }>;

  @Prop({ required: true }) value: IFormNestedCheckboxState;
  @Prop({ required: true }) id: string;

  @Emit("change") onChange(_value: IFormNestedCheckboxState) {}

  handleSelfChange(selected: boolean) {
    this.onChange({
      ...this.value,
      selected,
    });
  }

  handleChildChange(newChildValue: IFormNestedCheckboxState, index: number) {
    this.onChange({
      ...this.value,
      children: this.value.children?.map((oldChildValue, i) =>
        i === index ? newChildValue : oldChildValue
      ),
    });
  }

  private getChildId(name: string) {
    return `${this.id}_${name.replaceAll(" ", "")}`;
  }

  render() {
    const { id, value } = this;
    const children = value.children;

    return (
      <BFormGroup
        data-testid={id}
        class={{
          [style.container]: true,
          [style.disabled]: value.disabled,
        }}
      >
        <BFormCheckbox
          id={id}
          checked={value.selected}
          name={id}
          onChange={this.handleSelfChange}
          disabled={value.disabled}
        >
          {value.name}
        </BFormCheckbox>
        {children && (
          <div class={style.children}>
            {children.map((child, index) => (
              <FormNestedCheckbox
                key={child.name}
                id={this.getChildId(child.name)}
                value={child}
                onChange={(childValue) =>
                  this.handleChildChange(childValue, index)
                }
                class={style.child}
              ></FormNestedCheckbox>
            ))}
          </div>
        )}
      </BFormGroup>
    );
  }
}
