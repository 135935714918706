import * as tsx from "vue-tsx-support";
import style from "./EditHeaderCell.component.module.scss";
import { HeaderCell } from "./HeaderCell.component";

export const EditHeaderCell = tsx.component({
  functional: true,
  props: {
    isSelected: Boolean,
  },
  render(_, { props: { isSelected } }) {
    return (
      <HeaderCell
        class={{
          [style.cell]: true,
          [style.selected]: isSelected,
        }}
        isSelected={isSelected}
      ></HeaderCell>
    );
  },
});
