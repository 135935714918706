import * as tsx from "vue-tsx-support";
import style from "./SelectionHeaderCell.component.module.scss";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import iconCheckbox from "@/assets/img/icon-checkbox.svg";
import iconCheckboxLight from "@/assets/img/icon-checkbox-light.svg";
import iconCheckboxChecked from "@/assets/img/icon-checkbox-checked.svg";
import iconCheckboxLightChecked from "@/assets/img/icon-checkbox-light-checked.svg";
import { HeaderCell, IHeaderCellEvents } from "./HeaderCell.component";

const iconSrc = {
  light: iconCheckboxLight,
  dark: iconCheckbox,
};

const iconCheckedSrc = {
  light: iconCheckboxLightChecked,
  dark: iconCheckboxChecked,
};

export const SelectionHeaderCell = tsx
  .componentFactoryOf<IHeaderCellEvents>()
  .create({
    functional: true,
    props: {
      isSelected: Boolean,
    },
    render(_, { props: { isSelected }, listeners }) {
      return (
        <HeaderCell
          class={{
            [style.cell]: true,
            [style.selected]: isSelected,
          }}
          on={listeners}
        >
          {isSelected ? (
            <ThemedImage src={iconCheckedSrc} class={style.icon} />
          ) : (
            <ThemedImage src={iconSrc} class={style.icon} />
          )}
        </HeaderCell>
      );
    },
  });
