import Vue from "vue";
import * as tsx from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import styles from "./BundleBenefitsSections.component.module.scss";
import { IFreeTiers, IProductState } from "@/models/product-bundle";
import BundleBenefitsSection from "./BundleBenefitSection";

@Component
export default class ProductBenefitsSections extends Vue {
  _tsx!: tsx.DeclareProps<{
    bundleConfigs: ProductBenefitsSections["bundleConfigs"];
  }>;

  @Prop({ required: true }) readonly bundleConfigs: {
    product: IProductState;
    freeTiers?: IFreeTiers;
    selected?: boolean;
  }[];

  render() {
    return (
      <div class={styles.section}>
        <div class={styles.title}>
          <h3>{this.$t("bundle_compare_plans_title")}</h3>
        </div>
        <div class={styles.subtitle}>
          {this.$t("bundle_compare_plans_subtitle")}
        </div>
        <div class={styles.plans}>
          {this.bundleConfigs.map((bundle) => {
            return (
              <div class={styles.bundle}>
                <div class={styles.name}>{bundle.product.displayName}</div>
                <BundleBenefitsSection
                  benefits={
                    bundle.freeTiers
                      ? bundle.freeTiers[
                          this.$i18n.locale.toUpperCase() as keyof IFreeTiers
                        ]
                      : []
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
