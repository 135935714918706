import Vue from "vue";
import Vuex from "vuex";
import { config } from "vuex-module-decorators";
import type { IFormState } from "./modules/form";
import type { IGraphapiState } from "./modules/graphapi";
import type { IUserState } from "./modules/user";
import type { IOrgState } from "@/models/org";
import type { INavState } from "./modules/nav";
import type { IApiDataState } from "./modules/api-data";

Vue.use(Vuex);

export interface IRootState {
  form: IFormState;
  graphapi: IGraphapiState;
  user: IUserState;
  org: IOrgState;
  nav: INavState;
  apiData: IApiDataState;
}

// Prevent the errors from Vuex @Actions being wrapped
config.rawError = true;

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({});
