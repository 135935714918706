import { Component, Prop, Watch } from "vue-property-decorator";
import { updateApiMutation } from "@/api/api";
import {
  IApiState,
  IEnumState,
  declarationSchemaForInput,
  IFieldState,
  IEnumValueState,
} from "@/models";
import { IObjectTypeState } from "@/models";
import { v4 as uuid4 } from "uuid";
import _cloneDeep from "lodash/cloneDeep";
import _isEqualWith from "lodash/isEqualWith";
import _removeFp from "lodash/fp/remove";
import _remove from "lodash/remove";
import ApiManagementSectionHeader from "@/components/api-management/ApiManagementSectionHeader.component";
import FormEnumList from "@/components/forms/FormEnumList.component.vue";
import ObjectTypeList from "@/components/object-type/ObjectTypeList.component";
import Tabs from "@/components/shared/Tabs.component";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import { ApolloClient } from "apollo-boost";
import { persistanceService } from "@/utils/persistance-service";
import { IsEqualCustomizer } from "lodash";
import { IEnumNameBlurEvent } from "@/components/forms/FormEnumWidget.component";
import { ObjectTypeNameValidator } from "@/components/object-type/ObjectTypeNameValidator.mixin";

@Component<ApiDataTypes>({
  beforeRouteLeave(to, from, next) {
    this.onSaveTypesChange();
    next();
  },
  components: {
    ApiManagementSectionHeader,
    FormEnumList,
    ObjectTypeList,
    Tab: Tabs.Tab,
    Tabs,
  },
})
export default class ApiDataTypes extends ObjectTypeNameValidator {
  @Prop({ required: true }) value: IApiState;
  @Prop({ required: true }) apiClient: ApolloClient<unknown>;
  @Prop({ required: true }) apiSchemaHandler: ApiSchemaHandler | null;

  isLoading = false;

  enumTypes: IEnumState[] = [];

  private currentTabIndex = 0;

  selectedAction = null;
  actionOptions = [
    { value: "delete", text: this.$t("global_cta_delete") },
    { value: "duplicate", text: this.$t("global_cta_duplicate") },
  ];

  equalWithCustomizer: IsEqualCustomizer = (j: any, k: any, key: any) => {
    return key === "selected" ? true : undefined;
  };

  get noTypeSelected(): boolean {
    return (
      this.objectTypes?.some((type) => {
        return type.selected;
      }) != true
    );
  }

  get objectListKey(): string {
    return `${this.enumTypes?.length}-${this.objectTypes?.length}`;
  }

  get selectedEnumType(): IEnumState | undefined {
    return this.enumTypes.find((type: IEnumState) => type.selected);
  }

  get selectedObjectType(): IObjectTypeState | undefined {
    return this.objectTypes.find((type: IObjectTypeState) => type.selected);
  }

  get showCancelSaveButton(): boolean {
    return (
      !_isEqualWith(
        this.objectTypes,
        this.value.types,
        this.equalWithCustomizer
      ) ||
      !_isEqualWith(this.enumTypes, this.value.enums, this.equalWithCustomizer)
    );
  }

  get objectNameInHash() {
    return this.$route.hash.substring(1);
  }

  @Watch("value", { immediate: true })
  initialize() {
    this.objectTypes = _cloneDeep(this.value.types);
    this.enumTypes = _cloneDeep(this.value.enums);
    this.updateSelectedObjectType();
  }

  @Watch("objectNameInHash")
  updateSelectedObjectType(name: string = this.objectNameInHash) {
    const objectTypeIndex = this.objectTypes?.findIndex(
      (type) => type.name === name
    );
    if (objectTypeIndex > 0) {
      this.objectTypes?.forEach((objectType, index) => {
        if (index === objectTypeIndex) {
          objectType.selected = true;
        } else {
          objectType.selected = false;
        }
      });
    }
  }

  updateHash() {
    if (this.objectNameInHash !== this.selectedObjectType?.name) {
      const fullPath = `${this.$route.path}#${this.selectedObjectType?.name}`;
      this.$router.push(fullPath);
      persistanceService.setActiveRouteKey(fullPath);
    }
  }

  resetSelectionIfNecessary() {
    const someTypeIsSelected = this.objectTypes.some((t) => t.selected);
    if (!someTypeIsSelected) {
      this.objectTypes[0] ? (this.objectTypes[0].selected = true) : null;
    }

    const isSomeEnumTypeSelected = this.enumTypes.some((t) => t.selected);
    if (!isSomeEnumTypeSelected) {
      this.enumTypes[0] ? (this.enumTypes[0].selected = true) : null;
    }
  }

  removeUnusedEnumEntries(enums: IEnumState[]) {
    enums = _removeFp((type: IEnumState) => {
      return type.name === "" && type.values.length === 0;
    }, enums);

    return enums.map((type) => {
      type.name = type.name.replace(/\s+/g, "");
      type.values = _removeFp((value: IEnumValueState) => {
        return value.name === null || value.name === "";
      }, type.values);

      return type;
    });
  }

  removeUnusedTypeEntries(types: IObjectTypeState[]): IObjectTypeState[] {
    types = _removeFp((type: IObjectTypeState) => {
      return (
        type.name === "" &&
        type.targets.length === 0 &&
        type.fields.length <= 3 &&
        !type.parents.first?.parentId &&
        !type.parents.second?.parentId
      );
    }, types);

    return types.map((type) => {
      // remove unset targets and fields
      type.name = type.name.replace(/\s+/g, "");
      type.targets = _removeFp({ targetId: null }, type.targets);
      type.fields = _removeFp((field: IFieldState) => {
        return field.type === null || field.name === "";
      }, type.fields);

      return type;
    });
  }

  onEnumNameChange({ oldName, newName }: IEnumNameBlurEvent) {
    if (oldName === "") return;

    this.objectTypes.forEach((type) => {
      type.fields.forEach((field) => {
        if (field.type === oldName) {
          field.type = newName;
        }
      });
    });
  }

  onCancelTypesChange() {
    this.objectTypes = _cloneDeep(this.value.types);
    this.enumTypes = _cloneDeep(this.value.enums);
  }

  async onSaveTypesChange(): Promise<void> {
    if (this.errorMessage) {
      return;
    }

    try {
      this.isLoading = true;
      this.enumTypes = this.removeUnusedEnumEntries(this.enumTypes);
      this.value.enums = _cloneDeep(this.enumTypes);
      this.objectTypes = this.removeUnusedTypeEntries(this.objectTypes);
      this.value.types = _cloneDeep(this.objectTypes);
      this.resetSelectionIfNecessary();
      await this.createApiDeclarationFromData(this.value);
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  private onTabIndexChange(index: number) {
    this.currentTabIndex = index;
  }

  async createApiDeclarationFromData(apiData: IApiState): Promise<void> {
    try {
      apiData.schema = declarationSchemaForInput(apiData);
      await updateApiMutation({ id: apiData.id, schema: apiData.schema });
    } catch (err) {
      console.log(err);
    }
  }

  async onSelectAction(item: string): Promise<void> {
    if (item === "delete") {
      // remove all selected items
      if (this.currentTabIndex > 0) {
        this.enumTypes = _removeFp({ selected: true }, this.enumTypes);
      } else {
        const deletedItems: IObjectTypeState[] = _remove(this.objectTypes, {
          selected: true,
        });

        // remove delete item from targets
        this.objectTypes.forEach((objectType) => {
          deletedItems.forEach((deletedObjectType) => {
            objectType.targets = _removeFp(
              { targetId: deletedObjectType.id },
              objectType.targets
            );

            if (objectType.parents?.first?.parentId === deletedObjectType.id) {
              objectType.parents.first = null;
            }

            if (objectType.parents?.second?.parentId === deletedObjectType.id) {
              objectType.parents.second = null;
            }
          });
        });
      }

      setTimeout(() => {
        this.selectedAction = null;
      }, 100);

      this.resetSelectionIfNecessary();
    } else if (item === "duplicate") {
      if (this.currentTabIndex > 0) {
        if (this.selectedEnumType) {
          const newType = _cloneDeep(this.selectedEnumType);
          newType.id = uuid4();
          newType.name = `New${newType.name}`;
          this.enumTypes.push(newType);
        }
      } else {
        if (this.selectedObjectType) {
          const newType = _cloneDeep(this.selectedObjectType);
          newType.id = uuid4();
          newType.name = `New${newType.name}`;
          this.objectTypes.push(newType);
        }
      }

      setTimeout(() => {
        this.selectedAction = null;
      }, 100);

      this.resetSelectionIfNecessary();
    }
  }

  onSelectObjectType(objectType: IObjectTypeState) {
    this.updateSelectedObjectType(objectType.name);
    this.updateHash();
  }
}
