import { Vue, Component } from "vue-property-decorator";

@Component
export default class ErrorHandling extends Vue {
  errorMessage = "";

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }
}
