import { IThemedSrc } from "@/components/shared/ThemedImage.component";
import { Vue, Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { FormDropdown } from "./FormDropdown.component";
import { FormInputWithAction } from "./FormInputWithAction.component";

@Component
export class FormInputWithDropdown extends Vue {
  _tsx!: tsx.DeclareProps<
    Pick<
      FormInputWithDropdown,
      "id" | "iconSrc" | "titleContent" | "disclaimerContent" | "disabled"
    >
  >;
  $scopedSlots!: tsx.InnerScopedSlots<{
    dropdownContent?: void;
    input?: string;
  }>;

  @Prop({ required: true }) id: string;
  @Prop({ required: true }) iconSrc: IThemedSrc;
  @Prop({ required: true }) titleContent: string;
  @Prop({ required: true }) disclaimerContent: string;
  @Prop({ default: false }) disabled?: boolean;

  render() {
    const { id, iconSrc, titleContent, disclaimerContent, disabled } = this;

    return (
      <FormInputWithAction
        scopedSlots={{
          action: (className) => (
            <FormDropdown
              id={id}
              iconSrc={iconSrc}
              titleContent={titleContent}
              disclaimerContent={disclaimerContent}
              disabled={disabled}
              buttonClass={className}
            >
              {this.$scopedSlots.dropdownContent?.()}
            </FormDropdown>
          ),
          input: (className) => this.$scopedSlots?.input?.(className),
        }}
      ></FormInputWithAction>
    );
  }
}
