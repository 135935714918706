import { copyToClipboard } from "@/utils";
import { SelectedDataExtractor } from "./selected-data-extractor";
import {
  IArrowKey,
  ModifierEnum,
  TableSelectionState,
} from "./table-selection-state";
import TableView from "./TableView.component";
import {
  ICellClickPayload,
  IHeaderCellClickPayload,
  ReservedFieldsEnum,
} from "./types";
import {
  getDataColumnIndexOffset,
  getEditColumnIndex,
  getSelectionColumnIndex,
} from "./utils";
import { TableEditingState } from "./table-editing-state";

export class TableController {
  constructor(
    private tableSelectionState: TableSelectionState,
    private tableEditingState: TableEditingState,
    private selectedDataExtractor: SelectedDataExtractor,
    private hasSelectionColumn: boolean,
    private hasEditColumn: boolean,
    private table?: TableView
  ) {
    this.tableSelectionState = tableSelectionState;
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  public get cellInEditMode() {
    return this.tableEditingState.isEditing
      ? this.tableSelectionState.activeCellIndex
      : undefined;
  }

  public get cellInEditReplaceMode() {
    return this.tableEditingState.isEditing &&
      this.tableEditingState.shouldReplaceContent
      ? this.tableSelectionState.activeCellIndex
      : undefined;
  }

  public startKeyListener() {
    window.addEventListener("keydown", this.onKeyDown);
  }

  public stopKeyListener() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  public handleCellClick(payload: ICellClickPayload & { fieldIndex: number }) {
    const modifier = this.mapEventToModifier(payload);
    this.tableEditingState.isEditing = false;

    switch (payload.field) {
      case ReservedFieldsEnum.edit:
        break;
      case ReservedFieldsEnum.selected:
        this.tableSelectionState.toggleRowSelection(
          payload.relativeIndex,
          modifier
        );
        break;
      default: {
        this.tableSelectionState.toggleCellSelection(
          {
            row: payload.relativeIndex,
            column: payload.fieldIndex + this.dataColumnIndexOffset,
          },
          modifier
        );
      }
    }
  }

  public handleCellDblClick(
    payload: ICellClickPayload & { fieldIndex: number }
  ) {
    switch (payload.field) {
      case ReservedFieldsEnum.edit:
      case ReservedFieldsEnum.selected:
        break;
      default: {
        if (!this.tableEditingState.isReadOnly(payload.fieldIndex)) {
          this.tableEditingState.isEditing = true;
        }
      }
    }
  }

  public handleHeaderCellClick(
    payload: IHeaderCellClickPayload & { fieldIndex: number }
  ) {
    const fieldIndex =
      payload.field === ReservedFieldsEnum.selected
        ? getSelectionColumnIndex()
        : payload.field === ReservedFieldsEnum.edit
        ? getEditColumnIndex(this.hasSelectionColumn)
        : payload.fieldIndex + this.dataColumnIndexOffset;

    const modifier = this.mapEventToModifier(payload);

    this.tableSelectionState.toggleColumnSelection(fieldIndex, modifier);
  }

  private get dataColumnIndexOffset() {
    return getDataColumnIndexOffset(
      this.hasSelectionColumn,
      this.hasEditColumn
    );
  }

  private mapEventToModifier(event: {
    isCmdPressed: boolean;
    isShiftPressed: boolean;
  }) {
    return event.isCmdPressed && event.isShiftPressed
      ? ModifierEnum.ADD_RANGE
      : event.isCmdPressed
      ? ModifierEnum.ADD
      : event.isShiftPressed
      ? ModifierEnum.RANGE
      : undefined;
  }

  private onKeyDown(event: KeyboardEvent) {
    const isSomethingFocused =
      document.activeElement && document.activeElement.tagName !== "BODY";
    if (isSomethingFocused && !this.tableEditingState.isEditing) {
      return;
    }

    const isReadOnlyColumn = this.tableEditingState.isReadOnly(
      this.tableSelectionState.activeCellIndex.column -
        this.dataColumnIndexOffset
    );

    const isArrowKey = [
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
    ].includes(event.key);

    if (event.key === "c" && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      copyToClipboard(
        this.selectedDataExtractor.extractDataToCopy(
          this.tableSelectionState.selectedRows,
          this.tableSelectionState.selectedColumns,
          this.tableSelectionState.selectedCells
        )
      );
      return;
    }

    if (
      event.key === "a" &&
      (event.metaKey || event.ctrlKey) &&
      !this.cellInEditMode
    ) {
      event.preventDefault();
      this.tableSelectionState.selectAll();
      return;
    }

    if (
      event.key === "Escape" ||
      (event.key === "r" && (event.metaKey || event.ctrlKey))
    ) {
      if (this.tableEditingState.isEditing) {
        this.tableEditingState.isEditing = false;
        this.tableEditingState.shouldReplaceContent = false;
      } else {
        this.tableSelectionState.deselectAll();
      }
      return;
    }

    if (event.key === "Enter") {
      this.tableSelectionState.deselectAll();

      if (isReadOnlyColumn) {
        this.tableSelectionState.selectCellBelow();
        return;
      }

      this.tableEditingState.isEditing = true;
      this.tableSelectionState.selectCell(
        this.tableSelectionState.activeCellIndex
      );
    }

    if (isArrowKey && !this.tableEditingState.isEditing) {
      event.preventDefault();
      const isShiftPressed = event.shiftKey;
      const isCmdPressed = event.metaKey || event.ctrlKey;
      const modifier = this.mapEventToModifier({
        isCmdPressed,
        isShiftPressed,
      });
      this.tableSelectionState?.onArrowKey(event.key as IArrowKey, modifier);
      if (
        modifier === ModifierEnum.RANGE ||
        modifier === ModifierEnum.ADD_RANGE
      ) {
        this.scrollLastRangeCellIntoView();
        return;
      }
      this.scrollSelectedCellIntoView();
    }

    if (/^.$/u.test(event.key)) {
      // Key produces a character
      this.tableSelectionState.deselectAll();

      if (!isReadOnlyColumn) {
        this.tableEditingState.isEditing = true;
        this.tableEditingState.shouldReplaceContent = true;
        this.tableSelectionState.selectCell(
          this.tableSelectionState.activeCellIndex
        );
      }
    }
  }

  private scrollLastRangeCellIntoView() {
    this.table?.scrollCellIntoView(
      this.tableSelectionState.activeRangeLastCell
    );
  }

  private scrollSelectedCellIntoView() {
    this.table?.scrollCellIntoView(this.tableSelectionState.activeCellIndex);
  }
}
