import { Component, Vue } from "vue-property-decorator";
import styles from "./AddColumnHeaderCell.component.module.scss";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import { HeaderCell } from "./HeaderCell.component";
import plusIconWhite from "@/assets/img/plus-icon-light.svg";
import plusIconBlack from "@/assets/img/plus-icon.svg";
import * as tsx from "vue-tsx-support";
import Dropdown from "../../Dropdown.component";

const plusIcon = {
  dark: plusIconWhite,
  light: plusIconBlack,
};

@Component
export class AddColumnHeaderCell extends Vue {
  $scopedSlots!: tsx.InnerScopedSlots<{
    form: { cancel: () => void };
  }>;

  render() {
    return (
      <HeaderCell class={styles.headerCell}>
        <Dropdown
          dropdownMenuClass={styles.plate}
          toggleClass={styles.toggleButton}
          leftPlateOffset={10}
          topPlateOffset={20}
          scopedSlots={{
            menuContent: ({ close }) =>
              this.$scopedSlots.form({ cancel: close }),
          }}
        >
          <template slot="buttonContent">
            <ThemedImage
              src={plusIcon}
              class={styles.icon}
              alt={this.$t("model_widget_add_field").toString()}
            />
            {this.$t("model_widget_add_field")}
          </template>
        </Dropdown>
      </HeaderCell>
    );
  }
}
