import { BFormTextarea } from "@/lib/typed-bootstrap";
import { Vue, Component, Prop } from "vue-property-decorator";
import FormGroupWithLabel from "./FormGroupWithLabel.component";
import style from "./FormTextarea.component.module.scss";
import * as tsx from "vue-tsx-support";

@Component
export default class FormTextarea extends Vue {
  _tsx!: tsx.DeclareProps<{
    value?: FormTextarea["value"];
    id?: FormTextarea["id"];
    label?: FormTextarea["label"];
    upperCaseLabel?: FormTextarea["upperCaseLabel"];
    noResize?: FormTextarea["noResize"];
    readonly?: FormTextarea["readonly"];
    rowsCount?: FormTextarea["rowsCount"];
    placeholder?: FormTextarea["placeholder"];
  }> &
    tsx.DeclareOnEvents<{
      onInput: string;
      onUpdate: string;
    }>;

  @Prop() value?: string;
  @Prop() id?: string;
  @Prop() label?: string;
  @Prop({ default: false }) upperCaseLabel: boolean;
  @Prop({ default: false }) noResize: boolean;
  @Prop({ default: false }) readonly: boolean;
  @Prop({ default: 3 }) rowsCount: number;
  @Prop() placeholder?: string;

  get inputKey(): string {
    return `${this.id}Textarea`;
  }

  render() {
    return (
      <FormGroupWithLabel
        label={this.label}
        upperCaseLabel={this.upperCaseLabel}
        id={this.id}
      >
        <BFormTextarea
          id={this.inputKey}
          data-testid={this.inputKey}
          class={style.formTextarea}
          rows={this.rowsCount}
          noResize={this.noResize}
          placeholder={this.placeholder}
          value={this.value}
          plaintext={this.readonly}
          on={this.$listeners}
        />
      </FormGroupWithLabel>
    );
  }
}
