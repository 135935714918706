import { BillingIntervalEnum } from "@/api/common-types";
import { parseJsonStringProp } from "./utils";

export interface ITiers {
  [key: number]: {
    up_to: string;
    unit_amount_decimal: string;
    flat_amount_decimal?: string;
  };
}

export interface IPriceResponseMappableProps {
  tiers?: string;
  paymentProviderId?: string;
  price?: number;
  billingInterval?: BillingIntervalEnum;
}

export const mapResponseToPrice = <T extends IPriceResponseMappableProps>(
  data: T
) => ({
  ...parseJsonStringProp<T, "tiers", ITiers>(data, "tiers"),
  price: (data.price ?? 0) / 100,
});
