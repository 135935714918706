import { Vue, Component, Prop } from "vue-property-decorator";
import { BillingIntervalEnum } from "@/api/common-types";
import { IProductState } from "@/models/product-bundle";

@Component
export default class PricingListItem extends Vue {
  @Prop() product: IProductState;
  @Prop() billingInterval: BillingIntervalEnum;

  get billingIntervalString() {
    return {
      [BillingIntervalEnum.DAY]: `${this.$t("billing_interval_day")}`,
      [BillingIntervalEnum.MONTH]: `${this.$t("billing_interval_month")}`,
      [BillingIntervalEnum.WEEK]: `${this.$t("billing_interval_week")}`,
      [BillingIntervalEnum.YEAR]: `${this.$t("billing_interval_year")}`,
      [BillingIntervalEnum.ONETIME]: "",
    };
  }

  get selectedPricing() {
    return this.selectedPricings[0];
  }

  get selectedPricings() {
    return this.product?.pricings[this.billingInterval] ?? [];
  }

  get price() {
    return this.selectedPricing?.price ?? 0;
  }

  get priceTimeframe(): string {
    return !this.selectedPricing
      ? ""
      : this.selectedPricing?.recurring && this.selectedPricing.billingInterval
      ? `per ${
          this.billingIntervalString[this.selectedPricing.billingInterval]
        }`
      : "onetime";
  }

  get reservedUnits() {
    return this.selectedPricing?.reservedUnits ?? 0;
  }

  get reservedUnitsDisplayValue() {
    return this.reservedUnits ? this.reservedUnits : "-";
  }

  get totalReservedUnitsPrice(): number {
    return this.price * this.reservedUnits;
  }

  get totalReservedUnitsPriceDisplayValue(): string {
    return this.totalReservedUnitsPrice
      ? this.totalReservedUnitsPrice.toLocaleString(undefined, {
          style: "currency",
          currency: "EUR",
        })
      : "-";
  }

  get totalUnitAmount(): string {
    const totalUnitAmount =
      this.reservedUnits * (this.selectedPricing?.unitAmount ?? 0);
    return totalUnitAmount
      ? `${totalUnitAmount.toLocaleString()} ${this.unitType}`
      : "-";
  }

  get productName(): string {
    return this.product?.displayName ?? "-";
  }

  get unitPriceDisplayValue(): string {
    return this.price
      ? this.price.toLocaleString(undefined, {
          style: "currency",
          currency: "EUR",
        })
      : "-";
  }

  get unitType(): string {
    return this.selectedPricing.unitType || "";
  }
}
