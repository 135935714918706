import {
  IListFlowRunLogsByFlowRunQuery,
  IListFlowRunLogsByFlowRunQueryVariables,
} from "@/generated/types";
import { queryFunctionFactory } from "../internal-utils";
import LIST_FLOW_RUN_LOGS_BY_FLOW_RUN from "./list_flow_run_logs_by_flow_run.graphql";

export const queryFlowRunsLogsByFlowRun = queryFunctionFactory<
  IListFlowRunLogsByFlowRunQuery,
  IListFlowRunLogsByFlowRunQueryVariables
>(LIST_FLOW_RUN_LOGS_BY_FLOW_RUN);
