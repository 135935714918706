import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import { isArray, isFunction, isObject } from "./type-guards";
import { IAnyArray, IAnyObject } from "./types";

export enum ComparisonResultEnum {
  CREATED = "created",
  UPDATED = "updated",
  DELETED = "deleted",
  UNCHANGED = "unchanged",
}

export interface ILeafDiff<From, To> {
  _type: "leaf";
  from: From;
  to: To;
  type: ComparisonResultEnum;
}

export interface IArrayDiff<From extends IAnyArray, To extends IAnyArray> {
  _type: "array";
  diffs: Record<
    number,
    IDiffResultState<From[number] | undefined, To[number] | undefined>
  >;
}

export interface IObjectDiff<From extends IAnyObject, To extends IAnyObject> {
  _type: "object";
  _fullFrom: From;
  diffs: Partial<{
    [K in keyof From | keyof To]: IDiffResultState<
      (K extends keyof From ? From[K] : undefined) | undefined,
      (K extends keyof To ? To[K] : undefined) | undefined
    >;
  }>;
}

export type IDiffResultState<From, To> = From extends IAnyArray
  ? To extends IAnyArray
    ? IArrayDiff<From, To> | ILeafDiff<From, To>
    : ILeafDiff<From, To>
  : From extends IAnyObject
  ? To extends IAnyObject
    ? IObjectDiff<From, To> | ILeafDiff<From, To>
    : ILeafDiff<From, To>
  : ILeafDiff<From, To>;

export type IValueOf<T> = T[keyof T];

export const isUnchangedLeaf = (
  diffResult: IArrayDiff<any, any> | ILeafDiff<any, any> | IObjectDiff<any, any>
) =>
  diffResult._type === "leaf" &&
  diffResult.type == ComparisonResultEnum.UNCHANGED;

export function compareLeafs<ILeaf1, ILeaf2>(from: ILeaf1, to: ILeaf2) {
  if (_isEqual(from, to)) {
    return ComparisonResultEnum.UNCHANGED;
  }

  if (!from) {
    return ComparisonResultEnum.CREATED;
  }

  if (!to) {
    return ComparisonResultEnum.DELETED;
  }

  return ComparisonResultEnum.UPDATED;
}

const getArrayDiff = <IArray1 extends IAnyArray, IArray2 extends IAnyArray>(
  from: IArray1,
  to: IArray2,
  compareKeys: string[] = []
): IArrayDiff<IArray1, IArray2> | ILeafDiff<IArray1, IArray2> => {
  const diff: IArrayDiff<IArray1, IArray2> = { _type: "array", diffs: {} };
  const alreadyComparedKeys: Record<string, boolean> = {};

  from.forEach((value: IArray1[number], index) => {
    if (isFunction(value)) {
      return;
    }
    alreadyComparedKeys[index] = true;
    const diffResult = getTypesDiff(
      value,
      to[index] as IArray2[number] | undefined,
      compareKeys
    );

    if (isUnchangedLeaf(diffResult)) {
      return;
    }

    diff.diffs[index] = diffResult;
  });

  to.forEach((value: IArray2[number], index) => {
    if (isFunction(value) || alreadyComparedKeys[index]) {
      return;
    }

    const diffResult = getTypesDiff(
      from[index] as IArray1[number] | undefined,
      value,
      compareKeys
    );

    if (isUnchangedLeaf(diffResult)) {
      return;
    }

    diff.diffs[index] = diffResult;
  });

  return _isEmpty(diff.diffs)
    ? {
        _type: "leaf",
        type: ComparisonResultEnum.UNCHANGED,
        from,
        to,
      }
    : diff;
};

const getObjectsDiff = <
  IObject1 extends IAnyObject,
  IObject2 extends IAnyObject
>(
  from: IObject1,
  to: IObject2,
  compareKeys: string[] = []
): IObjectDiff<IObject1, IObject2> | ILeafDiff<IObject1, IObject2> => {
  const diff: IObjectDiff<IObject1, IObject2> = {
    _type: "object",
    _fullFrom: from,
    diffs: {},
  };
  const alreadyComparedKeys: Partial<
    Record<keyof IObject1 | keyof IObject2, boolean>
  > = {};

  Object.entries(from).forEach(
    ([key, value]: [keyof IObject1, IValueOf<IObject1>]) => {
      if (
        isFunction(value) ||
        (compareKeys.length > 0 && !compareKeys.includes(key as string))
      ) {
        return;
      }
      alreadyComparedKeys[key] = true;
      const diffResult = getTypesDiff(
        value,
        to[key as string] as IValueOf<IObject2>,
        compareKeys
      );

      if (isUnchangedLeaf(diffResult)) {
        return;
      }

      diff.diffs[key] = diffResult;
    }
  );

  Object.entries(to).forEach(
    ([key, value]: [keyof IObject2, IValueOf<IObject2>]) => {
      if (
        isFunction(value) ||
        alreadyComparedKeys[key] ||
        (compareKeys.length > 0 && !compareKeys.includes(key as string))
      ) {
        return;
      }

      const diffResult = getTypesDiff(
        from[key as string] as IValueOf<IObject1> | undefined,
        value,
        compareKeys
      );

      if (isUnchangedLeaf(diffResult)) {
        return;
      }

      diff.diffs[key] = diffResult;
    }
  );

  return _isEmpty(diff.diffs)
    ? {
        _type: "leaf",
        type: ComparisonResultEnum.UNCHANGED,
        from,
        to,
      }
    : diff;
};

const getLeafDiff = <ILeaf1, ILeaf2>(
  from: ILeaf1,
  to: ILeaf2
): ILeafDiff<ILeaf1, ILeaf2> => {
  return {
    _type: "leaf",
    type: compareLeafs(from, to),
    from: from,
    to: to,
  };
};

export const getTypesDiff = <From, To>(
  from: From,
  to: To,
  compareKeys: string[] = []
): IDiffResultState<From, To> => {
  if (isArray(from) && isArray(to)) {
    return getArrayDiff(from, to, compareKeys) as IDiffResultState<From, To>;
  }

  if (isObject(from) && isObject(to)) {
    return getObjectsDiff(from, to, compareKeys) as IDiffResultState<From, To>;
  }

  return getLeafDiff(from, to) as IDiffResultState<From, To>;
};
