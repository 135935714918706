import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { formattedTimeString } from "@/utils";
import * as tsx from "vue-tsx-support";
import FormFile from "./inputs/FormFile.component";

export interface IPageContext {
  currentPage: number;
  perPage: number;
}
@Component({
  components: {
    FormFile,
  },
})
export default class FormTable extends Vue {
  _tsx!: tsx.DeclareProps<
    Pick<FormTable, "value" | "items" | "fields"> &
      Partial<
        Pick<
          FormTable,
          | "hasNextToken"
          | "hasEditField"
          | "uppercaseHeaders"
          | "showFileInput"
          | "uploadProgress"
          | "selectMode"
          | "selectable"
          | "pageOptions"
          | "isLoading"
          | "stretchLastColumn"
          | "allowSelectingAllRows"
        >
      >
  > &
    tsx.DeclareOnEvents<{
      onContextChanged: IPageContext;
      onRowSelected: any;
      onRowEditing: number;
      onFileSelected: File;
    }>;

  $scopedSlots!: tsx.InnerScopedSlots<{ [key: string]: any }>;

  @Prop({ required: true }) readonly value: any[];
  @Prop({ required: true }) readonly items: any[];
  @Prop({ default: false })
  readonly allowSelectingAllRows: boolean;

  @Prop({ default: false }) readonly hasNextToken: boolean;
  @Prop({ required: true }) readonly fields: { key: string; label: string }[];
  @Prop({ default: false }) readonly hasEditField: boolean;
  @Prop({ default: true }) readonly uppercaseHeaders: boolean;
  @Prop({ default: false }) readonly showFileInput: boolean;
  @Prop({ default: 0 }) readonly uploadProgress: number;
  @Prop({ default: "single" }) readonly selectMode: string;
  @Prop({ default: true }) readonly selectable: boolean;
  @Prop({ default: () => [50, 100] })
  readonly pageOptions: number[];
  @Prop({ default: false }) readonly isLoading: boolean;
  @Prop({ default: true }) readonly stretchLastColumn: boolean;

  $refs!: {
    formsTable: HTMLFormElement;
    fileInput: HTMLFormElement;
  };

  currentPage = 1;
  perPage = 50;
  inputFile = null;
  sortBy = "";
  sortDesc = false;
  sortDirection = "asc";
  filter = null;
  filterOn = [];

  get cssClassTableHeader() {
    return [
      "default__table-header",
      this.uppercaseHeaders && "uppercase",
      this.selectable && this.allowSelectingAllRows && "withCheckbox",
    ];
  }

  get cssClassTableBody() {
    return `default__table-body ${
      this.stretchLastColumn ? "stretchLastColumn" : ""
    }`;
  }

  get allRowsSelected(): boolean {
    return (
      this.selected.length > 0 &&
      (this.selected.length === this.items.length ||
        this.selected.length === this.perPage)
    );
  }

  set allRowsSelected(checked: boolean) {
    this.$emit("input", checked);
  }

  get selected(): any[] {
    return this.value;
  }

  set selected(items: any[]) {
    this.$emit("input", items);
  }

  get noRowSelected(): boolean {
    return this.selected.length === 0;
  }

  get selectedRows(): string[] {
    return this.selected.filter((item: any) => item.selected);
  }

  get totalRows(): number {
    return this.items.length + (this.hasNextToken ? this.perPage : 0);
  }

  created() {
    this.perPage = this.pageOptions?.[0] ?? 50;
  }

  onCheckboxClicked(rowIndex: number): void {
    this.items[rowIndex]?.selected
      ? this.$refs.formsTable.selectRow(rowIndex)
      : this.unselectRow(rowIndex);
  }

  onEditIconClicked(rowIndex: number): void {
    this.$emit("rowEditing", rowIndex);
  }

  onFiltered(): void {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.currentPage = 1;
  }

  onRowClicked(): void {
    // It seems that onRowSelected is triggered twice
    // most likely because onCheckboxClicked is called
  }

  onRowSelected(items: any[]): void {
    this.$emit("rowSelected", items);
    this.items.map((item) => (item.selected = false));
    items.map((item) => (item.selected = true));
    this.selected = items;
  }

  formattedTimeString(value: string | number | Date): string {
    return Number.isInteger(value)
      ? formattedTimeString(+value * 1000)
      : formattedTimeString(value);
  }

  @Watch("items")
  resetTable(): void {
    this.items.map((item) => (item.selected = false));
    this.$refs.formsTable.refresh();
    this.$emit("tableReset");
  }

  toggleAll(checked: boolean): void {
    checked
      ? this.$refs.formsTable.selectAllRows()
      : this.$refs.formsTable.clearSelected();
  }

  unselectRow(rowIndex: number) {
    this.$refs.formsTable.unselectRow(rowIndex);
  }

  public refresh(): void {
    this.$refs.formsTable.refresh();
  }

  public resetFileInput(): void {
    this.$refs.fileInput.reset();
  }
}
