import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import {
  IObjectTypeState,
  toggleObjectGroup,
  toggleObjectPublicRead,
  toggleObjectPublicCreate,
  toggleObjectPublicUpdate,
  toggleObjectPublicDelete,
} from "@/models/object-type";
import {
  FormNestedCheckbox,
  IFormNestedCheckboxState,
} from "../forms/inputs/FormNestedCheckbox.component";
import style from "./ApiDataObjectTypePermissionsForm.component.module.scss";
import { ObjectDirectiveEnum } from "@/api/common-types";
import ApiTypesUpdater from "../object-type/ApiTypesUpdater.mixin";
import { IApiState } from "@/models";
import Dropdown from "../shared/Dropdown.component";
import { Button, ButtonThemeEnum } from "../shared/Button.component";
import { Loader } from "../shared/Loader.component";
import { BForm } from "@/lib/typed-bootstrap";
import _cloneDeep from "lodash/cloneDeep";
import Modal from "../shared/Modal.component";

@Component
export class ApiDataObjectTypePermissionsForm extends ApiTypesUpdater {
  _tsx!: tsx.DeclareProps<{
    apiState?: ApiDataObjectTypePermissionsForm["apiState"];
    typeName?: ApiDataObjectTypePermissionsForm["typeName"];
  }> &
    tsx.DeclareOnEvents<{
      onCancel: void;
      onSubmit: IObjectTypeState;
    }>;
  $scopedSlots!: tsx.InnerScopedSlots<{ action?: void; input?: string }>;

  @Prop({
    default: () => ({}),
  })
  apiState: IApiState;
  @Prop() typeName: string;

  @Emit("cancel") private onCancel() {}
  @Emit("submit") private onSubmit(_objectType: IObjectTypeState) {}

  private objectType: IObjectTypeState | null = null;

  @Watch("typeName", { immediate: true })
  @Watch("apiState")
  initObjectType() {
    const objectType = _cloneDeep(
      this.apiState.types.find(
        (objectType) => objectType.name === this.typeName
      )
    );
    this.objectType = objectType ?? null;
  }

  private get isQueryPublic() {
    return (
      this.objectType?.directives.includes(ObjectDirectiveEnum.PUBLIC_READ) ??
      false
    );
  }

  private get isGroup() {
    return (
      this.objectType?.directives.includes(ObjectDirectiveEnum.PUBLIC_READ) ||
      this.objectType?.directives.includes(ObjectDirectiveEnum.GROUP) ||
      false
    );
  }

  private get queryPublicState() {
    return {
      name: "Public",
      selected: this.isQueryPublic,
      children: [
        {
          name: "Group",
          selected: this.isGroup,
          children: [{ name: "Owner", selected: true, disabled: true }],
        },
      ],
    };
  }

  private get createMutationPublicState() {
    return {
      name: this.$t("api_details_data_type_public_create").toString(),
      selected:
        this.objectType?.directives.includes(
          ObjectDirectiveEnum.PUBLIC_CREATE
        ) ?? false,
    };
  }

  private get updateMutationPublicState() {
    return {
      name: this.$t("api_details_data_type_public_update").toString(),
      selected:
        this.objectType?.directives.includes(
          ObjectDirectiveEnum.PUBLIC_UPDATE
        ) ?? false,
    };
  }

  private get deleteMutationPublicState() {
    return {
      name: this.$t("api_details_data_type_public_delete").toString(),
      selected:
        this.objectType?.directives.includes(
          ObjectDirectiveEnum.PUBLIC_DELETE
        ) ?? false,
    };
  }

  private get showErrors() {
    return !!this.serverSideErrors;
  }

  private handleQueryPublicState(value: IFormNestedCheckboxState) {
    if (!this.objectType) {
      return;
    }

    if (this.queryPublicState.selected !== value.selected) {
      this.objectType = toggleObjectPublicRead(this.objectType);
      return;
    }

    if (
      this.queryPublicState.children[0].selected !==
      value.children?.[0].selected
    ) {
      this.objectType = toggleObjectGroup(this.objectType);
    }
  }

  private handleCreateMutationPublicState(value: IFormNestedCheckboxState) {
    if (!this.objectType) {
      return;
    }

    if (this.createMutationPublicState.selected !== value.selected) {
      this.objectType = toggleObjectPublicCreate(this.objectType);
    }
  }

  private handleUpdateMutationPublicState(value: IFormNestedCheckboxState) {
    if (!this.objectType) {
      return;
    }

    if (this.updateMutationPublicState.selected !== value.selected) {
      this.objectType = toggleObjectPublicUpdate(this.objectType);
    }
  }

  private handleDeleteMutationPublicState(value: IFormNestedCheckboxState) {
    if (!this.objectType) {
      return;
    }

    if (this.deleteMutationPublicState.selected !== value.selected) {
      this.objectType = toggleObjectPublicDelete(this.objectType);
    }
  }

  private async handleSubmit(ev: Event) {
    ev.preventDefault();

    const newObjectTypes = this.apiState.types.map((objectType) => {
      if (this.objectType && objectType.name === this.typeName) {
        return this.objectType;
      }
      return objectType;
    });

    const newApiState = {
      ...this.apiState,
      types: newObjectTypes,
    };

    await this.updateApiDeclarationFromState(newApiState);

    if (!this.serverSideErrors && this.objectType) {
      this.onSubmit(this.objectType);
      this.apiState.types = newObjectTypes;
    }
  }

  render() {
    return (
      <BForm
        onSubmit={this.handleSubmit}
        role="form"
        novalidate
        class={style.form}
      >
        <p class={style.subHeader} role="presentation">
          {this.$t("api_details_data_type_read_permissions").toString()}
        </p>
        <FormNestedCheckbox
          id={`${this.objectType?.id}-query-access-action`}
          class={style.action}
          value={this.queryPublicState}
          onChange={this.handleQueryPublicState}
        />
        <Dropdown.Divider />
        <p class={style.subHeader} role="presentation">
          {this.$t("api_details_data_type_write_permissions").toString()}
        </p>
        <FormNestedCheckbox
          id={`${this.objectType?.id}-create-mutation-access-action`}
          class={style.action}
          value={this.createMutationPublicState}
          onChange={this.handleCreateMutationPublicState}
        />
        <FormNestedCheckbox
          id={`${this.objectType?.id}-update-mutation-access-action`}
          class={style.action}
          value={this.updateMutationPublicState}
          onChange={this.handleUpdateMutationPublicState}
        />
        <FormNestedCheckbox
          id={`${this.objectType?.id}-delete-mutation-access-action`}
          class={style.action}
          value={this.deleteMutationPublicState}
          onChange={this.handleDeleteMutationPublicState}
        />
        <Dropdown.Divider />
        <Modal.ActionButtons
          class={[style.actionButtons, { [style.withErrors]: this.showErrors }]}
        >
          <Button
            onClick={this.onCancel}
            class={style.button}
            theme={ButtonThemeEnum.naked}
            type="button"
          >
            {this.$t("global_cta_cancel")}
          </Button>
          <Button
            theme={ButtonThemeEnum.primary}
            class={style.button}
            data-testid="uploadCloudFunction"
            type="submit"
            disabled={this.showErrors}
          >
            {this.isLoading ? (
              <Loader />
            ) : this.typeName === undefined ? (
              this.$t("global_cta_create")
            ) : (
              this.$t("global_cta_save")
            )}
          </Button>
        </Modal.ActionButtons>
        <p class={style.disclaimer}>
          {this.$t("model_widget_access_disclaimer").toString()}
        </p>
      </BForm>
    );
  }
}
