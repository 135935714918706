import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import FormSelect from "../forms/inputs/FormSelect.component";
import {
  IObjectTypeState,
  updateUserEmailDirective,
  updateUserPasswordDirective,
} from "@/models";
import * as tsx from "vue-tsx-support";
import FormGroupWithLabel from "../forms/inputs/FormGroupWithLabel.component";
import style from "./ApiConfigUsersTable.component.module.scss";
import { FieldDirectiveEnum, ObjectDirectiveEnum } from "@/api/common-types";

@Component
export default class ApiConfigUserTable extends Vue {
  _tsx!: tsx.DeclareProps<{
    objectTypes: ApiConfigUserTable["objectTypes"];
  }> &
    tsx.DeclareOnEvents<{
      onConfigured: void;
    }>;

  @Prop({ required: true }) objectTypes: IObjectTypeState[];

  @Emit("configured") onConfigured() {}

  private selectedObjectType: IObjectTypeState | null = null;
  private selectedEmailFieldId: string | null = null;
  private selectedPasswordFieldId: string | null = null;

  @Watch("objectTypes", { immediate: true })
  initialize() {
    if (!this.objectTypes) {
      return;
    }

    this.selectedObjectType =
      this.objectTypes.find((o) =>
        o.directives.includes(ObjectDirectiveEnum.USERS)
      ) ?? null;
    this.selectedEmailFieldId =
      this.selectedObjectType?.fields.find((field) =>
        field.directives?.includes(FieldDirectiveEnum.USER_EMAIL)
      )?.id ?? null;
    this.selectedPasswordFieldId =
      this.selectedObjectType?.fields.find((field) =>
        field.directives?.includes(FieldDirectiveEnum.USER_PASSWORD)
      )?.id ?? null;
  }

  @Watch("selectedObjectType", { immediate: true })
  @Watch("selectedEmailFieldId")
  @Watch("selectedPasswordFieldId")
  onValuesChange() {
    if (
      this.selectedObjectType &&
      this.selectedEmailFieldId &&
      this.selectedPasswordFieldId
    ) {
      this.onConfigured();
    }
  }

  private get selectOptions() {
    return this.objectTypes.map((objectType) => ({
      value: objectType.id,
      text: objectType.name,
    }));
  }

  private handleUserTypeSelection(objectTypeId: string) {
    const objectType = this.objectTypes.find((o) => o.id === objectTypeId);
    if (!objectType) {
      return;
    }

    this.selectedObjectType = objectType;
    this.selectedEmailFieldId = null;
    this.selectedPasswordFieldId = null;
    this.objectTypes.forEach((type) => {
      if (type.directives.find((d) => d === ObjectDirectiveEnum.USERS)) {
        type.fields.forEach((field) => {
          field.directives =
            field.directives?.filter(
              (d) =>
                d !== FieldDirectiveEnum.USER_EMAIL &&
                d !== FieldDirectiveEnum.USER_PASSWORD
            ) ?? [];
        });

        type.directives = type.directives.filter(
          (d) => d !== ObjectDirectiveEnum.USERS
        );
      }
    });
    objectType.directives.push(ObjectDirectiveEnum.USERS);
  }

  private handleEmailFieldSelection(fieldId: string) {
    this.selectedEmailFieldId = fieldId;

    if (!this.selectedObjectType?.directives) {
      return;
    }

    this.selectedObjectType.fields = updateUserEmailDirective(
      fieldId,
      this.selectedObjectType?.fields
    );
  }

  private handlePasswordFieldSelection(fieldId: string) {
    this.selectedPasswordFieldId = fieldId;

    if (!this.selectedObjectType?.directives) {
      return;
    }

    this.selectedObjectType.fields = updateUserPasswordDirective(
      fieldId,
      this.selectedObjectType?.fields
    );
  }

  private get emailFieldOptions() {
    return (
      this.selectedObjectType?.fields
        .filter(
          (field) =>
            field.id !== this.selectedPasswordFieldId && !field.readonly
        )
        .map((field) => ({
          value: field.id ?? "",
          text: field.name,
        })) ?? []
    );
  }

  private get passwordFieldOptions() {
    return (
      this.selectedObjectType?.fields
        .filter(
          (field) => field.id !== this.selectedEmailFieldId && !field.readonly
        )
        .map((field) => ({
          value: field.id ?? "",
          text: field.name,
        })) ?? []
    );
  }

  render() {
    return (
      <div class={style.container}>
        <FormGroupWithLabel
          label={this.$t("api_setup_auth_users_table_label").toString()}
        >
          <FormSelect
            class={style.select}
            selectOptions={this.selectOptions}
            placeholderText={this.$t(
              "api_setup_auth_users_table_placeholder"
            ).toString()}
            value={this.selectedObjectType?.id ?? null}
            onInput={this.handleUserTypeSelection}
            id="userTypeSelect"
          />
        </FormGroupWithLabel>
        {this.selectedObjectType?.id && [
          <FormGroupWithLabel
            label={this.$t("api_setup_auth_users_email_label").toString()}
          >
            <FormSelect
              selectOptions={this.emailFieldOptions}
              placeholderText={this.$t(
                "api_setup_auth_users_table_email_placeholder"
              ).toString()}
              class={style.select}
              value={this.selectedEmailFieldId}
              onInput={this.handleEmailFieldSelection}
              id="emailFieldSelect"
            />
          </FormGroupWithLabel>,
          <FormGroupWithLabel
            label={this.$t("api_setup_auth_users_password_label").toString()}
          >
            <FormSelect
              selectOptions={this.passwordFieldOptions}
              placeholderText={this.$t(
                "api_setup_auth_users_table_password_placeholder"
              ).toString()}
              class={style.select}
              value={this.selectedPasswordFieldId}
              onInput={this.handlePasswordFieldSelection}
              id="passwordFieldSelect"
            />
          </FormGroupWithLabel>,
        ]}
      </div>
    );
  }
}
