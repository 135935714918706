import {
  ICheckoutSessionInput,
  ICheckoutSessionUpdateInput,
} from "@/generated/types";
import {
  createCheckoutSession,
  updateCheckoutSession,
} from "@/models/checkout-session";
import { CheckoutModule } from "@/store/modules/checkout";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class CheckoutSession extends Vue {
  isLoading = false;
  async createCheckoutSession(input: ICheckoutSessionInput) {
    try {
      this.isLoading = true;
      return await createCheckoutSession({ input });
    } finally {
      this.isLoading = false;
    }
  }

  async updateCheckoutSession(input: ICheckoutSessionUpdateInput) {
    try {
      this.isLoading = true;
      return await updateCheckoutSession({ input });
    } finally {
      this.isLoading = false;
    }
  }

  async upsertCheckoutSession(
    input: ICheckoutSessionInput | ICheckoutSessionUpdateInput
  ) {
    try {
      this.isLoading = true;
      let session = undefined;
      if (input.id) {
        session = await this.updateCheckoutSession(
          input as ICheckoutSessionUpdateInput
        );
      } else {
        const { id, ...rest } = input;
        session = await this.createCheckoutSession(
          rest as ICheckoutSessionInput
        );
      }

      if (input.apiId) {
        CheckoutModule.handleCheckoutSessionResponse({
          apiId: input.apiId,
          session,
        });
      }

      return session;
    } finally {
      this.isLoading = false;
    }
  }
}
