import { BCol, BRow } from "@/lib/typed-bootstrap";
import { Vue, Component, Prop } from "vue-property-decorator";
import style from "./ItemChangesDiffRow.component.module.scss";
import * as tsx from "vue-tsx-support";
import { IDiffRowState } from "./types";
import { ComparisonResultEnum } from "@/utils";

@Component
export class ItemChangesDiffRow extends Vue {
  _tsx!: tsx.DeclareProps<{
    diff: ItemChangesDiffRow["diff"];
  }>;

  @Prop({ required: true }) diff: IDiffRowState;

  get hasFromSide(): boolean {
    return (
      this.diff.from !== undefined &&
      this.diff.type !== ComparisonResultEnum.CREATED
    );
  }

  get fromSide(): string {
    if (!this.diff.from || this.diff.type === ComparisonResultEnum.CREATED)
      return "";

    const { name, type } = this.diff.from;

    return `${name ?? ""}${type ? ":" : ""} ${type ?? ""}`;
  }

  get fromSideOperator(): string {
    return this.hasFromSide ? "-" : "";
  }

  get hasToSide(): boolean {
    return (
      this.diff.to !== undefined &&
      this.diff.type !== ComparisonResultEnum.DELETED
    );
  }

  get toSide(): string {
    if (!this.diff.to || this.diff.type === ComparisonResultEnum.DELETED)
      return "";

    const { name, type } = this.diff.to;

    return `${name ?? ""}${type ? ":" : ""} ${type ?? ""}`;
  }

  get toSideOperator(): string {
    return this.hasToSide ? "+" : "";
  }

  render() {
    return (
      <BRow class={style.row}>
        <BCol
          cols="6"
          class={{
            [style.col]: true,
            [style.fromSide]: this.hasFromSide,
          }}
        >
          <span class={style.operator}>{this.fromSideOperator}</span>
          <span>{this.fromSide}</span>
        </BCol>
        <BCol
          cols="6"
          class={{ [style.col]: true, [style.toSide]: this.hasToSide }}
        >
          <span class={style.operator}>{this.toSideOperator}</span>
          <span>{this.toSide}</span>
        </BCol>
      </BRow>
    );
  }
}
