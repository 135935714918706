import { Component, Prop, Watch } from "vue-property-decorator";
import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import _remove from "lodash/remove";
import FormTable from "../forms/FormTable.component.vue";
import {
  FormModalEditingType,
  FormModalInputTypeEnum,
  IFormModalInputState,
} from "../modal/FormModal.component";
import FormModal from "../modal/FormModal.component.vue";
import {
  convertDaysToSeconds,
  convertTimestampToDaysFromNow,
  getCurrentTimestampSeconds,
} from "@/utils";
import { ApolloClient, gql } from "apollo-boost";
import { mixins } from "vue-class-component";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import ErrorHandling from "@/mixins/ErrorHandling.mixin";
import { GraphapiModule } from "@/store";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import plusIconBlack from "@/assets/img/plus-icon.svg";
import plusIconWhite from "@/assets/img/plus-icon-light.svg";

@Component({
  components: {
    FormTable,
    FormModal,
    ThemedImage,
  },
})
export default class ApiManagementKeysSection extends mixins(
  ErrorHandling,
  UserPermissions
) {
  @Prop({ required: true }) apiClient: ApolloClient<unknown>;
  @Prop({ required: true }) isVisible: boolean;
  @Prop({ required: true }) apiId: string;

  apiKeys: any[] = [];
  editMode = FormModalEditingType.CREATE;
  apiKeyDataFormInput: IFormModalInputState[] | null = null;
  createApiKeyInputParam = "ApiKeyInput!";
  updateApiKeyInputParam = "ApiKeyUpdateInput!";
  createApiKeyMutationField = "createApiKey";
  updateApiKeyMutationField = "updateApiKey";
  deleteApiKeyInputParam = "ApiKeyDeleteInput!";
  deleteApiKeyMutationField = "deleteApiKey";
  selectedAction = null;
  selected: any[] = [];
  isLoading = false;

  $refs!: {
    apiKeysTable: FormTable;
  };

  plusIconSrc = {
    light: plusIconWhite,
    dark: plusIconBlack,
  };

  get hasAccessToken(): boolean {
    return !!GraphapiModule.accessTokens[this.apiId];
  }

  get defaultApiKeyDataFormInput() {
    return [
      {
        type: FormModalInputTypeEnum.STRING,
        label: this.$t("api_key_table_header_description").toString(),
        placeholderText: this.$t("form_placeholder_key_description").toString(),
        value: null,
        key: "description",
      },
      {
        type: FormModalInputTypeEnum.INT,
        label: this.$t("api_key_table_header_expires").toString(),
        placeholderText: this.$t("form_placeholder_expires").toString(),
        value: 365,
        min: 1,
        max: 365,
        key: "expires",
      },
    ];
  }

  get selectedApiKey() {
    return this.apiKeys.find((k) => k.selected);
  }

  set selectedApiKey(apiKey: any) {
    const apiKeyRowIndex = this.apiKeys.findIndex((k) => k.selected);
    this.apiKeys[apiKeyRowIndex] = apiKey;
  }

  get totalRows(): number {
    return this.apiKeys.length;
  }

  get apiKeyListQueryFieldName(): string {
    return "listApiKeys";
  }

  get apiKeyListQueryFields(): string[] {
    return [
      "id",
      "createdAt",
      "updatedAt",
      "key",
      "description",
      "expires",
      "deletes",
    ];
  }

  get apiKeyListQuery(): any {
    return gql`
      query ${this.apiKeyListQueryFieldName}($limit: Int, $nextToken: String) {
        ${this.apiKeyListQueryFieldName}(limit: $limit, nextToken: $nextToken) {
          nextToken
          items {
            ${this.apiKeyListQueryFields.join("\n")}
          }
        }
      }
    `;
  }

  get apiKeyDeleteMutation(): any {
    return gql`
      mutation ${this.deleteApiKeyMutationField}($input: ${
      this.deleteApiKeyInputParam
    }) {
        ${this.deleteApiKeyMutationField}(input: $input) {
          ${this.apiKeyListQueryFields.join("\n")}
        }
      }
    `;
  }

  get noRowSelected(): boolean {
    return this.selected.length === 0;
  }

  get actionOptions() {
    return [
      {
        value: "edit",
        text: this.$t("global_cta_edit"),
        disabled: !this.canManageUsers,
      },
      {
        value: "delete",
        text: this.$t("global_cta_delete"),
        disabled: !this.canManageUsers,
      },
    ];
  }

  get fields() {
    return [
      { key: "selected", label: "" },
      { key: "createdAt", label: this.$t("api_key_table_header_createdat") },
      { key: "updatedAt", label: this.$t("api_key_table_header_updatedat") },
      { key: "key", label: this.$t("api_key_table_header_key") },
      {
        key: "description",
        label: this.$t("api_key_table_header_description"),
      },
      { key: "expires", label: this.$t("api_key_table_header_expires") },
    ];
  }

  get refetchIndicator() {
    return `${this.isVisible}|${this.hasAccessToken}`;
  }

  @Watch("refetchIndicator", { immediate: true })
  async onApiChange() {
    if (this.isVisible) {
      await this.getApiKeys();
    }
  }

  onAddApiKey() {
    this.editMode = FormModalEditingType.CREATE;
    this.apiKeyDataFormInput = _cloneDeep(this.defaultApiKeyDataFormInput);
    this.$bvModal.show("form-modal");
  }

  async onCreateApiKey(): Promise<void> {
    const apiKeyDataInput =
      this.apiKeyDataFormInput?.reduce(
        (
          acc: Record<string, IFormModalInputState["value"]>,
          formInput: IFormModalInputState
        ) => {
          if (formInput.value) {
            if (formInput.key === "expires" && formInput.value) {
              acc[formInput.key] =
                getCurrentTimestampSeconds() +
                convertDaysToSeconds(formInput.value);
              return acc;
            }

            acc[formInput.key] = formInput.value;
          }

          return acc;
        },
        {}
      ) ?? {};
    apiKeyDataInput["key"] = "";

    try {
      const createMutation = gql`
        mutation ${this.createApiKeyMutationField}($input: ${
        this.createApiKeyInputParam
      }) {
          ${this.createApiKeyMutationField}(input: $input) {
            ${this.apiKeyListQueryFields.join("\n")}
          }
        }
      `;

      const data = await this.apiClient.mutate({
        mutation: createMutation,
        variables: { input: apiKeyDataInput },
      });

      this.apiKeys.unshift(
        _get(data, `data.${this.createApiKeyMutationField}`)
      );
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }
  }

  async onUpdateApiKey(): Promise<void> {
    const apiKeyDataInput =
      this.apiKeyDataFormInput?.reduce(
        (acc: { [key: string]: any }, formInput: IFormModalInputState) => {
          if (formInput.value) {
            if (formInput.key === "expires") {
              acc[formInput.key] =
                getCurrentTimestampSeconds() +
                convertDaysToSeconds(formInput.value);
              return acc;
            }

            acc[formInput.key] = formInput.value;
          }

          return acc;
        },
        {}
      ) ?? {};
    apiKeyDataInput["id"] = this.selectedApiKey?.id;
    apiKeyDataInput["key"] = this.selectedApiKey?.key;

    try {
      const updateMutation = gql`
        mutation ${this.updateApiKeyMutationField}($input: ${
        this.updateApiKeyInputParam
      }) {
          ${this.updateApiKeyMutationField}(input: $input) {
            ${this.apiKeyListQueryFields.join("\n")}
          }
        }
      `;

      const data = await this.apiClient.mutate({
        mutation: updateMutation,
        variables: { input: apiKeyDataInput },
      });

      this.selectedApiKey = _get(
        data,
        `data.${this.updateApiKeyMutationField}`
      );

      this.resetTable();
    } catch (err) {
      this.errorMessage = (err as Error).message;
      this.resetTable();
    }
  }

  async onSelectApiKeyAction(item: string): Promise<void> {
    try {
      if (this.canManageUsers) {
        const apiKey = this.selected[0];
        if (item === "delete") {
          if (
            window.confirm(
              this.$t("api_details_settings_apikey_delete_confirm").toString()
            )
          ) {
            try {
              await this.apiClient.mutate({
                mutation: this.apiKeyDeleteMutation,
                variables: { input: { id: apiKey.id, key: apiKey.key } },
              });
              _remove(this.apiKeys, { id: apiKey.id });
              this.resetActionsMenu();
            } catch (err) {
              this.errorMessage = (err as Error).message;
            }
          } else {
            this.resetTable();
          }
        } else if (item === "edit") {
          this.editMode = FormModalEditingType.UPDATE;
          this.apiKeyDataFormInput = _cloneDeep(
            this.defaultApiKeyDataFormInput
          );
          this.apiKeyDataFormInput[0].value = apiKey.description;
          this.apiKeyDataFormInput[1].value = convertTimestampToDaysFromNow(
            apiKey.expires
          );
          this.$bvModal.show("form-modal");
        }
      } else {
        throw new Error(
          this.$t("error_api_key_create_requires_role").toString()
        );
      }
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }
  }

  async getApiKeys(): Promise<any> {
    if (this.apiClient) {
      try {
        this.isLoading = true;
        const data = await this.apiClient.query({
          query: this.apiKeyListQuery,
          variables: { limit: 50 },
          errorPolicy: "ignore",
          fetchPolicy: "network-only",
        });

        this.apiKeys = _get(
          data,
          `data.${this.apiKeyListQueryFieldName}.items`,
          []
        );
        this.apiKeys.forEach((key) => (key.selected = false));
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    }
  }

  resetActionsMenu(): void {
    setTimeout(() => {
      this.selectedAction = null;
    }, 100);
    this.selected = [];
  }

  resetTable() {
    this.apiKeys.forEach((key) => (key.selected = false));
    setTimeout(() => {
      (this.$refs.apiKeysTable as any).refresh();
    }, 100);
    this.resetActionsMenu();
  }
}
