import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import moreIcon from "@/assets/img/icon-more.svg";
import { FormInputWithDropdown } from "../forms/inputs/FormInputWithDropdown.component";
import {
  getTypedBFormSelect,
  getTypedBFormSelectOption,
  IBFormSelectEvents,
} from "@/lib/typed-bootstrap";
import FormRadio from "../forms/inputs/FormRadio.component";

const BFormSelect = getTypedBFormSelect<string>();
const BFormSelectOption = getTypedBFormSelectOption<string | null>();

const moreIconSrc = {
  light: moreIcon,
  dark: moreIcon,
};

@Component
export class ObjectTypeSelect extends Vue {
  _tsx!: tsx.DeclareProps<
    Pick<
      ObjectTypeSelect,
      "value" | "typesOptions" | "connectorOptions" | "connectorValue"
    >
  > &
    tsx.DeclareOnEvents<
      IBFormSelectEvents<string> & { onConnectorChange: string }
    >;
  $scopedSlots!: tsx.InnerScopedSlots<{ action?: void; input?: string }>;

  @Prop({ required: true }) value: string | null;
  @Prop({ required: true }) connectorValue: string;
  @Prop({ required: true }) typesOptions: {
    value: string;
    text: string;
  }[];
  @Prop({ required: true }) connectorOptions: {
    value: string;
    text: string;
  }[];

  @Emit("connectorChange") onConnectorChange(_value: string) {}

  render() {
    const { value } = this;

    return (
      <FormInputWithDropdown
        id={`type-${value ?? "unknown"}`}
        iconSrc={moreIconSrc}
        titleContent={this.$t("model_widget_connector_title").toString()}
        disclaimerContent={this.$t(
          "model_widget_connector_disclaimer"
        ).toString()}
        scopedSlots={{
          dropdownContent: () => (
            <FormRadio
              options={this.connectorOptions}
              value={this.connectorValue}
              onInput={this.onConnectorChange}
            />
          ),
          input: (inputClass) => (
            <BFormSelect
              class={[inputClass, "default__form-input", "form-select__input"]}
              value={value ?? null}
              options={this.typesOptions}
              required
              on={this.$listeners}
            >
              <template slot="first">
                <BFormSelectOption value={null} disabled>
                  {this.$t("api_details_data_types_select_type")}
                </BFormSelectOption>
              </template>
            </BFormSelect>
          ),
        }}
      />
    );
  }
}
