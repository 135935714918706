import { Vue, Component, Prop } from "vue-property-decorator";
import { IPriceState, IProductState } from "@/models/product-bundle";
import {
  ProductTypeEnum,
  UsageTypeEnum,
  BillingIntervalEnum,
} from "@/api/common-types";
import * as tsx from "vue-tsx-support";
import AddonProductWidget from "./AddonProductWidget.component.vue";
import ProductWidget from "./ProductWidget.component.vue";
import PricingListItem from "../pricing/PricingListItem.component.vue";

@Component({
  components: { ProductWidget, AddonProductWidget, PricingListItem },
})
export default class ProductWidgetGroup extends Vue {
  _tsx!: tsx.DeclareProps<{
    products: ProductWidgetGroup["products"];
    bundleType: ProductWidgetGroup["bundleType"];
    billingInterval: ProductWidgetGroup["billingInterval"];
  }>;

  @Prop({ required: true })
  readonly products: IProductState[];
  @Prop({ required: true }) readonly bundleType: string;
  @Prop() billingInterval: BillingIntervalEnum;

  get bundleProduct(): IProductState {
    return this.products?.filter((p) => p.type === ProductTypeEnum.BUNDLE)[0];
  }

  get bundleProductName(): string {
    return this.bundleProduct?.displayName ?? "";
  }

  get bundlePricings() {
    return this.bundleProduct?.pricings[this.billingInterval] ?? [];
  }

  get bundlePrice(): number {
    return this.bundlePricings[0]?.price ?? 0;
  }

  get bundlePriceString(): string {
    return this.bundlePrice === 0
      ? "free"
      : this.bundlePrice.toLocaleString(undefined, {
          style: "currency",
          currency: "EUR",
        });
  }

  get firstBaseProductPricing(): IPriceState {
    return this.bundlePricings[this.baseProducts?.[0]?.selectedPricingIndex];
  }

  get selectedAddonProducts(): Array<IProductState> {
    return (
      this.products?.filter(
        (p) => p.type === ProductTypeEnum.ADDON && p.selected
      ) ?? []
    );
  }

  get addonProducts(): Array<IProductState> {
    return this.products?.filter((p) => p.type === ProductTypeEnum.ADDON);
  }

  get addonProductsTotalPrice() {
    return this.selectedAddonProducts.reduce((acc, product) => {
      const prices = product.pricings[this.billingInterval];
      if (prices && prices?.length > 0) {
        acc += prices?.[0].price;
      }

      return acc;
    }, 0);
  }

  get baseProducts(): Array<IProductState> {
    return this.products?.filter(
      (p) =>
        p.type === ProductTypeEnum.BASE && p.usageType === UsageTypeEnum.METERED
    );
  }

  get priceIntervalDisplayValue() {
    return ` / ${
      this.billingInterval === BillingIntervalEnum.YEAR
        ? this.$t("billing_interval_year")
        : this.$t("billing_interval_month")
    }`;
  }

  get totalPrice(): number {
    return this.bundlePrice + this.addonProductsTotalPrice;
  }

  get totalPriceString(): string {
    return (this.totalPrice ?? 0).toLocaleString(undefined, {
      style: "currency",
      currency: "EUR",
    });
  }
}
