import { Component, Emit, Vue } from "vue-property-decorator";
import { OrgModule } from "@/store/modules/org";
import { INavItem } from "@/store/modules/nav";
import { BContainer } from "@/lib/typed-bootstrap";
import styles from "./OrgManagementNavigation.component.module.scss";
import * as tsx from "vue-tsx-support";

@Component
export default class OrgManagementNavigation extends Vue {
  _tsx: tsx.DeclareOnEvents<{
    onLinkClick: void;
  }>;

  private get orgId(): string {
    return OrgModule.id;
  }

  private get buttons(): INavItem[] {
    return [
      {
        text: `${this.$t("nav_secondary_apis")}`,
        link: { name: "org-api-dashboard", params: { orgId: this.orgId } },
      },
      {
        text: `${this.$t("nav_secondary_billing")}`,
        link: { name: "org-billing", params: { orgId: this.orgId } },
      },
      {
        text: `${this.$t("nav_secondary_users")}`,
        link: { name: "org-users", params: { orgId: this.orgId } },
      },
      {
        text: `${this.$t("nav_secondary_settings")}`,
        link: { name: "org-settings", params: { orgId: this.orgId } },
      },
    ];
  }

  @Emit("linkClick")
  private onLinkClick() {}

  render() {
    return (
      <BContainer class={["justify-content-md-center", styles.container]}>
        {this.buttons.map((button) => (
          <router-link
            key={button.text}
            to={button.link}
            data-testid={`navLink${button.link.name}`}
            class={styles.button}
            custom
            scopedSlots={{
              default: ({
                href,
                navigate,
                isActive,
              }: {
                href: string;
                navigate: () => void;
                isActive: boolean;
              }) => (
                <a
                  href={href}
                  onClick={navigate}
                  class={[isActive && styles.isActive]}
                >
                  {button.text}
                </a>
              ),
            }}
            nativeOn={{
              click: this.onLinkClick,
            }}
          />
        ))}
      </BContainer>
    );
  }
}
