import { isNotNullable } from "@/utils";
import _pickBy from "lodash/pickBy";

export type ReplaceValueTypeIfMatching<Value, Type, Result, Fallback> =
  Value extends Type ? Result : Fallback;

/**
 * Input: ReplaceStringPropWithJSON<{ a: string}, 'a', { b: string}>
 * Output: { a: { b: string } }
 * Input: ReplaceStringPropWithJSON<{ a: string | undefined }, 'a', { b: string }>
 * Output:  { a: { b: string } | undefined }
 * Input: ReplaceStringPropWithJSON<{ a: number }, 'a', { b: string }>
 * Output: { a: number } // types remains unchanged
 */
export type ReplaceStringPropWithJSON<InputObject, Key, Result> = {
  [K in keyof InputObject]: ReplaceValueTypeIfMatching<
    K,
    Key,
    ReplaceValueTypeIfMatching<
      InputObject[K],
      string,
      Result,
      ReplaceValueTypeIfMatching<
        InputObject[K],
        any,
        Result,
        ReplaceValueTypeIfMatching<
          InputObject[K],
          string | undefined,
          Result | undefined,
          ReplaceValueTypeIfMatching<
            InputObject[K],
            string | null,
            Result | null,
            ReplaceValueTypeIfMatching<
              InputObject[K],
              string | null | undefined,
              Result | null | undefined,
              InputObject[K]
            >
          >
        >
      >
    >,
    InputObject[K]
  >;
};

export const parseJsonStringProp = <T, Key extends keyof T, Target>(
  obj: T,
  key: Key
): ReplaceStringPropWithJSON<T, Key, Target> => {
  const val = obj[key];

  if (typeof val === "string") {
    return {
      ...obj,
      [key]: val ? (JSON.parse(val) as Target) : undefined,
    } as ReplaceStringPropWithJSON<T, Key, Target>;
  }

  return obj as ReplaceStringPropWithJSON<T, Key, Target>;
};

export const extractData = <T extends { data: K }, K>(input: T): T["data"] =>
  input.data;

export const extractQueryname = <T, K extends keyof T>(input: T, key: K) =>
  input[key];

export const extractPropOrThrow = <T, K extends keyof T>(
  input: T,
  key: K
): NonNullable<T[K]> => {
  const val = input[key];

  if (isNotNullable(val)) {
    return val;
  }

  throw new Error(`${key.toString()} missing in response`);
};

export const extractItems = <T extends { items: K }, K>(input: T): T["items"] =>
  input.items;

export const extractAndFilterItemsOrProvideDefault = <
  T extends { items?: K[] | null } | null | undefined,
  K
>(
  input: T
): NonNullable<NonNullable<NonNullable<T>["items"]>[number]>[] => {
  if (!isNotNullable(input)) {
    return [];
  }

  if (!isNotNullable(input.items)) {
    return [];
  }

  return input.items.filter(isNotNullable);
};

export const provideNameDefault = <T extends { name?: string | null }>(
  input: T,
  defaultVal: string
) => {
  return {
    ...input,
    name: isNotNullable(input.name) ? input.name : defaultVal,
  };
};

export const extractNonEmptyKeys = <T extends object>(object: T) =>
  _pickBy(object, isNotNullable);
