import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import {
  BaseFieldTypeEnum,
  ComplexFieldTypeEnum,
  DateFieldTypeEnum,
  IFieldState,
  ListFieldTypeEnum,
} from "@/models";
import { ObjectTypeFieldNameInput } from "./ObjectTypeFieldNameInput.component";
import FormInputsRow from "../forms/inputs/FormInputsRow.component";
import * as tsx from "vue-tsx-support";
import {
  BFormGroup,
  getTypedBFormSelect,
  getTypedBFormSelectOption,
} from "@/lib/typed-bootstrap";
import { FieldDirectiveEnum } from "@/api/common-types";
import style from "./ObjectTypeField.component.module.scss";

export interface IFormSelectEntryState {
  value: string;
  text: string;
}

export interface IFormSelectGroupState {
  label: string;
  options: IFormSelectEntryState[];
}

export const ObjectFieldTypeSelect = getTypedBFormSelect<string>();

export const ObjectFieldTypeSelectOption = getTypedBFormSelectOption<string>();

@Component({ components: { ObjectTypeFieldNameInput, FormInputsRow } })
export class ObjectTypeField extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: ObjectTypeField["value"];
    isUnique?: ObjectTypeField["isUnique"];
    isSortByField: ObjectTypeField["isSortByField"];
    enumTypesOptions?: ObjectTypeField["enumTypesOptions"];
    index: ObjectTypeField["index"];
  }> &
    tsx.DeclareOnEvents<{
      onDelete: void;
      onInput: IFieldState;
      onAddEnumType: void;
      onSortByFieldChange: boolean;
      onRequiredFieldChange: FieldDirectiveEnum;
    }>;

  @Prop({ required: true }) value: IFieldState;
  @Prop({ required: false, default: null }) isUnique: boolean | null;
  @Prop({ required: true }) isSortByField: boolean;
  @Prop({ required: false, default: [] })
  enumTypesOptions: IFormSelectEntryState[];
  @Prop({ required: true }) index: number;

  @Emit("delete") onDelete() {}
  @Emit("sortByFieldChange") onSortByFieldChange(_isSortByField: boolean) {}
  @Emit("requiredFieldChange") onRequiredFieldChange(
    _directive: FieldDirectiveEnum
  ) {}
  @Emit("input") onInput(_value: IFieldState) {}
  @Emit("addEnumType") onAddEnumType() {}

  private selectValueAddEnumType = "add_enum_type";

  private get invalidFeedback(): string {
    return this.$t("form_feedback_field_name", {
      name: this.value.name,
    }).toString();
  }

  private get typeOptions(): IFormSelectGroupState[] {
    return [
      {
        label: this.$t("model_field_section_enums").toString(),
        options: [
          {
            value: "add_enum_type",
            text: this.$t("enum_widget_add_type").toString(),
          },
          ...this.enumTypesOptions,
        ],
      },
      {
        label: this.$t("model_field_section_base").toString(),
        options: [
          { value: BaseFieldTypeEnum.Boolean, text: "Boolean" },
          { value: BaseFieldTypeEnum.Float, text: "Float" },
          { value: BaseFieldTypeEnum.ID, text: "ID" },
          { value: BaseFieldTypeEnum.Integer, text: "Integer" },
          { value: BaseFieldTypeEnum.String, text: "String" },
        ],
      },
      {
        label: this.$t("model_field_section_base_list").toString(),
        options: [
          { value: ListFieldTypeEnum.ListBoolean, text: "List of Booleans" },
          { value: ListFieldTypeEnum.ListFloat, text: "List of Floats" },
          { value: ListFieldTypeEnum.ListId, text: "List of IDs" },
          { value: ListFieldTypeEnum.ListInteger, text: "List of Integers" },
          { value: ListFieldTypeEnum.ListString, text: "List of Strings" },
        ],
      },
      {
        label: this.$t("model_field_section_complex").toString(),
        options: [
          { value: ComplexFieldTypeEnum.Email, text: "Email" },
          { value: ComplexFieldTypeEnum.IpAddress, text: "IpAddress" },
          { value: ComplexFieldTypeEnum.Json, text: "Json" },
          { value: ComplexFieldTypeEnum.Phone, text: "Phone" },
          { value: ComplexFieldTypeEnum.Url, text: "Url" },
        ],
      },
      {
        label: this.$t("model_field_section_datetime").toString(),
        options: [
          { value: DateFieldTypeEnum.Date, text: "Date" },
          { value: DateFieldTypeEnum.DateTime, text: "DateTime" },
          { value: DateFieldTypeEnum.Time, text: "Time" },
          { value: DateFieldTypeEnum.Timestamp, text: "Timestamp" },
        ],
      },
    ];
  }

  private update(key: string, value: string | number) {
    if (value === this.selectValueAddEnumType) {
      this.onAddEnumType();
    } else {
      this.onInput({ ...this.value, [key]: value });
    }
  }

  render() {
    return (
      <BFormGroup
        class={style.formGroup}
        invalidFeedback={this.invalidFeedback}
        state={this.value.readonly ? null : this.isUnique}
      >
        <FormInputsRow>
          <template slot="leftColumn">
            <button
              onClick={tsx.modifiers.prevent(this.onDelete)}
              class={{
                [style.delete]: true,
                [style.hidden]: this.value.readonly,
              }}
            ></button>

            <BFormGroup class={style.nestedFormGroup}>
              <ObjectFieldTypeSelect
                class="default-select__form-input"
                value={this.value.type}
                options={this.typeOptions}
                onInput={(event) => this.update("type", event)}
                disabled={!!this.value.readonly}
              >
                <template slot="first">
                  <ObjectFieldTypeSelectOption value={null} disabled>
                    {this.$t("model_field_type_placeholder")}
                  </ObjectFieldTypeSelectOption>
                </template>
              </ObjectFieldTypeSelect>
            </BFormGroup>
          </template>
          <template slot="rightColumn">
            <BFormGroup class={style.nestedFormGroup}>
              <ObjectTypeFieldNameInput
                value={this.value}
                index={this.index}
                isUnique={this.isUnique}
                isSortByField={this.isSortByField}
                onSortByChange={this.onSortByFieldChange}
                onRequiredChange={this.onRequiredFieldChange}
                onNameChange={(event) => this.update("name", event)}
              ></ObjectTypeFieldNameInput>
            </BFormGroup>
          </template>
        </FormInputsRow>
      </BFormGroup>
    );
  }
}
