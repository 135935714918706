import BootstrapVue from "bootstrap-vue";
import Vue from "vue";
import IdleVue from "idle-vue";
import router, { secureRouter } from "@/router";
import { configureApiClient } from "@/api";
import store, { UserModule, OrgModule } from "@/store";
import FormSelect from "./components/forms/inputs/FormSelect.component";
import FormTable from "./components/forms/FormTable.component.vue";
import { Component } from "vue-property-decorator";
import { createVueInstance } from "./vue-instance";
import i18n from "./i18n";
import { userDataLoader } from "./utils/auth-service";
import { UserRedirector } from "./utils/auth-service/user-redirector";
import { authManager } from "./utils/auth-service/auth-manager";
import PortalVue from "portal-vue";

(window as any).__GRAPHAPI_VERSION__ =
  process.env.VUE_APP_CODEBUILD_SOURCE_VERSION || process.env.VUE_APP_COMMIT_ID;

configureApiClient({
  UserModule,
  OrgModule,
  authManager,
});

// Configure router
secureRouter(userDataLoader, new UserRedirector(OrgModule));

if (window.Cypress) {
  window.__store__ = store;
}

// Register components for global usage
Vue.component("FormSelect", FormSelect);
Vue.component("FormTable", FormTable);

Vue.config.productionTip = false;

Component.registerHooks(["onIdle", "onActive"]);

Vue.use(BootstrapVue);
Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  store,
  idleTime: 3600000,
});

Vue.use(PortalVue);

authManager.initialize();

createVueInstance(i18n, router, store).$mount("#app");
