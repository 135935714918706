import { ISchemaTemplate } from "@/models/schema-template";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import Card from "../shared/Card.component";
import * as tsx from "vue-tsx-support";
import style from "./ProjectSetupSchemaTemplateCard.component.module.scss";
import TextSection, {
  TextSectionComponentEnum,
  TextSectionSizeEnum,
} from "../shared/TextSection.component";

@Component
export default class ProjectSetupSchemaTemplateCard extends Vue {
  _tsx!: tsx.DeclareProps<{
    template: ProjectSetupSchemaTemplateCard["template"];
    isSelected: ProjectSetupSchemaTemplateCard["isSelected"];
  }> &
    tsx.DeclareOnEvents<{
      onToggleSelect: string;
    }>;

  @Prop({ required: true }) template: ISchemaTemplate;
  @Prop({ required: true }) isSelected: boolean;

  @Emit("toggleSelect") onToggleSelect() {
    return this.template.id;
  }

  render() {
    return (
      <Card
        selected={this.isSelected}
        onClick={this.onToggleSelect}
        data-testid={`${this.template.name}TemplateCard`}
        class={style.card}
      >
        <div class={style.container}>
          <TextSection
            title={this.template.name ?? ""}
            titleComponent={TextSectionComponentEnum.h6}
            size={TextSectionSizeEnum.m}
          >
            <div>
              <div class={style.objects}>
                {this.template.schema.objectTypes.map((type) => (
                  <span key={type.id} class={style.objectPill}>
                    {type.name}
                  </span>
                ))}
              </div>
              <div class={style.content}>{this.template.description}</div>
            </div>
          </TextSection>
        </div>
      </Card>
    );
  }
}
