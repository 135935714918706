import { LanguageCodeEnum, languagesList } from "@/i18n";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class LangSelect extends Vue {
  languages = languagesList.map((locale) => ({
    value: locale.code,
    text: locale.name,
  }));

  onSelect(val: LanguageCodeEnum) {
    this.$i18n.locale = val;
  }
}
