import { OrgModule } from "@/store/modules/org";
import CREATE_BUNDLE_SUBSCRIPTION from "./create_bundle_subscription.graphql";
import LIST_BUNDLE_SUBSCRIPTIONS_BY_API from "./list_bundle_subscriptions.graphql";
import DELETE_BUNDLE_SUBSCRIPTION from "./delete_bundle_subscription.graphql";
import {
  apolloClient,
  mutationFunctionFactory,
  queryFunctionFactory,
} from "../internal-utils";
import {
  IBundleSubscriptionInput,
  ICreateBundleSubscriptionMutation,
  IDeleteBillingAccountMutationVariables,
  IDeleteBundleSubscriptionMutation,
  IListBundleSubscriptionsByApiQuery,
} from "@/generated/types";

export const createBundleSubscriptionMutation = (
  input: IBundleSubscriptionInput
) => {
  input["orgId"] = OrgModule.id;
  input["paymentProviderCustomerId"] =
    OrgModule.billingAccount?.paymentProviderId ?? "";

  return apolloClient.mutate<ICreateBundleSubscriptionMutation>({
    mutation: CREATE_BUNDLE_SUBSCRIPTION,
    variables: { input },
  });
};

export const listBundleSubscriptionsByApiQuery = queryFunctionFactory<
  IListBundleSubscriptionsByApiQuery,
  { apiId: string }
>(LIST_BUNDLE_SUBSCRIPTIONS_BY_API);

export const deleteBundleSubscription = mutationFunctionFactory<
  IDeleteBundleSubscriptionMutation,
  IDeleteBillingAccountMutationVariables
>(DELETE_BUNDLE_SUBSCRIPTION);
