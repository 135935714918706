import style from "./PlaceholderCell.component.module.scss";
import { IRenderFn } from "@/utils/types";

interface IPlaceholderCellProps {
  isLastRow: boolean;
  isLastColumn: boolean;
}

export const renderPlaceholderCell: IRenderFn<IPlaceholderCellProps> = (
  h,
  { props: { isLastRow, isLastColumn }, data }
) => (
  <td
    class={{
      ...(typeof data?.class === "object" ? data.class : {}),
      ...(typeof data?.class === "string" ? { [data.class]: true } : {}),
      [style.cell]: true,
      [style.lastRow]: isLastRow,
      [style.lastColumn]: isLastColumn,
    }}
    ref={data?.ref}
  ></td>
);
