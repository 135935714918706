import { RouteAuthRequirementEnum } from "@/router/types";
import { IOrgModule } from "@/store";
import { Route, Location } from "vue-router";
import { persistanceService } from "../persistance-service";
import { UserStateEnum } from "./types";

export class UserRedirector {
  private orgModule: IOrgModule;

  constructor(orgModule: IOrgModule) {
    this.orgModule = orgModule;
  }

  private getCurrentOrgDashboardRoute() {
    return {
      name: "org-api-dashboard",
      params: { orgId: this.orgModule.id },
    };
  }

  private getAuthRelatedRedirect(
    isForAll: boolean,
    isNoAuthOnly: boolean,
    isAuthOnly: boolean,
    state: UserStateEnum,
    fullPath: string
  ) {
    if (isForAll) {
      return undefined;
    }

    switch (state) {
      case UserStateEnum.Authenticated: {
        if (isNoAuthOnly) {
          const activeRouteKey = persistanceService.getActiveRouteKey();
          if (activeRouteKey) {
            return {
              path: activeRouteKey,
            };
          }
          return this.getCurrentOrgDashboardRoute();
        }
        break;
      }
      case UserStateEnum.NotConfirmed: {
        return { name: "signup-code-entry" };
      }
      case UserStateEnum.NoRoles: {
        return { name: "workspace-setup" };
      }
      case UserStateEnum.FailedToGetRoles:
      case UserStateEnum.NotAuthenticated: {
        if (isAuthOnly) {
          persistanceService.setActiveRouteKey(fullPath);
          return { name: "login" };
        }
      }
    }

    return undefined;
  }

  private getStandardRedirect(state: UserStateEnum, to: Route) {
    switch (to.name) {
      case "home": {
        switch (state) {
          case UserStateEnum.Authenticated:
            return this.getCurrentOrgDashboardRoute();
          case UserStateEnum.NoRoles:
            return { name: "workspace-setup" };
          case UserStateEnum.NotConfirmed: {
            return { name: "signup-code-entry" };
          }
          case UserStateEnum.FailedToGetRoles:
          case UserStateEnum.NotAuthenticated:
            return { name: "login" };
          default:
            return undefined;
        }
      }
      case "api-details-overview": {
        const apiId = to.params?.apiId;

        if (!apiId) {
          return this.getCurrentOrgDashboardRoute();
        }

        return {
          name: "api-details-data",
          params: { apiId },
        };
      }
      default:
        return undefined;
    }
  }

  public getRedirectBasedOnState(
    state: UserStateEnum,
    to: Route
  ): Location | undefined {
    const isForAll = to.matched.some(
      (record) => record.meta?.authMode === RouteAuthRequirementEnum.all
    );

    const isNoAuthOnlyRoute = to.matched.some(
      (record) => record.meta?.authMode === RouteAuthRequirementEnum.noAuthOnly
    );
    const isAuthOnlyRoute = !isNoAuthOnlyRoute;

    const redirectResult = this.getAuthRelatedRedirect(
      isForAll,
      isNoAuthOnlyRoute,
      isAuthOnlyRoute,
      state,
      to.fullPath
    );

    if (redirectResult) {
      return redirectResult;
    }

    return this.getStandardRedirect(state, to);
  }
}
