import { Vue, Component, Watch } from "vue-property-decorator";
import { FormModule } from "@/store/modules/form";
import { UserModule } from "@/store/modules/user";
import { authManager } from "@/utils/auth-service/auth-manager";
import FormEmail from "@/components/forms/inputs/FormEmail.component.vue";
import FormPassword from "@/components/forms/inputs/FormPassword.component.vue";
import { Loader } from "@/components/shared/Loader.component";
import { UIModule } from "@/store/modules/ui";
@Component({
  components: {
    FormEmail,
    FormPassword,
    Loader,
  },
})
export default class LoginForm extends Vue {
  showFeedback = false;
  email = (this.$route.query.email as string) ?? "";
  password = "";
  formInputError = "";

  get errorMessage() {
    return UserModule.authError || this.formInputError;
  }

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  get credentials(): { username: string; password: string } {
    return {
      username: this.email,
      password: this.password,
    };
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get isLoading() {
    return UserModule.isLoading;
  }

  get isLoginButtonDisabled() {
    return this.isLoading;
  }

  get loaderVariant() {
    return UIModule.isLightTheme ? "light" : "dark";
  }

  private onKeydown(event: KeyboardEvent) {
    if (!event.metaKey && !event.shiftKey && !event.ctrlKey) {
      this.formInputError = "";
      UserModule.resetAuthError();
    }
  }

  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  }

  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeydown);
  }

  async onSubmit() {
    try {
      if (!this.email || !this.password) {
        throw new Error(this.$t("signup_error_invalid_parameter").toString());
      }

      await authManager.login(this.credentials);
    } catch (err) {
      this.formInputError = (err as Error).message;
    }
  }

  @Watch("isAuthenticated")
  authenticated(): void {
    if (this.isAuthenticated) {
      FormModule.setEmail("");
      FormModule.setPassword("");
    }
  }
}
