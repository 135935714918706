import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import { CognitoUserSession, CognitoUser } from "amazon-cognito-identity-js";
import store from "@/store/store";
import { SubscriptionHandler } from "@/lib/subscription-handler";
import { UserModule } from "./user";
import _get from "lodash/get";
import { PublishingStateEnum } from "@/api/common-types";

export interface IGraphapiState {
  accessTokens: { [apiId: string]: string };
  states: { [apiId: string]: PublishingStateEnum };
}

@Module({ dynamic: true, store, name: "graphapi", namespaced: true })
class Graphapi extends VuexModule implements IGraphapiState {
  private subscriptions: { [apiId: string]: SubscriptionHandler } = {} as {
    [apiId: string]: SubscriptionHandler;
  };
  public accessTokens: { [apiId: string]: string } = {} as {
    [apiId: string]: string;
  };
  public apiUsers: { [apiId: string]: CognitoUser | null } = {} as {
    [apiId: string]: CognitoUser | null;
  };
  public states: { [apiId: string]: PublishingStateEnum } = {} as {
    [apiId: string]: PublishingStateEnum;
  };
  public reloadRequired: { [apiId: string]: boolean } = {} as {
    [apiId: string]: boolean;
  };

  @Mutation
  private SET_ACCESS_TOKENS(accessTokens: { [key: string]: string }): void {
    this.accessTokens = accessTokens;
  }

  @Mutation
  private SET_API_USERS(apiUsers: { [key: string]: CognitoUser | null }): void {
    this.apiUsers = apiUsers;
  }

  @Mutation
  private SET_SUBSCRIPTIONS(subscriptions: {
    [key: string]: SubscriptionHandler;
  }): void {
    this.subscriptions = subscriptions;
  }

  @Mutation
  private SET_STATES(states: { [apiId: string]: PublishingStateEnum }) {
    this.states = states;
  }

  @Mutation
  private SET_RELOAD_REQUIRED(reloadRequired: { [apiId: string]: boolean }) {
    this.reloadRequired = reloadRequired;
  }

  @Action
  async reloadApi(apiId: string) {
    this.SET_RELOAD_REQUIRED({ ...this.reloadRequired, [apiId]: true });
  }

  @Action
  async initializeTokenEntry(apiId: string) {
    if (!(apiId in this.accessTokens)) {
      this.SET_ACCESS_TOKENS(
        Object.assign({}, this.accessTokens, { [apiId]: "" })
      );
    }
  }

  @Action
  initializeStateEntry(apiState: {
    apiId: string;
    state: PublishingStateEnum;
  }) {
    this.SET_STATES(
      Object.assign({}, this.states, { [apiState.apiId]: apiState.state })
    );
  }

  @Action
  initializeStateSubscription(apiState: {
    apiId: string;
    state: PublishingStateEnum;
  }) {
    if (!(apiState.apiId in this.states)) {
      this.SET_STATES(
        Object.assign({}, this.states, { [apiState.apiId]: apiState.state })
      );
    }

    if (!(apiState.apiId in this.subscriptions)) {
      const query = `{"query":"subscription ON_UPDATE_API($id: ID) {\\n onUpdateApi(id: $id) {\\n __typename\\n id\\n state\\n apiKey\\n }\\n }","variables":{ "id": "${apiState.apiId}"}}`;

      const subscriptionHandler = new SubscriptionHandler({
        host: process.env.VUE_APP_GRAPHQL_REALTIME_HOST!,
        accessToken: UserModule.accessToken,
        query: query,
        realtimeEndpoint: process.env.VUE_APP_GRAPHQL_REALTIME_ENDPOINT!,
        onMessageHandler: (data: { [key: string]: string }) => {
          apiState.state = _get(
            data,
            "data.onUpdateApi.state"
          ) as PublishingStateEnum;
          this.SET_STATES(
            Object.assign({}, this.states, { [apiState.apiId]: apiState.state })
          );
          if (
            apiState.state === "PUBLISHED" ||
            apiState.state === "PUBLISHING_FAILED"
          ) {
            this.reloadRequired[apiState.apiId] = true;
            this.subscriptions[apiState.apiId]?.stopListening();
            delete this.subscriptions[apiState.apiId];
          }
        },
      });

      this.SET_SUBSCRIPTIONS(
        Object.assign({}, this.subscriptions, {
          [apiState.apiId]: subscriptionHandler,
        })
      );
    }
  }

  @Action handleApiReloaded(apiId: string) {
    const { [apiId]: reloadedApi, ...rest } = this.reloadRequired;
    this.SET_RELOAD_REQUIRED(rest);
  }

  @Action
  handleSignInResponse(response: {
    apiId: string;
    apiUser: CognitoUser | null;
  }): Promise<void> {
    return new Promise((res) => {
      response.apiUser?.getSession(
        (error: Error, session: CognitoUserSession | null) => {
          if (error) {
            console.log(error);
          } else {
            this.SET_API_USERS({
              ...this.apiUsers,
              [response.apiId]: response.apiUser,
            });

            const cognitoAccessToken = session?.getAccessToken();
            this.SET_ACCESS_TOKENS({
              ...this.accessTokens,
              [response.apiId]: cognitoAccessToken?.getJwtToken() ?? "",
            });
          }
          res();
        }
      );
    });
  }

  @Action
  handleAccessTokenProvision({
    apiId,
    accessToken,
  }: {
    apiId: string;
    accessToken: string;
  }): void {
    this.SET_ACCESS_TOKENS({
      ...this.accessTokens,
      [apiId]: accessToken,
    });
  }

  @Action
  handleSignOut(): void {
    this.SET_ACCESS_TOKENS({});
    this.SET_API_USERS({});
    this.SET_STATES({});
    this.SET_SUBSCRIPTIONS({});
  }

  @Action
  handleSignOutForApiWithId(apiId: string): void {
    delete this.accessTokens[apiId];
    delete this.apiUsers[apiId];
    this.SET_RELOAD_REQUIRED(
      Object.assign({}, this.reloadRequired, { [apiId]: true })
    );
  }

  @Action
  public updateToken({ apiId, newToken }: { apiId: string; newToken: string }) {
    try {
      this.SET_ACCESS_TOKENS({ ...this.accessTokens, [apiId]: newToken ?? "" });
      return newToken;
    } catch (err) {
      return null;
    }
  }
}

export const GraphapiModule = getModule(Graphapi);

export type IGraphapiModule = typeof GraphapiModule;
