export const modelReadonlyFieldKeys = [
  "createdAt",
  "updatedAt",
  "__typename",
] as const;

export interface Metadata {
  key: string;
  value: any;
}
export interface MetadataObj {
  [key: string]: Metadata;
}

export enum FieldDirectiveEnum {
  CONNECTOR = "@connector",
  UNIQUE = "@unique",
  SORTBY = "@sortby",
  REQUIRED = "@required",
  REQUIRED_ON_CREATE = "@required_oncreate",
  REQUIRED_ON_UPDATE = "@required_onupdate",
  REQUIRED_ON_DELETE = "@required_ondelete",
  USER_EMAIL = "@user_email",
  USER_PASSWORD = "@user_password",
}

export enum ObjectDirectiveEnum {
  PUBLIC_READ = "@public_read",
  GROUP = "@group_read",
  PUBLIC_CREATE = "@public_create",
  PUBLIC_UPDATE = "@public_update",
  PUBLIC_DELETE = "@public_delete",
  USERS = "@users",
}

export interface IGraphQlError {
  errorType: string;
  message: string;
}

export {
  ApiCreationType as ApiCreationTypeEnum,
  ArchiveFormatType as ArchiveFormatTypeEnum,
  AuthProviderType as AuthProviderTypeEnum,
  AuthMethodType as AuthMethodTypeEnum,
  AwsRegion as AwsRegionEnum,
  CurrencyType as CurrencyTypeEnum,
  BillingInterval as BillingIntervalEnum,
  BillingScheme as BillingSchemeEnum,
  PublishingState as PublishingStateEnum,
  PublishRunStatus as PublishRunEnum,
  UsageType as UsageTypeEnum,
  UnitType as UnitTypeEnum,
  LanguageCode as LanguageCodeEnum,
  BundleType as BundleTypeEnum,
  ProductType as ProductTypeEnum,
  RoleType as RoleTypeEnum,
  PermissionType as PermissionTypeEnum,
  TrustedEntityType as TrustedEntityTypeEnum,
  SchemaTemplateState as SchemaTemplateStateEnum,
} from "@/generated/types";
