import { ArchiveFormatTypeEnum } from "@/api/common-types";
import { ISchemaExplorerType } from "@/api/schema";
import { AccessControlType } from "@/generated/types";
import { ApolloClient, gql, MutationOptions } from "apollo-boost";
import { ApiMutation } from "../shared/api-mutation";
import { MutationTypeEnum } from "@graphapi-io/api-declaration";

export interface IUploadFileIntentVariables {
  input: {
    accessControl: AccessControlType;
    key: string;
    mimeType?: string;
  };
}

export interface IUploadInlineFileVariables {
  input: {
    accessControl: AccessControlType;
    archiveFormat: ArchiveFormatTypeEnum;
    body: string;
    isCloudFunction?: boolean;
    key: string;
    mimeType?: string;
  };
}

export class DedicatedApiFileMutations {
  private customerApiMutation: ApiMutation;
  public objectTypes: Record<string, ISchemaExplorerType>;

  constructor(
    objectTypes: Record<string, ISchemaExplorerType>,
    private apolloClient: ApolloClient<unknown>
  ) {
    this.objectTypes = objectTypes;
    this.customerApiMutation = new ApiMutation(
      MutationTypeEnum.CREATE,
      this.apolloClient
    );
  }

  public async uploadFileIntent(
    variables: IUploadFileIntentVariables
  ): Promise<{
    key: string;
    mimeType: string;
    uploadUrl: string;
    name: string;
    url: string;
  }> {
    const mutation = gql`
      mutation ($input: FileInput!) {
        uploadFileIntent(input: $input) {
          key
          mimeType
          uploadUrl
          url
          name
        }
      }
    `;

    return this.customerApiMutation.mutate(
      mutation,
      variables as any,
      "uploadFileIntent"
    );
  }

  public async uploadInlineFile(
    variables: IUploadInlineFileVariables,
    updateCache?: MutationOptions<any>["update"]
  ): Promise<{
    key: string;
    mimeType: string;
    url: string;
    name: string;
  }> {
    const mutation = gql`
      mutation uploadInlineFile($input: InlineFileInput!) {
        uploadInlineFile(input: $input) {
          id
          createdAt
          updatedAt
          key
          mimeType
          name
          url
        }
      }
    `;

    return this.customerApiMutation.mutate(
      mutation,
      variables as any,
      "uploadInlineFile",
      updateCache
    );
  }
}
