import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import plusIconDark from "@/assets/img/plus-icon-thin.svg";
import plusIconLight from "@/assets/img/plus-icon-thin-light.svg";
import trashIconDark from "@/assets/img/icon-trash.svg";
import trashIconLight from "@/assets/img/icon-trash-light.svg";
import importIconDark from "@/assets/img/icon-import.svg";
import importIconLight from "@/assets/img/icon-import-light.svg";
import exportIconDark from "@/assets/img/icon-export.svg";
import exportIconLight from "@/assets/img/icon-export-light.svg";
import reloadIconBlack from "@/assets/img/icon-reload-black.svg";
import reloadIconWhite from "@/assets/img/icon-reload-white.svg";
import { ThemedImage } from "@/components/shared/ThemedImage.component";
import style from "./TableActions.component.module.scss";
import TableActionButton from "./TableActionButton.component";
import { Loader } from "../Loader.component";

const plusIconSrc = {
  light: plusIconLight,
  dark: plusIconDark,
};

const trashIconSrc = {
  light: trashIconDark,
  dark: trashIconLight,
};

const importIconSrc = {
  light: importIconDark,
  dark: importIconLight,
};

const exportIconSrc = {
  light: exportIconDark,
  dark: exportIconLight,
};

const reloadIconSrc = {
  light: reloadIconBlack,
  dark: reloadIconWhite,
};

@Component
export default class TableActions extends Vue {
  _tsx!: tsx.DeclareProps<{
    canDeleteItems?: TableActions["canDeleteItems"];
    canExport?: TableActions["canExport"];
    canAddItems?: TableActions["canAddItems"];
    canImport?: TableActions["canImport"];
    isLoading?: TableActions["isLoading"];
    withImport?: TableActions["withImport"];
    withExport?: TableActions["withExport"];
    withDelete?: TableActions["withDelete"];
    withDivider?: TableActions["withDivider"];
    withReload?: TableActions["withReload"];
    addActionCopy?: TableActions["addActionCopy"];
  }> &
    tsx.DeclareOnEvents<{
      onAddEntry: void;
      onImport: void;
      onExport: void;
      onReload: void;
      onDeleteEntries: void;
    }>;

  @Prop({ default: false }) canDeleteItems: boolean;
  @Prop({ default: false }) canExport: boolean;
  @Prop({ default: false }) canAddItems: boolean;
  @Prop({ default: false }) canImport: boolean;
  @Prop({ default: false }) isLoading: boolean;
  @Prop({ default: false }) withImport: boolean;
  @Prop({ default: false }) withExport: boolean;
  @Prop({ default: false }) withDelete: boolean;
  @Prop({ default: false }) withDivider: boolean;
  @Prop({ default: false }) withReload: boolean;
  @Prop({ required: false }) addActionCopy: string;

  @Emit("addEntry") onAddEntry() {}
  @Emit("deleteEntries") onDeleteEntries() {}
  @Emit("import") onImport() {}
  @Emit("export") onExport() {}
  @Emit("reload") onReload() {}

  public get addEntryButtonRef(): HTMLButtonElement {
    return (this.$refs.addEntryButton as TableActionButton).buttonRef;
  }

  render() {
    return (
      <div class={style.container}>
        <TableActionButton
          data-testid="addEntry"
          onClick={this.onAddEntry}
          disabled={!this.canAddItems}
          ref="addEntryButton"
          class={style.primaryButton}
        >
          <ThemedImage src={plusIconSrc} class={style.icon} />
          <span>{this.addActionCopy ?? this.$t("global_cta_new")}</span>
        </TableActionButton>
        {this.withDelete && (
          <TableActionButton
            data-testid="deleteEntries"
            disabled={!this.canDeleteItems}
            onClick={this.onDeleteEntries}
          >
            <ThemedImage src={trashIconSrc} class={style.icon} />
            <span>{this.$t("global_cta_delete")}</span>
          </TableActionButton>
        )}
        {this.withDivider && <div class={style.divider} />}
        {this.$slots.default}
        {this.withReload && (
          <TableActionButton
            onClick={this.onReload}
            class={style.reloadButton}
            disabled={this.isLoading}
          >
            {this.isLoading ? (
              <Loader small />
            ) : (
              <ThemedImage src={reloadIconSrc} class={style.icon} />
            )}

            <span class={style.desktopOnly}>
              {this.$t("global_cta_reload")}
            </span>
          </TableActionButton>
        )}
        {this.withDivider && <div class={style.divider} />}
        {this.withImport && (
          <TableActionButton
            onClick={this.onImport}
            class={style.importButton}
            disabled={!this.canImport}
          >
            <ThemedImage src={importIconSrc} class={style.icon} />
            <span class={style.desktopOnly}>
              {this.$t("global_cta_import")}
            </span>
          </TableActionButton>
        )}
        {this.withExport && (
          <TableActionButton
            onClick={this.onExport}
            class={style.exportButton}
            disabled={!this.canExport}
          >
            <ThemedImage src={exportIconSrc} class={style.icon} />
            <span class={style.desktopOnly}>
              {this.$t("global_cta_export")}
            </span>
          </TableActionButton>
        )}
      </div>
    );
  }
}
