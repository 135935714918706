import { Vue, Component, Prop } from "vue-property-decorator";
import styles from "./Checkbox.component.module.scss";
import * as tsx from "vue-tsx-support";
import { SyntheticEvent } from "vue-tsx-support/types/dom";
import iconCheckboxDark from "@/assets/img/icon-checkbox.svg";
import iconCheckboxLight from "@/assets/img/icon-checkbox-light.svg";
import iconCheckboxCheckedDark from "@/assets/img/icon-checkbox-checked-reverse.svg";
import iconCheckboxCheckedLight from "@/assets/img/icon-checkbox-light-checked-reverse.svg";
import { ThemedImage } from "./ThemedImage.component";

const iconCheckbox = {
  dark: iconCheckboxDark,
  light: iconCheckboxLight,
};

const iconCheckboxChecked = {
  dark: iconCheckboxCheckedDark,
  light: iconCheckboxCheckedLight,
};

@Component
export class Checkbox extends Vue {
  _tsx!: tsx.DeclareProps<{
    checked: Checkbox["checked"];
    id: Checkbox["id"];
  }> &
    tsx.DeclareOnEvents<{
      onChange: SyntheticEvent<HTMLInputElement, Event>;
    }>;

  @Prop({ required: true }) checked: boolean;
  @Prop({ required: true }) id: string;

  render() {
    return (
      <label class={styles.container}>
        <input
          id={this.id}
          class={styles.input}
          type="checkbox"
          checked={this.checked}
          on={this.$listeners}
        />
        <ThemedImage
          src={this.checked ? iconCheckboxChecked : iconCheckbox}
          class={styles.icon}
          data-testid={`${this.id}CheckboxIcon`}
        />
        <span class={styles.label}>{this.$slots.default}</span>
      </label>
    );
  }
}
