import { Component } from "vue-property-decorator";
import { OrgModule } from "@/store/modules/org";
import { IOrgUpdateInput } from "@/generated/types";
import { updateOrg } from "@/models/org";
import ErrorHandling from "@/mixins/ErrorHandling.mixin";

@Component
export default class UpdateOrg extends ErrorHandling {
  isLoading = false;

  async updateOrg(getValidOrg: () => IOrgUpdateInput) {
    try {
      this.isLoading = true;
      this.errorMessage = "";
      const updateResponse = await updateOrg({
        orgUpdateInput: getValidOrg(),
      });

      OrgModule.handleQueryResponse(updateResponse);
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isLoading = false;
    }
  }
}
