import { Component, Vue } from "vue-property-decorator";
import { persistanceService } from "@/utils/persistance-service";
import { RouteAuthRequirementEnum } from "@/router/types";
import styles from "./NavbarBackButon.component.module.scss";
import backButtonBlack from "@/assets/img/icon-back.svg";
import backButtonWhite from "@/assets/img/icon-back-white.svg";
import { ThemedImage } from "@/components/shared/ThemedImage.component";

const backButtonSrc = {
  light: backButtonBlack,
  dark: backButtonWhite,
};
@Component
export class NavbarBackButton extends Vue {
  get isNoAuthOnlyPage() {
    return this.$route.meta?.authMode === RouteAuthRequirementEnum.noAuthOnly;
  }

  get showBackButton() {
    return this.$route.name && this.$route.params.orgId === undefined;
  }

  onBackButtonClick() {
    persistanceService.removeActiveRouteKey();
    this.$router.go(-1);
  }

  render() {
    return this.isNoAuthOnlyPage ? null : (
      <button
        class={{
          [styles.backButton]: true,
          [styles.hidden]: !this.showBackButton,
        }}
        onClick={this.onBackButtonClick}
      >
        <ThemedImage src={backButtonSrc} />
      </button>
    );
  }
}
