import { ISchemaExplorerType } from "@/api/schema";
import { ApiSchema } from "@/models/api-schema";
import { MutationTypeEnum } from "@graphapi-io/api-declaration";
import {
  ApolloQueryResult,
  MutationOptions,
  MutationUpdaterFn,
} from "apollo-boost";
import Observable from "zen-observable";
import { SortOrderType } from "@/generated/types";
import { IObjectTypeState } from "@/models/object-type/types";

export type ICustomerListVariables = {
  limit: number | undefined;
  nextToken: string | null;
  filter: unknown | undefined;
  sortOrder: SortOrderType | null;
};

export type ICustomerListByVariables = ICustomerListVariables & {
  id: string;
};

export type ISharedListByVariables = ICustomerListVariables & {
  parentId: string;
  parentKey: string;
};

export type ICustomerApiFieldConfig = {
  name: string;
  type: string;
};

export interface IResultParser {
  parseObjectQueryResult: (data: any, typeName: string) => any;
  parseListQueryResult: (items: any[], typeName: string) => any;
}

export interface IVariablesParser {
  parseVariablesForListByQuery: (
    variables: ICustomerListByVariables,
    typeName: string,
    parentType: string
  ) => Record<string, unknown>;

  buildMutationVariables: (
    type: MutationTypeEnum,
    variables: { input: Record<string, unknown> },
    typeName: string,
    typeId: string,
    namespace: string
  ) => { input: Record<string, unknown> };
}

export interface ICacheUpdatesHandler {
  addToListFrontAfterCreationFactory: (
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ) => MutationUpdaterFn<any>;

  addToListFrontAfterBatchCreationFactory: (
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ) => MutationUpdaterFn<any>;

  clearObjectsAfterBatchDeletionFactory: (
    objectType: IObjectTypeState
  ) => MutationUpdaterFn<any>;

  clearListAfterBatchDeletionFactory: (
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ) => MutationUpdaterFn<any>;

  clearAllAfterBatchDeletionFactory: (
    objectType: IObjectTypeState,
    variables: Record<string, unknown>
  ) => MutationUpdaterFn<any>;
}

export interface IGraphqlApi {
  apiSchema: ApiSchema;

  schemaFieldTypes: Record<string, ISchemaExplorerType>;

  cacheUpdates: ICacheUpdatesHandler;

  queryObject: (objectType: IObjectTypeState, id: string) => Promise<any>;

  watchQueryObject: (
    objectType: IObjectTypeState,
    id: string
  ) => Observable<object>;

  watchListObjects: <T = object>(
    objectType: IObjectTypeState,
    variables: ICustomerListVariables
  ) => {
    observable: Observable<{ items: T[]; nextToken: string | null }>;
    refetch: (
      variables: ICustomerListVariables
    ) => Promise<ApolloQueryResult<unknown>>;
    fetchMore: (
      variables: ICustomerListVariables
    ) => Promise<ApolloQueryResult<unknown>>;
  };

  watchListConnections: <T = object>(
    objectType: IObjectTypeState,
    parentObjectType: IObjectTypeState,
    variables: ICustomerListByVariables,
    fields?: string[]
  ) => {
    observable: Observable<{ items: T[]; nextToken: string | null }>;
    refetch: (
      variables: ICustomerListByVariables
    ) => Promise<ApolloQueryResult<unknown>>;
    fetchMore: (
      variables: ICustomerListByVariables
    ) => Promise<ApolloQueryResult<any>>;
  };

  createObject: (
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) => Promise<any>;

  batchCreateObjects: (
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown>[] },
    updateCache?: MutationOptions<any>["update"]
  ) => Promise<any>;

  updateObject: (
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) => Promise<any>;

  deleteObject: (
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown> },
    updateCache?: MutationOptions<any>["update"]
  ) => Promise<any>;

  batchDeleteObjects: (
    objectType: IObjectTypeState,
    variables: { input: Record<string, unknown>[] },
    updateCache?: MutationOptions<any>["update"]
  ) => Promise<any>;
}

export const ofType = (objectType: IObjectTypeState) => {
  return objectType?.usePrefix ? objectType.id : objectType.name;
};
