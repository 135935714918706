import {
  isNotNullable,
  lowerCaseFirstLetter,
  upperCaseFirstLetter,
} from "@/utils";
import _set from "lodash/fp/set";
import _sortBy from "lodash/sortBy";
import { hasConnectorDirective } from "./field-directives";
import {
  ConnectionCardinalityEnum,
  IConnectedTypeState,
  IObjectTypeState,
} from "./types";

export const getObjectTypeParentsCount = (type: IObjectTypeState) =>
  Object.values(type.parents).filter(Boolean).length;

export const canObjectTypeHaveMoreParents = (type: IObjectTypeState) =>
  getObjectTypeParentsCount(type) < 2;

export const getTypesWithTwoParents = (types: IObjectTypeState[]) =>
  types.filter((type) => !canObjectTypeHaveMoreParents(type));

export const getObjectTypeConnections = (objectType: IObjectTypeState) =>
  _sortBy(
    [
      ...Object.values(objectType.parents)
        .map((parent) => parent)
        .filter(isNotNullable)
        .map((parent) => ({
          id: parent.parentId,
          cardinality: ConnectionCardinalityEnum.IS_CHILD_OF,
          index: parent.connectionIndex,
        })),
      ...objectType.targets.map((target) => ({
        id: target.targetId ?? null,
        cardinality: ConnectionCardinalityEnum.IS_PARENT_OF,
        index: target.connectionIndex,
      })),
    ],
    "index"
  );

export const getAvailableConnectionTypes = (
  objectType: IObjectTypeState,
  allTypes: IObjectTypeState[],
  cardinality: ConnectionCardinalityEnum
): { type: IObjectTypeState; disabled: boolean }[] => {
  const connections = getObjectTypeConnections(objectType);

  return allTypes
    .filter((type) => objectType.id !== type.id)
    .map((type) => {
      const isAlreadyConnected = connections.some(
        (connection) => connection.id === type.id
      );

      if (isAlreadyConnected) {
        return { disabled: true, type };
      }

      if (cardinality === ConnectionCardinalityEnum.IS_PARENT_OF) {
        return { disabled: !canObjectTypeHaveMoreParents(type), type };
      }

      return {
        disabled: false,
        type,
      };
    });
};

export const extendObjectTypeWithConnectionIndexes = (
  objectType: IObjectTypeState
) => {
  let connectionIndex = 0;
  const parents = objectType.parents;
  const firstParent = parents.first;
  const secondParent = parents.second;

  if (firstParent?.parentId) {
    firstParent.connectionIndex = connectionIndex++;
  }

  if (secondParent?.parentId) {
    secondParent.connectionIndex = connectionIndex++;
  }

  objectType.targets.forEach((target) => {
    target.connectionIndex = connectionIndex++;
  });
};

const addNextParent = (
  parents: IObjectTypeState["parents"],
  parentId: string | null,
  connectionIndex: number
): IObjectTypeState["parents"] => {
  if (!parents.first) {
    return {
      first: { parentId, connectionIndex },
      second: parents.second,
    };
  }

  if (!parents.second) {
    return {
      first: parents.first,
      second: { parentId, connectionIndex },
    };
  }

  return parents;
};

const removeParentByConnectionIndex = (
  parents: IObjectTypeState["parents"],
  connectionIndex: number
): IObjectTypeState["parents"] => {
  if (parents.first?.connectionIndex === connectionIndex) {
    return _set("first", null, parents);
  }

  if (parents.second?.connectionIndex === connectionIndex) {
    return _set("second", null, parents);
  }

  return parents;
};

const updateParentIdByConnectionIndex = (
  parents: IObjectTypeState["parents"],
  connectionIndex: number,
  parentId: string | null
): IObjectTypeState["parents"] => {
  if (parents.first?.connectionIndex === connectionIndex) {
    return _set("first.parentId", parentId, parents);
  }

  if (parents.second?.connectionIndex === connectionIndex) {
    return _set("second.parentId", parentId, parents);
  }

  return parents;
};

const addTargetWithConnectionIndex = (
  targets: IObjectTypeState["targets"],
  connectionIndex: number,
  targetId: string | null = null
): IObjectTypeState["targets"] => {
  return [...targets, { connectionIndex, targetId }];
};

const updateTargetIdByConnectionIndex = (
  targets: IObjectTypeState["targets"],
  connectionIndex: number,
  targetId: string | null
): IObjectTypeState["targets"] => {
  const targetIndex = targets.findIndex(
    (target) => target.connectionIndex === connectionIndex
  );

  if (targetIndex === -1) {
    return targets;
  }

  return _set(targetIndex, { ...targets[targetIndex], targetId }, targets);
};

const removeTargetByConnectionIndex = (
  targets: IObjectTypeState["targets"],
  connectionIndex: number
): IObjectTypeState["targets"] => {
  return targets.filter((target) => target.connectionIndex !== connectionIndex);
};

export const addConnection = (
  objectType: IObjectTypeState,
  allTypes: IObjectTypeState[],
  connection: IConnectedTypeState
) => {
  if (connection.cardinality === ConnectionCardinalityEnum.IS_PARENT_OF) {
    objectType.targets.push({
      targetId: connection.id,
      connectionIndex: connection.index,
    });
    if (connection.id) {
      addParentToConnectedTarget(allTypes, objectType.id, connection.id);
    }
  } else {
    objectType.parents = addNextParent(
      objectType.parents,
      connection.id,
      connection.index
    );
    if (connection.id) {
      addTargetToConnectedParent(allTypes, objectType.id, connection.id);
    }
  }
};

const updateConnectionCardinality = (
  objectType: IObjectTypeState,
  allTypes: IObjectTypeState[],
  id: string | null,
  connectionIndex: number,
  newCardinality: ConnectionCardinalityEnum
) => {
  if (newCardinality === ConnectionCardinalityEnum.IS_PARENT_OF) {
    objectType.parents = removeParentByConnectionIndex(
      objectType.parents,
      connectionIndex
    );
    objectType.targets = addTargetWithConnectionIndex(
      objectType.targets,
      connectionIndex
    );
    if (id) {
      removeTargetFromConnectedParent(allTypes, objectType.id, id);
    }

    return;
  }

  objectType.parents = addNextParent(objectType.parents, null, connectionIndex);
  objectType.targets = removeTargetByConnectionIndex(
    objectType.targets,
    connectionIndex
  );
  if (id) {
    removeParentFromConnectedTarget(allTypes, objectType.id, id);
  }
};

const addParentToConnectedTarget = (
  allTypes: IObjectTypeState[],
  id: string,
  targetId: string
) => {
  const targetObjectType = allTypes.find((type) => type.id === targetId);

  if (!targetObjectType) {
    return;
  }
  const targetConnections = getObjectTypeConnections(targetObjectType);

  targetObjectType.parents = addNextParent(
    targetObjectType.parents,
    id,
    targetConnections.length
  );
};

const removeParentFromConnectedTarget = (
  allTypes: IObjectTypeState[],
  id: string,
  targetId: string
) => {
  const targetObjectType = allTypes.find((type) => type.id === targetId);

  if (!targetObjectType) {
    return;
  }
  const targetConnections = getObjectTypeConnections(targetObjectType);
  const connectionIndex = targetConnections.findIndex(
    (connection) => connection.id === id
  );

  const parents = removeParentByConnectionIndex(
    targetObjectType.parents,
    connectionIndex
  );

  const { newTargets, newParents } = decreaseConnectionIndexes(
    targetObjectType.targets,
    parents,
    connectionIndex
  );

  targetObjectType.targets = newTargets;
  targetObjectType.parents = newParents;
};

const addTargetToConnectedParent = (
  allTypes: IObjectTypeState[],
  id: string,
  parentId: string
) => {
  const parentObjectType = allTypes.find((type) => type.id === parentId);

  if (!parentObjectType) {
    return;
  }
  const parentConnections = getObjectTypeConnections(parentObjectType);

  parentObjectType.targets = addTargetWithConnectionIndex(
    parentObjectType.targets,
    parentConnections.length,
    id
  );
};

const removeTargetFromConnectedParent = (
  allTypes: IObjectTypeState[],
  id: string,
  parentId: string
) => {
  const parentObjectType = allTypes.find((type) => type.id === parentId);

  if (!parentObjectType) {
    return;
  }
  const parentConnections = getObjectTypeConnections(parentObjectType);

  const connectionIndex = parentConnections.findIndex(
    (connection) => connection.id === id
  );

  const targets = removeTargetByConnectionIndex(
    parentObjectType.targets,
    connectionIndex
  );

  const { newTargets, newParents } = decreaseConnectionIndexes(
    targets,
    parentObjectType.parents,
    connectionIndex
  );

  parentObjectType.targets = newTargets;
  parentObjectType.parents = newParents;
};

export const updateConnection = (
  objectType: IObjectTypeState,
  allTypes: IObjectTypeState[],
  currentConnection: IConnectedTypeState,
  newConnection: IConnectedTypeState
) => {
  const index = newConnection.index;
  const currentCardinality = currentConnection.cardinality;
  const newCardinality = newConnection.cardinality;

  if (currentCardinality !== newCardinality) {
    updateConnectionCardinality(
      objectType,
      allTypes,
      currentConnection.id,
      index,
      newCardinality
    );
    return;
  }

  if (currentConnection.cardinality === ConnectionCardinalityEnum.IS_CHILD_OF) {
    if (currentConnection.id) {
      removeTargetFromConnectedParent(
        allTypes,
        objectType.id,
        currentConnection.id
      );
    }
    objectType.parents = updateParentIdByConnectionIndex(
      objectType.parents,
      index,
      newConnection.id
    );
    if (newConnection.id) {
      addTargetToConnectedParent(allTypes, objectType.id, newConnection.id);
    }
    return;
  }

  if (currentConnection.id) {
    removeParentFromConnectedTarget(
      allTypes,
      objectType.id,
      currentConnection.id
    );
  }

  objectType.targets = updateTargetIdByConnectionIndex(
    objectType.targets,
    index,
    newConnection.id
  );

  if (newConnection.id) {
    addParentToConnectedTarget(allTypes, objectType.id, newConnection.id);
  }
};

const decreaseConnectionIndexes = (
  targets: IObjectTypeState["targets"],
  parents: IObjectTypeState["parents"],
  threshold: number
) => {
  const newTargets = targets.map((target) => {
    if (target.connectionIndex > threshold) {
      return { ...target, connectionIndex: target.connectionIndex - 1 };
    }

    return target;
  });

  let newParents = parents;

  if (parents.first && parents.first?.connectionIndex > threshold) {
    newParents = _set(
      "first.connectionIndex",
      parents.first.connectionIndex - 1,
      parents
    );
  }

  if (parents.second && parents.second?.connectionIndex > threshold) {
    newParents = _set(
      "second.connectionIndex",
      parents.second.connectionIndex - 1,
      newParents
    );
  }

  return { newTargets, newParents };
};

export const deleteConnection = (
  objectType: IObjectTypeState,
  allTypes: IObjectTypeState[],
  connection: IConnectedTypeState
) => {
  let parents = objectType.parents;
  let targets = objectType.targets;
  if (connection.cardinality === ConnectionCardinalityEnum.IS_CHILD_OF) {
    if (connection.id) {
      removeTargetFromConnectedParent(allTypes, objectType.id, connection.id);
    }
    parents = removeParentByConnectionIndex(parents, connection.index);
  } else {
    if (connection.id) {
      removeParentFromConnectedTarget(allTypes, objectType.id, connection.id);
    }
    targets = removeTargetByConnectionIndex(targets, connection.index);
  }

  const { newTargets, newParents } = decreaseConnectionIndexes(
    targets,
    parents,
    connection.index
  );

  objectType.targets = newTargets;
  objectType.parents = newParents;
};

export const getParentConnectionField = (parentType?: IObjectTypeState) => {
  return (
    parentType?.fields.find(hasConnectorDirective) ??
    parentType?.fields.find((field) => field.name === "id")
  );
};

export const getParentConnectionFieldName = (parentType?: IObjectTypeState) => {
  return getParentConnectionField(parentType)?.name ?? "id";
};

export const getParentConnectionFieldNameInChild = (
  parentType?: IObjectTypeState
) => {
  const fieldName = getParentConnectionFieldName(parentType);

  return `${lowerCaseFirstLetter(parentType?.name ?? "")}${upperCaseFirstLetter(
    fieldName
  )}`;
};
