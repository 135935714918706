import { FieldDirectiveEnum } from "@/api/common-types";
import { ISchemaExplorerType } from "@/api/schema";
import { IObjectTypeState } from "@/models";

export class MandatoryFieldsConfiguration {
  constructor(
    private readonly graphqlFieldTypes: Record<string, ISchemaExplorerType>,
    private readonly objectDefinition: IObjectTypeState | null
  ) {}

  public get createInputParam(): string {
    return `${this.objectDefinition?.name}Input`;
  }

  public get updateInputParam(): string {
    return `${this.objectDefinition?.name}UpdateInput`;
  }

  public get createInputFieldKeys(): Set<string> {
    const mandatoryFields = new Set<string>();

    if (this.objectDefinition?.fields) {
      this.objectDefinition?.fields.map((field) => {
        const required = field.directives?.includes(
          FieldDirectiveEnum.REQUIRED
        );
        const requiredOnCreate = field.directives?.includes(
          FieldDirectiveEnum.REQUIRED_ON_CREATE
        );
        if (requiredOnCreate || required) {
          mandatoryFields.add(field.name);
        }
      });
    }

    this.graphqlFieldTypes[this.createInputParam]?.inputFields?.forEach(
      (field) => {
        if (field?.type?.kind === "NON_NULL") {
          mandatoryFields.add(field.name);
        }
      }
    );

    return mandatoryFields;
  }

  public get updateInputFieldKeys(): Set<string> {
    const mandatoryFields = new Set<string>();

    if (this.objectDefinition?.fields) {
      this.objectDefinition?.fields.map((field) => {
        const required = field.directives?.includes(
          FieldDirectiveEnum.REQUIRED
        );
        const requiredOnUpdate = field.directives?.includes(
          FieldDirectiveEnum.REQUIRED_ON_UPDATE
        );
        if (requiredOnUpdate || required) {
          mandatoryFields.add(field.name);
        }
      });
    }

    this.graphqlFieldTypes[this.updateInputParam]?.inputFields?.forEach(
      (field) => {
        if (field?.type?.kind === "NON_NULL") {
          mandatoryFields.add(field.name);
        }
      }
    );

    return mandatoryFields;
  }
}
