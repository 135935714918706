import {
  mutationFunctionFactory,
  queryFunctionFactory,
} from "../internal-utils";
import CREATE_BILLING_ACCOUNT from "./create_billing_account.graphql";
import UPDATE_BILLING_ACCOUNT from "./update_billing_account.graphql";
import DELETE_BILLING_ACCOUNT from "./delete_billing_account.graphql";
import LIST_BILLING_ACCOUNTS_BY_ORG from "./list_billing_accounts_by_org.graphql";
import {
  ICreateBillingAccountMutation,
  ICreateBillingAccountMutationVariables,
  IDeleteBillingAccountMutation,
  IDeleteBillingAccountMutationVariables,
  IListBillingAccountsByOrgQuery,
  IListBillingAccountsByOrgQueryVariables,
  IUpdateBillingAccountMutation,
  IUpdateBillingAccountMutationVariables,
} from "@/generated/types";

export const createBillingAccountMutation = mutationFunctionFactory<
  ICreateBillingAccountMutation,
  ICreateBillingAccountMutationVariables
>(CREATE_BILLING_ACCOUNT);

export const updateBillingAccountMutation = mutationFunctionFactory<
  IUpdateBillingAccountMutation,
  IUpdateBillingAccountMutationVariables
>(UPDATE_BILLING_ACCOUNT);

export const deleteBillingAccountMutation = mutationFunctionFactory<
  IDeleteBillingAccountMutation,
  IDeleteBillingAccountMutationVariables
>(DELETE_BILLING_ACCOUNT);

export const listBillingAccountsByOrgQuery = queryFunctionFactory<
  IListBillingAccountsByOrgQuery,
  IListBillingAccountsByOrgQueryVariables
>(LIST_BILLING_ACCOUNTS_BY_ORG);
