import { Vue, Component, Prop } from "vue-property-decorator";
import { getTypedBFormRadioGroup } from "@/lib/typed-bootstrap";
import * as tsx from "vue-tsx-support";
import style from "./FormRadio.component.module.scss";

const BFormRadioGroup = getTypedBFormRadioGroup<string>();

@Component
export default class FormRadio extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: string;
    options: { value: string; text: string }[];
  }> &
    tsx.DeclareOnEvents<{
      onInput: string;
    }>;

  @Prop({ required: true }) value: string;
  @Prop({ required: true }) options: {
    value: string;
    text: string;
  }[];

  render() {
    return (
      <BFormRadioGroup
        id="radio-group"
        checked={this.value}
        options={this.options}
        class={style.radioGroup}
        on={this.$listeners}
      />
    );
  }
}
