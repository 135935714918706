import { Component, Watch } from "vue-property-decorator";
import { getPublishRun } from "@/models/publish-run";
import { IPublishRun } from "@/generated/types";
import { getErrorMessage, isUnchangedLeaf } from "@/utils";
import { LoadingScreen } from "@/components/shared/LoadingScreen.component";
import ApiPublish from "@/components/api-management/ApiPublish.component";
import { enumsDiffToChangesList } from "@/components/diff/enums-diff-to-changes-list";
import { objectTypesDiffToChangesList } from "@/components/diff/object-types-diff-to-changes-list";
import {
  getObjectTypeArraysDiff,
  IEnumState,
  IObjectTypeState,
  parseDeclarationSchema,
  publishApi,
} from "@/models";
import { getEnumArraysDiff } from "@/models/enum";
import DeleteModal from "@/components/modal/DeleteModal.component";
import ApiPublishedTypes from "@/components/api-management/ApiPublishedTypes.mixin";

@Component
export default class ApiRepublish extends ApiPublishedTypes {
  private publishRun: IPublishRun | null = null;
  private publishRunError: string = "";
  private publishRunLoading = false;
  private updateLoading = false;
  private updateError = "";

  get error() {
    return this.publishRunError || this.apiDataError || this.updateError;
  }

  get isQueryLoading() {
    return this.publishRunLoading || this.apiDataLoading;
  }

  private get previouslyPublishedTypes(): {
    enumTypes: IEnumState[];
    objectTypes: IObjectTypeState[];
  } {
    const oldSchema = this.publishRun?.schema;
    return parseDeclarationSchema(this.apiId, oldSchema);
  }

  private get enumsChangesList() {
    const enumTypesDiffResult = getEnumArraysDiff(
      this.publishedTypes.enumTypes,
      this.previouslyPublishedTypes.enumTypes
    );

    if (!isUnchangedLeaf(enumTypesDiffResult)) {
      return enumsDiffToChangesList(enumTypesDiffResult);
    }
    return undefined;
  }

  private get objectTypesChangesList() {
    const objectTypesDiffResult = getObjectTypeArraysDiff(
      this.publishedTypes.objectTypes,
      this.previouslyPublishedTypes.objectTypes
    );

    if (!isUnchangedLeaf(objectTypesDiffResult)) {
      return objectTypesDiffToChangesList(
        objectTypesDiffResult,
        this.previouslyPublishedTypes.objectTypes
      );
    }
    return undefined;
  }

  @Watch("$route.params.versionId", { immediate: true })
  async onVersionChange() {
    try {
      this.publishRunLoading = true;
      this.publishRun = await getPublishRun(
        this.$route.params.versionId,
        "cache-first"
      );
    } catch (err) {
      this.publishRunError = getErrorMessage(err);
    } finally {
      this.publishRunLoading = false;
    }
  }

  private onSubmit() {
    if (this.hasUnpublishedChanges) {
      this.$bvModal.show("delete-unsafed-changes-modal");
      return;
    }
    this.republish();
  }

  private async republish() {
    if (!this.apiData) return;
    try {
      this.updateLoading = true;
      await this.updateSchema(this.publishRun?.schema);
      await publishApi(
        this.apiData,
        this.apiData.state,
        this.apiData.products,
        this.publishRun?.schema
      );
      this.$router.push({
        name: "api-setup-publishing",
        params: { apiId: this.apiData.id },
      });
    } catch (err) {
      this.updateError = (err as Error).message;
    } finally {
      this.updateLoading = false;
    }
  }

  render() {
    const { schemaVersion } = this.publishRun ?? {};

    if (this.isQueryLoading) return <LoadingScreen />;

    return (
      <div>
        <ApiPublish
          errorMessage={this.error}
          ctaButtonText={this.$t("global_cta_publish").toString()}
          cancelButtonText={this.$t("global_cta_cancel").toString()}
          enumsChangesList={this.enumsChangesList}
          objectTypesChangesList={this.objectTypesChangesList}
          onSubmit={this.onSubmit}
          isLoading={this.updateLoading}
        >
          <template slot="header">
            <i18n path="api_republish_headline">
              <template slot="version">{schemaVersion}</template>
            </i18n>
          </template>
        </ApiPublish>
        <DeleteModal
          id="delete-unsafed-changes-modal"
          title={this.$t("api_republish_modal_title").toString()}
          info={this.$t("api_republish_modal_info").toString()}
          instruction={this.$t("api_republish_modal_instruction").toString()}
          name="republish"
          onConfirmDelete={this.republish}
        />
      </div>
    );
  }
}
