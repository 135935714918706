import { SelectionVector } from "@/utils/selection-vector";
import { ITableRange } from "./types";
import { ActivityState } from "./activity-state";

export class RowSelectionState {
  private minRowIndex: number;
  private maxRowIndex: number;
  private activityState: ActivityState;

  public selectedRows = new SelectionVector();

  constructor(
    { minRowIndex, maxRowIndex }: ITableRange,
    activityState: ActivityState
  ) {
    this.minRowIndex = minRowIndex;
    this.maxRowIndex = maxRowIndex;
    this.activityState = activityState;
  }

  public updateRanges({ minRowIndex, maxRowIndex }: ITableRange) {
    this.minRowIndex = minRowIndex;
    this.maxRowIndex = maxRowIndex;
  }

  public deselectAll() {
    this.selectedRows.clear();
  }

  public selectAllRows() {
    for (let i = this.minRowIndex; i <= this.maxRowIndex; i++) {
      this.selectedRows.add(i);
    }
  }

  public selectRowsByActiveRange() {
    const { rangeHeight } = this.activityState;
    const { row } = this.activityState.activeCellIndex;
    const rowStart = Math.min(row, row + rangeHeight);
    const rowEnd = Math.max(row, row + rangeHeight);

    for (let i = rowStart; i <= rowEnd; i++) {
      this.selectedRows.add(i);
    }
  }

  public toggleRowSelection(key: number) {
    if (this.selectedRows.has(key)) {
      this.selectedRows.delete(key);
    } else {
      this.selectedRows.add(key);
    }
  }

  public selectRow(key: number) {
    this.selectedRows.add(key);
  }

  public isRowSelected(key: number) {
    return this.selectedRows.has(key);
  }

  public deselectRow(key: number) {
    this.selectedRows.delete(key);
  }

  public get allSelected() {
    for (let i = this.minRowIndex; i <= this.maxRowIndex; i++) {
      if (!this.selectedRows.has(i)) {
        return false;
      }
    }
    return true;
  }
}
