import { DedicatedApi } from "./dedicated-api";
import { ICustomerListVariables } from "../shared/types";
import { IObjectTypeState } from "@/models/object-type/types";

export const ACTIVITY_TYPE = "Activity";

const activityType = (): IObjectTypeState => {
  return {
    id: "",
    name: ACTIVITY_TYPE,
    targets: [],
    parents: { first: null, second: null },
    fields: [],
    directives: [],
    resolvers: [],
    selected: false,
  };
};

export class CustomerApiForActivities {
  constructor(private dedicatedApi: DedicatedApi) {}

  watchActivities(variables: ICustomerListVariables) {
    return this.dedicatedApi.watchListObjects<{
      [key: string]: string | number | null | undefined;
    }>(activityType(), variables);
  }
}
