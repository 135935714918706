import _without from "lodash/without";
import _uniq from "lodash/uniq";

import { ObjectDirectiveEnum } from "@/api/common-types";
import { IObjectTypeState } from "./types";

const createUpdateObjectDirectives =
  (callback: (directives: string[]) => string[]) =>
  (object: IObjectTypeState) => {
    object.directives = callback(object.directives);
    return object;
  };

export const toggleObjectPublicRead = createUpdateObjectDirectives(
  (directives) => {
    const hasPublicReadDirective =
      directives?.includes(ObjectDirectiveEnum.PUBLIC_READ) ?? false;

    if (hasPublicReadDirective) {
      return _without(directives, ObjectDirectiveEnum.PUBLIC_READ);
    }

    return _without(
      _uniq([...directives, ObjectDirectiveEnum.PUBLIC_READ]),
      ObjectDirectiveEnum.GROUP
    );
  }
);

export const toggleObjectPublicCreate = createUpdateObjectDirectives(
  (directives) => {
    const hasPublicCreateDirective =
      directives?.includes(ObjectDirectiveEnum.PUBLIC_CREATE) ?? false;

    if (hasPublicCreateDirective) {
      return _without(directives, ObjectDirectiveEnum.PUBLIC_CREATE);
    }

    return _uniq([...directives, ObjectDirectiveEnum.PUBLIC_CREATE]);
  }
);

export const toggleObjectPublicUpdate = createUpdateObjectDirectives(
  (directives) => {
    const hasPublicUpdateDirective =
      directives?.includes(ObjectDirectiveEnum.PUBLIC_UPDATE) ?? false;

    if (hasPublicUpdateDirective) {
      return _without(directives, ObjectDirectiveEnum.PUBLIC_UPDATE);
    }

    return _uniq([...directives, ObjectDirectiveEnum.PUBLIC_UPDATE]);
  }
);

export const toggleObjectPublicDelete = createUpdateObjectDirectives(
  (directives) => {
    const hasPublicDeleteDirective =
      directives?.includes(ObjectDirectiveEnum.PUBLIC_DELETE) ?? false;

    if (hasPublicDeleteDirective) {
      return _without(directives, ObjectDirectiveEnum.PUBLIC_DELETE);
    }

    return _uniq([...directives, ObjectDirectiveEnum.PUBLIC_DELETE]);
  }
);

export const toggleObjectGroup = createUpdateObjectDirectives((directives) => {
  const hasGroupDirective =
    directives?.includes(ObjectDirectiveEnum.GROUP) ?? false;
  const hasPublicDirective =
    directives?.includes(ObjectDirectiveEnum.PUBLIC_READ) ?? false;

  if (hasGroupDirective || hasPublicDirective) {
    return _without(
      directives,
      ObjectDirectiveEnum.GROUP,
      ObjectDirectiveEnum.PUBLIC_READ
    );
  }

  return _uniq([...directives, ObjectDirectiveEnum.GROUP]);
});
