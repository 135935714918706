import { Vue, Component, Prop } from "vue-property-decorator";
import style from "./Button.component.module.scss";
import * as tsx from "vue-tsx-support";
import { ElementAttrs } from "vue-tsx-support/types/base";
import { ButtonHTMLAttributes, ElementType } from "vue-tsx-support/types/dom";

export enum ButtonThemeEnum {
  primary = "primary",
  secondary = "secondary",
  outline = "outline",
  naked = "naked",
}

export enum ButtonShapeEnum {
  pill = "pill",
  edgeToEdge = "edgeToEdge",
  square = "square",
}

export enum ButtonSizeEnum {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

@Component
export class Button extends Vue {
  _tsx!: tsx.DeclareProps<
    ElementAttrs<ElementType<HTMLButtonElement, ButtonHTMLAttributes>> & {
      theme?: Button["theme"];
      shape?: Button["shape"];
      size?: Button["size"];
    }
  > &
    tsx.DeclareOnEvents<{
      onClick: MouseEvent;
    }>;
  @Prop({ default: ButtonThemeEnum.secondary }) theme: ButtonThemeEnum;
  @Prop({ default: ButtonShapeEnum.pill }) shape: ButtonShapeEnum;
  @Prop({ default: ButtonSizeEnum.md }) size: ButtonSizeEnum;
  @Prop({ default: false }) disabled: boolean;

  $scopedSlots!: tsx.InnerScopedSlots<{ content: { isHovered: boolean } }>;
  private isHovered = false;

  private get className() {
    return {
      [style.button]: true,
      [style.themePrimary]: this.theme === ButtonThemeEnum.primary,
      [style.themeSecondary]: this.theme === ButtonThemeEnum.secondary,
      [style.themeOutline]: this.theme === ButtonThemeEnum.outline,
      [style.themeNaked]: this.theme === ButtonThemeEnum.naked,
      [style.shapePill]: this.shape === ButtonShapeEnum.pill,
      [style.shapeEdgeToEdge]: this.shape === ButtonShapeEnum.edgeToEdge,
      [style.shapeSquare]: this.shape === ButtonShapeEnum.square,
      [style.sizeXs]: this.size === ButtonSizeEnum.xs,
      [style.sizeSm]: this.size === ButtonSizeEnum.sm,
      [style.sizeMd]: this.size === ButtonSizeEnum.md,
      [style.sizeLg]: this.size === ButtonSizeEnum.lg,
      [style.sizeXl]: this.size === ButtonSizeEnum.xl,
    };
  }

  private get shouldHandleHover() {
    return !!this.$scopedSlots.content;
  }

  private handleMouseEnter(ev: MouseEvent) {
    this.isHovered = true;
    this.$emit("mouseenter", ev);
  }

  private handleMouseLeave(ev: MouseEvent) {
    this.isHovered = false;
    this.$emit("mouseleave", ev);
  }

  render() {
    return (
      <button
        class={this.className}
        disabled={this.disabled}
        on={{
          ...this.$listeners,
          ...(this.shouldHandleHover
            ? {
                mouseenter: this.handleMouseEnter,
                mouseleave: this.handleMouseLeave,
              }
            : {}),
        }}
      >
        {this.shouldHandleHover
          ? this.$scopedSlots.content({ isHovered: this.isHovered })
          : this.$slots.default}
      </button>
    );
  }
}
