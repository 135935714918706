import { v4 as uuid4 } from "uuid";

export interface ISubscriptionHandlerProps<T> {
  readonly host: string;

  readonly realtimeEndpoint: string;

  readonly accessToken: string;

  readonly query: string;

  readonly onMessageHandler?: (data: T) => void;

  readonly onErrorHandler?: (error: Error) => void;
}

export class SubscriptionHandler<T = { [key: string]: string }> {
  private readonly props: ISubscriptionHandlerProps<T>;

  private header: string;

  private payload: string = "{}";

  private subscriptionId: string;

  private subscription: string;

  public readonly connection: WebSocket;

  constructor(props: ISubscriptionHandlerProps<T>) {
    this.props = props;
    this.prepareSubscriptionHeader();
    this.prepareSubscription();
    this.connection = new WebSocket(
      `${this.props.realtimeEndpoint}?header=${this.header}&payload=${btoa(
        this.payload
      )}`,
      "graphql-ws"
    );
    this.connection.onopen = () => {
      this.connection.send(JSON.stringify({ type: "connection_init" }));
    };

    this.connection.onerror = (event: Event) => {
      console.log(`WebSocket error: ${event}`);
      if (this.props.onErrorHandler) {
        this.props.onErrorHandler(new Error("WebSocket error"));
      }
    };

    this.connection.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      if (data.type === "connection_error" && this.props.onErrorHandler) {
        this.props.onErrorHandler(new Error(data.payload.errors[0].message));
      } else if (data.type === "connection_ack") {
        this.connection.send(this.subscription);
      } else if (data.type === "data" && this.props.onMessageHandler) {
        this.props.onMessageHandler(data.payload);
      } else if (data.type === "complete") {
        this.connection.close();
      }
    };
  }

  private prepareSubscriptionHeader() {
    const header = {
      Authorization: this.props.accessToken,
      host: this.props.host,
    };

    this.header = btoa(JSON.stringify(header));
  }

  private prepareSubscription() {
    this.subscriptionId = uuid4();
    this.subscription = JSON.stringify({
      id: this.subscriptionId,
      payload: {
        data: this.props.query,
        extensions: {
          authorization: {
            Authorization: this.props.accessToken,
            host: this.props.host,
          },
        },
      },
      type: "start",
    });
  }

  public stopListening() {
    this.connection.send(
      JSON.stringify({ type: "stop", id: this.subscriptionId })
    );
  }
}
