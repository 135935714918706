import { Vue, Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";

@Component
export default class FormPassword extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: FormPassword["value"];
    id?: FormPassword["id"];
    placeholderText: FormPassword["placeholderText"];
    shouldValidate?: FormPassword["shouldValidate"];
    isDisabled?: FormPassword["isDisabled"];
    label?: FormPassword["label"];
    autocompleteType?: FormPassword["autocompleteType"];
  }> &
    tsx.DeclareOnEvents<{
      onInput: string;
    }>;
  @Prop({ required: true }) value: string;
  @Prop({ default: "passwordInput" }) readonly id: string;
  @Prop({ required: true }) readonly placeholderText: string;
  @Prop({ default: false }) readonly shouldValidate: boolean;
  @Prop({ required: false, default: false }) readonly isDisabled: boolean;
  @Prop({ default: "" }) readonly label!: string;
  @Prop({ default: "current-password" }) readonly autocompleteType: string;

  inputLabel = this.label || this.$t("global_password");

  get internalValue(): string {
    return this.value;
  }

  set internalValue(password: string) {
    this.$emit("input", password);
  }

  get validation(): boolean | null {
    return this.internalValue?.length >= 1
      ? this.internalValue?.length >= 12
      : null;
  }
}
