import DropdownsState, {
  DropdownStateEnum,
} from "@/mixins/DropdownsState.mixin";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class CollapsibleSection extends DropdownsState {
  @Prop() id: string;

  toggleId = "collapsibleSection";

  get isOpen() {
    return this.dropDownState[this.toggleId] === DropdownStateEnum.shown;
  }
}
