import {
  ICellDataContent,
  IItem,
  ITableField,
  ReservedFieldsEnum,
} from "../types";
import { Element } from "vue-tsx-support/types/base";
import { renderEditCell } from "./EditCell.component";
import { renderPlaceholderCell } from "./PlaceholderCell.component";
import { renderSelectionCell } from "./SelectionCell.component";
import { renderCell, ICellProps } from "./Cell.component";
import { CreateElement } from "vue";
import { renderRow } from "./Row.component";
import CellInEditMode from "./CellInEditMode.component";
import OptionsCell from "./OptionsCell.component";
import { getInputTypeForFieldType } from "../utils";
import { ComplexFieldTypeEnum } from "@/models";
import { renderUrlCell } from "./UrlCell.component";
import JSONCellInEditMode from "./JSONCellInEditMode.component";

export interface ICellData {
  handleClick: (ev: MouseEvent) => void;
  handleChange: (value: ICellDataContent) => void;
  handleDblClick: (ev: MouseEvent) => void;
  ref: string;
  content: Element | string | undefined;
  rawContent: ICellDataContent;
  absoluteIndex: number;
  cacheKey: string;
  item: IItem;
  field: ITableField;
}

export interface ICellState extends ICellProps {
  isFirstColumn: boolean;
  isLastRow: boolean;
  isRowSelected: boolean;
  isPrevRowSelected: boolean;
  isNextRowSelected: boolean;
  isInEditMode: boolean;
  isInEditReplaceMode: boolean;
}

export class BodyRenderer {
  private handleMouseDown(ev: MouseEvent) {
    if (ev.shiftKey) {
      ev.preventDefault();
    }
  }

  private renderCell(
    h: CreateElement,
    cellData: ICellData,
    cellState: ICellState
  ) {
    const { field } = cellData;
    const hasOptions = field.options && field.options?.length > 0;
    const data = {
      ref: cellData.ref,
      key: field.key,
    };
    const listeners = {
      click: cellData.handleClick,
      mousedown: this.handleMouseDown,
      dblclick: cellData.handleDblClick,
    };
    switch (field.key) {
      case ReservedFieldsEnum.selected:
        return renderSelectionCell(h, {
          props: {
            isCellSelected: cellState.isRowSelected,
            isBottomCellSelected: cellState.isNextRowSelected,
            isTopCellSelected: cellState.isPrevRowSelected,
          },
          data,
          listeners,
          children: cellData.content,
        });
      case ReservedFieldsEnum.edit:
        return renderEditCell(h, {
          props: {
            isCellSelected: cellState.isRowSelected,
            index: cellData.absoluteIndex,
            isBottomCellSelected: cellState.isNextRowSelected,
            isTopCellSelected: cellState.isPrevRowSelected,
            isFirstColumn: cellState.isFirstColumn,
          },
          data,
          listeners,
        });
      case ReservedFieldsEnum.addColumn:
        return renderPlaceholderCell(h, {
          props: { isLastRow: cellState.isLastRow, isLastColumn: false },
          data,
        });
      case ReservedFieldsEnum.placeholder:
        return renderPlaceholderCell(h, {
          props: { isLastRow: cellState.isLastRow, isLastColumn: true },
          data,
        });
      default: {
        if (cellState.isInEditMode) {
          return field.type === ComplexFieldTypeEnum.Json ? (
            <JSONCellInEditMode
              onCellChange={cellData.handleChange}
              cellState={cellState}
              value={cellData.content}
              rawValue={cellData.rawContent}
              inputType={getInputTypeForFieldType(cellData.field.type)}
              replaceModeEnabled={cellState.isInEditReplaceMode}
            />
          ) : hasOptions ? (
            <OptionsCell
              onCellChange={cellData.handleChange}
              cellState={cellState}
              value={cellData.content}
              rawValue={cellData.rawContent}
              options={cellData.field.options}
            />
          ) : (
            <CellInEditMode
              onCellChange={cellData.handleChange}
              cellState={cellState}
              value={cellData.content}
              rawValue={cellData.rawContent}
              inputType={getInputTypeForFieldType(cellData.field.type)}
              replaceModeEnabled={cellState.isInEditReplaceMode}
            />
          );
        }
        const defaultCellArgs = {
          props: { ...cellState, hasOptions },
          data,
          children: cellData.content,
          listeners,
        };

        return field.type === ComplexFieldTypeEnum.Url
          ? renderUrlCell(h, {
              ...defaultCellArgs,
              props: {
                ...defaultCellArgs.props,
                url: cellData.rawContent as string,
              },
            })
          : renderCell(h, defaultCellArgs);
      }
    }
  }

  private renderRow(
    h: CreateElement,
    rowIndex: number,
    startIndex: number,
    computedCellsData: ICellData[],
    computedSelectionState: ICellState[]
  ) {
    const item = computedCellsData[0].item;

    const newElement = renderRow(h, {
      props: { index: rowIndex + startIndex },
      data: { key: (item["id"] ?? rowIndex).toString() },
      children: computedCellsData.map((cellData, colIndex) =>
        this.renderCell(h, cellData, computedSelectionState[colIndex])
      ),
    });

    return newElement;
  }

  public render(
    h: CreateElement,
    startIndex: number,
    computedCellsData: ICellData[][],
    computedSelectionState: ICellState[][]
  ) {
    return computedCellsData.map((_, rowIndex) =>
      this.renderRow(
        h,
        rowIndex,
        startIndex,
        computedCellsData[rowIndex],
        computedSelectionState[rowIndex]
      )
    );
  }
}
