import { Vue, Component, Watch } from "vue-property-decorator";
import { OrgModule } from "@/store";
import { getOrg, IOrgState } from "@/models/org";
import { LoadingScreen } from "@/components/shared/LoadingScreen.component";
import Navbar from "@/components/navbar/Navbar.component.vue";
import style from "./OrgManagement.layout.module.scss";
import OrgManagementNavigation from "@/components/org-management/OrgManagementNavigation.component";

@Component({ name: "OrgManagement" })
export default class OrgManagement extends Vue {
  get routerOrgId() {
    return this.$route.params.orgId;
  }

  get storeOrgId() {
    return OrgModule.id;
  }

  get isLoading() {
    return OrgModule.isLoading;
  }

  @Watch("routerOrgId", { immediate: true }) async onRouterOrgIdChange() {
    if (
      this.routerOrgId &&
      this.routerOrgId !== this.storeOrgId &&
      !this.isLoading
    ) {
      OrgModule.handleOrgLoadingStarted();
      const orgData: IOrgState = await getOrg(this.routerOrgId);
      OrgModule.handleQueryResponse(orgData);
    }
  }

  render() {
    return (
      <div class={style.container}>
        <Navbar
          scopedSlots={{
            secondaryNavbar: ({ onLinkClick }) => (
              <OrgManagementNavigation onLinkClick={onLinkClick} />
            ),
          }}
          showSecondaryNav={true}
        />
        <div class="content">
          {!this.isLoading && <router-view />}
          {this.isLoading && <LoadingScreen />}
        </div>
      </div>
    );
  }
}
