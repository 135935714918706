import { Vue, Component } from "vue-property-decorator";
import FormEmail from "@/components/forms/inputs/FormEmail.component.vue";
import { UserModule } from "@/store/modules/user";
import { updateUserMutation } from "@/api/user";
import { FormModule } from "@/store/modules/form";
import { auth } from "@/utils/auth-service";

@Component({
  components: {
    FormEmail,
  },
})
export default class ChangeEmailForm extends Vue {
  email = "";
  errorMessage = "";
  form = null;
  verificationCode = null;
  isLoading = false;

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  created() {
    this.email = UserModule.email ?? "";
  }

  async onSubmit(): Promise<void> {
    this.isLoading = true;

    try {
      await auth.updateUserAttributes({ email: this.email });
      await updateUserMutation({ id: UserModule.userId, email: this.email });
      FormModule.setEmail(this.email);
      this.$router.push({ name: "account-verify-email" });
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }

    this.isLoading = false;
  }
}
