import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import {
  getTypedBFormSelect,
  getTypedBFormSelectOption,
  IBFormSelectEvents,
  IBFormSelectOption,
} from "@/lib/typed-bootstrap";
import styles from "./CsvSeparatorSelect.component.module.scss";

const BFormSelect = getTypedBFormSelect<string>();
const BFormSelectOption = getTypedBFormSelectOption<string>();

@Component
export default class CsvSeparatorSelect extends Vue {
  _tsx!: tsx.DeclareProps<{
    actions: CsvSeparatorSelect["actions"];
    selectedAction: CsvSeparatorSelect["selectedAction"];
    disabled?: CsvSeparatorSelect["disabled"];
  }> &
    tsx.DeclareOnEvents<IBFormSelectEvents<string>>;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: () => [] }) actions: IBFormSelectOption<string>[];
  @Prop({ default: null }) selectedAction: string | null;

  render() {
    return (
      <BFormSelect
        class={styles.csvSelect}
        disabled={this.disabled}
        value={this.selectedAction}
        options={this.actions}
        on={this.$listeners}
      >
        <template slot="first">
          <BFormSelectOption value={null} disabled>
            {this.$t("form_select_csv_option")}
          </BFormSelectOption>
        </template>
      </BFormSelect>
    );
  }
}
