import * as tsx from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { BAlert, BForm, BRow } from "@/lib/typed-bootstrap";
import styles from "./WorkspaceSetupName.view.module.scss";
import WizardStep from "@/components/wizard/WizardStep.component";
import BillingAccount from "@/mixins/BillingAccount.mixin";
import ErrorHandling from "@/mixins/ErrorHandling.mixin";
import LabeledTextInput from "@/components/shared/LabeledTextInput.component";
import {
  Button,
  ButtonShapeEnum,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "@/components/shared/Button.component";
import { mixins } from "vue-class-component";
import { UserModule } from "@/store/modules/user";
import { mapRolePermissionConnection } from "@/models/roles";
import { createOrgWithOwner } from "@/models/org";
import { OrgModule } from "@/store";
import { v4 as uuid4 } from "uuid";
import { Loader } from "@/components/shared/Loader.component";
import { userDataLoader } from "@/utils/auth-service";

interface IOrgSetupInputState {
  name: string;
}

@Component
export default class WorkspaceSetupName extends mixins(
  BillingAccount,
  ErrorHandling
) {
  isLoading = false;
  form = null;

  @Prop({ required: false, default: false }) showCloseButton: boolean;

  orgInput: IOrgSetupInputState = {
    name: "",
  };

  private onNameInput(value: string) {
    this.orgInput.name = value ?? "";
  }

  private async onSubmit(): Promise<void> {
    try {
      this.isLoading = true;
      OrgModule.initializeOrg(uuid4());
      const { role, org } = await createOrgWithOwner(
        OrgModule.id,
        UserModule.email,
        this.validatedOrgInput(this.orgInput)
      );

      if (org) {
        OrgModule.handleOrgCreation(org);
        this.upsertBillingAccount({
          name: org.name ?? "",
          orgId: org.id,
          billingEmail: UserModule.email,
          paymentProviderId: "",
        });
      }

      if (role) {
        UserModule.handleRoleConnections([mapRolePermissionConnection(role)]);
      }

      OrgModule.handleQueryResponse(org);
      await UserModule.onUserAction(async () => {
        userDataLoader.refreshToken();
        await userDataLoader.loading;
      });

      this.$router.push({
        name: "org-api-dashboard",
        params: { orgId: org.id },
      });
    } catch (err) {
      this.errorMessage = (err as any).message;
    } finally {
      this.isLoading = false;
    }
  }

  private validatedOrgInput(input: IOrgSetupInputState) {
    if (input.name?.length <= 0) {
      throw new Error(this.$t("error_org_requires_name").toString());
    }

    return input;
  }

  render() {
    return (
      <WizardStep showCloseButton={this.showCloseButton}>
        <template slot="wizardName">
          {this.$t("org_setup_wizard_name", { step: 1, stepCount: 1 })}
        </template>
        <template slot="header">{this.$t("org_setup_init_headline")}</template>
        <template slot="description">
          {this.$t("org_setup_init_subline")}
        </template>

        <BRow class="default__section">
          <BAlert
            show={this.showErrorMessage}
            variant="danger"
            data-testid="workspaceSetupFormError"
          >
            {this.errorMessage}
          </BAlert>

          <BForm
            ref="form"
            class="default__inline-form"
            accept-charset="UTF-8"
            role="form"
            novalidate
            onSubmit={tsx.modifiers.stop.prevent(this.onSubmit)}
          >
            <LabeledTextInput
              id="apiName"
              labelText={this.$t("form_input_label_workspace_name").toString()}
              placeholder={this.$t(
                "form_placeholder_workspace_name"
              ).toString()}
              value={this.orgInput.name}
              onInput={this.onNameInput}
              type="text"
              autocomplete="off"
              autoFocus
              data-testid="workspaceNameInput"
            />
            <Button
              type="submit"
              onClick={tsx.modifiers.prevent(this.onSubmit)}
              data-testid="continue-button"
              class={styles.submitButton}
              size={ButtonSizeEnum.lg}
              theme={ButtonThemeEnum.primary}
              shape={ButtonShapeEnum.square}
            >
              {this.isLoading ? (
                <Loader />
              ) : (
                <div class="button__text">{this.$t("form_cta_continue")}</div>
              )}
            </Button>
          </BForm>
        </BRow>
      </WizardStep>
    );
  }
}
