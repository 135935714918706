import { SimplifyType, UnwrappedPromise } from "@/utils/types";
import {
  extractAndFilterItemsOrProvideDefault,
  extractData,
  extractPropOrThrow,
} from "./utils";
import { createUserMutation, userQuery } from "@/api/user";
import {
  mapRolePermissionConnection,
  IRolePermissionMappableProperty,
} from "./roles";
import { FetchPolicy } from "apollo-boost";
import { IUserInput } from "@/generated/types";

export type IUserState = SimplifyType<
  UnwrappedPromise<ReturnType<typeof getUser>>
>;
export type IRoleState = SimplifyType<IUserState["roles"][number]>;

export async function getUser(id: string, fetchPolicy?: FetchPolicy) {
  const response = await userQuery({ id }, fetchPolicy);

  return mapUserRolesConnection(
    extractPropOrThrow(extractData(response), "user")
  );
}

export async function createUser(input: IUserInput) {
  const response = await createUserMutation({ input });

  return extractPropOrThrow(extractPropOrThrow(response, "data"), "createUser");
}

interface IUserRolePermissionMappableProperty {
  roleConnection?: {
    items?: (IRolePermissionMappableProperty | null)[] | null;
  } | null;
}

const mapUserRolesConnection = <T extends IUserRolePermissionMappableProperty>({
  roleConnection,
  ...rest
}: T) => {
  type IRoleConnection = T["roleConnection"];
  type IRoleConnectionItem = NonNullable<
    NonNullable<T["roleConnection"]>["items"]
  >[number];

  return {
    ...rest,
    roles: extractAndFilterItemsOrProvideDefault<
      IRoleConnection,
      IRoleConnectionItem
    >(roleConnection).map((role) =>
      mapRolePermissionConnection<typeof role>(role)
    ),
  };
};
