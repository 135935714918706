import style from "./SelectionCell.component.module.scss";
import { renderCell, ICellEvents, ICellProps } from "./Cell.component";
import { IRenderFn } from "@/utils/types";

type ISelectionCellProps = Pick<
  ICellProps,
  "isCellSelected" | "isBottomCellSelected" | "isTopCellSelected"
>;

export const renderSelectionCell: IRenderFn<
  ISelectionCellProps,
  ICellEvents
> = (h, { props, children, listeners, data }) =>
  renderCell(h, {
    props: {
      ...props,
      isLeftCellSelected: false,
      isRightCellSelected: false,
      isFirstDataColumn: false,
      isPrevCellSelection: false,
    },
    children,
    listeners,
    data: {
      ...data,
      class: {
        [style.cell]: true,
        [style.selected]: props.isCellSelected,
      },
    },
  });
