import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { BModal, IBModalEvents } from "@/lib/typed-bootstrap";
import style from "./Modal.component.module.scss";
import closeIconBlack from "@/assets/img/icon-close-black.svg";
import closeIconWhite from "@/assets/img/icon-close-white.svg";
import { ThemedImage } from "../shared/ThemedImage.component";
import { RenderContext } from "vue";

const closeIconSrc = {
  dark: closeIconWhite,
  light: closeIconBlack,
};

const Divider = Vue.extend({
  functional: true,
  render(_, { data: { class: cls, staticClass: sCls } }: RenderContext) {
    return <div class={[style.divider, cls, sCls]} />;
  },
});

const ActionButtons = Vue.extend({
  functional: true,
  render(
    _,
    { children, data: { class: cls, staticClass: sCls } }: RenderContext
  ) {
    return <div class={[style.actionButtons, cls, sCls]}>{children}</div>;
  },
});

@Component
export default class Modal extends Vue {
  static ActionButtons = ActionButtons;
  static Divider = Divider;

  _tsx!: tsx.DeclareProps<{
    id?: Modal["id"];
    initialTitle?: Modal["initialTitle"];
    visible?: Modal["visible"];
    dialogClass?: Modal["dialogClass"];
  }> &
    tsx.DeclareOnEvents<IBModalEvents>;
  $scopedSlots!: tsx.InnerScopedSlots<{
    body?: {
      cancel: () => void;
      visible: boolean;
      setTitle: (title: string) => void;
    };
  }>;

  @Prop({ required: true }) id: string;
  @Prop({ default: "" }) initialTitle: string;
  @Prop() visible?: boolean;
  @Prop({ default: "" }) dialogClass: string;

  private title = "";

  @Watch("initialTitle", { immediate: true }) private onInitialTitleChange() {
    this.title = this.initialTitle;
  }

  private setTitle(title: string) {
    this.title = title;
  }

  render() {
    return (
      <BModal
        id={this.id}
        size="md"
        modalClass={style.modal}
        contentClass={style.content}
        dialogClass={[style.dialog, this.dialogClass]}
        bodyClass={style.body}
        headerClass={style.header}
        footerClass={style.footer}
        visible={this.visible}
        on={this.$listeners}
        scopedSlots={{
          "modal-header": ({ close }) => (
            <div class={style.headerInner}>
              <h5 class={style.title}>{this.title}</h5>
              <button onClick={close} aria-label="Close" class={style.close}>
                <ThemedImage
                  class={style.closeButtonIcon}
                  src={closeIconSrc}
                  alt="Close"
                />
              </button>
            </div>
          ),
          default: (props) =>
            this.$scopedSlots.body &&
            this.$scopedSlots.body({
              ...props,
              setTitle: this.setTitle,
            }),
          "modal-footer": () => <span />,
        }}
      ></BModal>
    );
  }
}
