import { Vue, Component } from "vue-property-decorator";
import { FormModule } from "@/store/modules/form";
import { UserModule } from "@/store/modules/user";
import { auth } from "@/utils/auth-service";

@Component
export default class VerifyEmailForm extends Vue {
  public isLoading = false;

  errorMessage = "";
  form = null;
  emailVerificationCode = "";
  placeholderText = this.$t("placeholder_text_code_entry") as string;

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  async onSubmit(): Promise<void> {
    try {
      this.isLoading = true;
      await auth.verifyUserAttributeSubmit("email", this.emailVerificationCode);
      this.$router.push({ name: "account-settings" });
      UserModule.handleEmailUpdate(FormModule.email);
      this.isLoading = false;
    } catch (err) {
      this.errorMessage = (err as Error).message;
      this.isLoading = false;
    }
  }

  async resendConfirmation(): Promise<void> {
    try {
      this.emailVerificationCode = "";
      await auth.verifyUserAttribute("email", {
        email: FormModule.email,
      });
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }
  }
}
